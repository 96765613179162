import Constants from "./constants";
import Helper from "./helper";
import axios from "axios";

  const executeRequest = async (req, onUploadProgress = null,source = null ) => {
    const URL = Constants.HARMIX_BASE_URL + req.url;
    let headers = null;
    if(req?.headers){
      headers = req.headers
    }else{
      headers = {
        'x-preferred-language': "en", 
        'Content-Type': 'application/json'
      };
    }
    //adding no-cache
    headers['Cache-Control'] = 'no-cache';
    headers['Pragma'] = 'no-cache';
    headers['Expires'] =  '0';
    
    Helper.addAttributeToObject(headers, "api_key", Constants.HARMIX_API_KEY);
    let tempReqObj = {
      method: req.method,
      url: URL,
      headers: headers,
      data : req.data,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    }
    if(onUploadProgress)
      tempReqObj = {...tempReqObj, onUploadProgress};
    if(source?.token)
      tempReqObj.cancelToken = source.token;

    return await axios(tempReqObj).then(resp => {
      return resp?.data ? resp.data : resp;
    })
    .catch(err => {
      if (axios.isCancel(err)) {
        throw err;
      }
      throw err.response.data;
    });
  }


  

  
const HarmixService = { executeRequest }

export default HarmixService;