import React, { useContext, useMemo, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import useResponsiveFontSize from "../../services/responsiveFontSize";
import TrackContext from '../../contexts/track-context';
import API from '../../services/api';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#fff",
          letterSpacing: "0.025em",
          fontFamily: "synthese, Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );
  return options;
};

export default function SubscriptionForm(props) {

  const [cardError, setCardError] = useState({});
  const trackContext = useContext(TrackContext);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const checkCardNumber = (e) => {
    // console.log(e);
    if (e.error !== undefined) {
      setCardError(e.error)
    } else {
      setCardError({});
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)
    });
    // console.log("[PaymentMethod]", paymentMethod);
    // console.log("[error]", error);

    // If payment setup correctly
    // call create user function 
    if (error !== undefined) {
      // console.log('should not be called');
      // console.log(error.type);
      setCardError(error)
      // signupContext.handleSubmit(null);
    } 
    if (paymentMethod !== undefined) {
      // signupContext.handleSubmit(paymentMethod);
      // console.log(object);
      // console.log('else');
      // console.log(props.planChosen);
      // console.log(paymentMethod);
      // console.log(trackContext.userDetails)
      props.setOverlayLoader(true);

      API
      .createSubscription(props.planChosen, paymentMethod.id, trackContext.userDetails._id, props.tax.taxId)
      .then(resp => {
        props.setOverlayLoader(false);
        // console.log('res after creating subscription : ', resp);
        window.location = '/billing';
      })
    }
  };

  return (
    <>
      <input
        className='w-full bg-transparent p-2 mb-2 border border-gray-500 focus:outline-none'
        placeholder='Card Holder Name'
      />
      <label>
        <CardNumberElement
          disabled={true}
          className='p-2 border-gray-500 border text-white'
          options={options}
          onReady={() => {
            // console.log("CardNumberElement [ready]");
          }}
          onChange={event => checkCardNumber(event)}
          onBlur={() => {
            // console.log("CardNumberElement [blur]");
          }}
          onFocus={() => {
            // console.log("CardNumberElement [focus]");
          }}
        />
        {cardError.code === "incomplete_number" && <span className='text-red-600 text-xs mb-2'> {cardError.message} </span>}
      </label>
      <div className='flex mt-2'>
        <label className='w-1/4 mr-2'>
          <CardExpiryElement
            className='p-2 border-gray-500 border mb-2'
            options={options}
            onReady={() => {
              // console.log("CardNumberElement [ready]");
            }}
            onChange={event => {
              // console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              // console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              // console.log("CardNumberElement [focus]");
            }}
          />
          {cardError.code === "incomplete_expiry" && <span className='text-red-600 text-xs mb-2'> {cardError.message} </span>}
        </label>
        <label className='w-1/4'>
          <CardCvcElement
            className='p-2 border-gray-500 border mb-2'
            options={options}
            onReady={() => {
              // console.log("CardNumberElement [ready]");
            }}
            onChange={event => {
              // console.log("CardNumberElement [change]", event);
            }}
            onBlur={() => {
              // console.log("CardNumberElement [blur]");
            }}
            onFocus={() => {
              // console.log("CardNumberElement [focus]");
            }}
          />
          {cardError.code === "incomplete_cvc" && <span className='text-red-600 text-xs mb-2'> {cardError.message} </span>}
        </label>
      </div>
      <div className='flex justify-center p-4 pt-8'>
        <button
          onClick={handleSubmit}
          className='pt-2 pb-2 pl-4 pr-4 signin-btn font-bold text-sm '
          type='button'>
          SUBSCRIBE
        </button>
      </div>
    </>
  )
};