import React, {useEffect, useState } from "react";
import { TextField } from '@material-ui/core';
import Validator from "../../services/validator";
import CookieManager from "../../services/CookieManager";
import API from "../../services/api";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";

export default function CorporateUserForm(props){
    const [user, setUser] = useState({});
    const [helperText, setHelperText] = useState({})
    let validator = new Validator();
    const profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    const [saving, setSaving] = useState(false);

    useEffect(() => {
      let userData = {...user};
      userData.startDate = new Date().toISOString().substr(0, 10);
      if(profileData?.userType === "ADMIN"){
          userData.companyName = profileData.companyName;
          userData.taxNumber = profileData.taxNumber;
      }
      setUser(userData);
    },[])

    const handleInputChange = (e, key) => {
        let value = key === 'tos' ? e : e.target.value;
        if (key === 'first_name') {
          let res = validator.setValue(value).isNotEmpty();
          let temp = { ...helperText };
          if(!res?.status)
            temp[key] = "First name can not be blank";
          else
            temp[key] = "";
          setHelperText(temp);
        }
        if (key === 'email') {
          let res = validator.setValue(value).isNotEmpty().isEmail();
          let temp = { ...helperText };
          if(!res?.status)
            temp[key] = "Please Enter valid email";
          else
            temp[key] = "";
          setHelperText(temp);
        }
        if (key === 'companyName') {
          let res = validator.setValue(value).isNotEmpty();
          let temp = { ...helperText };
          if(!res?.status)
            temp[key] = "Please Enter valid company name";
          else
            temp[key] = "";
          setHelperText(temp);
        }
        if (key === 'totalLicenseCount') {
          let res = validator.setValue(value).isNotEmpty();
          let temp = { ...helperText };
          if(!res?.status)
            temp[key] = "Please Enter valid license count";
          else
            temp[key] = "";
          setHelperText(temp);
        }
        if (key === 'taxNumber') {
          let res = validator.setValue(value).isNotEmpty().isGSTIN();
          let temp = { ...helperText };
          if(!res?.status)
            temp[key] = res.mssg;
          else
            temp[key] = "";
          setHelperText(temp);
        }
        if (key === 'startDate') {
          let res = validator.setValue(value).isNotEmpty();
          let temp = { ...helperText };
          let startDate = new Date(value).getTime();
          let now = new Date();
          now.setUTCHours(0,0,0,0);
          now = now.getTime();
          if(!res?.status || startDate < now)
            temp[key] = "Please select valid date.";
          else
            temp[key] = "";
          setHelperText(temp);
        }
        let tempUser = { ...user };
        tempUser[key] = value;
        setUser(tempUser);
      }

      const createUser=()=>{
        setSaving(true);
        let counter = 0;
        let temp = {...helperText};
        let resFirstName = new Validator(user.first_name).isNotEmpty().isString();
        if(!resFirstName?.status){
          temp['first_name'] = "First name can not be blank";
          counter++;
        }
        else
          temp['first_name'] = "";
        let resEmail = new Validator(user.email).isNotEmpty().isString().isEmail();
        if(!resEmail?.status){
          temp['email'] = "Please Enter valid email";
          counter++;
        }
        else
          temp["email"] = "";
        let resCompanyName = new Validator(user.companyName).isNotEmpty();
        if(!resCompanyName?.status){
          temp['companyName'] = "Please Enter valid company name";
          counter++;
        }
        else
          temp["companyName"] = ""
        let resTaxNumber = new Validator(user.taxNumber).isNotEmpty().isGSTIN();
        if(!resTaxNumber?.status){
          temp['taxNumber'] = resTaxNumber.mssg;
          counter++;
        }
        else
          temp["taxNumber"] = "";
        let resTotalLicenseCount = new Validator(user.totalLicenseCount).isNotEmpty();
        if(!resTotalLicenseCount?.status && profileData?.userType === "SUPER_ADMIN"){
          temp['totalLicenseCount'] = "Please Enter valid license count";
          counter++;
        }
        else
          temp["totalLicenseCount"] = "";
        let resStartDate = new Validator(user.startDate).isNotEmpty();
        let startDate = new Date(user.startDate).getTime();
        let now = new Date();
        now.setUTCHours(0,0,0,0);
        now = now.getTime();
        if(!resStartDate?.status || startDate < now){
          temp['startDate'] = "Please Enter valid start date";
          counter++;
        }
        else
          temp["startDate"] = ""
        setHelperText(temp);

       if(counter===0)
       {
         let profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
         let userData={...user};

        if(profileData?.userType === "SUPER_ADMIN")
           userData.userType = "ADMIN"
        else if(profileData?.userType === "ADMIN"){
            userData.userType = "CORPORATE"
        }

        //setting admin ref
        if(profileData?.userType === "ADMIN" && profileData?.email?.length > 0)
            userData['admin'] = profileData.email;

         API.createCorporateAdmin({...userData}).then(data => {
            if(data?.success){
              toast(({ closeToast }) => <SuccessToast  message="User created successfully"/>);
              props.setShowAddNewUserForm(false);
              setUser({});
              props.fetchUserList();
            }else{
              toast(({ closeToast }) => <ErrorToast  message={data.mssg}/>);
            }
            setSaving(false);
          })
        }
        else
          setSaving(false);
    }
    

    return (
        <div 
        className='fixed top-0 w-full h-full left-0 justify-center flex' 
        style={{zIndex: 1, background: 'rgba(250,250,250,0.6)', backdropFilter: 'blur(5px)' }} >
        <div className='w-1/3 bg-white shadow-xl p-4 mt-20 mb-10 overflow-y-auto' style={{height: '650px'}}>
          <div className='flex'>
            <div className='text-lg font-semibold w-full'>Corporate Details</div>
            <button className='px-2' onClick={() => props.setShowAddNewUserForm(false)} >X</button>
          </div>
          <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>Full Name <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'first_name')}
            error={helperText.first_name !== undefined && helperText.first_name !== '' ? true : false}
            type="text"
            name="first_name"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.first_name}
            helperText={helperText.first_name}
          />
     </div>
          <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>Email Address <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'email')}
            error={helperText.email !== undefined && helperText.email !== '' ? true : false}
            type="text"
            name="email"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.email}
            helperText={helperText.email}
          />
          </div>
          <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>Company Name <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'companyName')}
            error={helperText.companyName !== undefined && helperText.companyName !== '' ? true : false}
            type="text"
            name="companyName"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.companyName}
            helperText={helperText.companyName}
            disabled = {profileData?.userType === "ADMIN"}
          />
          </div> <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>GST No. <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'taxNumber')}
            error={helperText.taxNumber !== undefined && helperText.taxNumber !== '' ? true : false}
            type="text"
            name="taxNumber"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.taxNumber}
            helperText={helperText.taxNumber}
            disabled = {profileData?.userType === "ADMIN"}
          />
          </div>
          {profileData?.userType === "SUPER_ADMIN" &&
          <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>Number Of Users <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'totalLicenseCount')}
            error={helperText.totalLicenseCount !== undefined && helperText.totalLicenseCount !== '' ? true : false}
            type="number"
            name="totalLicenseCount"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.totalLicenseCount}
            helperText={helperText.totalLicenseCount}
          />
          </div>}
          <div className='flex my-4 flex-wrap'>
            <label className='w-full text-xs text-gray-700'>Start Date <span style={{color: 'red'}}>*</span></label>
            <TextField variant="outlined"
            onChange={(e) => handleInputChange(e, 'startDate')}
            error={helperText.startDate !== undefined && helperText.startDate !== '' ? true : false}
            type="date"
            id = "subscription_startDate"
            defaultValue={new Date().toISOString().substr(0, 10)}
            inputProps ={{min : new Date().toISOString().substr(0, 10)}}
            name="startDate"
            className="w-full  outline-none border border-textGray-300 text-textGray-500"
            value={user?.startDate}
            helperText={helperText.startDate}
          />
          </div>
          <div>
            <button className='bg-black text-white py-2 px-6 text-sm' 
             onClick={createUser} 
             disabled = {saving}>
              Save
            </button>
          </div>
        </div>
      </div>
    )
}