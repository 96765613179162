import React, { useContext } from 'react';
import './style.css';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';
import TrackContext from '../../contexts/track-context';
import { Link } from 'react-router-dom';
import Voicegen from '../../components/Voicegen';


export default function AlertSubscribePopup(props) {
  let trackContext = useContext(TrackContext);
  console.log(trackContext.error)
  return (
    <div className='flex fixed top-0 h-screen w-full subscribe-modal justify-center' style={{zIndex: '9'}}>
      <div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' onClick={() => trackContext.setShowAlertPopups(false)}/>
        </div>
        
        <div className='border-b border-gray-300 px-10 pt-2'>
          <h1 className='text-lg uppercase font-semibold py-2'>Alert</h1>
          <h3 className='text-sm' style={{color: '#8C8C8C'}}>{trackContext.error}</h3>
          <p className='pt-6 pb-12 text-sm'>
         please fill the Data
          </p>
        </div>
        
      </div>
    </div> 
  )
}