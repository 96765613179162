
import React from "react";
import { useEffect, useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, id, value, setValue }) => {

  const [toggle, setToggle] = useState(value);
  useEffect(() => {
  }, [toggle]);

  return (
    <div className="w-full flex py-1">
      <span style={{fontSize:"15px"}}>{label}{" "}</span>
      <div className="toggle-switch px-2">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={toggle} onChange={() => {setValue(id, !toggle); setToggle(!toggle)}} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;