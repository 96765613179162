const CookieManager = {

	setCookie : (name,value,days) => {
    // console.log('setting cookie');
    // console.log({value});
		var expires = '';
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days*24*60*60*1000));
			expires = '; expires=' + date.toUTCString();
		}
		// document.cookie = name + '=' + (btoa(value) || '')  + expires + '; path=/';
		document.cookie = name + '=' + (value || '')  + expires + '; path=/';
	},

	getCookie : (name) => {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) === 0) {
				let res =  c.substring(nameEQ.length,c.length);
        // return simpleCrypto.decrypt(res);
        return res;
        // return atob(res);
			};
		}
		return null;
	},

	deleteCookie: (name) => {
		document.cookie = name+'=; Max-Age=-99999999;'; 
	}
};

export default CookieManager;