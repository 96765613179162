import React, { useEffect, useState } from 'react';

export default function SvgInline(props){
    const [svg, setSvg] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isErrored, setIsErrored] = useState(false);

    useEffect(() => {
        fetch(props.url)
            .then(res => res.text())
            .then((res) => {
                if(!res?.includes("<Error>"))
                    setSvg(res)
            })
            .catch((err) => {
                setIsErrored(true);
            })
            .then(() => setIsLoaded(true))
    }, [props.url]);

    useEffect(() => {
        if(svg?.length > 0){
        setSvg(svg.replace('fill="#000000"', 'fill="#b2b2b2"'));
        }
    },[svg])
    
    return (
        isErrored ? <></> : 
        <div 
            className={`w-full flex p-1 svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
            dangerouslySetInnerHTML={{ __html: svg }}
           
        />
    );
}