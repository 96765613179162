import Constants from "./constants";

class Validator {
	
  constructor( value, fieldName ) {
  	this.value = value;
    this.mssg = '';
    this.status = true;
    this.fieldName = fieldName;
  }
  
   setValue( val, currentFieldName ) {
    this.value = val;
    this.fieldName = currentFieldName;
    return this;
  }
  
  isNotEmpty() {
  	if ( !this.status ) {
    	return this;
    }
    
  	if ( String( this.value ).length === 0 || this.value === undefined ) {
    	this.mssg = this.fieldName + ' cannot be blank';
      this.status = false;
    	return this;
    } else {
      
    	return this;
    }
  }

  isNotEmptycheck() {
  	if ( !this.status ) {
    	return this;
    }
  	if ( this.value === false ) {
    	this.mssg = this.fieldName +'box '+' cannot be blank';
      this.status = false;
    	return this;
    } else {
      
    	return this;
    }
  }

  isString() {
  	if ( !this.status ) {
    	return this;
    }
		if ( typeof this.value === 'string' ) {
    	return this;
    } else {   
      this.mssg = this.fieldName + ' contains invalid input';
      this.status = false;
      return this;
    }
  }

  doesNotContainSpecialChars() {
    var format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if ( !this.status ) {
    	return this;
    }
    if ( format.test( this.value ) ) {
      this.mssg = 'Special characters are not allowed';
      this.status = false;
      return this;
    } else {
      return this;
    }
  }
  
  isEmail() {
  	if ( !this.status ) {
    	return this;
    }
    let val = ''+this.value;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if( re.test( val.toLowerCase() ) ) {
    	return this;
    } else {
    	this.mssg = 'Invalid Email';
      this.status = false;
      return this;
    }
  }

  checkPasswordStrength() {
    if ( !this.status ) {
    	return this;
    }
    let passLen;
    if ( this.value ) {
      let val = this.value;
      passLen = val.length;
    }

    if ( passLen >= process.env.REACT_APP_MIN_PASSWORD_LENGTH 
        && 
        String( this.value ).match( /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/ ) !== null ) {
      return this
    } else {
      this.mssg = 'Minimum of 8 characters, with atleast 1 uppercase character, 1 lowercase character and 1 digit';
      this.status = false;
      return this;
    }
  }
  isGSTIN() {
    var format = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    if ( !this.status ) {
    	return this;
    }
    if ( !format.test( this.value ) ) {
      this.mssg = 'Please Enter Valid GST Number';
      this.status = false;
      return this;
    } else {
      
      return this;
    }
  }

  isGSTINSOLO() {
    var format = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    if ( !this.status ) {
    	return this;
    }
    if ( !format.test( this.value ) ) {
      if( this.value === undefined || String( this.value ).length === 0 ) {
        this.mssg = '';
        this.status = false;
        return this;
      }
      this.mssg = 'Please Enter Valid GST Number';
      this.status = false;
      return this;
    } else {
      
      return this;
    }
  }

  isMandatory() {
    if ( !this.status ) {
    	return this;
    }
  	if( String( this.value ).length === 0 || this.value === undefined ) {
      this.mssg = this.fieldName + ' cannot be blank';
      this.status = false;
    	return this;

    } else {
    	return this;
    }
  }
}

export default Validator;