import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import API from '../../services/api';

export default function EmailVerification() {

  const [verifying, setIsVerifying] = useState(false);
  const [verificationMssg, setVerificationMssg] = useState('');
  let {token} = useParams();

  useEffect(() => {
    setIsVerifying(true);
    API.activateUser(token).then(resp => {
      // console.log({resp});
      setIsVerifying(false)
      if(resp.success) {
        setVerificationMssg(resp.mssg);
        setTimeout(() => {
          setVerificationMssg('Redirecting...');
          window.location = '/signin';
        }, 500)
      } else {
        setVerificationMssg(resp.mssg);
      }
    })
  }, []);

  return(
    <div className='container mx-auto justify-center m-20 text-center'>
      <h1 className='text-center text-2xl'>Email Verification</h1>
      {
        verifying && <h1 className='text-red-500 p-2'>Verifying User</h1>
      }
      {
        verificationMssg !== '' && <h1 className='text-green-500 p-2'>{verificationMssg}</h1>
      }
    </div>
  )
}