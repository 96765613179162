import React, { useEffect, useState, useContext } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import TrackContext from '../../contexts/track-context';
import dayjs from 'dayjs';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import CookieManager from '../../services/CookieManager';
import ConfirmationPopup from '../../pure-components/ConfirmationPopup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import axios from "axios";
import Helper from '../../services/helper';
import suspendedImg from "../../assets/red_card.png"
import canceledImg from "../../assets/canceled.png"
import { useLocation } from "react-router-dom";

export default function Billing(props) {

  let trackContext = useContext(TrackContext);
  const [billingInfo, setBillingInfo] = useState({});
  const [noPaymentMethodFound, setNoPaymentMethodFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [showUpdatePaymentMethod, setShowUpdatePaymentMethod] = useState(false);
  const [corporateUserRenewalDate, setCorporateUserRenewalDate] = useState('');
  const [showCancelSubConfirm, setShowCancelSubConfirm] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    // console.log({'billing context' : trackContext});
    (async () => {
      await getUser();
      if (trackContext.userDetails.userType !== 'CORPORATE USER') {
        await fetchBillingInfo();
        await fetchInvoices();
      } else {
        await fetchCorporateUserBillingInfo();
      }
    })();
    
  }, [location.pathname]);

  useEffect(()=>{
    //console.log("user details : ", trackContext.userDetails)
  },[trackContext.userDetails])

  const fetchCorporateUserBillingInfo = async () => {
    try{
      let billing = await API.getCorpUserBillingInfo(trackContext.userDetails._id);
      // console.log('corp billing : ', billing);
      if (billing.success) {
        // console.log('resds : ', billing);
        setCorporateUserRenewalDate(billing.data.renewalDate);
      } else {
        // console.log('err fetching billing info');
      }
    }catch(err){
      toast(({ closeToast }) => <ErrorToast message={"BillingInfoLoadingError : " + err?.message} />);
    }
  }

  const getUser = async () => {
    try{
      let user = await API.getUser();
      setLoading(false);
      //console.log('[get user] ', user);
      CookieManager.setCookie('user-data', JSON.stringify(user.data));
      trackContext.setUserDetails(user.data);
    }catch(err){
      toast(({ closeToast }) => <ErrorToast message={"UserDetailsLoadingError : " + err?.message} />);
    }
  } 

  const fetchBillingInfo = async() => {
    setLoading(true);
    await API.getBillingInfo(trackContext.userDetails._id).then(res => {
      setLoading(false)
      if (res.data.success) {
        //console.log('[res billing profile] : ', res);
        setBillingInfo(res.data.data);
      } else {
        setNoPaymentMethodFound(true);
      }
    })
    .catch(err => {
      toast(({ closeToast }) => <ErrorToast message={"BillingInfoLoadingError : " + err?.message} />);
    });
  }

  const cancelSubscription = async() => {

    await API.cancelSubscription(billingInfo.subscriptions.data[0].id, trackContext.userDetails._id)
    .then(res => {
      if (res.data.success) {
        toast(({ closeToast }) => <SuccessToast message={"Subscription Cancelled Successfully"} />);
        window.location = '/billing';
      }else{
        toast(({ closeToast }) => <ErrorToast message={"SubscriptionCancellationError : " + res.data.message} />);
      }
    })
    .catch(err => {
      toast(({ closeToast }) => <ErrorToast message={"SubscriptionCancellationError : " + err?.message} />);
    }).finally(() => {
      setShowCancelSubConfirm(false);
    })
  }

  const fetchInvoices = async() => {
    await API.getInvoices(trackContext.userDetails._id)
      .then((res) => {
        //console.log('res invoice : ', res);
        if (res?.data?.success) {
          // console.log('INSIDE IF');
          // console.log(res.data.data);
          setInvoices(res.data.data);
          // setInvoices(res.data.data.data);
        }else{
          toast(({ closeToast }) => <ErrorToast message={"InvoiceLoadingError : " +(res?.data?.mssg?.length ? res.data.mssg : res?.data?.message)} />);
        }
      })
      .catch(err => {
        toast(({ closeToast }) => <ErrorToast message={"InvoiceLoadingError : " + err?.message} />);
      });
  }

  const updatePaymentMethod = async (email, paymentMethodId) => {
    await API.updatePaymentMethod(email, paymentMethodId)
    .then(async res => {
      // console.log('[res after updating payment] ', res);
      if (res.data.success) {
        toast(({ closeToast }) => <SuccessToast message={res.data.message}/>);
        setShowUpdatePaymentMethod(false);
        await fetchBillingInfo();
        // console.log('[payment updated ... updating billing] ', newBillingDetail);
        await fetchInvoices();
      } else {
        setShowUpdatePaymentMethod(false);
        toast(({ closeToast }) => <ErrorToast message={res.data.message}/>);
      }
    })
    .catch(err => {
      toast(({ closeToast }) => <ErrorToast message={"PaymentMethodUpdateError : " + err?.message} />);     
    })
  }

  

  const closeSubscriptionForm = () => {
    setShowCancelSubConfirm(false);
  }
  
	const toggleYear = (e, currentTarget) => {
		e.stopPropagation();
		if (currentTarget.classList.contains('active')) {
			currentTarget.classList.remove('active');
		} else {
			currentTarget.className += ' active';
		}
	}

	const toggleMonth = (e, currentTarget) => {
		if ( currentTarget.classList.contains('active') ) {
			currentTarget.classList.remove('active');
		} else {
			currentTarget.className += ' active';
		}
	}

  const createCheckoutSession = async() => {
    const xAuth = CookieManager.getCookie("x-access-token");
    const profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    if(!Helper.isExist(profileData?.stripeCustomerId)){
      toast(({ closeToast }) => <ErrorToast message={"stripe customer Id not fount for the user, please contact your admin!"} />);
      return;      
    }
    let headers =  {
      'Content-Type': 'application/json',
      "x-host": process.env.REACT_APP_X_HOST,
      "x-access-token": xAuth,
      'Cache-Control': 'no-cache',
    };
    let data = {
      payment_method_types: ['card'],
      mode: 'setup',
      customer : profileData.stripeCustomerId,
      success_url: window.location.href,
      cancel_url: window.location.href,
    };

    await axios({
      url: process.env.REACT_APP_BASE_URL + "api/users/create-checkout-session",
      method: "post",
      headers: headers,
      data : data
    }).then(function (response) {
      // handle success
      var link = document.createElement('a');
      link.setAttribute('href', response.data.data.url);
      link.setAttribute('target', 'popup')
      link.click();
    })
    .catch(function (error) {
      console.log(error)
      toast(({ closeToast }) => <ErrorToast message={"Payment method update Error : " + error.response.data.mssg} />);
    });
  }

  return (
    <>
      {
        trackContext.userDetails.userType !== 'CORPORATE USER' ?
        <div className="lg:w-3/4">
          <div className="sm:mt-8 mb-4 ml-4 items-baseline self-center">
            <span className='w-full flex flex-wrap'>
              <h1 style={{ fontSize: "28px" }} className='font-bold text-black' > 
                {trackContext.userDetails.productDesc}
              </h1>
              {!trackContext.userDetails?.subscribed && 
                <img style={{height:"36px"}} alt="Suspended" src={suspendedImg} />}
              {trackContext.userDetails?.subscriptionStatus === "canceled" && 
                <img className='mt-1' style={{height:"36px"}} alt="Canceled" src={canceledImg} />}
            </span>
            <div className="md:flex w-full">
            <div className="w-full md:w-1/2 text-sm text-textGray-100"></div>
            <div className="w-full md:w-1/2 md:justify-end flex">
            <div className={`greenbuttonwide mx-3`}
            > 
             <a target="_self" href={process.env.REACT_APP_MKT_SITE_PRICING}>Change Plan</a>
            </div>
            </div>
            </div>
            </div>
            <div className="w-full ml-4 mb-20 pr-4">
              <div className='md:flex py-6 border-b border-textGray-300'>
                <div className='w-full md:w-1/4 text-sm '> Payment Method</div>
                <div className='w-full md:w-3/4 flex flex-wrap'>
                  <div className='w-3/4 text-sm self-center text-textGray-500'> 
                  {
                    loading ? 
                      <CircularProgress size='15px'/> :
                      noPaymentMethodFound ? <span>No Payment Method Found</span> : 
                      billingInfo?.card?.brand + ' card ending in ' + billingInfo?.card?.last4
                  }
                  </div>
                  {
                    !loading &&
                    billingInfo?.subscriptions?.data?.length !== 0 &&
                    <div className="w-full md:justify-end flex">
                    <button 
                      onClick={() => createCheckoutSession()}
                      className='w-1/4 m-2 greenbuttonwide'>
                      Update
                    </button>
                    </div>
                  }
                </div>
              </div>
              {!trackContext.userDetails?.subscribed ? 
                
                <div className='md:flex py-6 border-b border-textGray-300'>
                  <div className='w-full md:w-1/4 text-sm self-center'> Billing Interval</div>
                  <div className='w-full md:w-3/4 text-sm self-center text-textGray-500 flex'> 
                    <div className='flex-grow'>No subscriptions found</div>
                    <a target="_self" href={process.env.REACT_APP_MKT_SITE_PRICING} className='pinkbuttonwide'>
                      Subscribe
                    </a>
                  </div>
                </div>
                :
                trackContext.userDetails?.userType != "FREE" &&
                <>
                  <div className='md:flex py-6 border-b border-textGray-300'>
                    <div className='w-full md:w-1/4 text-sm self-center'> Billing Interval</div>
                    <div className='w-full md:w-3/4 text-sm self-center text-textGray-500 capitalize'>
                      {
                        (!loading && billingInfo?.subscriptions?.data?.length === 0) && 'No Billing Interval Found!'
                      }
                      {
                        billingInfo?.subscriptions?.data[0]?.plan?.interval === 'day' && 'daily, '
                      }
                      {
                        billingInfo?.subscriptions?.data[0]?.plan?.interval ===  'month' && 'monthly, ' 
                      } 
                      {
                        billingInfo?.subscriptions?.data[0]?.plan?.interval ===  'year' && 'yearly, '
                      } 
                      {
                        billingInfo?.subscriptions?.data[0]?.current_period_end !== undefined ?
                        (trackContext.userDetails?.subscriptionStatus === "canceled" ? 'Ends on ' : `Renews on `)
                        +`${dayjs.unix(billingInfo?.subscriptions?.data[0]?.current_period_end).add(1, 'day').format('DD MMM, YYYY') }`
                        :
                        <>{loading && <CircularProgress size='15px'/>}</>
                      }
                    </div>
                  </div>
                </>
              }
                  
              {
                trackContext.userDetails?.subscribed && !(trackContext.userDetails?.subscriptionStatus?.length > 0) && !(trackContext.userDetails.userType=="FREE") ? 
                <div className='flex py-6 justify-end'>
                  <button 
                    onClick={() => setShowCancelSubConfirm(true)}
                    // onClick={() => cancelSubscription()}
                    className='m-2 pinkbuttonwide'>
                    Cancel Subscription
                  </button>
                </div>
                :
                null
              }
              {(invoices?.invoiceCount && (trackContext.userDetails.userType !== 'FREE'))&&
                <div className='flex py-6 mt-6  '>
                  <div className='w-1/3 text-base self-center font-medium'> ALL INVOICES </div>
                </div>
              }
              {

                (invoices?.invoiceCount && (trackContext.userDetails.userType !== 'FREE')) ?
                invoices.year.map( year => 
									<div>
										<div className='accordion-header bg-ctaRed text-black text-sm font-bold cursor-pointer' onClick={(e) => toggleYear(e, e.currentTarget)}>
											<ArrowDropUpIcon className='up-arrow' fontSize="large" />
											<ArrowDropDownIcon className='down-arrow' fontSize="large" />
											{year}
										</div>
										<div className='accordion-body'>
											{
												Object.keys( invoices[`${year}_month`] ).map( (month, i) => 
													<React.Fragment key={i}>
														<div className='accordion-header flex text-white font-bold cursor-pointer' style={{backgroundColor: '#808080'}} onClick={(e) => toggleMonth(e, e.currentTarget)}>
															<div className='w-2/3'>
																<ArrowDropUpIcon className='up-arrow' fontSize="default" />
																<ArrowDropDownIcon className='down-arrow' fontSize="default" />
																{month}
															</div>
															<div>{invoices[`${year}_month_${month}_duration`]}</div>
														</div>
														<div className='accordion-body'>
														{
															invoices[`${year}_month`][month].map( (eachInvoices, index) =>
																<div className='flex pb-1' key={eachInvoices._id}>
                                   <div key={index} className="bg-white flex w-full justify-between">                                      
                                      <div className="text-textGray-100 p-0 self-center font-normal">  
                                         {dayjs.unix(eachInvoices.created).format('MMMM DD, YYYY')}
                                       </div>
                                       <div className='text-textGray-100 p-0 text-sm self-center font-normal'> 
                                         INR {eachInvoices.total/100}
                                       </div>
                                       <a href={eachInvoices.invoice_pdf} target='__blank'
                                      className='hidden md:block w-1/4 greenbuttonwide text-right '> 
                                      View Invoice
                                    </a>
                                  </div>  
																</div>
															)
														}
														</div>
													</React.Fragment>	
												)
											}
										</div>
									</div>
								)
                // Object.keys(invoices).map((key, index) => 
                //   { 
                //     return <>
                //     {<>
                      
                //       {// Year 
                //         <div key={index} className="bg-ctaRed text-white text-sm font-bold cursor-pointer w-full" onClick={() => toggleYear(index)}>
                //           {
                //             (key === 'year' ) && 
                //             (
                //               ( clicked === false ) ?
                //               <>
                //                 <ArrowDropUpIcon fontSize="large"/>
                //                 {invoices.year}  
                //               </>
                //             :
                //               <>
                //                 <ArrowDropDownIcon fontSize="large"/>
                //                 {invoices.year}                                                            
                //               </>
                //             )
                //           }
                //           { //Month
                //             clicked && 
                //             ( key !== 'year' && key !== 'invoiceCount' ) && 
                //             Object.keys(invoices[key]).map( ( key1, index ) => {
                //               return <>
                //               <div key={index} className="bg-symTextDarkGrey-dafault text-white font-bold"onClick={(e) => toggleMonth(e, key1)}>
                //                 {
                                  
                //                     (clickedMonth === false ) ?
                //                     <div className="flex flex-row w-full">                         
                //                       <ArrowDropUpIcon fontSize="default" />
                //                       {key1}
                //                     </div>
                //                     :
                //                     <>
                //                     <div className="flex flex-row w-full">                         
                //                       <ArrowDropDownIcon fontSize="default" />
                //                       {key1}
                //                     </div>
                                
                //                   {
                //                     Object.keys(invoices[key][key1]).map ( ( key2, index ) => {
                //                         return <>                                    
                //                         <div key={index} className="bg-white flex w-full justify-between">                                      
                //                           <div className="text-textGray-100 p-0 self-center font-normal">  
                //                             {dayjs.unix(invoices[key][key1][key2].created).format('MMMM DD, YYYY')}
                //                           </div>
                //                           <div className='text-textGray-100 p-0 text-sm self-center font-normal'> 
                //                             INR {invoices[key][key1][key2].total/100}
                //                           </div>
                //                           <a href={invoices[key][key1][key2].invoice_pdf} target='__blank'
                //                             className='hidden md:block w-1/4 pr-2 font-normal text-sm text-symLink cursor-pointer text-right '> 
                //                             View Invoice
                //                           </a>
                //                         </div>                                    
                //                       </>

                //                     })
                //                   }
                //                   </>                                
                //                 }
                //                 </div>
                //               </>
                //             })
                //           } 
                //         </div>
                //       }
                                          
                      
                //       </>
                //     }                                                                                                                        
                //     </>                                       
                  
                //   // <div key={index} className='flex py-2'>
                //   //   <div className='w-3/4 md:w-1/4 text-sm self-center text-textGray-100'>
                //   //     {
                //   //       dayjs.unix(invoice.created).format('MMMM DD, YYYY')
                //   //     }
                //   //   </div>
                //   //   <div className='w-1/4 md:w-2/4 self-center text-sm '> INR {invoice.total/100}</div>
                //   //   <a href={invoice.invoice_pdf} target='__blank'
                //   //     className='hidden md:block w-1/4 pr-2  text-sm text-symLink cursor-pointer text-right '> 
                //   //     View Invoice
                //   //   </a>
                //   // </div>
                // })
                :
                <div className={(invoices.invoiceCount !== undefined) ? 'text-red-500 flex' : 'text-red-500 hidden'}>
                  No invoices found
                </div>

                // (invoices.length && (trackContext.userDetails.userType !== 'FREE')) ?
                // invoices.map((invoice, index) => 
                //   <div key={index} className='flex py-2'>
                //     <div className='w-3/4 md:w-1/4 text-sm self-center text-textGray-100'>
                //       {
                //         dayjs.unix(invoice.created).format('MMMM DD, YYYY')
                //       }
                //     </div>
                //     <div className='w-1/4 md:w-2/4 self-center text-sm '> INR {invoice.total/100}</div>
                //     <a href={invoice.invoice_pdf} target='__blank'
                //       className='hidden md:block w-1/4 pr-2  text-sm text-symLink cursor-pointer text-right '> 
                //       View Invoice
                //     </a>
                //   </div>
                // )
                // :
                // <div>No invoices found</div>
              }
            </div>
          </div>
          :
          <div className='px-4 mt-8'>
            <h1 style={{ fontSize: "28px" }} className='font-bold text-black' > CORPORATE Plan</h1>
            <div className="flex w-full">
              <div className="w-1/2 text-sm text-textGray-100">
                
              </div>
            </div>
            <div className='flex py-6 mt-4'>
              <div className='w-1/4 text-sm self-center'> Billing Interval</div>
              <div className='w-3/4 text-sm self-center text-textGray-500 flex'> 
              <div className='flex-grow'>
                Ends On {dayjs(corporateUserRenewalDate).format('DD MMM, YYYY')}
              </div>
              </div>
            </div>
          </div>
      }
      {
        showCancelSubConfirm &&
        <div>
          <ConfirmationPopup
          title={process.env.REACT_APP_CANCEL_SUBSC_MSG}
           yesAction={cancelSubscription}
           cancelAction={closeSubscriptionForm}
          />
        </div>
      }
    </>
  )
}
