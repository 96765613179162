import React, { useContext } from "react";
import TrackContext from "../../contexts/track-context";
import { ReactComponent as Play } from "../../assets/play.svg";
import { ReactComponent as Pause } from "../../assets/pause.svg";
import { ReactComponent as CreateNewFolderIcon } from "../../assets/folder.svg";
import { ReactComponent as GetAppIcon } from "../../assets/download.svg";
import { CardContent, Menu, MenuItem } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnFavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ReactComponent as Activedownload } from "../../assets/activedownload.svg";
import API from "../../services/api";
import { ReactComponent as ThumbPrint } from "../../assets/auto_fix_high_black_18dp.svg";
import CookieManager from "../../services/CookieManager";
import { useHistory } from "react-router-dom";
import Helper from "../../services/helper";
import Constants from "../../services/constants";
import SvgInline from "../../pure-components/SvgInLine";
import { toast } from "react-toastify";
import { ErrorToast } from "../../pure-components/CustomToast";
import MewoService from "../../services/mewoService";

export default function AlternateTracks(props) {
  let trackContext = useContext(TrackContext);
  const history = useHistory();
  
  const handleThumbPrintSearch = async(trackId, title) => {
    
      let filter=trackContext.trackFilter
      filter.selectedChildTags=[]
      filter.selectedTags=[]
      filter.url=""
      filter.searchId="";
      filter.fileName="";
      filter.searchText = "";
      filter.searchTitle = title;
      filter.trackId=trackId;
      trackContext.setTrackFilter(filter);
      localStorage.setItem("filter_music" , JSON.stringify(filter))
      trackContext.setShowLoaderMagic(true);
      trackContext.setTrackData([]);
      
      if(trackContext.path.startsWith("/search")||
        trackContext.path==="/"|| 
        trackContext.path.startsWith("/albums") ||
        trackContext.path.startsWith("/artist") || 
        trackContext.path.startsWith("/playlists"))
      {
        history.push( `/search/track/`); 
      }

      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": "",
          "options": 
          {
            trackId : trackId,
            "filters": {
              ...trackContext.searchTags,
              exclude : []
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      await MewoService.loadMewoData(reqObj).then(res => {
        if(res){
          trackContext.setShowLoaderMagic(false);
          trackContext.setTrackData(res.hits);
          trackContext.setTotaltracks(res.total.value);
        }else{
          Helper.handleError(res);
        }
      }).catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={"Track Loading Error : " + err?.response?.data?.message}/>);
      }).finally(() => {
        trackContext.setShowLoaderMagic(false);
      });
  };
  return (
    <>
      {props.alternateVersions.map((alternateVersion, i) => (
        <div className="flex w-full" key={alternateVersion.id}>
          <div className="self-center play-button p-1">
            <div
              aria-label="play/pause"
              className="p-0 outline-none focus:outline-none play-button border-gray-300 cursor-pointer"
              onClick={() => props.fetchTrack(alternateVersion.id, true)}
            >
              {trackContext.playPause &&
                alternateVersion.id === trackContext.currentTrackDetail.id ? (
                <Pause className="outline-none" />
              ) : (
                <Play className="outline-none" />
              )}
            </div>
          </div>
          <div className="w-3/12 self-center px-2 py-1">
            <div className="track-title">
              {String(alternateVersion?.display_title).toLowerCase()}
            </div>
            <div className="track-artist">
              {String(
                alternateVersion?.artists_publishing_ownerships[0]?.artist
                  .full_name
              ).toLowerCase()}
            </div>
          </div>
          <div className="text-gray-600 w-2/12 self-center px-2 py-1">
            <span className="text-title">
              {alternateVersion?.version?.names[0]?.value}
            </span>
            {/* <Tabs
                  variant="scrollable"
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  className="p-3 w-full"
                >
                  {
                    props.tags.sort((a,b)=>(a.names[0].value < b.names[0].value) ? -1 : 1).map(tag => (
                      <div  key={tag.id} style={{margin:'0% 1%'}} 
                      onClick={() => {if(!(trackContext.searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !trackContext.searchTags.tags_not?.includes(tag.id)
                        || trackContext.searchTags.tags?.includes(tag.id))) addSearchTag(tag.id, tag.names[0].value)}} 
                    className={
                      (trackContext.searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !trackContext.searchTags.tags_not?.includes(tag.id)) ||
                      trackContext.searchTags.tags?.includes(tag.id)?
                      `flex pt-1 pb-1 pl-2 pr-2 mr-1 mb-1 text-xs border text-gray-800 border-transparent rounded-full cursor-pointer bg-gray-200`
                        :
                        `flex pt-1 pb-1 pl-2 pr-2 mr-1 mb-1 text-xs border border-gray-600 rounded-full cursor-pointer hover:bg-gray-400 hover:text-gray-800 hover:scale-75`
                      }
                      >{tag.names[0].value}
                      {(trackContext.searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !trackContext.searchTags.tags_not?.includes(tag.id)
                    || trackContext.searchTags.tags?.includes(tag.id)) &&
                    <Close 
                    onClick={(e) => {e.stopPropagation();removeSearchTag(tag.id, tag.names[0].value)}}
                    className="mt-1 ml-1" />}
                      </div>
                    ))
                  }
                  </Tabs> */}
          </div>
          <div className="w-3/12 self-center px-2 py-1">
            <SvgInline url = {alternateVersion.waveform.small.url} width="100%"/>
          </div>
          <div className=" text-center w-1/12 self-center text-description text-gray-600">
            {Math.round(alternateVersion.bpm)} BPM
          </div>
          <div className="text-center w-1/12 self-center text-description text-gray-600">
            {alternateVersion.duration &&
              new Date(alternateVersion.duration * 1000)
                .toISOString()
                .substr(14, 5)}
          </div>
          <div className="flex justify-end self-center p-1">
            {
              <ThumbPrint
                className="trackicon thumbprint-icon mx-2 self-center cursor-pointer hover:scale-50"
                style={{height : "26px"}}
                // onClick={() => handleThumbPrintSearch(alternateVersion.id, alternateVersion?.display_title)}
                onClick={() => props.handleMagicWandClick({id : alternateVersion.id, title: alternateVersion?.display_title})}
              />
            }
            {props.favs.indexOf(alternateVersion.id) !== -1 ? (
              <FavoriteIcon
                onClick={() =>
                  props.handleAddTrackToFavorites(alternateVersion.id)
                }
                style={{ fontSize: "20px" }}
                className="fav-icon mx-2 self-center cursor-pointer text-red-600 hover:scale-50"
              />
            ) : (
              <UnFavoriteIcon
                onClick={() =>
                  props.handleAddTrackToFavorites(alternateVersion.id)
                }
                style={{ fontSize: "20px" }}
                className="unfav-icon mx-2 self-center cursor-pointer text-gray-600  hover:text-red-600"
              />
            )}
            <CreateNewFolderIcon
              style={{ fontSize: "20px" }}
              onClick={() => props.handleProjectModal(alternateVersion.id)}
              className="folder-icon mt-3 mx-2 self-center cursor-pointer text-gray-700 hover:text-blue-600"
            />
            {/* <GetAppIcon
              onClick={(event) => props.handleDownloadTrack(event,alternateVersion.id)}
              style={{fontSize: '20px'}} 
              className='hidden sm:-1 sm:mt-3 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 sm:block'
            /> */}
            {props.download.indexOf(alternateVersion.id) !== -1 ? (
              <Activedownload
                onClick={(event) =>
                  props.handleDownloadTrack(event, alternateVersion.id)
                }
                style={{ fontSize: "20px" }}
                className="self-center mx-2 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600"
              />
            ) : (
              <GetAppIcon
                onClick={(event) =>
                  props.handleDownloadTrack(event, alternateVersion.id)
                }
                style={{ fontSize: "20px" }}
                className="self-center mt-1 mx-2 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600"
              />
            )}
            {
              <Menu
                id="customized-menu"
                anchorEl={props.ChidDownloadModalPosition}
                open={Boolean(props.showDownloadModal)}
                onClose={props.handleClose}
                style={{ width: "320px" }}
              >
                <MenuItem className="card-action">
                  <a
                    href={props.originalFile}
                    target="_blank"
                    rel="noopener noreferrer"
                    download=""
                  >
                    Original HD - Single track
                  </a>
                </MenuItem>
                <MenuItem
                  onClick={() => { props.handleDownloadArchive(alternateVersion.id, "original"); props.handleClose() }
                  }
                  className="card-action"
                >
                  <span className="card-action-text">
                    Original HD - All versions
                  </span>
                </MenuItem>
                <MenuItem
                  onClick={() => { props.setDownloadHistory(alternateVersion.id); props.handleClose() }}
                  className="card-action"
                >
                  <a
                    href={props.hdmp3File}
                    target="_blank"
                    rel="noopener noreferrer"
                    download=""
                  >
                    MP3 - Single track
                  </a>
                </MenuItem>
                <MenuItem
                  onClick={() => { props.handleDownloadArchive(alternateVersion.id, "hd_mp3"); props.handleClose() }
                  }
                  className="card-action"
                >
                  <span className="card-action-text">
                    MP3 - All versions
                  </span>
                </MenuItem>
              </Menu>
            }
          </div>
        </div>
      ))}
    </>
  );
}
