import React, { useEffect, useState } from "react"
import API from '../../services/api';

import CookieManager from "../../services/CookieManager";
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import { toast } from 'react-toastify';
import { CircularProgress, TextField } from '@material-ui/core';
import { DateRangePicker } from "materialui-daterange-picker";


const ExportLicenseComp = () => {

    const [totalLicenseCount, setTotalLicenseCount] = useState(-1);
    const [licenseCountLoading, setLicenseCountLoading] = useState(true);
    const [exportingLicenses, setExportingLicenses] = useState(false);
    const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth()-6)));
    const [endDate, setEndDate] = useState(new Date())

    const [dateRangeVisible, setDateRangeVisible] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({startDate : startDate, endDate : endDate});

    const toggle = () => setDateRangeVisible(!dateRangeVisible);
    const setDateRangeValues = (dateRange) => {
        setStartDate(new Date(dateRange.startDate));
        setEndDate(new Date(dateRange.endDate));
        setDateRange(dateRange);
    }
    useEffect(() => {
        (async () => {

          await API.getTotalLicenseCount().then(res => {
            if (res.success) {
              setTotalLicenseCount(res.data.licenseCount);
            }
            setLicenseCountLoading(false);
          }).catch((err) => {
            setLicenseCountLoading(false);
            toast(({ closeToast }) => <ErrorToast message={`Error : ${err.status}: ${err?.message}`} />);
        })

        })();
    }, [])

    const exportLicenses = async() => {
        setExportingLicenses(true);
        const xAuth = CookieManager.getCookie("x-auth");
        const xAccessToken = CookieManager.getCookie("x-access-token");
        const url = `${process.env.REACT_APP_BASE_URL}api/license/download-all`;

        await fetch(url, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "x-auth": xAuth,
            "x-access-token": xAccessToken,
          },
          body : JSON.stringify({startDate : startDate, endDate : endDate})
        }).then(function(res) {
          if (res.status === 200) {
            res.json().then(function(resbody){
                if(resbody.success)
                    window.open(resbody.downloadUrl, '_blank');
                else
                    toast(({ closeToast }) => <ErrorToast message={`Error : ${resbody.code}: ${resbody?.message}`} />);
            });
          }
          else {
            res.json().then(function(resbody){
                toast(({ closeToast }) => <ErrorToast message={`Error : ${resbody.status}: ${resbody?.statusText} ${res?.mssg}`} />);
            });
            toast(({ closeToast }) => <ErrorToast message={`Error : ${res.status}: ${res?.statusText}`} />);
          }
          setExportingLicenses(false);
        }).catch((err) => {
            setExportingLicenses(false);
            toast(({ closeToast }) => <ErrorToast message={`Error : ${err.status}: ${err?.message}`} />);
        })
    }

    return (
    <div className='flex shadow-sm p-2 mr-2' style={{"height" : "80px"}}>
        <div className='w-3/12 m-2  center-align'>
            <div >
                <h6>Total License Count :</h6>
                {licenseCountLoading ? 
                    <CircularProgress size='15px'/> :
                      totalLicenseCount<0 ? <label style={{"color" : "red"}}>N/A</label> : 
                      <label className="p-1" style={{"background": "#29efa2", "borderRadius": "50%"}}>{totalLicenseCount}</label>
                }
            </div>
        </div>
        {exportingLicenses ? 
        <label className="w-9/12" style={{"text-align": "center", "color": "green"}}>Exporting Licenses... Please Wait...</label> : 
        <>
            <div className='w-7/12'>
                {/* <DateRangeComp /> */}
                <div className="flex flex-wrap">
                    <div className='w-1/2 p-1'>     
                        <label className="text-xs">From : </label>
                        <div style={{"overflow" : "hidden", "borderColor": "lightgray", "borderWidth": "2px"}}>
                            <TextField value = {startDate.toLocaleDateString('pt-PT')} onClick={() => toggle()} />
                        </div>
                    </div>
                    <div className='w-1/2 p-1'>
                        <label className="text-xs">To : </label>
                        <div style={{"overflow" : "hidden", "borderColor": "lightgray", "borderWidth": "2px"}}>
                            <TextField value = {endDate.toLocaleDateString('pt-PT')} onClick={() => toggle()} />
                    
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap">
                    <DateRangePicker
                        open={dateRangeVisible}
                        toggle={toggle}
                        onChange={(range) => setDateRangeValues(range)}
                    />
                </div>
            </div>
            <div className='w-2/12 center-align' >
                <div className={`greenbutton cursor-pointer`}
                    onClick={() => exportLicenses()}
                > Export
                </div>
            </div>
        </>
        }
    </div>)
}

export default ExportLicenseComp