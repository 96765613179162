import React, { useState, useContext ,useEffect} from 'react';
import CancelIcon from '@material-ui/icons/Close';
import API from '../../services/api';
import TrackContext from '../../contexts/track-context';
import {ReactComponent as Play } from '../../assets/play.svg';
import {ReactComponent as Pause } from '../../assets/pause.svg';
import Amplitude from 'amplitudejs'
import Helper from '../../services/helper'
import { Link, useHistory } from 'react-router-dom';
import "./style.css";
import MewoService from '../../services/mewoService';
import Constants from '../../services/constants';

function AiSearch(props) {
  let trackContext = useContext(TrackContext);
  const history = useHistory();
  const {
    searchkey,
    setSearchkey,
    initialRouting,
    setinitialRouting,
    setTrackFilter
  } = trackContext;
  const [searchAI,setSearchAI]=useState(false);
  const [panel,setPanel]=useState([]);
  const [totalPanel,setTotalPanel]=useState(0);
  const [track,setTrack]=useState([]);
  const [totalTrack,setTotalTrack]=useState(0);


  const setAISearch = (e) => {
    if (e.key !== 'Enter' && e.target.value !== "" && e.target.value.startsWith('https://')) {
      props.handleAISearch(e.target.value);
      props.handleAISearchmusic(e.target.value);
      
    }
    if (e.key === 'Enter' && e.target.value !== "" && e.target.value.startsWith('https://')) {

      if(trackContext.path==="/" ||trackContext.path.startsWith("/search/track/")||trackContext.path.startsWith("/search/albums") ){
      history.push(`/search/track/`)
    }

    if(trackContext.path==="/sound-design" ||trackContext.path.startsWith("/sound-design/search/track/")||trackContext.path.startsWith("/sound-design/search/albums") ){
      history.push(`/sound-design/search/track/`)
    } 

      props.handleAISearch(e.target.value); 
      // props.handleAISearchmusic(e.target.value);
      
    }
  }
  const getTrackList = Helper.debounce(async(e) => {
    trackContext.setSearch(true)
    setSearchAI(e ? true:false); 
    setSearchkey(e); 
    
    if (e.indexOf('https://') !== -1) {
      setSearchAI(false);
      return;
    }
    let trackReqObj = {
      url : `public/search/panel/track`,
      method : 'post',
      data: JSON.stringify({
        query : e,
        options: { size: 10, search_descriptions: trackContext.searchDescription33333},
      }),
    };
    
    await MewoService.loadMewoData(trackReqObj).then((res) => {
    
      if(res?.hits !== undefined || res?.hits !== null){
        setTrack(res.hits);
        setTotalTrack(res.total?.value);
      }else{
        Helper.handleError(res);
      }
    });
    let albumsReqObj = {
      url : `public/search/panel`,
      method : 'post',
      data: JSON.stringify({
        query : e,
        albums: { page: 0, max: Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE, search_descriptions: trackContext.searchDescription },
      }),
    };
    await MewoService.loadMewoData(albumsReqObj).then((res) => {
      if(res?.albums !== undefined || res?.albums !== null){
        setPanel(res.albums?.hits)
        setTotalPanel(res.albums?.total?.value);
      }else{
        Helper.handleError(res);
      }
    });

  }, 500)
  const fetchTrack = (trackId) => {
    if (trackId === trackContext.currentTrackDetail.id) {
      trackContext.setPlayPause(!trackContext.playPause);
      return ;
    }
    if (Amplitude.getSongs().length) {
      Amplitude.stop()
      Amplitude.removeSong(0)
    }
    API.playTrack(trackId).then(res => {
      if(res?.data?.success){
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail(res.data.data)
        trackContext.setCurrentTrackImage(res.data.data.album.image.xsmall.url);
      }else{
        Helper.handleError(res?.data);
      }
    });
  }

  const seeTrackListData = () => {
    props.handleAISearch(trackContext.searchkey);
    trackContext.setSearchAlbums(false);
    
    props.closeProjectModal();
  }
  const seeAlbumListData = () => {
    //load album list data  
    trackContext.setSearchAlbums(true);
    props.handleAISearchmusic(trackContext.searchkey,props.checked);
    props.closeProjectModal();
  }

  const loadAlbumData=(albumId)=>{
    //go to album page & load album data
    props.closeProjectModal();
    localStorage.removeItem("filter_album");
    // history.push(`/album/${albumId}`);
  }

  return (
    <div className='aisearch-modal fixed right-0 top-0 shadow-md'>
      <div className='py-4 px-6 lg:p-4 mt-4 lg:mr-16 pb-0 flex justify-end'>
        <CancelIcon
          onClick={props.closeProjectModal}
          className='text-black cursor-pointer'
          style={{ fontSize: '32px' }}
        />
      </div>

      <div className='aisearch-wrapper w-full p-4 overflow-auto'>
      
          <div className="w-full lg:px-40">
          <input
            autoFocus={true}
            onChange={(e) => { getTrackList(e.target.value) }}
            onKeyDown={setAISearch}
            className="placeholder-textGray-100 font-light p-2 mb-2 w-full text-2xl lg:text-2xl focus:outline-none border-b text-black bg-transparent" 
            placeholder='Search Music...'
          />
          { !searchAI &&
          <div className="flex w-full flex-wrap">
            <div className="w-full lg:w-1/5 ">
              <div className="font-normal text-base">Search  by:</div>
            </div>
            <div className="w-full mt-3 lg:mt-0 lg:w-2/5 pr-2">
              <div className="font-semibold text-base">URL</div>
              <div className="text-sm text-textGray-100">Copy and paste a URL from a YouTube or Vimeo video here and our A.I. Search kicks into action to list the best matched tracks in our music!</div>

            </div>
            <div className="w-full mt-3 lg:mt-0 lg:w-2/5 pr-2">
              <div className="font-semibold text-base">Keyword</div>
              <div className="text-sm text-textGray-100">Enter a keyword to search by Track Title, Description or by Album Name</div>

            </div>
          </div>
          }
           { searchAI && 
           <>
           <div className="w-full pb-4">
           <div className="font-normal text-lg">Search result for "{searchkey}"</div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full md:w-2/4 pr-2 ">
              <div className="font-semibold text-base pb-4">Tracks ({totalTrack})</div>
              <div className="album-wrapper lg:overflow-y-auto">{
                track?.map(item=>{
                  return <div className="flex"> <div className='self-center play-button'>
                  <div
                    aria-label="play/pause"
                    className='outline-none focus:outline-none play-button border-symStrokeGrey cursor-pointer'
                   onClick={() => fetchTrack(item.id)}
                  >
                    {                  
                  (trackContext.playPause && item.id === trackContext.currentTrackDetail.id) ? 
                   <Pause className='outline-none' />
                        :
                        <Play className='outline-none' />
                    }
                  </div>
                </div>
                <div className='w-4/6 self-center p-2'>
                  <h1 className='text-base mr-2 text-black capitalize' > {item.title?.toLowerCase()}</h1>
                  <h2 className='text-xs self-center text-textGray-100 capitalize'>{item.master_ownerships[0]?.label?.label_name?.toLowerCase()} </h2>
                </div>
                </div>
                })
              }
              </div>
              {totalTrack > 0 &&
                (
                  <Link className="self-center" to={(trackContext.path.startsWith("/search")||trackContext.path==="/" ||  trackContext.path.includes("/album/"))?`/search/track/`:`/sound-design/search/track/`}  >
                  <div className="w-1/2 text-center font-normal text-sm pb-4 cursor-pointer see-tracks"
                  onClick={seeTrackListData} ><span className='showborder'>See Tracks List</span></div>
                   </Link>
                   )
                }
            </div>
            {panel?.length > 0 && 
              <div className="w-full lg:w-2/4 pr-2 " >
                <div className="font-semibold text-base pb-4">Albums ({totalPanel})</div>
                  <div className='album-wrapper lg:overflow-y-auto'>
                  {
                    panel?.map(item=>{
                      return <div className="flex mb-3">
                    <div className='w-20 h-20  overflow-hidden'>
                      <Link className="self-center" to={`/album/${item.id}`}  >
                      <img onClick={()=>loadAlbumData(item.id)} src={item.image?.small?.url} className="cursor-pointer" width='100%' alt='album art' onError={(e)=>trackContext.setDefaultImage(e)}/>
                      </Link>
                    </div>
                    <div className='w-4/6 self-center p-2'>
                      <h1 className='text-base mr-2 text-black capitalize' > {item.title?.toLowerCase()}</h1>
                      <h2 className='text-xs self-center text-textGray-100 capitalize'>{item.display_artists[0]?.artist?.full_name?.toLowerCase()} </h2>
                    </div>
                    </div>
                    })
                  }
                  </div>
                  {totalPanel> 0 &&(
                  <Link className="self-center " to= {(trackContext.path.startsWith("/search")||trackContext.path==="/"|| trackContext.path.includes("/album/"))?`/search/albums/${searchkey}`:`/sound-design/search/albums/${searchkey}`} >
                    <div className="w-1/2 text-center font-normal text-sm pb-4 cursor-pointer see-tracks"
                    onClick={seeAlbumListData} > <span className='showborder'>See Albums List</span></div>
                      </Link>) 

                  }
                </div>

            }
            </div>
           </>
          } 
        </div>
    
      {/* {
        searchAI &&
        <div className='w-full px-10 py-10' >
          <input
            autoFocus={true}
            onChange={(e) => { getTrackList(e.target.value) }}
            onKeyDown={setAISearch}
            className='p-2 mb-2 w-6/12 text-lg focus:outline-none border-b text-black bg-transparent'
            placeholder='Search Music...'
          />
         </div> 
      } */}
      </div>
    </div>
  )
}

export default AiSearch;