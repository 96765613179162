import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import API from '../../services/api';
import Validator from '../../services/validator';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [helperText, setHelperText] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  let validator = new Validator();
  const handleInputChange = (e) => {
    let {value} = e.target;
    let res = validator.setValue(value).isNotEmpty().isEmail();
    if(!res.status){
      let temp = {...helperText}
      temp['email'] = res.mssg;
      setHelperText(temp);
    }
    else{
      let temp = {...helperText}
      temp['email'] = '';
      setHelperText(temp);
      setButtonDisabled(false);
    }
    if(!(value?.length > 0))
      setHelperText({'email' : 'Email cannot be blank'})
    setEmail(value);
  };

  const sendEmail = async() => {
    let res = validator.setValue(email).isNotEmpty().isEmail();
    // console.log(res);
    if (res.status === false) {
      setHelperText(res.mssg);
    } else {
      
      await API.sendForgotPasswordEmail(email).then(res => {
        // console.log('res password link : ', res);
        if (res.data.success) {
          setEmail('');
          toast(({ closeToast }) => <SuccessToast message={res.data.message}/>);
          setTimeout(() => {
            window.location = '/signin';
          }, 4000);
        } else {
          toast(({ closeToast }) => <ErrorToast message={res.data.message}/>);
          // setTimeout(() => {
          //   window.location = '/signin';
          // }, 4000);
        }
      });
    }
  }

  return (
    <div className='px-10 sm:pt-24 sm:px-32 flex ' style={{minHeight: '520px'}}>
      <div className='sm:w-1/3 self-center'>
        <h1 className='font-bold text-lg'>Forgot Password</h1>
        <div className='py-4 flex w-full flex-wrap'>
          <TextField
            error={ helperText['email']?.length > 0 ? true : false}
            type='text'
            size='small'
            className='w-full'
            label="Enter Email"
            variant="outlined"
            value={email}
            onChange={(e) => handleInputChange(e)}
            helperText={helperText['email']}
          />
          <div className='w-full'>
            <button onClick={sendEmail} 
            className={buttonDisabled ? "mt-2" : 'p-2 signin-btn rounded text-white my-4'}
            disabled = {buttonDisabled}> Send Email</button>
          </div>
        </div>
      </div>
    </div>
  )
}