import React from 'react';

export default function NotFoundPage() {

  return (
    <div className='flex text-center flex-wrap' style={{minHeight: '430px'}}>
      <div className='self-center text-center w-full'>
        <h1 className='text-6xl text-gray-500 w-full'>404</h1>
        <h1 className='text-2xl text-gray-500 w-full'>PAGE NOT FOUND</h1>
      </div>
    </div>
  )
}