import React from "react";
import defaultImage from "../../assets/default_music.png";
import "./style.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import Audio_similarity_search from "../../assets/gif/Audio_similarity_search.gif"
import Prompt_search from "../../assets/gif/Prompt_search.gif"
import crownIcon from "../../assets/icons/crown.png";
import playDemoImg from "../../assets/landingPage/play_the_demo.png";

export default function GIFContainer (props) {
    
    const getGIF = () => {
        switch(props.idx){
            case 0 : 
                return(<LazyLoadImage
                    src={Prompt_search}
                    effect="blur"
                    alt="GIF"
                    width="100%"
                    style={{ height: "100%" }}
                    className="rounded-2xl"
                    />)
            case 1 : 
                return(<LazyLoadImage
                    src={Prompt_search}
                    effect="blur"
                    alt="GIF"
                    width="100%"
                    style={{ height: "100%" }}
                    className="rounded-2xl"
                    />)
        
            case 2 : 
                return(<LazyLoadImage
                    src={Prompt_search}
                    effect="blur"
                    alt="GIF"
                    width="100%"
                    style={{ height: "100%" }}
                    className="rounded-2xl"
                    />)

            case 3 : 
                return(<LazyLoadImage
                    src={Prompt_search}
                    effect="blur"
                    alt="GIF"
                    width="100%"
                    style={{ height: "100%" }}
                    className="rounded-2xl"
                    />)            
        }
    }

    const getTagImage = (tag) => {
        let img = tag.substring(
            tag.lastIndexOf("<img>") + 5, 
            tag.lastIndexOf("</img>")
        );
        switch(img){
            case "crownIcon" : 
                return(<LazyLoadImage
                    src={crownIcon}
                    className="rounded-2xl h-12"
                    />)
        }
    }

    const showVideo = ()=> {
        props.setPopup(true); 
        props.setGIFLink(props.imageURL)
    }
    return(
    <div className="w-1/4 flex">
    <div className="w-full flex border gif-box rounded-2xl mx-2" style={{height : "auto", "display" : "grid"}}>
        <div className="w-full">
            <div className="w-full gif-box self-center border rounded-2xl">
                {props?.gifURL ? (
                    <img
                        src={props?.gifURL || defaultImage}
                        alt="Demo img"
                        className="rounded-2xl cursor-pointer"
                        style={{width: "100%"}}
                        onClick={() => showVideo()}
                        />
                ) : (
                    <img
                    src={defaultImage}
                    alt="Demo img"
                    className="rounded-2xl"
                    />
                )}
                {/* {getGIF()} */}
            </div>
            <div className="w-full justify-center px-4">
                {props.title && 
                <div className="w-full flex justify-center py-4">
                    <span className="w-full flex justify-center topic-title-pink">{props.title}{props.title_tag && <div style={{marginTop: "-10px"}} dangerouslySetInnerHTML={{__html : props.title_tag}} ></div>}</span>
                </div>}
                {props.tags && 
                <div className="w-full flex pt-1">
                    <span className="w-full flex-wrap">
                        {props.tags?.map(tag => (
                        tag.indexOf("<img") > -1 ? <span className="w-full flex justify-center">{getTagImage(tag)}</span>:
                        <span className="w-full flex justify-center" dangerouslySetInnerHTML={{__html : tag}} />))}
                    </span>
                </div>}
                {props.descriptions && 
                <div className="w-full flex py-1">
                    <ul className="list-disc pl-6 pr-2 desc-text">
                        {props.descriptions?.map(desc => (
                        <li className="py-1" dangerouslySetInnerHTML={{__html : desc}} />))}
                    </ul>
                </div>}
                
            </div>
        </div>
        <div className="w-full flex justify-center py-2" style={{"alignSelf": "flex-end"}}>
                <LazyLoadImage
                src={playDemoImg}
                className="rounded-2xl h-12 cursor-pointer"
                onClick={() => showVideo()}
                />
        </div>
    </div>
    </div>);
}