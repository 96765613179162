import React from "react"
import { DateRangePicker } from "materialui-daterange-picker";
import ReactSelect from "react-select";
import { TextField, Tooltip } from "@material-ui/core";
import { CSVLink } from "react-csv";
import CookieManager from "../../services/CookieManager";
import DownloadIcon from "@material-ui/icons/GetAppSharp";

const FilterComp = (props) => {
    const [dateRangeVisible, setDateRangeVisible] = React.useState(false);
    const toggle = () => setDateRangeVisible(!dateRangeVisible);
    const userData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    let csvFileName = props.buttonTitle?.includes('Licenses') ? `Licenses-${userData._id}-${new Date().getTime()}` : `Projects-${userData._id}-${new Date().getTime()}`;

    return (
        <div className='w-full flex flex-wrap'>
            <div className='w-4/12 p-1'>     
                <label className="text-xs">User(s) : </label>
                <div >
                    <ReactSelect
                    value={props.selectedUsers}
                    options={props.users}
                    isMulti = {true}
                    isSearchable = {true}
                    onChange = {props.handleUserSelection}
                    />
                </div>
            </div>
            <div className="w-6/12 flex flex-wrap">
                <div className='w-1/2 p-1'>     
                    <label className="text-xs">From : </label>
                    <div style={{"overflow" : "hidden", "borderColor": "lightgray", "borderWidth": "2px"}}>
                        <TextField value = {props.startDate.toLocaleDateString('pt-PT')} onClick={() => toggle()} />
                    </div>
                </div>
                <div className='w-1/2 p-1'>
                    <label className="text-xs">To : </label>
                    <div style={{"overflow" : "hidden", "borderColor": "lightgray", "borderWidth": "2px"}}>
                        <TextField value = {props.endDate.toLocaleDateString('pt-PT')} onClick={() => toggle()} />
                
                    </div>
                </div>

                <DateRangePicker
                    open={dateRangeVisible}
                    toggle={toggle}
                    onChange={(range) => props.setDateRangeValues(range)}
                />
            </div>
            <div className='w-2/12 pt-6 flex justify-center' >
                {props.csvData?.length > 0 && 
                <Tooltip title="Download" arrow placement="top">
                <CSVLink
                className="p-2"
                data={props.csvData}
                filename={`${csvFileName}.csv`}
                target="_blank">
                    <DownloadIcon
                        width="40"
                    />
                </CSVLink>
                </Tooltip>}
                <div className={`greenbutton cursor-pointer`}
                    onClick={() => props.loadData()}
                > {props.buttonTitle}
                </div>
            </div>
        </div>
    )
}

export default FilterComp