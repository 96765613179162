import React from 'react';
import './style.css';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';

export default function MobilePopup(props) {
  return (
    <div className='flex fixed top-0 h-screen w-full subscribe-modal justify-center' style={{zIndex: '9'}}>
      <div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' onClick={() => props.setShowMobilePopup(false)}/>
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
          <p className='pt-6 pb-12 text-base'>
          This feature is only available in the desktop view
          </p>
        </div>
        <div onClick={() => props.setShowMobilePopup(false)}
        className='flex flex-wrap justify-center pt-6 pb-8'>
           <div className='w-full text-center'>
            <div className='get-started px-10 w-1/3' >
            Ok             
           </div>
          </div>
         
         
        </div>
      </div>
    </div> 
  )
}