import React from "react";
import ServiceUnavailableImage from "../../assets/broken-page-1.png";
import './style.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log("error : ", error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className="min-h-screen flex justify-center items-end py-32"
          style={{ 
            backgroundImage: `url(${ServiceUnavailableImage})`, 
            backgroundSize: 'contain', 
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundColor: '#eddbc3'
          }}
        >
          <button
            onClick={this.handleRefresh}
            class='refresh-btn relative' style={{backgroundColor: '#2befa2'}}>
            Refresh
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
