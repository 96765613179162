import React from 'react';
import {ReactComponent as Error } from '../../assets/error.svg';
import {ReactComponent as Success } from '../../assets/SM-Success.svg';

export function ErrorToast({message}) {
  return (
    <>
      <div className="flex m-1 w-full">
        {/* <Error className="p-1 w-1/4 mr-2 border-r border-gray-300"/> */}
        <div className="self-center break-word text-red-500 ">
          <h1 className='text-xs ' > Sorry, please try again.</h1>
          <h2 className='text-xs self-center '> {message} </h2>
        </div> 
      </div>
    </>  
  )
}

export function SuccessToast({message,page}) {
  return (
    <>
      <div className="flex m-1 w-full">
        {/* <Success className="p-1 w-1/4 mr-2 border-r border-gray-300"/> */}
        <div className="self-center break-word text-green-500 ">
          {page==='music'?'':<h1 className='text-xs ' > Success, </h1>}
          {page==='music'?<h2 className='text-xs self-center '><i>{message}</i>  </h2>:<h2 className='text-xs self-center'> {message} </h2>}
        </div> 
      </div>
    </>  
  )
}

export function InfoToast({message,page}) {
  return (
    <>
      <div className="flex m-1 w-full">
        {/* <Success className="p-1 w-1/4 mr-2 border-r border-gray-300"/> */}
        <div className="self-center break-word text-black ">
          <h1 className='text-xs ' > Info, </h1>
          <h2 className='text-xs self-center '> {message} </h2>
        </div> 
      </div>
    </>  
  )
}

export function WarningToast({message}) {
  return (
    <>
      <div className="flex m-1 w-full">
        <div className="self-center break-word " style={{color : "#f66a30"}}>
          <h1 className='text-xs ' > Warning! </h1>
          <h2 className='text-xs self-center '> {message} </h2>
        </div> 
      </div>
    </>  
  )
}

