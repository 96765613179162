import React, { useContext } from 'react';
import './style.css';
import Constants from '../../services/constants';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';
import TrackContext from '../../contexts/track-context';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CookieManager from '../../services/CookieManager';
import Helper from '../../services/helper';



export default function LogoutPopup(props) {
  const history = useHistory();
  let trackContext = useContext(TrackContext);
  const userData = JSON.parse(CookieManager.getCookie("user-data") || "null");
  return (
    <div className='flex fixed top-0 h-screen w-full subscribe-modal justify-center' style={{zIndex: '9'}}>
      {(userData) &&
      <div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' 
            onClick={() => {
              trackContext.setShowLogoutPopup(false)
            }}
          />
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
          <h1 className='text-lg text-center uppercase font-semibold py-2'>Are you sure you want to Sign Out</h1>
        </div>
        <div className='flex flex-wrap justify-center pt-6 pb-8'>
          <div className='w-full text-center'>
            <button
              onClick={() => {Helper.signOut()}}
              className="inline-block  navPinkbuttonwide mt-4 lg:mt-0" >
              SIGN OUT
            </button>
          </div>
        </div>
      </div>
     }
    </div> 
  )
}