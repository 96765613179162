import React, { useState, useEffect, useContext, useRef } from "react";
import TrackContext from "../../contexts/track-context";
import TrackItem from "../TrackItem";
import API from "../../services/api";
import AddTrackToProject from "../Projects/addTrackToProject";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import Helper from "../../services/helper";



const SoundDesign = (props) => {
  let trackContext = useContext(TrackContext);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [excludedTrack, setExcludedTrack] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const firstMount = useRef(true);
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const pageId='soundfx';

  let soundDesignTagID = "5d36d75104a4286541ea2d1d";
  //Gothic Tool Works
  let labelTagID1 = "613e35c6b52f5e75a7fabf2b";
  // Strike Audio
  let labelTagID2 = "5f105463dd200590c481c317";
  // Aura
  let labelTagID3 = "602ea745357d1e5f788b0559";

  useEffect(() => {
    if (window.location.pathname === "/sound-design" || window.location.pathname.startsWith(`/sound-design/search/track/`)) {
      let tempFilter = JSON.parse(JSON.stringify(trackContext.trackFilter));
      tempFilter.searchTags.labels = [labelTagID1, labelTagID2, labelTagID3];
      tempFilter.searchTags.tags = [soundDesignTagID];
      if (tempFilter?.searchTags?.tags_not !== undefined) {
        const tagArrarSoundDesign = tempFilter?.searchTags?.tags?.filter(
          (eachTags) => eachTags === soundDesignTagID
        );

        tempFilter.searchTags.tags = tagArrarSoundDesign;
        tempFilter.searchTags.tags_not = [];
      }
      trackContext.setTrackFilter(tempFilter);
    }
  }, []);
  useEffect(() => 
 {
  if(!trackContext.isFileUpload && !trackContext.trackFilter.url && !trackContext.searchAlbums)
  {
    trackContext.trackFilter.searchTags.labels = [labelTagID1, labelTagID2, labelTagID3];
    trackContext.trackFilter.searchTags.tags = [soundDesignTagID];
    trackContext.trackFilter.searchTags.tags_not = [];
    trackContext.trackFilter.fetchList = true;
    if (
      trackContext.trackFilter.fetchList &&
      !trackContext.trackFilter.searchTitle?.length
    ) {
      if (
        trackContext?.trackFilter?.searchTags?.tags?.length &&
        trackContext?.trackFilter?.searchTags?.tags?.indexOf(
          "5d36d75104a4286541ea2d1d"
        ) !== -1
      ) {
        trackContext.setIsLoading(true);
        trackContext.setSearchkey("")
        setNoDataFound(false);
        API.searchTracks(
          trackContext.trackFilter.searchText,
          trackContext.trackFilter.searchId,
          trackContext.trackFilter.searchTags,
          [],
          trackContext.searchDescription
        ).then((res) => {
          if(res?.success){
              trackContext.setShowProjectModal(false);
              trackContext.setIsLoading(false);
              trackContext.setTrackData(res.data.hits);
              trackContext.setTotaltracks(res.data.total.value);
              let newTracks = [...res.data.hits];
              let tracksToExclude = newTracks.map((track) => track.ref_id);
              // console.log('Tracks to Exclude: ', res.data);
              setExcludedTrack(tracksToExclude);
              if (res.data.hits.length === 0) setNoDataFound(true);
            } else {              
              Helper.handleError(res);
            }
          })
          .catch((err) => {
            Helper.handleError(err);
          });
      }
    }
  }
 }, [
    // trackContext.trackFilter.searchTitle,
    // trackContext.trackFilter.searchTags,
    // trackContext.trackFilter.searchText,
    // trackContext.trackFilter.url,
    // trackContext.fileName
  ]);

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };
  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };
  return (
    <>
      {(!trackContext.loading  && !trackContext.trackData?.length === 0 && !trackContext.isFileUpload) && (
        
        <div className="text-xl text-center text-red-500">
         No Tracks Found
        </div>
      )}
      {(!trackContext.loading &&
        trackContext.trackData?.length > 0 && !trackContext.isFileUpload) &&
        //  trackContext.trackData.filter( ( eachTrack ) => eachTrack.tags.some( ( eachTag ) => eachTag.key === 'contemporary-classical') ).map((track,index,arr) =>
        trackContext.trackData.map((track, index, arr) => (
          <>
            {/* <div on= {()=>{ console.log('Each Track:',track.title)}}> */}
            {track.duration && (
              <TrackItem
                //   setSearchTags={props.setSearchTags}
                tags={track.tags}
                image={track.album?.image}
                //   searchTags={props.searchTags}
                key={track.id}
                title={track.display_title}
                albumId={track.album}
                duration={track.duration}
                similarity={track?.similarity}
                description={track?.descriptions[0]?.value}
                previousTrack={index}
                bpm={track?.bpm}
                versions={track?.versions}
                producer={track.master_ownerships[0]?.label?.label_name}
                artist={
                  track.artists_publishing_ownerships[0]?.artist?.full_name
                }
                rights_type={
                  track.artists_publishing_ownerships[0]?.rights_type?.key
                }
                album={track.album?.title}
                trackId={track.id}
                waveform={track?.waveform?.small?.url}
                setTrackUrl={props.setTrackUrl}
                setFetchingTrack={props.setFetchingTrack}
                changeCurrentTrackImage={props.changeCurrentTrackImage}
                displayProjectModal={displayProjectModal}
                handleCurrentTrackDetail={props.handleCurrentTrackDetail}
                expandedTrackId={expandedTrackId}
                setExpandedTrackId={setExpandedTrackId}
                pageId={pageId}
                artists={
                  track.display_artists
                }
              />
            )}
            {/* </div> */}
          </>
        ))
      }
      {showProjectModal && (
        <AddTrackToProjectPopup
          trackToBeAddedToProject={trackToBeAddedToProject}
          closeProjectModal={closeProjectModal}
        />
      )}
    </>
  );
};

export default SoundDesign;