import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import API from '../../services/api';
import Helper from '../../services/helper';
import { ReactComponent as Logo1 } from '../../assets/logo-day.svg';
//import { ReactComponent as LogoDay } from '../../assets/logo-day.svg';
//import { ReactComponent as LogoNight } from '../../assets/logo-night.svg';
//import {ReactComponent as Logo1 } from '../../assets/Logo1.svg';
import { ReactComponent as UserProfile } from '../../assets/profile-fill.svg';
import { ReactComponent as MenuLogo } from '../../assets/hamburger.svg';
import { ReactComponent as ThumbPrintIcon } from '../../assets/auto_fix_high_black_36dp.svg';
// import { Avatar } from '@material-ui/core';
import AiSearch from './aiSearch';
import TrackContext from '../../contexts/track-context';
import Constants from '../../services/constants';
import CookieManager from '../../services/CookieManager';
import { useHistory } from "react-router-dom";
import MobileMenu from './mobileMenu';
import { ErrorToast, InfoToast } from "../../pure-components/CustomToast";
import { toast } from 'react-toastify';
import AudioFileIcon from '@material-ui/icons/Audiotrack';
import axios from 'axios';
import suspendedImg from "../../assets/red_card.png"
import { Checkbox } from '@material-ui/core';
import MewoService from '../../services/mewoService';
import signInButtonImg from '../../assets/sign_in_button.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import UploadIconGIF from "../../assets/upload_icon.gif"

export default function Navbar(props) {
  const history = useHistory();
  const [userData, setUserData] = useState(JSON.parse(CookieManager.getCookie('user-data') || 'null'));
  // console.log('[PROPS NAVBAR]', props);

  // console.log(['/signin', '/register'].indexOf(props.location.pathname));

  // const [searchText, setSearchText] = useState('');
  const [showProjectModal, setShowProjectModal] = useState(false);
  let trackContext = useContext(TrackContext);
  const [showMobileMenu, setMobileMenu] = useState(false);

  const handleChange = (e) => {
    trackContext.setSearchDescription(e.target.checked);
  };

  const handleMobileMenu = () => {
    setMobileMenu(true);
    let navTag = document.getElementsByTagName('nav');
    navTag[0].style.zIndex = '20';

  }

  useEffect(() => {
  }, [userData])


  useEffect(() => {
    // console.log('monitoring body', props);
    let bodyTag = document.getElementsByTagName('body');
    if (trackContext.showProjectModal) {
      bodyTag[0].style.overflow = 'hidden';
    } else {
      bodyTag[0].style.overflow = 'auto';
    }
  }, [trackContext.showProjectModal])

  const handleMobileMenuClose = () => {
    setMobileMenu(false)
  }

  // const signOut = () => {
  //   Helper.signOut();
  // }


  const hiddenFileInput = React.useRef(null);
  //allow user to upload audio file if user is signed in
  const handleClick = event => {
    hiddenFileInput.current.value=null;
    if (!trackContext.validateUser(Constants.ACTION.AUDIO_SEARCH)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.AUDIO_SEARCH);
      return;
    }
    hiddenFileInput.current.click();
  };

  //uploading audio file
  const uploadAudioFile=(file)=>{
    let fileName=""
    if(file){
      
      fileName=file.name
    }else{

      fileName = trackContext.trackFilter.fileName
    }
    {(trackContext.path.startsWith("/search")||trackContext.path==="/")?
      history.push( `/search/track/`)
      :
      history.push( `/sound-design/search/track/`)} 
      trackContext.setFileUpload(true);
      let data=new FormData();
      data.append('file',file);
      handleAudioSearch(data,file.name);
  }

  let counter = 60;

  const handleSearch = async (e) => {
    let userData = JSON.parse(CookieManager.getCookie('user-data'));
    //  if (trackContext.userDetails.email === undefined || trackContext.userDetails.subscribed === false) 
    if (userData === null) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction('AI Search');
      return;
    }
      trackContext.setSearch(true);
  }

  const handleAudioSearch = async (value,fileName) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    trackContext.setCancelToken(source);
    let response = await API.uploadAudioFileToMEWO(value, (event) => {
      // trackContext.setIsLoading(true);
      trackContext.setShowProgress(true);
      let progress = Math.round((100 * event.loaded) / event.total);
      trackContext.setProgressValue(progress);
    },source);
    CookieManager.setCookie("stopAISearch", false);
    if (response.status == 200) {
      clearSearchTag();
      trackContext.setShowProgress(false);
      trackContext.setProgressValue(0);
      trackContext.handleMaiaOnWork(true);
      trackContext.setTrackData([]);
      trackContext.setCancelToken();
      let {data}=response;
      let newTrackFilter = { ...trackContext.trackFilter };
      newTrackFilter.fileName=fileName;
      newTrackFilter.searchId=data;
      trackContext.setTrackFilter(newTrackFilter);
      looper(data);
    } else{
      if(response.message == Constants.FILE_UPLOAD_CANCELLED)
        toast(({ closeToast }) => <InfoToast message={response.message}/>, {autoClose: 2000, closeOnClick: true});
      else if(response.status == 400 && response.data?.key === "invalid_token"){
        toast(({ closeToast }) => <ErrorToast message={`File Upload Error : Invalid Token. Please login again to process this request.`}/>, {autoClose: 2000, closeOnClick: true});
      }else
        toast(({ closeToast }) => <ErrorToast message={`File Upload Error : ${response?.data?.message ? response?.data?.message : ""}`}/>, {autoClose: 2000, closeOnClick: true});
      
      clearFileUploadContext();
      trackContext.setIsLoading(false);
      props.handleMaiaOnWork(false);
      trackContext.setShowProjectModal(false);
    }
    
  };

  const clearFileUploadContext=()=>{
    trackContext.setCancelToken();
    trackContext.setShowProgress(false);
    trackContext.setFileUpload(false);
  }

  const clearSearchTag = () => {
    const filterMenu = trackContext.filterMenu;
    let newTrackFilter = trackContext.trackFilter;
    newTrackFilter.searchTags = {};
    newTrackFilter.selectedChildTags = [];
    newTrackFilter.selectedTags = [];
    newTrackFilter.duration = [];
    newTrackFilter.bpm = [];
    newTrackFilter.url = "";
    newTrackFilter.searchText = "";
    newTrackFilter.searchTitle = "";
    newTrackFilter.searchId = "";
    newTrackFilter.trackId = "";
    newTrackFilter.fetchList = true;
    trackContext.setTrackFilter(newTrackFilter);
    let newFilter = filterMenu?.map((item) => {
      item.count = 0;
      return item;
    });
    trackContext.setFilterMenu(newFilter);
    localStorage.setItem("filterMenu", JSON.stringify(newFilter));
  };

  const handleAISearch = async (value) => {
    trackContext.setTrackData([]);
    trackContext.setTotaltracks(0)
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.searchTags = {};
    newTrackFilter.selectedTags = [];
    newTrackFilter.duration = [];
    newTrackFilter.bpm = [];
    newTrackFilter.searchTitle = "";
    newTrackFilter.trackId = "";
    newTrackFilter.url="";
    newTrackFilter.searchId="";
    newTrackFilter.fileName="";
    newTrackFilter.fetchList = false;
    let newFilter = trackContext.filterMenu.map(item => {
      item.count = 0;
      return item;
    })
    trackContext.setFilterMenu(newFilter);
    localStorage.setItem('filterMenu', JSON.stringify(newFilter))

    trackContext.setTrackData([]);
    if (value.indexOf('https://') !== -1) {
      newTrackFilter.url = value;
      newTrackFilter.searchText = "";
      let reqObj = { 
        url : 'public/search/url', 
        method : 'post', 
        data : {link: value}
      };
      await MewoService.loadMewoData(reqObj).then(resp => {
        if(resp?.length > 0){
          looper(resp);
          newTrackFilter.searchId=resp;
        }else{
          Helper.handleError(resp);
        }  
        props.handleMaiaOnWork(true);
        trackContext.setShowProjectModal(false);
      }).catch(err => {
        toast(({ closeToast }) => <ErrorToast message={"Tracks Search Error : " + err?.response?.data?.message}/>);
      })
    } else {
      // perform normal search
      if (value !== '' & value.length > 0) {
       
          if(trackContext.path === "/" ||
          trackContext.path.includes("/search/track/")||
          // props.location.pathname.includes("/sound-design")
          trackContext.path.includes("/sound-design")||
           trackContext.path.includes("/search/albums/")||
           trackContext.path.includes("/album/")){        
          newTrackFilter.searchText = value;
          newTrackFilter.url = "";
          trackContext.setResultFromMawoSearch(true)
          trackContext.setIsLoading(true);

          let reqObj = {
            url : `public/search`,
            method : 'post',
            data: {
              "query": value,
              "options": 
              {
                  "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
                  "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
              }
            },
          };
          await MewoService.loadMewoData(reqObj).then((res) => {
            if(res){
              trackContext.setShowProjectModal(false);
              trackContext.setResultFromMawoSearch(false);
              props.handleMaiaOnWork(false);
              trackContext.setIsLoading(false);
              trackContext.setSearch(false)
              props.handleResultfromMaia(res.hits);
              trackContext.setTotaltracks(res.total.value)
              if (Constants.MEWO_TRACK_SEARCH_BATCH_SIZE > res.hits?.length)
                trackContext.setAllTracksLoaded(true);
              else
                trackContext.setAllTracksLoaded(false);
            }else{
              Helper.handleError(res);
            }
          }).catch(err => {
            toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
          })
        }
        
      

        // if (props.location.pathname === "/sound-design" ) {
        //   console.log("inside sound design")
        //   newTrackFilter.searchText = value;
        //   newTrackFilter.url = "";
        //   newTrackFilter.searchTags = {}
        //   props.handleMaiaOnWork(false);
        // }
      }     

    }
    trackContext.setTrackFilter(newTrackFilter); 
    if(trackContext.path === "/"  || 
    trackContext.path.startsWith("/search/track/")||
    trackContext.path.startsWith("/search/albums") ||
    trackContext.path.startsWith("/album/")
 ){
  localStorage.setItem("filter_music", JSON.stringify(newTrackFilter));
 }else if (trackContext.path === "/sound-design"  ||  trackContext.path.startsWith("/sound-design/search/track/") || trackContext.path.startsWith("/sound-design/search/albums/")) {
  localStorage.setItem("filter_soundfx", JSON.stringify(newTrackFilter));
  
 } 
  };

  const handleAISearchmusic = async (value) => {
    // props.handleMaiaOnWork(true);
    trackContext.setTotaltracks(0);
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.searchTags = {};
    newTrackFilter.selectedTags = [];
    newTrackFilter.duration = [];
    newTrackFilter.bpm = [];
    newTrackFilter.searchTitle = "";
    newTrackFilter.fetchList = false;
    let newFilter = trackContext.filterMenu.map((item) => {
      item.count = 0;
      return item;
    });
    trackContext.setFilterMenu(newFilter);
    localStorage.setItem("filterMenu", JSON.stringify(newFilter));

    trackContext.setTrackData([]);

    if (value.indexOf("https://") !== -1) {
      newTrackFilter.url = value;
      newTrackFilter.searchText = "";

      let reqObj = { 
        url : 'public/search/url', 
        method : 'post', 
        data : {link: value}
      };
      await MewoService.loadMewoData(reqObj).then(resp => {
        if (resp?.length > 0) {
          looper(resp);
        } else {
          Helper.handleError(resp);
        }
        props.handleMaiaOnWork(true);
        trackContext.setShowProjectModal(false);
      }).catch(err => {
        toast(({ closeToast }) => <ErrorToast message={"Tracks Search Error : " + err?.response?.data?.message}/>);
      })      
    } else {
      // perform normal search
      if ((value !== "") & (value.length > 0)) {
        if (props.location.pathname === "/"  || 
        trackContext.path.includes("/search/albums/")||
        props.location.pathname === "/sound-design"|| 
        trackContext.path.includes("/search/track/")||
        trackContext.path.includes("/album/")) {
          newTrackFilter.searchText = value;
          newTrackFilter.url = "";
          trackContext.setResultFromMawoSearch(true);
          trackContext.setIsLoading(true);
          let reqObj = {
            url : `public/search/panel`,
            method : 'post',
            data: JSON.stringify({
              query : value,
              albums: { page: 0, max: Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE, search_descriptions: trackContext.searchDescription },
            }),
          };
          await MewoService.loadMewoData(reqObj).then((res) => {
            if (res) {
              trackContext.setShowProjectModal(false);
              trackContext.setResultFromMawoSearch(false);
              props.handleMaiaOnWork(false);
              trackContext.setIsLoading(false);
              trackContext.setSearch(false)
              props.handleResultfromMaia(res.albums.hits);
              trackContext.setTotaltracks(res.albums.total?.value);
              trackContext.setAllTracksLoaded(true);
              if (
                process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE >
                res.albums.hits?.length
              )
                trackContext.setAllTracksLoaded(true);
              else trackContext.setAllTracksLoaded(false);
            } else {
              Helper.handleError(res);
            }
          });
        }


        if (props.location.pathname === "/sound-design" ) {
          // console.log("inside sound design")
          newTrackFilter.searchText = value;
          newTrackFilter.url = "";
          newTrackFilter.searchTags = {}
          props.handleMaiaOnWork(false);
        }
      }     

    }
    trackContext.setTrackFilter(newTrackFilter); 
    if(trackContext.path === "/" ||
    trackContext.path.startsWith("/search/track/")||
    trackContext.path.startsWith("/search/albums/")||
    trackContext.path.startsWith("/album/")
 ){
  localStorage.setItem("filter_music", JSON.stringify(newTrackFilter));
 }else if ( trackContext.path.includes("/sound-design")||trackContext.path.startsWith("/album/")) {
  localStorage.setItem("filter_soundfx", JSON.stringify(newTrackFilter));
  
 }  
  };
  const closeProjectModal = () => {
    trackContext.setShowProjectModal(false);
  }

  const closeAISearch = () => {
    trackContext.setSearch(false);
  }

  let searchTime=0;
  let searchInterval;
  let flag=false;

  const looper = async (searchId) => {
    // console.log({counter});
    let stopSearch = CookieManager.getCookie("stopAISearch");

    if(stopSearch){
      CookieManager.deleteCookie("stopAISearch");
      return;
    }
    let maia = await API.searchTracksByMaiaId(searchId);
    if (maia?.success) {
      if (maia.data?.hits?.length === 0) {
        if(flag === false){
          flag=true;
          searchInterval=setInterval(()=>{
            searchTime+=1;
            trackContext.setSearchTime(searchTime);
          },1000)
          trackContext.setIntervalId(searchInterval)
        }
        setTimeout(async () => {
          let result = await looper(searchId);
          // console.log({maia1: result});
        }, 1000)
      } else {
        clearInterval(searchInterval);
        trackContext.setIntervalId(null);
        trackContext.setSearchTime(0);
        trackContext.setFileUpload(false);
        props.handleMaiaOnWork(false);
        trackContext.setSearch(false)
        trackContext.setTrackData([]);
        trackContext.setTotaltracks(maia.data?.total?.value)
        props.handleResultfromMaia(maia.data?.hits);
        if (process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE > maia.data?.hits?.length)
          trackContext.setAllTracksLoaded(true);
        else
          trackContext.setAllTracksLoaded(false);
      }
    } else {
      clearInterval(searchInterval);
      trackContext.setIntervalId(null);
      trackContext.setSearchTime(0);
      trackContext.setFileUpload(false);
      Helper.handleError(maia);
      props.handleMaiaOnWork(false);
      trackContext.setTotaltracks(0);
      trackContext.setTrackData([]);
      props.handleResultfromMaia([]);
      trackContext.setAllTracksLoaded(true);
    }
    return maia;
  }

  const handleRedirectToVoiceGen = async (e) => {
    e.preventDefault();
    let cachedUserData = JSON.parse(CookieManager.getCookie('user-data'));
    if (!trackContext.validateUser(Constants.ACTION.TTS)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.TTS);
      return;
    }
    let userData = {id : cachedUserData._id};
    if(cachedUserData?.userType === "ADMIN" || cachedUserData?.userType === "SUPER_ADMIN")
      userData = {id : cachedUserData.email, userType : "ADMIN"};
    let resp = await API.validateSubscription(userData.id, userData.userType);
    if (resp?.success) {
      if (resp.data?.userType === "FREE" || !resp.data?.subscribed) {
        trackContext.setShowSubscribePopup(true);
        trackContext.setUserAction(Constants.ACTION.TTS);
      } else {
        CookieManager.setCookie("user-data", JSON.stringify(resp.data));
        window.location = process.env.REACT_APP_MKT_SITE_VOICEGEN;
      }
    } else {
      toast(({ closeToast }) => <ErrorToast message={resp.mssg} />);
    }

  }

  return (
    <>
      <nav className="fixed top-0 flex items-center justify-between flex-wrap bg-white shadow-sm w-full z-10 custom-nav">
        <div className="lg:mx-7 xl:mx-20 w-full flex">
          <a href={process.env.REACT_APP_MARKETING_SITE_URL}>
            <div className="flex items-center flex-shrink-0">
              <div className="rounded-full">
                <Logo1 />
              </div>
            </div>
          </a>

          <div className="w-full flex lg:hidden justify-end">
            <div className="m-3 row">
              {userData !== null ? userData?.productDesc : ""}
              {((userData?.userType === "ADMIN" && userData?.isSuspended)||(!userData?.subscribed)) && (
                <img style={{height:"36px"}} alt="Suspended" src={suspendedImg} />
              )}
            </div>
            <button className="flex items-center px-3 py-2 pr-0  text-teal-200 hover:text-white hover:border-white">
              <MenuLogo onClick={handleMobileMenu} />
            </button>
          </div>
          <div
            className="w-3/4 flex-grow lg:flex lg:items-center hidden lg:w-auto "
            style={{ paddingLeft: "2px" }}
          >
            <div className="w-4/6 text-sm lg:flex-grow flex bg-gray-200 border rounded-2xl py-1">
              <Link className=" w-1/6 flex justify-center" to={"/"}>
                <span
                  className={
                    props.location.pathname === "/"||
                    props.location.pathname.startsWith("/search/track/") ||
                    props.location.pathname.startsWith("/search/albums/")
                      ? "font-semibold text-xl "
                      : "text-xl "
                  }
                >
                  {process.env.REACT_APP_MKT_SITE_MUSIC_LABEL}
                </span>
                <sup className='border tag-box rounded-2xl px-1 my-1 text-xs'><b>Key</b></sup>
              </Link>
              <Link className=" w-1/6 flex justify-center" to={"/library"}>
                <span
                  className={
                    props.location.pathname === "/library"
                      ? "font-semibold text-xl "
                      : "text-xl "
                  }
                >
                  {process.env.REACT_APP_MKT_SITE_MUSIC_LABEL}
                </span>
                <sup className='border tag-box rounded-2xl px-2 my-1 text-xs'><b>AI</b></sup>
              </Link>
              <Link className=" w-1/6 flex justify-center" to={process.env.REACT_APP_MKT_SITE_SOUND_DESIGN}>
                <span
                  className={
                    props.location.pathname === process.env.REACT_APP_MKT_SITE_SOUND_DESIGN ||
                    props.location.pathname.startsWith("/sound-design/search/track/")||
                    props.location.pathname.startsWith("/sound-design/search/albums/")      
                      ? "font-semibold text-xl "
                      : "text-xl "
                  }
                >
                  {process.env.REACT_APP_MKT_SITE_SOUND_DESIGN_LABEL}
                </span>
              </Link>
              {/* <a
                href={process.env.REACT_APP_MKT_SITE_SOUND_DESIGN}
                className="text-lg mx-5 hover:cursor-pointer"
              >
                {process.env.REACT_APP_MKT_SITE_SOUND_DESIGN_LABEL}
              </a> */}
              {/* <a
                // href={process.env.REACT_APP_MKT_SITE_VOICEGEN}
                onClick={handleRedirectToVoiceGen}
                className="text-lg mx-5 hover:cursor-pointer"
              >
                {process.env.REACT_APP_MKT_SITE_VOICEGEN_LABEL}
              </a> */}
              {/* <Link>
                <span
                  className={
                    props.location.pathname === process.env.REACT_APP_MKT_SITE_VOICEGEN
                      ? "font-semibold text-lg "
                      : "text-lg "
                  }
                  onClick={handleRedirectToVoiceGen}
                >
                  {process.env.REACT_APP_MKT_SITE_VOICEGEN_LABEL}
                </span>
              </Link> */}
              
              {/* redirects to new inspiration page */}
              <Link className=" w-1/6 flex justify-center" to={"/new-inspiration"}>
                <span className={props.location.pathname.startsWith("/new-inspiration")
                      ? "font-semibold text-xl "
                      : "text-xl "}>
                  {process.env.REACT_APP_MKT_SITE_INSPIRATION_LABEL}
                </span>
              </Link>
              <Link className=" w-1/6 flex justify-center" to={"/catalogs"}>
                <span className={props.location.pathname.startsWith("/catalogs")
                      ? "font-semibold text-xl "
                      : "text-xl "}>
                  CATALOGS
                </span>
              </Link>
              {/* redirects to inspiration page on marketing side */}
              {/* <a
                href={process.env.REACT_APP_MKT_SITE_INSPIRATION}
                className="text-lg mx-5 hover:text-whilte"
              >
                {process.env.REACT_APP_MKT_SITE_INSPIRATION_LABEL}
              </a> */}
              {userData !== null && userData.subscribed === true ? (
              <Link className=" w-1/6 flex justify-center" to={"/projects"}>
                  <span className={props.location.pathname.startsWith("/projects")
                      ? "font-semibold text-xl "
                      : "text-xl "}>
                    PROJECTS
                  </span>
                </Link>
              ) : (
                <a
                  href={process.env.REACT_APP_MKT_SITE_PRICING}
                  className={`text-xl w-1/6 flex justify-center ${(props.location.pathname.includes("/home") || props.location.pathname.includes("/signup")) && "font-semibold"}`}
                >
                  {process.env.REACT_APP_MKT_SITE_PRICING_LABEL}
                </a>
              )}
            </div>
            {/* {
          (['/signin', '/signup', '/forgot-password'].indexOf(props.location.pathname) === -1 
           && String(props.location.pathname).includes('/set-password') === false) ?
          <div className="w-1/2  text-sm lg:flex-grow justify-end flex">
            <input
              onFocus={handleSearch}
              className={`p-2 self-center h-8	border bg-white outline-none text-textGray-100 w-3/12 aisearch 
              ${showProjectModal ? "border-symStrokeGrey placeholder-textGray-100 dis-aisearch" :" cursor-pointer placeholder-black  border-black"}`}  
              type="text" 
              placeholder='AI Search'
              disabled={showProjectModal ? true :false}
            />
          </div>
          : null
        } */}
            <div>
              {userData !== null ? (
                <span className="text-lg mx-5 justify-end row">
                  {userData.productDesc}
                  {((userData?.userType === "ADMIN" && userData?.isSuspended)||(!userData?.subscribed)) && (
                    <img
                      style={{ height: "30px", marginTop:"0px" }}
                      alt="Suspended"
                      data-tip="test"
                      src={suspendedImg}
                    />
                  )}
                </span>
              ) : (
                " "
              )}
            </div>

            <div className="flex ml-6 justify-end">
              {props?.name?.length ? (
                <div>
                  <Link to="/projects">
                    <UserProfile style={{ width: "30px" }} />
                  </Link>
                </div>
              ) : (
                <>
                  <Link
                    // style={{ padding: "10px 25px", minWidth: "98px" }}
                    to="/signin"
                  >
                    <LazyLoadImage
                    src={signInButtonImg                    }
                    className="rounded-2xl h-12 cursor-pointer"
                    />
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        {trackContext.AISearch && (
          <AiSearch
            handleAISearchmusic={handleAISearchmusic}
            handleAISearch={handleAISearch}
            closeProjectModal={closeAISearch}
            checked={trackContext.searchdescription}
          />
        )}
        {showMobileMenu && (
          <MobileMenu
            handleMobileMenuClose={handleMobileMenuClose}
            props={props}
            // handleAISearch={handleAISearch}
            closeProjectModal={closeProjectModal}
            setShowProjectModal={trackContext.setShowProjectModal}
          />
        )}
      </nav>
      {[
        "/signin",
        "/signin/",
        "/signup",
        "/signup/",
        "/signup/#solo",
        "/signup/#solo_pro",
        "/forgot-password",
        "/projects",
        "/favourites",
        "/history",
        "/profile",
        "/billing",
        "/support",
        "/admin",
        "/pricing",
        "/blog-listing",
        "/confirmation",
        "/verification",
        "/choose-plan",
        "/downloads",
        "/licenses",
        "/new-inspiration",
        "/sound-fx"
      ].indexOf(props.location.pathname) === -1 &&
      Constants.KB_LINKS.indexOf(props.location.pathname) === -1 && 
      String(props.location.pathname).includes("/set-password") === false &&
      !props.location.pathname.includes("/playlists/") &&
      !props.location.pathname.includes("/license/") &&
      !props.location.pathname.includes("/insights") &&
      !props.location.pathname.includes("/artist") && 
      !props.location.pathname.includes("/album") &&
      !props.location.pathname.includes("/catalogs") &&
      !props.location.pathname.includes("/home") &&
      !props.location.pathname.includes("/library")
       ? (
        <div className="search-wrapper hidden md:flex flex-wrap xl:mx-20 md:pl-6 bg-white pt-20 lg:ml-20">
          <div className="pr-1">
            <ThumbPrintIcon />
          </div>
          <div className=" self-center h-8 md:w-5/6 xl:w-3/4 border bg-white outline-none text-textGray-100 rounded-full aisearch border-pink  flex">
            <input
              onFocus={handleSearch}
              className={`self-center text-title xl:w-3/4 lg:w-2/3 md:w-2/3 md:truncate bg-white outline-none text-textGray-100 rounded-full 
                  ${
                    trackContext.showProjectModal
                      ? " placeholder-textGray-100 "
                      : " cursor-pointer placeholder-textGray-600  "
                  }`}
              type="text"
              placeholder="Enter a keyword"
              disabled={trackContext.showProjectModal ? true : false}
            />
            <div className="w-2/6 px-1 flex justify-end">
              <Checkbox
                style={{ color: "#e30185" }}
                className="checkbox-input"
                checked={trackContext.searchdescription}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <span className="pt-2 text-title">search in descriptions</span>
            </div>
          </div>
          
          {/* <span onClick={handleClick} disabled={trackContext.isFileUpload} className="justify-end cursor-pointer">
            <img
              src={UploadIconGIF}
              alt="logo"
              className="h-10"
              title="Click to upload an audio file."
            />
            <span className="hide">Click to upload an audio file.</span>
            <input type="file" accept=".mp3,.aac,.m4a,.m4p,.m4b,.mp4,.3gp,.aif,.aiff,.aifc,.ogg,.ogv,.oga,.ogx,.spx,.opus,.ogm,.flac,.oga,.alac,.wav" style={{ display: "none" }} onChange={(e) => uploadAudioFile(e.target.files[0])} ref={hiddenFileInput} />
          </span> */}
        </div>
      ) : null}
    </>
  );
}