import React from "react";
import { useState, useEffect, useContext } from "react";
import API from "../../services/api";
import { Link } from "react-router-dom";
import defaultImage from "../../assets/default_music.png";
import TrackContext from "../../contexts/track-context";
import Helper from "../../services/helper";
import Constants from "../../services/constants";
import MewoService from "../../services/mewoService";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";

const Playlist = (props) => {
  const [playlists, setPlaylist] = useState([]);
  const [noPlaylists, setNoPlaylist] = useState(false);
  const trackContext = useContext(TrackContext);

  useEffect(() => { 
    (async() => {
      await getPlaylists();
    })();
  }, []);

  //fetch all playlists
  const getPlaylists = async() => {
    trackContext.setIsLoading(true);
    let reqObj = {
      url : `public/search/panel`,
      method : 'post',
      data: {
        "query" : "",
        "playlists" :{
        "page": 0,
        "max": Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE
        }
      },
    };
    await MewoService.loadMewoData(reqObj).then((resp) => {
        trackContext.setIsLoading(false);
        if (resp) {
          setPlaylist(resp?.playlists?.hits);
        } else {
          setNoPlaylist(true);
        }
      })
      .catch((err) => {
        trackContext.setIsLoading(false);
        toast(({ closeToast }) => <ErrorToast message={"Error Loading Playlists : " + err?.response?.data?.message}/>);
      });
  };

  return (
    <>
      {noPlaylists && (
        <div className="text-xl text-center text-red-500">
          No Playlists Found
        </div>
      )}
      <div className="bg-white grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-10">
        {playlists?.map((playlist, index, arr) => (
          <>
          <Link to={`/playlists/${playlist?.id}`}>
              <div className=" music-tile" key={playlist?.id}>
                <div className="image-container">
                  {playlist != null && playlist?.image != null ? (
                    <img
                      src={playlist?.image?.large?.url || defaultImage}
                      alt="Album img"
                      className=" sm:flex border sm:cursor-pointer rounded-2xl "
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <img
                      src={defaultImage}
                      alt="Album img"
                      className="sm:flex border-b sm:cursor-pointer music-image justify-center align-middle "
                      width="100%"
                      height="100%"
                    />
                  )}
                  <span className="play_icon" title="Play"></span>
                </div>
                <div className=" p-2 ">
                  <h3 className="album-title" title={playlist?.title}>
                    {playlist?.title}
                  </h3>
                  <p
                    className="text-sm font-light text-left description"
                    title={playlist.descriptions[0]?.value}
                  >
                    {playlist.descriptions[0]?.value || playlist?.title}
                  </p>
                </div>
              </div>
            </Link>
          </>
        ))}
      </div>
    </>
  );
};

export default Playlist;