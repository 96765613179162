import React, { useState, useEffect } from 'react';
import API from '../../services/api';
import './style.css';
import Constants from '../../services/constants';
import Signup from './signup';
import FreeSignUp from './free-user-signup.js';
import Validator from '../../services/validator';
import Helper from '../../services/helper';
import SignUpContext from '../../contexts/signup-context';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LinearProgress } from '@material-ui/core';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import TrackContext from '../../contexts/track-context';
import CookieManager from '../../services/CookieManager';

function Register({ location }) {

  const userDetails = {
    'first_name': '',
    'last_name': '',
    'email': '',
    'confirmEmail': '',
    'password': '',
    'confirmPassword': '',
    'tos': false,
    'userType': '',
    'country': '',
    'phone': '',
    'companyName': '',
    'companyAddress': '',
    'taxNumber': '',
    'subscriptionPeriod': '',
    'taxId': '',
    'billingAddress': '',
    'billingCity': '',
    'billingPinCode': '',
    'countryName': '',
    'check':false
  };
  const [userData, setUserData] = useState({ ...userDetails })
  const [showAlertMssg, setShowAlertMssg] = useState(false);
  const [alertMssg, setAlertMssg] = useState({});
  // const [showSigninForm, setShowSigninForm] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(true);
  const [helperText, setHelperText] = useState({});
  const [showOverlayLoader, setShowOverlayLoader] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [tax, setTax] = useState(false);

  const userType = JSON.parse(process.env.REACT_APP_USER_TYPE);
  // console.log({cookieUserData});

  useEffect(() => {

    let hash = ('' + location.hash).toUpperCase().slice(1);
    // console.log({hash});
    let userTypeArr = [
      userType.SOLO,
      userType.SOLO_PRO,
      userType.ORGANIZATION,
      userType.FREE
    ];
    let index = userTypeArr.indexOf(hash);
    if (index === -1) {
      // setUserNotFound(true);
    } else {
      // setUserNotFound(false);
      let temp = { ...userData };
      temp.userType = userTypeArr[index];
      // console.log('UserType:', temp.userType);
      setUserData(temp);
    }
  }, [location])

  let validator = new Validator();

  const handleInputChange = (e, key) => {
    let value = key === 'tos' ? e : e.target?.value;
    // console.log('Value:', value, ' Key:', key);
    // localStorage.setItem(key, JSON.stringify(value));
    // console.log('Local Storage First Name',JSON.parse(localStorage.getItem(key)));
    if (key === 'first_name') {
      let res = validator.setValue(value, 'Full Name').isNotEmpty().isString();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }

    if (key === 'email') {
      let res = validator.setValue(value, 'Email').isNotEmpty().isEmail();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }

    if (key === 'confirmEmail') {
      if (userData.email !== value) {
        let temp = { ...helperText };
        temp[key] = "Email addresses do not match";
        setHelperText(temp);
      } else {
        let temp = { ...helperText };
        temp[key] = "";
        setHelperText(temp);
      }
    }

    if (key === 'password') {
      let res = validator.setValue(value, 'Password').isNotEmpty().isString().checkPasswordStrength();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }

    if (key === 'confirmPassword') {
      if (userData.password !== value) {
        let temp = { ...helperText };
        temp[key] = "Passwords do not match";
        setHelperText(temp);
      } else {
        let temp = { ...helperText };
        temp[key] = "";
        setHelperText(temp);
      }
    }
    if (key === 'billingAddress') {
      let res = validator.setValue(value, 'Billing Address').isNotEmpty().isString();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'billingCity') {
      let res = validator.setValue(value, 'Billing City').isNotEmpty().isString();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'check') {
      value = key === 'check' ?e.target.checked:e;
      let res = validator.setValue(value, 'check').isNotEmptycheck()
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'billingPinCode') {
      let res = validator.setValue(value, 'Billing Postcode').isNotEmpty().isString();


      if (isNaN(userData.billingPinCode) === true) {
        let temp = { ...helperText };
        temp[key] = "A valid postcode is required";
        setHelperText(temp);
      }
      else if (String(userData.billingPinCode).length > 9 || String(userData.billingPinCode).length < 4) {
        let temp = { ...helperText };
        temp[key] = "Enter proper post code";
        setHelperText(temp);
      }
      else {

        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);

      }
    }

    if (key === 'companyName') {
      if (userData.userType === userType.SOLO || userData.userType === userType.FREE) {

        let res = validator.setValue(value, 'Company Name');
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }
      if (userData.userType === userType.SOLO_PRO) {
        let res = validator.setValue(value, 'Company Name').isMandatory();
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }
    }

    if (key === 'taxNumber') {

      if (userData.userType === userType.SOLO || userData.userType === userType.FREE) {

        let res = validator.setValue(value, 'GST Number').doesNotContainSpecialChars().isGSTINSOLO();
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }

      if (userData.userType === userType.SOLO_PRO) {

        let res = validator.setValue(value, 'GST Number').isMandatory().doesNotContainSpecialChars().isGSTIN();
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }

    }

    let tempUserData = { ...userData };
    // console.log('Temp User Data:', tempUserData);
    tempUserData[key] = value;
    setUserData(tempUserData);
  };

  const handleShowSignInForm = () => {
    // setShowSigninForm(true);
    setShowSignupForm(false);
  }

  const handleShowSignUpForm = () => {
    // setShowSigninForm(false);
    setShowSignupForm(true);
  }

  const setSubscriptionPeriod = (period) => {
    let temp = { ...userData };
    temp.subscriptionPeriod = period;
    setUserData(temp);
  }

  const handleSubmit = async (paymentDetails) => {
    let counter = 0;
    let temp = { ...helperText };
    // console.log('[start] : ', counter);

    let resFirstName = new Validator(userData.first_name, 'Full Name').isNotEmpty().isString();
    temp['first_name'] = resFirstName.mssg;
    resFirstName.status === false && counter++;

    let resEmail = new Validator(userData.email, 'Email').isNotEmpty().isString().isEmail();
    temp['email'] = resEmail.mssg;
    resEmail.status === false && counter++;

    let resPassword = new Validator(userData.password, 'Password').isNotEmpty().checkPasswordStrength();
    temp['password'] = resPassword.mssg;
    resPassword.status === false && counter++;
    // COMPANY NAME IS NOT COMPULSORY FOR SOLO USER
    if (userData.userType !== userType.SOLO || userData.userType !== userType.FREE) {
      let resCompanyName = new Validator(userData.companyName, 'Company Name').isMandatory();
      temp['companyName'] = resCompanyName.mssg;
      // temp['companyName'] = 'It is a mandatory field';
      resCompanyName.status === false && counter++;
    }

    // COMPANY NAME IS NOT COMPULSORY FOR SOLO USER
    if (userData.userType === userType.SOLO || userData.userType === userType.FREE) {
      let resCompanyName = new Validator(userData.companyName, 'Company Name');
      temp['companyName'] = resCompanyName.mssg;
      // temp['companyName'] = 'It is a mandatory field';
      resCompanyName.status === false && counter++;
    }

    //new-code    
    let resBillingAddress = new Validator(userData.billingAddress, 'Billing Address').isNotEmpty().isString();
    temp['billingAddress'] = resBillingAddress.mssg;
    resBillingAddress.status === false && counter++;

    let resBillingCity = new Validator(userData.billingCity, 'Billing City').isNotEmpty().isString();
    temp['billingCity'] = resBillingCity.mssg;
    resBillingCity.status === false && counter++;

    // let resBillingPinCode = new Validator( userData.billingPinCode, 'Billing PinCode' ).isNotEmpty().isString();
    // temp['billingPinCode'] = resBillingPinCode.mssg;
    // resBillingPinCode.status === false && counter++;

    // 

    // GST Type IS  Mandatory & Validate GST FOR SOLO_PRO USER
    if (userData.userType !== userType.SOLO || userData.userType !== userType.FREE) {
      let resTaxNumber = new Validator(userData.taxNumber, 'GST Number').isMandatory().doesNotContainSpecialChars().isGSTIN();
      temp['taxNumber'] = resTaxNumber.mssg;
      resTaxNumber.status === false && counter++;
    }

    //  GST Type IS NOT Mandatory & GST Validation Check FOR SOLO USER
    if (userData.userType === userType.SOLO || userData.userType === userType.FREE) {
      let resTaxNumber = new Validator(userData.taxNumber, 'GST Number').doesNotContainSpecialChars().isGSTINSOLO();
      temp['taxNumber'] = resTaxNumber.mssg;
      // resTaxNumber.status === false && counter++;
    }

    if (userData.userType === userType.SOLO || userData.userType === userType.FREE) {
      let resChecked = new Validator(userData.check, 'check ').isNotEmptycheck()
      temp['check'] = resChecked.mssg;
      resChecked.status === false && counter++;
    }
     
    if (userData.password !== userData.confirmPassword) {
      temp['confirmPassword'] = "Passwords do not match";
      counter++;
    }

    if (userData.password !== userData.confirmPassword) {
      temp['confirmEmail'] = "Email addresses do not match";
      counter++
    }

    //

    //
    if (userData.subscriptionPeriod === '' || userData.subscriptionPeriod === undefined) {
      temp['subscriptionPeriod'] = 'Choose a plan';
      counter++;
    }

    setHelperText(temp);
    // console.log('[payment details] : ', paymentDetails, counter);
    if ((paymentDetails !== null && window.location.hash === '#solo') || (paymentDetails !== null && window.location.hash === '#solo_pro')) {
      // if (1) return ;
      setShowOverlayLoader(true);
      paymentDetails = { id: paymentDetails };
      //console.log({...userData, ...paymentDetails, ...tax });
      API.createUser({ ...userData, ...paymentDetails, ...tax }).then(res => {
        console.log('user created : ', res);
        if (res.success === false) {
          setShowAlertMssg(true);
          setTimeout(() => { setShowAlertMssg(false) }, 7000);
          setAlertMssg({ success: res.success, mssg: res.mssg, bgColor: 'red' });
          toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
          setShowOverlayLoader(false);
          // window.location.reload()
        } else {
          // toast(({ closeToast }) => <SuccessToast message={res.mssg}/>);
          setUserData(userDetails);
          setShowAlertMssg(true);
          setTimeout(() => { setShowAlertMssg(false) }, 7000);
          setAlertMssg({ success: res.success, mssg: res.mssg, bgColor: 'blue' });
          setShowOverlayLoader(false);
          setTimeout(() => {
            // window.location.reload();
            window.location = process.env.REACT_APP_CONFIRMATION;
          }, 3000)
        }
      }
      );
    }
    if (window.location.hash === '#free') {
      setShowOverlayLoader(true);
      API.createUser({ ...userData, ...paymentDetails, ...tax }).then(res => {
        // console.log('user created in Else: ', res);
        // console.log('user Data : ', userData);
        // console.log('user Payment Details : ', paymentDetails);
        // console.log('user Tax : ', tax);
        if (res.success === false) {
          setShowAlertMssg(true);
          setTimeout(() => { setShowAlertMssg(false) }, 7000);
          setAlertMssg({ success: res.success, mssg: res.mssg, bgColor: 'red' });
          toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
          setShowOverlayLoader(false);
          // window.location.reload()
        } else {
          toast(({ closeToast }) => <SuccessToast message={res.mssg} />);
          setUserData(userDetails);
          setShowAlertMssg(true);
          setTimeout(() => { setShowAlertMssg(false) }, 7000);
          setAlertMssg({ success: res.success, mssg: res.mssg, bgColor: 'blue' });
          setShowOverlayLoader(false);
          setTimeout(() => {
            // window.location.reload();
            window.location = process.env.REACT_APP_CONFIRMATION;
          }, 1000)
        }
      }
      );
    }
  }

  const SignupProps = {
    ...userData,
    handleInputChange,
    handleSubmit,
    handleShowSignInForm,
    helperText,
    setSubscriptionPeriod,
    setHelperText,
    location,
    setUserNotFound,
    setUserData,
    userData,
    tax,
    setTax,
    setShowOverlayLoader,
    userDetails
  }


  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <div className=''>
        {
          userNotFound
            ? <div className='flex w-full h-screen justify-center'>
              <div className='self-center'>
                <h1 className='text-center text-4xl'>404</h1>
                <h1 className='text-center text-xl'>Page not found</h1>
              </div>
            </div>
            :
            <>
              {
                showOverlayLoader &&
                <div className='overlay-loader flex fixed top-0 left-0 w-full z-10 h-full justify-center'>
                  <div className='self-center p-10 bg-white rounded text-center shadow'>
                    <div className='text-lg text-blue-700 font-bold mb-1'> PROCESSING </div>
                    <LinearProgress />
                    <div className='text-lg text-blue-700 mt-4'> Please wait while we set up things for you! </div>
                  </div>
                </div>
              }
              {showSignupForm &&
                <SignUpContext.Provider value={{ ...SignupProps }}>
                  {location.hash !== '#free' ?
                    <Signup />
                    :
                    <FreeSignUp />

                  }
                </SignUpContext.Provider>
              }

              {
                // showSigninForm && <Signin {...SigninProps}/> 
              }
            </>
        }
      </div>
    </GoogleReCaptchaProvider>
  )

}

export default Register;