import React from "react";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import { BouncingLoader } from "../../pure-components/Loader";
import { Link } from "react-router-dom";
import Helper from "../../services/helper";
import defaultImage from "../../assets/default_music.png";
import CookieManager from "../../services/CookieManager";
import MewoService from "../../services/mewoService";

import { useParams } from "react-router-dom";


const CatalogAlbum = (props) => {
  
  const ref = useRef(null);
  
  const [searchText,setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [albumList, setAlbumList] = useState([]);
  const [catalogData, setCatalogData] = useState(null);
  const {catalogId, title}=useParams();
  
  useEffect(() => {
      (async() => {
        await loadCatalogData();
        await loadAlbumList()
      })()
    
  }, [])

  useEffect(() => {
    // console.log(pageNo)
  }, [pageNo, catalogData])

  useEffect(() => {
    if(loadMore)
      (async() => {
        await loadAlbumList()
      })()
    
  }, [loadMore])

  const loadAlbumList = async() => {
    setLoading(true);
    let reqObj = {
      url : `public/catalogs/${catalogId}/albums?page=${pageNo}&max=100`,
      method : 'get'
    };
    await MewoService.loadMewoData(reqObj).then((resp) => {
      let tempDataList = pageNo > 0 ? [...albumList] : [];
      if(resp?.data?.length > 0)
        tempDataList.push(...resp?.data)

      if(resp?.data?.length > 0 && tempDataList?.length < resp?.total){
        setAllDataLoaded(false);
        setPageNo(pageNo+1);
      }else
        setAllDataLoaded(true);

      setAlbumList(tempDataList);
    }).catch((err) => {
      console.log("Catalog Album List Loading Error : ", err);
      Helper.handleError(err);
    });
    setLoadMore(false)
    setLoading(false);
  }

  const loadCatalogData = async() => {
    let reqObj = {
      url : `public/catalogs/${catalogId}`,
      method : 'get'
    };
    await MewoService.loadMewoData(reqObj).then((resp) => {
      setCatalogData(resp);
    }).catch((err) => {
      console.log("Catalog Data Loading Error : ", err);
      Helper.handleError(err);
    });
  }

  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreData, options);
      let target = document.querySelector("#load-more-data");
      observer.observe(target);
    }
  });


  const loadMoreData = async(entries, observer) => {
    entries.map(async(entry) => {
      if (entry.isIntersecting) {
        if (!allDataLoaded) {
          setLoadMore(true);
        }
      }
    });
  };

  return (
    <div className="lg:px-8 items-center flex-1 lg:pt-3 sm:mx-8 mx-8 lg:mx-20 playlist">
      
      {/* {!loading &&
        <div className="flex w-full">
          <div className=" sm:w-10/12 lg:block">
            <span
              className="text-base "
            >
              Albums for Catalog :
            </span>
            <div className="flex mt-3 pb-4 ">
              <div
                className="flex px-1 py-1 max-h-7 cursor-pointer bg-black text-white"
              >
                {title}
              </div>
          </div>
          </div>
        </div>
      } */}
      {(!loading && catalogData && <div className="pb-3">
        <div className="flex">
          <div className=" w-full lg:w-3/12 rounded-md " >
            <div className="image-container">
              {catalogData?.image?.large?.url ? (
                <img
                  src={catalogData?.image?.large?.url || defaultImage}
                  alt="Catalog img"
                  className=" sm:flex border rounded-2xl "
                  width="100%"
                  height="100%"
                />
              ) : (
                <img
                  src={defaultImage}
                  alt="Catalog img"
                  className="sm:flex border-b music-image justify-center align-middle "
                  width="100%"
                  height="100%"
                />
              )}
            </div>
          </div>
          <div className="w-full lg:w-8/12 pl-5">
            <h1 className="mb-2 text-xl" >{catalogData?.name}</h1>
            <h1 className="w-full lg:w-3/5 text-justify text-description flex-row self-center text-all"> {catalogData?.descriptions[0]?.value}</h1>
          </div>
        </div>
      </div>)}
      <div className="border-t my-2"></div>
      <div className="w-full">
        {/* {loading &&  
          <div className='w-full'>
            <BouncingLoader />
          </div>
        } */}
        
       
        {!loading && !(albumList?.length > 0) ? (
          <div className="text-xl text-center text-red-500 my-5">
            No Album Found
          </div>
        ) : 
        (<div className="bg-white grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-10">
          {albumList?.map((elem, index, arr) => (
            <>
            <Link to={`/album/${elem?.id}`}>
                <div className=" music-tile" key={elem?.id}>
                  <div className="image-container">
                    {elem != null && elem?.image != null ? (
                      <img
                        src={elem?.image?.large?.url || defaultImage}
                        alt="Album img"
                        className=" sm:flex border sm:cursor-pointer rounded-2xl "
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <img
                        src={defaultImage}
                        alt="Album img"
                        className="sm:flex border-b sm:cursor-pointer music-image justify-center align-middle "
                        width="100%"
                        height="100%"
                      />
                    )}
                    <span className="play_icon" title="Play"></span>
                  </div>
                  <div className=" p-2 ">
                    <p className="text-sm"><b>{elem?.title}</b> </p>

                    {elem.descriptions?.length > 0 &&
                      <p className="text-sm font-light text-left description"
                        title={elem?.descriptions[0]?.value}
                      >
                        {elem?.descriptions[0]?.value || elem?.title}
                      </p>
                    }
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>)
        }
        {(!allDataLoaded || loading) && (
          <div id="load-more-data" ref={ref} className="p-2">
           <BouncingLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default CatalogAlbum;