import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactComponent as Logo } from "../../assets/logo-day.svg";
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import { toast } from 'react-toastify';

import bannerImage from "../../assets/landingPage/new-banner.png";
import audioQualityImg from "../../assets/landingPage/audio_quality.png"
import "./style.css";
import pageModelData from "./modelData.json"
import GIFContainer from "./gif-container";
import ProcessStep from "./process-step";
import PlanContainer from "./plan-container"
import Constants from "../../services/constants";
import ToggleSwitch from "./ToggleSwitch";
import GifPopup from "./gif-popup";
import API from "../../services/api";
import { BouncingLoader } from "../../pure-components/Loader";
export default function () {

    const[modelData, setModelData] = useState(null);
    const [yearlyPlan, setYearlyPlan] = useState(true);
    const [plans, setPlans] = useState(null);
    const [popup, setPopup] = useState(false);
    const [GIFLink, setGIFLink] = useState(null);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [allProducts, setAllProducts] = useState(null);
    // const [costData, setCostData] = useState(null);
    const [costData, setCostData] = useState(null); 
    // useEffect(() => {
    //     console.log("monthly plan : ", yearlyPlan)
    // },[yearlyPlan])
    useEffect(() => {
        if(pageModelData){
            pageModelData.plans = pageModelData.plans?.map(elem => {
                if(elem.id !== Constants.SUBSCRIPTION_TYPE.CORPORATE)
                    elem.link = elem.link.replace("$[BASE_URL]", process.env.REACT_APP_PUBLIC_URL + 'signup/');
                // else
                // elem.link = elem.link.replace("$[FORM_BASE_URL]", window.location.host);
                
                return elem;
            }) 
            setModelData(pageModelData);
            (async()=>{
                await loadProducts();
            })();
        }
    },[])

    const loadProducts = async()=>{
        await API.getProducts().then((res) => {
            // console.log("loadProductsResp : ", res.data);
            setAllProducts(res.data);
            }).catch(err => {
              console.log("Products loading error : "+ err)
              toast(({ closeToast }) => <ErrorToast message={"Products loading error"} />);
            })
        setLoadingProducts(false);
    }

    const updatePlanData = (planCost)=>{
        if(!planCost)
            planCost = costData;
        let tempPlans = [];
        if(yearlyPlan)
            tempPlans = plans?.map(elem=>{
                if(elem.id === "SOLO")
                    elem.cost = parseInt(planCost?.solo.yearly / 12);
                if(elem.id === "PRO")
                    elem.cost = parseInt(planCost?.pro.yearly / 12);
                return elem;
            })
        else
            tempPlans = plans?.map(elem=>{
                if(elem.id === "SOLO")
                    elem.cost = parseInt(planCost?.solo.monthly);
                if(elem.id === "PRO")
                    elem.cost = parseInt(planCost?.pro.monthly);
                return elem;
            })
             
              
        setPlans(tempPlans);
    }
    useEffect(() => {
        if(modelData?.plans)
            setPlans(modelData.plans);
    }, [modelData])

    useEffect(()=>{
        if(plans?.length > 0 && costData)
            updatePlanData();
    },[yearlyPlan])

    useEffect(()=>{
        if(allProducts){
            let tempData = {solo:{monthly: 0, yearly: 0}, pro:{monthly: 0, yearly: 0}};
            for(const prod of allProducts){
                if(prod?.name?.indexOf("SOLO_PRO_") > -1){
                    if(prod?.name?.indexOf("_MONTHLY") > -1){
                        tempData.pro.monthly = parseFloat(prod.unit_amount_decimal)/100;
                    }else if(prod?.name?.indexOf("_YEARLY") > -1){
                        tempData.pro.yearly = parseFloat(prod.unit_amount_decimal)/100;
                    }
                }else if(prod?.name?.indexOf("SOLO_") > -1){
                    if(prod?.name?.indexOf("_MONTHLY") > -1){
                        tempData.solo.monthly = parseFloat(prod.unit_amount_decimal)/100;
                    }else if(prod?.name?.indexOf("_YEARLY") > -1){
                        tempData.solo.yearly = parseFloat(prod.unit_amount_decimal)/100;
                    }
                }
                 
            }
            
            // console.log("cost data ; ", tempData);
            setCostData(tempData);
            updatePlanData(tempData);
                     
        }
    },[allProducts])

    useEffect(()=>{
        if(costData && plans?.length > 0){
            updatePlanData();
        }
    },[costData])
  return (
    <div className="w-full flex flex-wrap canvas">

        <div className="w-full flex mx-4">
            <div className="w-full pt-16 rounded-2xl" >
                <LazyLoadImage
                src={bannerImage}
                effect="blur"
                alt="SyncMama - The world's most complete music library by subscription"
                width="100%"
                style={{ height: "100%" }}
                className="rounded-2xl"
                />
            </div>
      
        </div>
        <div className="mb-4 mx-4 w-full bg-white rounded-2xl" style={{"marginTop" : "-10px"}}>
            <div className="w-full flex-wrap py-8">
                <span className="w-full flex justify-center pt-16">
                    <span className="header-text-black">FIND YOUR TRACK IN </span>&nbsp;&nbsp;
                    <span className="header-text-pink">60 SECONDS! </span>
                </span>
                <span className="w-full flex justify-center topic-title py-2">
                    Powerful keywords + AI search combination
                </span>
            </div>
            <div className="w-full flex flex-wrap py-2 px-16">
                {pageModelData.gifBlocks?.map((elem, index) => (
                    <>
                        <GIFContainer {...elem} idx= {index} setPopup={setPopup} setGIFLink={setGIFLink}/>
                    </>
                ))}
            </div>
            <div className="w-full flex-wrap py-8">
                <span className="w-full flex justify-center py-2 pt-16">
                    <span className="header-text-black">MUSIC LICENSING </span>&nbsp;&nbsp;
                    <span className="header-text-pink">REDEFINED </span>
                </span>
                <span className="w-full flex justify-center topic-title py-2 pb-6">
                    Bye-bye royalty-free. Hello real licenses!
                </span>
            </div>
            <div className="w-full flex flex-wrap py-4 px-16">
                {pageModelData.steps?.map((elem, index) => (
                    <>
                        <ProcessStep {...elem} idx= {index} />
                    </>
                ))}
            </div>
            <div className="w-full " id="card-container">
                <div className="w-full flex-wrap py-6">
                    <span className="w-full flex justify-center py-4 pt-16">
                        <span className="header-text-black">MAMA HAS A </span>&nbsp;&nbsp;
                        <span className="header-text-pink">PLAN FOR YOU </span>
                    </span>
                </div>
            
                <div className="w-full flex justify-center pb-4">
                    <span className="w-3/12 flex border rounded-2xl bg-gray-100 py-2">
                        <span className="w-4/12 flex topic-title px-6 justify-start">Monthly </span>
                        <span className="w-4/12 flex px-6 justify-center" style={{marginTop: "-10px"}}>

                            <ToggleSwitch label={" "} value={yearlyPlan} setValue={setYearlyPlan}/>
                        </span>
                        <span className="w-4/12 flex topic-title px-6 justify-end">Yearly </span>
                    </span>
                </div>

                <div className="w-full flex flex-wrap py-2 px-16">
                    {loadingProducts ? 
                        <div className='w-full'>
                            <BouncingLoader />
                        </div>:
                        plans?.map(plan => (
                        <>
                            <PlanContainer plan={plan} />
                        </>
                    ))}
                </div>
            </div>
            <div className="w-full flex justify-center">
                <LazyLoadImage
                src={audioQualityImg}
                className="w-8/12 h-30"
                />
            </div>
        </div>

       
        <div className="w-full flex justify-center">
            <Logo alt="brand-logo" />
        </div>
        <div className="m-4 w-full flex">
            <div className="w-full flex rounded-2xl px-20 py-10 bg-black justify-center">
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/about`}>ABOUT US</a>
                </span>
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/terms`}>TERMS OF SERVICE</a>
                </span>
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/privacy`}>PRIVACY POLICY</a>
                </span>
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/cookies`}>COOKIE POLICY</a>
                </span>
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/faqs`}>FAQS</a>
                </span>
                <span className="w-2/12 text-white justify-center">
                    <a className="w-full flex justify-center" href={`/contact`}>CONTACT US</a>
                </span>
            </div>
        </div>
        <div className="w-full flex justify-center mb-10">
            © {new Date().getFullYear()} SyncMama. All rights reserved.
        </div>
        {popup ? <GifPopup setPopup={setPopup} GIFLink={GIFLink} /> : null}
    </div>
  );
}
