import React, { useEffect, useState } from "react"
import API from '../../services/api';

import CookieManager from "../../services/CookieManager";
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import { toast } from 'react-toastify';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TableComp from "./tableComp";
import FilterComp from "./filterComp";
import InsightImg from "../../assets/insights.png"
import LicenseInsightImg from "../../assets/license_insights.png"


const licenseTableHeaderStr="License Number,License Date,Licensee,Project Name,Release Date,Distribution Channel";
const projectTableHeaderStr="Title,Created By, Created By Email,Track,Track Duration,Created On,Edited On,Description";


export default function  LicenseInsight(props){

    const profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
  
    const [staffUsers, setStaffUsers] = useState([]);

    const [loadingStaffs, setLoadingStaffs] = useState(false)
    const [loadingLicenses, setLoadingLicenses] = useState(false);
    const [licensePageNo, setLicensePageNo] = useState(0);
    const [licenseFilter, setLicenseFilter] = useState({selectedStaffs : [], dateRange : {startDate : new Date(new Date().setMonth(new Date().getMonth()-6)), endDate : new Date()}})
    const [licenseList, setLicenseList] = useState([]);
    const [licenseCount, setLicenseCount] = useState(0);
    const [licenseTableHeaders, setLicenseTableHeaders] = useState(licenseTableHeaderStr.split(','));
    const [licenseTableData, setLicenseTableData] = useState([]);
    const [licenseCSVData, setLicenseCSVData] = useState([]);

    const [expandedElement, setExpandedElement] = useState('');
    
    const [projectFilter, setProjectFilter] = useState({selectedStaffs : [], dateRange : {startDate : new Date(new Date().setMonth(new Date().getMonth()-6)), endDate : new Date()}})
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [projectCount, setProjectCount] = useState(0);
    const [projectTableHeaders, setProjectTableHeaders] = useState(projectTableHeaderStr.split(','));
    const [projectTableData, setProjectTableData] = useState([]);
    const [projectCSVData, setProjectCSVData] = useState([]);

    useEffect(() => {
        (async () => {
          await fetchCopStaffs();
        })();
    }, [])


    useEffect(() => {
        if(licenseList?.length > 0){
            let tempTableData = [];
            
            //setting License CSV Data
            let tempCSVData = [];
            tempCSVData.push(licenseTableHeaderStr.split(','));
            
            licenseList.forEach(item =>{ 
                let licensor=item.licensor ||"";
                licensor=licensor.replace(/\n/g," ");
                licensor=licensor.replace(/,/g," ");
                let lic1=`${item.licenseNumber},${item.createdAt ?new Date(item.createdAt).toDateString():""},${item.licensee ? item.licensee.first_name :""},${item.project? item.project.title.replace(/,/g," ") :""},${item.releaseDate ?new Date(item.releaseDate).toDateString():""},${item.distributionChannel?.replace(/,/g," ")?.replace(/\n/g," ") ||""}`;
                tempTableData.push(lic1.split(','))
                tempCSVData.push(lic1.split(","));
            })

            setLicenseTableData(tempTableData)
            setLicenseCSVData(tempCSVData)
        }
    }, [licenseList])
    
    const fetchCopStaffs =async()=>{
        setLoadingStaffs(true);
        let filter = {userType : "CORPORATE"};
        filter["admin"] = profileData.email;

        await API.getUserList({filter:filter}).then(res => {
            if (res.success){
                let tempStaffArr = [];
                res.data.map(staff =>{tempStaffArr.push({label : staff.first_name + ' ' + staff.last_name , value : staff._id })})
            
                setStaffUsers(tempStaffArr);
            }
            else
            toast(({ closeToast }) => <SuccessToast  message={res.msg}/>);

            setLoadingStaffs(false);
        })
    }
    
    const handleLicenseFilterStaffSelection = (e) => {
        setLicenseFilter({...licenseFilter, selectedStaffs : e})
    } 

    const setLicenseFilterDates = (e) => {
        setLicenseFilter({...licenseFilter, dateRange : e})
    }

    const loadLicenseData = async() => {
        //re-setting csv data 
        setLicenseCSVData([]);

        let userIds = ["64181898884b6090bc2ff5aa", "6405b9608a3d6f7f1404e08f", "6405b9608a3d6f7f1404e08f"];
        if(licenseFilter?.selectedStaffs?.length === 0 ){
            toast(({ closeToast }) => <ErrorToast message={`Please select user(s) and try again.`} />);
            return;
        }
        
        setLoadingLicenses(true);
        licenseFilter.selectedStaffs.map(staff => {
            userIds.push(staff.value);
        })
        let filter = {licensee : {'$in' : userIds}, "$or" : [
            {"$and":[{createdAt:{"$gte":licenseFilter.dateRange.startDate}},{createdAt:{"$lte":licenseFilter.dateRange.endDate}} ]},
            {"$and":[{updatedAt:{"$gte":licenseFilter.dateRange.startDate}},{updatedAt:{"$lte":licenseFilter.dateRange.endDate}} ]}
        ]}
        
        await API.getLicenses({query : filter})
        .then(resp => {
          if (resp.success) {
            setLicenseList(resp.data)
            setLicenseCount(resp.count);
          }
          else {
            toast(({ closeToast }) => <ErrorToast message={`Error : ${resp.status}: ${resp?.statusText}`} />);
          }
          setLoadingLicenses(false);
        }).catch((err) => {
            setLoadingLicenses(false);
            toast(({ closeToast }) => <ErrorToast message={`Error : ${err.status}: ${err?.message}`} />);
        })
        
    }

    const handleProjectFilterStaffSelection = (e) => {
        console.log(e)
        setProjectFilter({...projectFilter, selectedStaffs : e})
    } 

    const setProjectFilterDates = (e) => {
        setProjectFilter({...projectFilter, dateRange : e})
    }

    useEffect(() => {
        if(projectList?.length > 0){
            let tempTableData = [];
            
            //setting License CSV Data
            let tempCSVData = [];
            tempCSVData.push(projectTableHeaderStr.split(','));
            
            projectList.forEach(item =>{ 
                let proj1=`${item.title? item.title.replace(/,/g," ") :""},${item.userId? item.userId.first_name.replace(/,/g," ") :""},${item.userId? item.userId.email.replace(/,/g," ") :""}`;
                let proj2 = `${item.createdAt ?new Date(item.createdAt).toDateString():""},${item.updatedAt ?new Date(item.updatedAt).toDateString():""},${item.description? item.description.replace(/,/g," ").replace(/\n/g," ") :""}`;
               
                let trackDetails=item.trackIds ? item.trackIds :[]
                trackDetails.forEach(track=>{
                    if(track){
                        let tempRowData = `${proj1},${track.trackId?.title || ""},${track.trackId?.duration ? new Date(parseInt(track.trackId.duration)* 1000).toISOString().substr(14, 5) : ""},${proj2}`;
                        tempTableData.push(tempRowData.split(','));
                        tempCSVData.push(tempRowData.split(","));
                    }
                })
            })

            setProjectTableData(tempTableData)
            setProjectCSVData(tempCSVData)
        }
    }, [projectList])

    const loadProjectData = async() => {
        //re-setting csv data
        setProjectCSVData([]);

        let userIds = ["64181898884b6090bc2ff5aa", "6405b9608a3d6f7f1404e08f", "6405b9608a3d6f7f1404e08f"];
        if(projectFilter.selectedStaffs?.length === 0 ){
            toast(({ closeToast }) => <ErrorToast message={`Please select user(s) and try again.`} />);
            return;
        }
        
        setLoadingProjects(true);
        projectFilter.selectedStaffs.map(staff => {
            userIds.push(staff.value);
        })
        let filter = {userId : {'$in' : userIds}, "$or" : [
            {"$and":[{createdAt:{"$gte":projectFilter.dateRange.startDate}},{createdAt:{"$lte":projectFilter.dateRange.endDate}} ]},
            {"$and":[{updatedAt:{"$gte":projectFilter.dateRange.startDate}},{updatedAt:{"$lte":projectFilter.dateRange.endDate}} ]}
        ]}
        
        await API.loadProjects({query : filter})
        .then(resp => {
          if (resp.success) {
            setProjectList(resp.data)
            setProjectCount(resp.count);
          }
          else {
            toast(({ closeToast }) => <ErrorToast message={`Error : ${resp.status}: ${resp?.statusText}`} />);
          }
          setLoadingProjects(false);
        }).catch((err) => {
            setLoadingProjects(false);
            toast(({ closeToast }) => <ErrorToast message={`Error : ${err.status}: ${err?.message}`} />);
        })
        
    }

    return (
    <>    
        <div className="w-full flex mt-20 px-20">
            <img
                className="mr-2"
                style={{ height: "36px" }}
                alt="Suspended"
                src={InsightImg}
            />
            <h1 className="text-lg py-2">Insights </h1>
        </div>
        <div className='flex flex-wrap p-1 m-1 bg-white lg:mx-20 border border-symStrokeGrey last:border-b-0'>
            <div className='w-full center-align'>
                <div className="w-10/12 flex">
                    <img
                        className="mr-2"
                        style={{ height: "36px" }}
                        alt="Suspended"
                        src={LicenseInsightImg}
                    />
                    <h1 className="text-lg">License Insights </h1>
                </div>
                <div className="w-2/12" style={{textAlign : 'right'}}>
                {expandedElement === 'LICENSE' ?
                <ExpandLessIcon
                onClick={() => setExpandedElement('')}
                style={{ fontSize: "35px" }}
                className="trackicon self-center cursor-pointer text-gray-700"
                />
                : 
                <ExpandMoreIcon
                onClick={() => setExpandedElement('LICENSE')}
                style={{ fontSize: "35px" }}
                className="trackicon self-center cursor-pointer sm:text-gray-700"
                />
                }
                </div>
            </div>
            
            {expandedElement === 'LICENSE' &&
            <>
                <FilterComp 
                selectedUsers = {licenseFilter.selectedStaffs}
                users = {staffUsers}
                handleUserSelection = {handleLicenseFilterStaffSelection}
                startDate = {licenseFilter.dateRange.startDate}
                endDate = {licenseFilter.dateRange.endDate}
                setDateRangeValues = {setLicenseFilterDates}
                loadData = {loadLicenseData}
                buttonTitle = {"View Licenses"}
                csvData = {licenseCSVData}
                />

                <div className="w-full center-align" >
                    {loadingLicenses ? 
                    <label className="w-full " style={{"textAlign": "center", "color": "green"}}>Loading Licenses... Please Wait...</label> 
                    :
                    licenseList?.length > 0 ? 
                    <TableComp 
                    totalCount = {licenseCount}
                    headers = {licenseTableHeaders}
                    data = {licenseTableData}
                    pageNo = {licensePageNo}
                    setPageNo = {setLicensePageNo}
                    loadData = {loadLicenseData}
                    />
                    :
                    <label className="w-full " style={{"textAlign": "center", "color": "red"}}>No Records Found, Select Filter and Try Again!!!</label> 
                    }
                </div>
            </>
            }  
        </div>
        <div className='flex flex-wrap p-1 bg-white lg:mx-20 border border-symStrokeGrey last:border-b-0'>
            <div className='w-full center-align'>
                <div className="w-10/12 flex">
                    <img
                        className="mr-2"
                        style={{ height: "36px" }}
                        alt="Suspended"
                        src={LicenseInsightImg}
                    />
                    <h1 className="text-lg">Project Insights </h1>
                </div>
                <div className="w-2/12" style={{textAlign : 'right'}}>
                {expandedElement === 'PROJECT' ?
                <ExpandLessIcon
                onClick={() => setExpandedElement('')}
                style={{ fontSize: "35px" }}
                className="trackicon self-center cursor-pointer text-gray-700"
                />
                : 
                <ExpandMoreIcon
                onClick={() => setExpandedElement('PROJECT')}
                style={{ fontSize: "35px" }}
                className="trackicon self-center cursor-pointer sm:text-gray-700"
                />
                }
                </div>
            </div>
            
            {expandedElement === 'PROJECT' &&
            <>
                <FilterComp 
                selectedUsers = {projectFilter.selectedStaffs}
                users = {staffUsers}
                handleUserSelection = {handleProjectFilterStaffSelection}
                startDate = {projectFilter.dateRange.startDate}
                endDate = {projectFilter.dateRange.endDate}
                setDateRangeValues = {setProjectFilterDates}
                loadData = {loadProjectData}
                buttonTitle = {"View Projects"}
                csvData = {projectCSVData}
                />
                <div className="w-full center-align" >
                    {loadingProjects ? 
                    <label className="w-full " style={{"textAlign": "center", "color": "green"}}>Loading Projects Data... Please Wait...</label> 
                    :
                    projectList?.length > 0 ? 
                    <TableComp 
                    totalCount = {projectCount}
                    headers = {projectTableHeaders}
                    data = {projectTableData}
                    loadData = {loadProjectData}
                    />
                    :
                    <label className="w-full " style={{"textAlign": "center", "color": "red"}}>No Records Found, Select Filter and Try Again!!!</label> 
                    }
                </div>
            </>
            }  
        </div>

    </>)

}
