import React, { useContext } from "react";
import { ReactComponent as CloseModal } from "../../assets/close-day.svg";
import { ReactComponent as CloseDay } from "../../assets/close-day.svg";
import { ReactComponent as ProfileDay } from "../../assets/profile-day.svg";
// import {ReactComponent as UserProfile } from '../../assets/profile-fill.svg';
import { Link } from "react-router-dom";
import Constants from "../../services/constants";
import CookieManager from "../../services/CookieManager";
import "./style.css";

import Helper from "../../services/helper";
import TrackContext from "../../contexts/track-context";

function MobileMenu(props) {
  // console.log('Mobile menu props : ', props)
  let userData = JSON.parse(CookieManager.getCookie("user-data"));
  let trackContext = useContext(TrackContext);
  
  const handleMobileSearch = () => {
    props.setShowProjectModal(true);
    props.handleMobileMenuClose();
  };

  const handleSignoutClick = () => {
    trackContext.setShowLogoutPopup(true);
    props.handleMobileMenuClose();
  };

  return (
    <div className="lg:hidden flex flex-col fixed inset-0 overflow-hidden h-screen w-full bg-black text-white">
      <div className="flex justify-end px-10 pt-10">
        <CloseModal
          className="cursor-pointer"
          onClick={props.handleMobileMenuClose}
        />
      </div>
      <div className="capitalize text-xl ml-10 mt-20">
        <Link to={"/"} onClick={props.handleMobileMenuClose}>
          <div className="mb-4">{process.env.REACT_APP_MKT_SITE_MUSIC_LABEL}</div>
        </Link>
        <div className="mb-4">
          {" "}
          <Link
            to={process.env.REACT_APP_MKT_SITE_SOUND_DESIGN}
            onClick={props.handleMobileMenuClose}
          >
            {process.env.REACT_APP_MKT_SITE_SOUND_DESIGN_LABEL}
          </Link>
        </div>
        <div className="mb-4">
          {" "}
          {/* <Link
            to={process.env.REACT_APP_MKT_SITE_VOICEGEN}
            onClick={props.handleMobileMenuClose}
          >
            {process.env.REACT_APP_MKT_SITE_VOICEGEN_LABEL}
          </Link> */}
        </div>
        <div className="mb-4">
          {" "}
          <Link
            to={'/new-inspiration'}
            onClick={props.handleMobileMenuClose}
          >
            {process.env.REACT_APP_MKT_SITE_INSPIRATION_LABEL}
          </Link>
          {/* <a href={process.env.REACT_APP_MKT_SITE_INSPIRATION}>
            {process.env.REACT_APP_MKT_SITE_INSPIRATION_LABEL}
          </a> */}
        </div>
        <div className="mb-4" onClick={handleMobileSearch}>
          AI SEARCH
        </div>

        {userData === null ? (
          <>
            <div className="mb-4">
              {" "}
              <a href={process.env.REACT_APP_MKT_SITE_PRICING}>
                {process.env.REACT_APP_MKT_SITE_PRICING_LABEL}
              </a>
            </div>
            <Link to={"/signin"} onClick={props.handleMobileMenuClose}>
              <div className="mb-4">SIGN IN</div>
            </Link>
          </>
        ) : (
          <>
            <Link to={"/projects"} onClick={props.handleMobileMenuClose}>
              <div className="mb-4">
                {/* <UserProfile className='mobile-user-icon'/> */}
                ACCOUNT
              </div>
            </Link>
            <div >
              <button className="navPinkbuttonwide" onClick={handleSignoutClick}>
                  SIGN OUT
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default MobileMenu;
