import React, { useState, useContext, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Constants from "../../services/constants";
// import {Elements} from '@stripe/react-stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./stripe-form";
import SignUpContext from "../../contexts/signup-context";
import API from "../../services/api";
import { useLocation } from "react-router-dom";
//import { useGoogleReCaptcha, GoogleReCaptcha } from "react-google-recaptcha-v3";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import ReCAPTCHA from "react-google-recaptcha";
import FormControl from "@material-ui/core/FormControl";
import { OutlinedInput } from "@material-ui/core";
import { BouncingLoader } from "../../pure-components/Loader";
import CookieManager from "../../services/CookieManager";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

let a = [];
export default function Signup(props) {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  // const token = executeRecaptcha("register");
  // console.log('[google token ] : ', token);
  const signUpContext = useContext(SignUpContext);
  const [showMembershipDetails, setShowMembershipDetails] = useState(false);
  const [productPrices, setProductPrices] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [tax, setTax] = useState({ taxName: "", taxPercentage: 0, taxId: "" });

  // const classes = useStyles();
  const [countryValue, setCountryValue] = useState("India");
  const [countryTax, setCountryTax] = useState("");
  const [countryCode, setCountryCode] = useState("IN");
  const [countrySymbol, setCountrySymbol] = useState("INR");
  //const [productMonthly, setProductMonthly] = useState([]);
  //const [productYearly, setProductYearly] = useState([]);
  const [tempCountryTax, setTempCountryTax] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //const [nonIndianCountry, setNonIndianCountry] = useState([]);
  // const countryValueRef = React.useRef()
  // const [firstName, setFirstName] = useState('');
  //const firstNameRef = React.useRef();
  //const emailRef = React.useRef();
  //const confirmEmailRef = React.useRef();
  //const passwordRef = React.useRef();
  //const confirmPasswordRef = React.useRef();
  //const companyNameRef = React.useRef();
  //const soloRef = React.useRef();
  //const soloProRef = React.useRef();
  // const countries = [
  //   {
  //     code: "BD",
  //     label: "Bangladesh",
  //     phone: "880",
  //     taxName: "GST",
  //     currencyCode: "bdt",
  //     currencySymbol: "BDT",
  //   },
  //   {
  //     code: "IN",
  //     label: "India",
  //     phone: "91",
  //     taxName: "GST",
  //     currencyCode: "inr",
  //     currencySymbol: "INR",
  //   },
  //   {
  //     code: "MV",
  //     label: "Maldives",
  //     phone: "960",
  //     taxName: "VAT",
  //     currencyCode: "mvr",
  //     currencySymbol: "MVR",
  //   },
  //   {
  //     code: "MU",
  //     label: "Mauritius",
  //     phone: "230",
  //     taxName: "VAT",
  //     currencyCode: "mur",
  //     currencySymbol: "MUR",
  //   },
  //   {
  //     code: "NP",
  //     label: "Nepal",
  //     phone: "977",
  //     taxName: "VAT",
  //     currencyCode: "npr",
  //     currencySymbol: "NPR",
  //   },
  //   {
  //     code: "PK",
  //     label: "Pakistan",
  //     phone: "92",
  //     taxName: "VAT",
  //     currencyCode: "pkr",
  //     currencySymbol: "PKR",
  //   },
  //   {
  //     code: "LK",
  //     label: "Sri Lanka",
  //     phone: "94",
  //     taxName: "VAT",
  //     currencyCode: "lkr",
  //     currencySymbol: "LKR",
  //   },
  // ];

  const countries = [
    {
      code: "IN",
      label: "India",
      phone: "91",
      taxName: "GST",
      currencyCode: "inr",
      currencySymbol: "INR",
    },
  ];

  const location = useLocation();
  // console.log('[location ] : ', location);

  const countryList = JSON.parse(process.env.REACT_APP_COUNTRIES);
  const userType = JSON.parse(process.env.REACT_APP_USER_TYPE);
  const cachedUserData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;

  useEffect(() => {
    let hash = String(location.hash).substring(1).toUpperCase();

    if (hash === "") {
      window.location = "/signin";
    }
    (async () => {
      await API.getProducts(hash).then((res) => {
      setAllProducts(res.data);
      let temp = { ...productPrices };
      // let tempMonth = {...productPrices};
      let tempYear = { ...productPrices };
      }).catch(err => {
        console.log("Products loading error : "+ err)
      })
      countries.map(async (ele, index) => {
        let tempTax = await API.getTaxRate(ele.label, ele.taxName);
        setTempCountryTax((tempCountryTax) => [...tempCountryTax, tempTax.data]);
      });
    })();

  }, [location.pathname]); // value

  useEffect(() => {
    let temp = { ...productPrices };
    let hash = String(location.hash).substring(1).toUpperCase();

    let prods = allProducts.filter((prod) => {
      if (
        String(prod.currency).toLowerCase() === "inr" &&
        countryValue === countryList.India
      ) {
        // if (String(prod.currency).toLowerCase()  === String(countrySymbol).toLowerCase()) {
        if (prod.name === `${hash}_MONTHLY` || prod.name === `${hash}_YEARLY`) {
          // console.log('inr',prod)
          return prod;
        }
      }
      if (
        String(prod.currency).toLowerCase() === "usd" &&
        countryValue === countryList.India
      ) {
        if (prod.name === `${hash}_MONTHLY` || prod.name === `${hash}_YEARLY`) {
          // console.log('usd',prod)
          return prod;
        }
      }
    });

    if (prods.length) {
      prods.map((prod) => {
        // console.log('prod : ', prod.name);
        if (prod.name === `${hash}_MONTHLY`) {
          if (
            String(prod.currency).toLowerCase() === "usd" &&
            countryValue !== countryList.India
          ) {
            temp.monthly = parseFloat(prod.unit_amount_decimal) / 100;
          } else {
            temp.monthly = parseFloat(prod.unit_amount_decimal) / 100;
          }
          temp.renewal = prod.recurring?.interval;
          temp.interval_count = prod.recurring?.interval_count;
          temp.country_code = prod.nickname;

          a.push(temp);
          let tempUserData = { ...signUpContext.userData };

          tempUserData.countryName = countryCode;
          if (prod.metadata.type.includes("pro")) {
            tempUserData.userType = userType.SOLO_PRO;
          } else {
            tempUserData.userType = String(prod.metadata.type).toUpperCase();
          }
          tempUserData.country = prod.nickname;
          tempCountryTax.map((tax) => {
            // console.log(('Tax=>', tax));

            if (tax && tax.country === countryCode) {
              tempUserData.taxId = tax.id;
            }
          });
          signUpContext.setUserData(tempUserData);
        }
        if (prod.name === `${hash}_YEARLY`) {
          if (
            String(prod.currency).toLowerCase() === "usd" &&
            countryValue !== countryList.India
          ) {
            temp.yearly = parseFloat(prod.unit_amount_decimal) / 100;
            // console.log('////',temp.yearly)
          } else {
            temp.yearly = parseFloat(prod.unit_amount_decimal) / 100;
          }
          temp.yearly_renewal = prod.recurring?.interval;
          temp.interval_count = prod.recurring?.interval_count;
          temp.country_code = prod.nickname;
          // console.log('Country Code:', temp.country_code);
          // setProductYearly(productYearly => [...productYearly, temp]);
          let tempUserData = { ...signUpContext.userData };
          tempUserData.countryName = countryCode;
          if (prod.metadata.type.includes("pro")) {
            tempUserData.userType = userType.SOLO_PRO;
          } else {
            tempUserData.userType = String(prod.metadata.type).toUpperCase();
          }
          tempUserData.country = prod.nickname;
          tempCountryTax.map((tax) => {
            if (tax && tax.country === countryCode) {
              // let tempUserData = {...signUpContext.userData}
              tempUserData.taxId = tax.id;
              // signUpContext.setUserData(tempUserData)
            }
          });
          signUpContext.setUserData(tempUserData);
        }
      });
    }

    setProductPrices(temp);
  }, [countryCode, allProducts]);

  useEffect(() => {
    handleSubscriptionPeriod("yearly");
    // signUpContext.setSubscriptionPeriod("yearly");
  }, [productPrices]);
  const handleSubscriptionPeriod = (value) => {
    // console.log(('Subscription Period:', value));
    signUpContext.setSubscriptionPeriod(value);
    // console.log(signUpContext);
    let tempHelperText = { ...signUpContext.helperText };
    delete tempHelperText.subscriptionPeriod;
    signUpContext.setHelperText(tempHelperText);
    // console.log('Helper Text:', tempHelperText, ' =>', signUpContext.helperText);
  };

  const onChangeRecaptcha = (e) => {
    // console.log('e : ', e);
  };

  const handleCountryChange = (e) => {
    let tempCountryName = "";
    let tempTaxName = "";
    // console.log("country", countries);
    countries.map((ele) => {
      if (ele.label === e.target.value) {
        setCountryValue(ele.label);
        setCountryTax(ele.taxName);
        setCountryCode(ele.code);
        setCountrySymbol(ele.currencySymbol);
      }
    });
  };

  const handleLoader = (value) => {
    setLoading(value);
  };

  return (
    <>
      {isLoading ? (
        <div className="set-height-relative">
          <div className="bouncy-positioning">
            <BouncingLoader />
          </div>
        </div>
      ) : null}
      <>
        <div className="md:flex mt-16" style={{ background: "#F2F5F6" }}>
          <div className="md:w-1/2 mx-auto p-4 md:pl-24">
            <div className="stepsbox flex flex-wrap mb-10 pl-4 w-full lg:w-2/3">
              <div className="steps active flex-1 flex flex-wrap">
                <div className="stepsnumber">1</div>
                <div className="stepstext text-sm pl-2">
                  Enter
                  <br />
                  Details
                </div>
              </div>
              <div className="steps flex-1 flex flex-wrap">
                <div className="stepsnumber">2</div>
                <div className="stepstext text-sm pl-2">
                  Confirm
                  <br />
                  Email
                </div>
              </div>
              <div className="steps flex-1 flex">
                <div className="stepsnumber">3</div>
                <div className="stepstext text-sm pl-2">
                  Start with
                  <br />
                  SyncMama
                </div>
              </div>
            </div>
            {(signUpContext.userType === userType.SOLO_PRO ||
              signUpContext.userType === userType.SOLO) && (
                <div className="pl-4 mb-10">
                  {signUpContext.userType === userType.SOLO_PRO ? (
                    <h1
                      className="text-3xl font-bold"
                      style={{ lineHeight: "40px" }}
                    >
                      Pro Plan
                    </h1>
                  ) : (
                    <h1
                      className="text-3xl font-bold"
                      style={{ lineHeight: "40px" }}
                    >
                      Solo Plan
                    </h1>
                  )}
                </div>
              )}

            <div className="flex flex-wrap p-2">
              {cachedUserData === null &&
                (<>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-full-name"
                      autoFocus={true}

                      error={
                        signUpContext.helperText.first_name !== undefined &&
                          signUpContext.helperText.first_name !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-full-name"
                      label="Full Name"
                      variant="outlined"
                      value={signUpContext.first_name}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "first_name")
                      }
                      helperText={signUpContext.helperText.first_name}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-email"
                      error={
                        signUpContext.helperText.email !== undefined &&
                          signUpContext.helperText.email !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-email"
                      label="Email"
                      variant="outlined"
                      value={signUpContext.email}
                      onChange={(e) => signUpContext.handleInputChange(e, "email")}
                      helperText={signUpContext.helperText.email}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="confirm-email"
                      error={
                        signUpContext.helperText.confirmEmail !== undefined &&
                          signUpContext.helperText.confirmEmail !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full"
                      label="Confirm Email"
                      variant="outlined"
                      value={signUpContext.confirmEmail}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "confirmEmail")
                      }
                      helperText={signUpContext.helperText.confirmEmail}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-password"
                      error={
                        signUpContext.helperText.password !== undefined &&
                          signUpContext.helperText.password !== ""
                          ? true
                          : false
                      }
                      type="password"
                      size="small"
                      className="w-full signup-password"
                      label="Password"
                      variant="outlined"
                      value={signUpContext.password}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "password")
                      }
                      helperText={signUpContext.helperText.password}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-confirm-password"
                      error={
                        signUpContext.helperText.confirmPassword !== undefined &&
                          signUpContext.helperText.confirmPassword !== ""
                          ? true
                          : false
                      }
                      type="password"
                      size="small"
                      className="w-full signup-confirm-password"
                      label="Confirm Password"
                      variant="outlined"
                      value={signUpContext.confirmPassword}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "confirmPassword")
                      }
                      helperText={signUpContext.helperText.confirmPassword}
                    />
                  </div>
                </>)
              }
              <div className="w-full p-2 mb-1">
                <FormControl
                  variant="outlined"
                  className="w-full rounded"
                  size="small"
                  labelwidth="800"
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Choose a country
                  </InputLabel>
                  <Select
                    name="signup-country"
                    labelId="de mo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={countryValue}
                    label="Country Value"
                    input={<OutlinedInput labelWidth={130} />}
                    // defaultValue={countryValue}
                    onChange={handleCountryChange}

                  // onClose={getTaxRates}
                  >
                    {countries.map((ele) => (
                      <MenuItem key={ele.code} id={ele.code} value={ele.label}>
                        {ele.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {
                <>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-billing-address"
                      autoFocus={true}
                      error={
                        signUpContext.helperText.billingAddress !== undefined &&
                          signUpContext.helperText.billingAddress !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-billing-address"
                      label="Billing Address"
                      variant="outlined"
                      value={signUpContext.billingAddress}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "billingAddress")
                      }
                      helperText={signUpContext.helperText.billingAddress}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-billing-city"
                      autoFocus={true}
                      error={
                        signUpContext.helperText.billingCity !== undefined &&
                          signUpContext.helperText.billingCity !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-billing-city"
                      label="Billing City"
                      variant="outlined"
                      value={signUpContext.billingCity}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "billingCity")
                      }
                      helperText={signUpContext.helperText.billingCity}
                    />
                  </div>
                  <div className="w-full p-2 mb-1">
                    <TextField
                      required
                      name="signup-billing-postcode"
                      autoFocus={true}
                      error={
                        signUpContext.helperText.billingPinCode !== undefined &&
                          signUpContext.helperText.billingPinCode !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-billing-postcode"
                      label="Billing Postcode"
                      variant="outlined"
                      value={signUpContext.billingPinCode}
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "billingPinCode")
                      }
                      helperText={signUpContext.helperText.billingPinCode}
                    />
                  </div>
                </>
              }

              {signUpContext.userType === userType.SOLO_PRO ? (
                <div className="p-2 w-full mb-1">
                  <TextField
                    required
                    name="signup-company-name"
                    error={
                      signUpContext.helperText.companyName !== undefined &&
                        signUpContext.helperText.companyName !== ""
                        ? true
                        : false
                    }
                    size="small"
                    className="w-full signup-company-name"
                    label="Company Name"
                    variant="outlined"
                    value={signUpContext.companyName}
                    onChange={(e) =>
                      signUpContext.handleInputChange(e, "companyName")
                    }
                    helperText={signUpContext.helperText.companyName}
                  />
                </div>
              ) : (
                <div className="p-2 w-full mb-1">
                  <TextField
                    error={
                      signUpContext.helperText.companyName !== undefined &&
                        signUpContext.helperText.companyName !== ""
                        ? true
                        : false
                    }
                    size="small"
                    className="w-full signup-company-name"
                    label="Company Name (Optional)"
                    variant="outlined"
                    value={signUpContext.companyName}
                    onChange={(e) =>
                      signUpContext.handleInputChange(e, "companyName")
                    }
                    helperText={signUpContext.helperText.companyName}
                  />
                </div>
              )}
              {countryValue === "India" &&
                (signUpContext.userType === userType.SOLO_PRO ? (
                  <div className="p-2 w-full">
                    <TextField
                      required
                      name="signup-gst-number"
                      error={
                        signUpContext.helperText.taxNumber !== undefined &&
                          signUpContext.helperText.taxNumber !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-gst-number"
                      label="GST Number"
                      inputProps={{ maxLength: 15 }}
                      variant="outlined"
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "taxNumber")
                      }
                      helperText={signUpContext.helperText.taxNumber}
                    />
                  </div>
                ) : (
                  <div className="p-2 w-full">
                    <TextField
                      error={
                        signUpContext.helperText.taxNumber !== undefined &&
                          signUpContext.helperText.taxNumber !== ""
                          ? true
                          : false
                      }
                      size="small"
                      className="w-full signup-gst-number"
                      label="GST Number (Optional)"
                      inputProps={{ maxLength: 15 }}
                      variant="outlined"
                      onChange={(e) =>
                        signUpContext.handleInputChange(e, "taxNumber")
                      }
                      helperText={signUpContext.helperText.taxNumber}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div
            className="md:w-1/2  h-full lg:pr-24 md:pr-5 md:pl-9 lg:pl-16"
            style={{ background: "#F2F5F6" }}
          >
            <div className="plan-details pt-4 pb-4">
              <div className="plan-description ">
                {showMembershipDetails && (
                  <div className="text-sm mt-2">
                    <div className="flex mb-1">
                      <img
                        className="mr-4"
                        src={require("../../assets/correct.svg")}
                      />
                      Same as SOLO + digital ads
                    </div>
                    <div className="flex mb-1">
                      <img
                        className="mr-4"
                        src={require("../../assets/correct.svg")}
                      />
                      Licenses are for commercial use and can be in client’s
                      name
                    </div>
                    <div className="flex mb-1">
                      <img
                        className="mr-4"
                        src={require("../../assets/correct.svg")}
                      />
                      Audio format: mp3 320kbps & WAV
                    </div>
                  </div>
                )}
                <div className="mt-4 pb-4 planoptions w-full">
                  <h1 className="mb-2">SELECT YOUR OPTION</h1>
                  {signUpContext.helperText.subscriptionPeriod !==
                    undefined && (
                      <span className="text-red-600 text-xs wobble">
                        Choose a plan
                      </span>
                    )}
                  <div className="flex w-full">
                    <div className="subbox w-1/2 sm:w-full flex-1">
                      <button
                        className={
                          signUpContext.subscriptionPeriod === "yearly"
                            ? "w-full p-2 border border-white greenbg outline-none subboxbutton"
                            : "w-full p-2 border borderpink subboxbutton"
                        }
                        onClick={() => handleSubscriptionPeriod("yearly")} //changes
                      >
                        <div className="pricetotals hidden">
                          {countryValue === countryList.Nepal ||
                            countryValue === countryList.Bangladesh
                            ? Math.round(productPrices?.yearly / 12)
                            : productPrices?.yearly / 12 !== undefined
                              ? tempCountryTax.map((ele) => {
                                if (ele && ele.country === countryCode) {
                                  return `${(
                                    (productPrices?.yearly /
                                      (100 + ele.percentage)) *
                                    100
                                  ).toFixed()}`;
                                }
                              })
                              : "-"}
                        </div>
                        <div className="pricerowa priceboxfade font-bold">
                          <p className="mb-4">{Constants.YEARLY_PLAN}</p>
                          <span className="currency mr-2">
                            {countryValue === countryList.India
                              ? countrySymbol
                              : process.env.REACT_APP_USD}
                          </span>
                          <span className="subrate mr-2">
                            {countryValue === countryList.Nepal ||
                              countryValue === countryList.Bangladesh
                              ? Math.round(productPrices?.yearly / 12)
                              : productPrices?.yearly / 12 !== undefined
                                ? tempCountryTax.map((ele) => {
                                  if (ele && ele.country === countryCode) {
                                    return `${(
                                      (productPrices?.yearly /
                                        12 /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed()}`;
                                  }
                                })
                                : "-"}
                          </span>
                          <span>/ month</span>
                        </div>
                        <div className="pricerowb priceboxfade text-xs">
                          <span className="tax">
                            {productPrices?.yearly / 12 !== undefined ? (
                              countryValue === "" ? (
                                <>{`(+ ${(
                                  (tax.taxPercentage / 100) *
                                  ((productPrices?.yearly /
                                    12 /
                                    (100 + tax.taxPercentage)) *
                                    100)
                                ).toFixed()} ${tax.taxName})`}</>
                              ) : (
                                tempCountryTax.map((ele) => {
                                  //console.log(ele);
                                  if (
                                    ele &&
                                    ele.country ===
                                    process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                    countryValue === countryList.India
                                  ) {
                                    return `(+ ${(
                                      (ele.percentage / 100) *
                                      (productPrices?.yearly /
                                        12 /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed()} ${ele?.display_name})`;
                                  }
                                })
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div className="pricerowc priceboxfade text-xs">
                          <p className="tax">(Paid upfront, </p>
                          <span className="tax">
                            you save{" "}
                            {countryValue === countryList.India
                              ? countrySymbol
                              : process.env.REACT_APP_USD}{" "}
                            {productPrices?.monthly * 12 -
                              productPrices?.yearly}
                            )
                          </span>
                          <p className="tax">&nbsp;</p>
                          <span className="tax">
                            Annual cost{" "}
                            {countryValue === countryList.India
                              ? countrySymbol
                              : process.env.REACT_APP_USD}{" "}
                            {(
                              productPrices?.yearly -
                              tempCountryTax.map((ele) => {
                                //console.log(ele);
                                if (
                                  ele &&
                                  ele.country === process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                  countryValue === countryList.India
                                ) {
                                  return (
                                    (ele.percentage / 100) *
                                    (productPrices?.yearly /
                                      (100 + ele.percentage)) *
                                    100
                                  ).toFixed();
                                }
                              })
                            ).toFixed()}{" "}
                          </span>
                          <p className="tax">
                            {productPrices?.yearly / 12 !== undefined ? (
                              countryValue === "" ? (
                                <>{`(+ ${(
                                  (tax.taxPercentage / 100) *
                                  ((productPrices?.yearly /
                                    (100 + tax.taxPercentage)) *
                                    100)
                                ).toFixed()} ${tax.taxName})`}</>
                              ) : (
                                tempCountryTax.map((ele) => {
                                  //console.log(ele);
                                  if (
                                    ele &&
                                    ele.country ===
                                    process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                    countryValue === countryList.India
                                  ) {
                                    return `(+ ${(
                                      (ele.percentage / 100) *
                                      (productPrices?.yearly /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed()} ${ele?.display_name})`;
                                  }
                                })
                              )
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </button>
                    </div>
                    <div className="subbox w-1/2 sm:w-full flex-1 ml-2">
                      <button
                        className={
                          signUpContext.subscriptionPeriod === "monthly"
                            ? "w-full p-2 border border-white greenbg outline-none subboxbutton "
                            : "w-full p-2 border borderpink subboxbutton"
                        }
                        onClick={() => handleSubscriptionPeriod("monthly")}
                      >
                        <div className="pricetotals hidden">
                          {countryValue === countryList.Nepal ||
                            countryValue === countryList.Bangladesh
                            ? productPrices?.monthly
                            : productPrices?.monthly !== undefined
                              ? tempCountryTax.map((ele) => {
                                if (ele && ele.country === countryCode) {
                                  return `${(
                                    (productPrices?.monthly /
                                      (100 + ele.percentage)) *
                                    100
                                  ).toFixed()}`;
                                }
                              })
                              : "-"}
                        </div>
                        <div className="pricerowa priceboxfade font-bold">
                          <p className="mb-4">{Constants.MONTHLY_PLAN}</p>
                          <span className="currency mr-2">
                            {countryValue === countryList.India
                              ? countrySymbol
                              : process.env.REACT_APP_USD}
                          </span>
                          <span className="subrate mr-2">
                            {countryValue === countryList.Nepal ||
                              countryValue === countryList.Bangladesh
                              ? productPrices?.monthly
                              : productPrices?.monthly !== undefined
                                ? tempCountryTax.map((ele) => {
                                  if (ele && ele.country === countryCode) {
                                    return `${(
                                      (productPrices?.monthly /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed()}`;
                                  }
                                })
                                : "-"}
                          </span>
                          <span>/ month</span>
                        </div>
                        <div className="pricerowb priceboxfade text-xs">
                          <span className="tax">
                            {productPrices?.monthly !== undefined ? (
                              countryValue === "" ? (
                                <>{`(+ ${(
                                  (tax.taxPercentage / 100) *
                                  ((productPrices?.monthly /
                                    (100 + tax.taxPercentage)) *
                                    100)
                                ).toFixed()} ${tax.taxName})`}</>
                              ) : (
                                tempCountryTax.map((ele) => {
                                  if (
                                    ele &&
                                    ele.country ===
                                    process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                    countryValue === countryList.India
                                  ) {
                                    return `(+ ${(
                                      (ele.percentage / 100) *
                                      (productPrices?.monthly /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed()} ${ele?.display_name})`;
                                  }
                                })
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div className="pricerowc priceboxfade text-xs">
                          <span className="tax">Paid monthly</span>
                          <p className="tax">&nbsp;</p>
                          <span className="tax">
                            Annual cost{" "}
                            {countryValue === countryList.India
                              ? countrySymbol
                              : process.env.REACT_APP_USD}{" "}
                            {(
                              12 *
                              (productPrices?.monthly -
                                tempCountryTax.map((ele) => {
                                  //console.log(ele);
                                  if (
                                    ele &&
                                    ele.country ===
                                    process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                    countryValue === countryList.India
                                  ) {
                                    return (
                                      (ele.percentage / 100) *
                                      (productPrices?.monthly /
                                        (100 + ele.percentage)) *
                                      100
                                    ).toFixed();
                                  }
                                }))
                            ).toFixed()}{" "}
                          </span>
                          <p className="tax">
                            {productPrices?.monthly / 12 !== undefined ? (
                              countryValue === "" ? (
                                <>{`(+ ${(
                                  (tax.taxPercentage / 100) *
                                  ((productPrices?.monthly /
                                    (100 + tax.taxPercentage)) *
                                    100) *
                                  12
                                ).toFixed()} ${tax.taxName})`}</>
                              ) : (
                                tempCountryTax.map((ele) => {
                                  //console.log(ele);
                                  if (
                                    ele &&
                                    ele.country ===
                                    process.env.REACT_APP_COUNTRY_CODE_INDIA &&
                                    countryValue === countryList.India
                                  ) {
                                    return `(+ ${(
                                      (ele.percentage / 100) *
                                      (productPrices?.monthly /
                                        (100 + ele.percentage)) *
                                      100 *
                                      12
                                    ).toFixed()} ${ele?.display_name})`;
                                  }
                                })
                              )
                            ) : (
                              ""
                            )}
                          </p>
                          <p className="tax">(When billed monthly)</p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-gray-800 border-b"></div>
              </div>
              <div className="card-details mt-4 w-full">
                <div className="w-full">
                  <h3 className="text-lg pb-4">Payment Method</h3>
                </div>
                <Elements stripe={stripePromise}>
                  <StripeForm returnLoaderData={handleLoader} />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
}
