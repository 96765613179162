import React, { useState, useEffect } from 'react';
import OKIcon from '../../assets/ok_icon.png';

function SignupVerify() {

useEffect(() => {
  setTimeout(() => {
    window.location = '/';
  }, 3000)
}, []);

return(
    <>
    <div className='md:flex'>
      <div className='flex md:w-1/2 mx-auto pt-16 p-4 md:px-16 md:pt-32 h-full' style={{background: '#FFF'}}>
        
        <div className='mt-5 w-full'>
            <div className='stepsbox flex flex-wrap mb-10 pl-4 w-full'>
                <div className='steps flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>1</div>
                    <div className='stepstext text-sm pl-2'>Enter<br/>Details</div>
                </div>
                <div className='steps flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>2</div>
                    <div className='stepstext text-sm pl-2'>Confirm<br/>Email</div>
                </div>
                <div className='steps active flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>3</div>
                    <div className='stepstext text-sm pl-2'>Start with<br/>SyncMama</div>
                </div>
            </div>
          <div className='p-2 verifyicon mb-10'>
          <img src={OKIcon} className="verifyicon"/>
          </div>
          <div className='p-2 verifytext mb-10'>
            <h1 className='text-3xl font-bold pinkblock' style={{lineHeight: '40px'}}>WELCOME TO SYNCMAMA</h1>
            <span className=''>You will now be redirected to the <a style={{color: '#E30185'}} href="/">Music Page</a> to get started</span>
          </div>
 

        </div>
      </div>
      <div className='hidden md:flex md:w-1/2 min-height-100' style={{color: '#FFF'}}>
          <div className='timessquare'>
          </div>
      </div> 
    </div> 
    </>
  )
}
export default SignupVerify;