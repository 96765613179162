import React, { useContext, useEffect, useState } from 'react';
import TrackContext from '../../contexts/track-context';
import AdminList from './admin';
import Corporate from './corporate';
import UserList from './user';
import License from './license';
import LicenseBar from './licenseBar';
import API from '../../services/api';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import CookieManager from '../../services/CookieManager';
import ExportLicenseComp from './exportLicenseComp';

export default function Admin() {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [users, setUsers] = useState({});
  const [download, setDownload] = useState(0)
  const [adminList, setAdminList] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    
  let query = [
    {
      '$project': {
        'month': {
          '$month': '$createdAt'
        },
        'year': {
          '$year': '$createdAt'
        }
      }
    }, {
      '$group': {
        '_id': {
          'month': '$month',
          'year': '$year'
        },
        'total': {
          '$sum': 1
        },
        'month': {
          '$first': '$month'
        },
        'year': {
          '$first': '$year'
        }
      }
    },
    {
      '$match': {
        'year': new Date().getFullYear()
      }
    }
  ]

  useEffect(() => {
    (async () => {
      await API.getUserAggregate(query).then(res => {
        if (res.success) {
          chartData(res.data)
        }
      })
    })();
  }, []);
  
  useEffect(() => {
    let query=[
         {
           '$project': {
             'lable': 'count', 
             'downloadedTracks': {
               '$sum': {
                 '$size': '$downloadedTracks'
               }
             }
           }
         }, {
           '$group': {
             '_id': '$lable', 
             'total': {
               '$sum': '$downloadedTracks'
             }
           }
         }
       ]
   
    API.getUserAggregate(query).then(res => {
      if (res.success) {
        let count=res.data.length ? res.data[0].total : 0;
        setDownload(count);
       // setCountUser(res.data);
        chartData(res.data)
      }
    })
  }, []);

  useEffect(() => {
    if(profileData?.userType === "SUPER_ADMIN")
      fetchAdmin();
  }, [])

  const fetchAdmin =()=>{
    API.getAdminList().then(res => {
      if (res.success) {

        setAdminList(res.data)
        if(res.data?.length)
          setSelectedAdmin(res.data[0]?.email);
      }
    })
  }

  const chartData = (filterdata = []) => {
    // let filterdata=countUser.filter(item=>item.year===year);
    //    console.log(filterdata)
    if (filterdata.length) {
      filterdata = filterdata.sort((a, b) => (a.month > b.month) ? 1 : -1);
      let xaxis = month.splice(0, filterdata[filterdata.length - 1].month);
      let ydata = [];

      let i = 1;
      while (i <= filterdata[filterdata.length - 1].month) {
        let filtermonth = filterdata.filter(item => item.month === i);
        ydata[i - 1] = filtermonth.length ? filtermonth[0].total : 0;
        i++;
      }
      // console.log(ydata,xaxis)
      let chartopt = {
        chart: {
          type: "line",
          height: "350px"
        },
        title: {
          text: "Total number of users"
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },
        xAxis: {
          categories: xaxis
        },
        yAxis: {
          title: {
            text: 'No. of Users'
          }
        },
        series: [
          {
            name: 'month',
            data: ydata
          }]
      };
      setUsers(chartopt);
    }
  }
  let trackContext = useContext(TrackContext);
  return (
    <div className='admin-wrapper lg:w-3/4 lg:inline-block lg:ml-0 lg:mt-8'>
      <h1 className='text-3xl font-bold m-2 text-black' >
        Hello, {trackContext.userDetails.first_name}
      </h1>
      <div className='flex'>
        {profileData?.userType === "SUPER_ADMIN" &&
        <div className='shadow-sm border p-4 w-2/3 mt-2 mb-2 admin-list' >
          <AdminList adminList = {adminList} selectedAdmin = {selectedAdmin} setSelectedAdmin = {setSelectedAdmin}/>
        </div>}
        <div className='shadow-sm border py-4 px-8 w-full m-2 admin-list'>
          <Corporate admin = {selectedAdmin} />
        </div>
      </div>
      {profileData?.userType === "SUPER_ADMIN" &&
      <>
        <ExportLicenseComp />
        <div className='flex'>
          <div className='shadow-sm border py-4 px-8 w-3/5 mt-2 mb-2 admin-list' style={{ height: "380px" }}>
            <HighchartsReact highcharts={Highcharts} options={users} />
          </div>
          <div className='shadow-sm border p-4 w-2/5 m-2 admin-list  overflow-y-auto' style={{ height: "380px" }}>
            <UserList />
          </div>

        </div>
        <div className='flex'>

          <div className='w-full shadow-sm border p-4  mt-2  mb-2 mr-2 admin-list  overflow-y-auto' >
            <LicenseBar />
          </div>

        </div>
        <div className='flex'>

          <div className='w-2/3 shadow-sm border p-4  mt-2  mb-2 mr-2 admin-list  overflow-y-auto' >
            <License />
          </div>
          <div className='w-1/3 shadow-sm  mt-2  mb-2 mr-2 admin-list  overflow-y-auto' >
            <div className="flex py-4  border border-symStrokeGrey last:border-b-0"

            >
              <div className='self-center w-11/12 pl-10 py-4'>
                <h1 className='text-2xl mr-2 text-black  font-bold text-textGray-600' style={{fontSize:"56px"}}> {download}</h1>
                <h2 className='text-xs self-center text-black font-light text-textGray-400' style={{fontSize:"24px"}}>Downloaded Tracks </h2>
              </div>

            </div>        
            </div>
        </div>
      </>}
    </div>
  )
}