import React, { useState, useContext, useEffect } from "react";
import { ReactComponent as CloseModal } from "../../assets/modal-close.svg";
import { ReactComponent as BackArrow } from "../../assets/back-arrow.svg";
import { ReactComponent as Close } from "../../assets/BlackButtonRoundMinusSmall.svg";
import "./style.css";
// import Filters from './Filters'
import TrackContext from "../../contexts/track-context";
import SliderCustom from "./sliderCustom";
//import RotateRightIcon from '@material-ui/icons/RotateRight';
import { CircularProgress } from "@material-ui/core";
import Helper from "../../services/helper";
import API from "../../services/api";
import { toast } from "react-toastify";
import { ErrorToast } from "../../pure-components/CustomToast";

function MobileFilters(props) {
  const [tags, setAllTags] = useState([]);

  let trackContext = useContext(TrackContext);
  // console.log({trackContext});
  let { selectedTags } = trackContext.trackFilter;
  // console.log("props in mobile filters", props);
  // const [selectedTags, setSelectedTags] = useState([]);
  // console.log("selectedTags",selectedTags);
  const [subMenu, setSubMenu] = useState("");
  // const [value, setValue] = useState();
  const [filterSubMenu, setfilterSubMenu] = useState([]);
  //const [count, setCount] = useState(0);
  // const [showLevel1, setShowLevel1] = useState(true);
  // const [showLevel2, setShowLevel2] = useState(false);
  const [showSlider, setShowSlider] = useState({});

  
  // const handleStates = () => {
  //   props.setShowLevel1(true);
  // };   

  // const handleStates = useCallback(() => {
  //   // handle the click even
  //   props.setShowLevel1(false);

  // }, []);

  const handleStatesClose = () => {
    props.setShowLevel1(true);
  };


  return (
    <div className="lg:hidden flex mobile-filter-wrapper">
      {props.showLevel1 && (
        <div className="lg:hidden flex mobile-filter-lv1 flex-col fixed inset-0 overflow-hidden h-screen w-full bg-black text-white">
          <div className="flex pt-10 mobile-filter-lv2 w-full">
            <div
              className="w-3/4 mobile-reset-filters justify-self-startfont-semibold text-base cursor-pointer underline text-white ml-8"
              onClick={props.clearSearchTag}
            >
              Reset Filters
            </div>
            <div className="justify-end px-10 pt-11 w-1/4 flex">
              <CloseModal
                className="cursor-pointer"
                onClick={props.handleMobileFiltersClose}
              />
            </div>
          </div>
          <div
            className="capitalize ml-6 mt-2 my-2"
            // onClick={handleStates}
          >
            {trackContext?.filterMenu?.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={`capitalize relative px-2 mr-2 py-1 text-xl cursor-pointer mb-4 `}
                  onClick={() => {
                    props.handleChange(item.key, item.isSlider)
                    props.setShowLevel1(false)
                    props.setShowLevel2(true)
                 
                  }}
                >
                  <div className="inline-block relative">
                    {item.value}
                    {item.count > 0 && (
                      <div className="select-menu-count">{item.count}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {props.showLevel2 && (
        <div className="h-screen level-2-cat-wrapper">
          <div className="flex w-full p-5">
            <div
              className="flex w-1/2 justify-start pl-2 pr-8 pt-4 text-lg items-center"
              onClick={handleStatesClose}
            >
              <div className="pr-2">
                <BackArrow className="cursor-pointer" />{" "}
              </div>
              <div
                className="text-sm cursor-pointer"
                style={{ color: "#404040" }}
              >
                back
              </div>
            </div>
            <div className="flex w-1/2 justify-end pl-10 pt-4 pr-5">
              <CloseModal
                onClick={handleStatesClose}
                className="cursor-pointer"
              />
            </div>
          </div>
          <div className="text-3xl font-semibold capitalize bg-white mt-4 ml-2 p-5">
            {props.value}
          </div>
         
          
          <div
            className="capitalize ml-6 mt-2 my-2"
            // onClick={handleStates}
          >
           <div
                  className={`capitalize relative px-2 mr-2 py-1 text-xl cursor-pointer mb-4 w-1/2 `}
                >
                  {props.isSlider && (
                    <div>
                      {props.value === "duration" ? (
                        trackContext.path === "/sound-design" ?
                          <SliderCustom
                            ValueLabelComponent={props.ValueLabelComponent}
                            duration={props.duration}
                            marksDuration={props.marksDuration}
                            key="duration"
                            min={0}
                            max={20}
                            sliderkey="duration"
                            onChangeCommitted={props.handleSliderChange}
                            handleSlider={props.handleSlider}
                          /> :
                          <SliderCustom
                            ValueLabelComponent={props.ValueLabelComponent}
                            duration={props.duration}
                            marksDuration={props.marksDuration}
                            key="duration"
                            min={0}
                            max={600}
                            sliderkey="duration"
                            onChangeCommitted={props.handleSliderChange}
                            handleSlider={props.handleSlider}
                          />
                      ) : (
                        <SliderCustom
                          ValueLabelComponent={props.ValueLabelBPMComponent}
                          duration={props.bpm}
                          marksDuration={props.marksBpm}
                          key="bpm"
                          sliderkey="bpm"
                          min={30}
                          max={220}
                          onChangeCommitted={props.handleSliderBPMChange}
                          handleSlider={props.handleSlider}
                        />
                      )}
                    </div>
                  )}
                </div>
          
</div>

          <div
            className=" flex-wrap bg-white px-5 pb-5"
            style={{ maxHeight: "55%", overflow: "scroll" }}
          >
            {/* {props.filterSubMenu.length === 0 ? <CircularProgress /> : null} */}
            {props.filterSubMenu.map((item) => {
              return (
                <div
                  // className="px-3 py-1 mx-2 my-3 text-md border w-1/5 text-center"
                  className={`inline-block py-2 px-6 text-sm cursor-pointer syncbuttonlight mx-1 my-1 text-md text-center ${
                    selectedTags.includes(
                      `${item.id}-${item.names[0].value}-${props.value}`
                    )
                      ? "text-white syncbutton"
                      : "px-6 syncbuttonlight filterplus"
                  }`}
                  onClick={() =>

                    props.addSearchTag(item.id,
                      [...item.names].filter(
                        (eachItem) => eachItem.locale === "en"
                      )[0].value,
                      props.value)
                  }
                >
                  {item.names[0].value}
                  {selectedTags.includes(
                    `${item.id}-${item.names[0].value}-${props.value}`
                  ) && (
                    <Close
                      onClick={(e) => {
                        e.stopPropagation();
                        props.removeSearchTag(
                          item.id,
                          [...item.names].filter(
                            (eachItem) => eachItem.locale === "en"
                          )[0].value,
                          props.value
                        );                      }}
                      className="filterclose ml-2 inline-block"
                    />
                  )}
                </div>
              );
            })}

            <div className="flex w-full mt-1 flex-wrap">
        {props.filterSubMenuChild?.map((item, index) => (
          <div
            key={index}
            className={`flex px-2 py-1 mr-2 mt-1 text-xs text-symTextGrey syncbutton cursor-pointer rounded-full ${
              (props.searchTags.tags_or?.some((item1) => item1.includes(item.id)) &&
                !props.searchTags.tags_not?.includes(item.id)) ||
              props.searchTags.tags?.includes(item.id)
                ? " px-4 pr-4 bg-symbgGray "
                : "filterplus px-4 pl-6 syncbuttongrey hover:bg-symbgGray hover:scale-75"
            }`}
            onClick={() => {
              props.addSearchChildTag(
                item.id,
                [...item.names].filter(
                  (eachItem) => eachItem.locale === "en"
                )[0].value,
                item.sub_category_key
              );
            }}
          >
            {
              [...item.names].filter((eachItem) => eachItem.locale === "en")[0]
                .value
            }{" "}
            {((props.searchTags.tags_or?.some((item1) => item1.includes(item.id)) &&
              !props.searchTags.tags_not?.includes(item.id)) ||
              props.searchTags.tags?.includes(item.id)) && (
              <Close
                onClick={(e) => {
                  e.stopPropagation();
                  props.removeSearchChildTag(
                    item.id,
                    [...item.names].filter(
                      (eachItem) => eachItem.locale === "en"
                    )[0].value
                  );
                }}
                className="filterclose ml-3 "
              />
            )}
          </div>
        ))}
      </div>
      <br></br>


      <div
            className=" text-base pl-5 pb-3 sm:mb-1 lg:mb-10 cursor-pointer"
            style={{ color: "#1140E6" }}
            onClick={props.clearSearchTag}
          >
            Reset Filters 
          </div>
          <div className="flex px-5 pb-3">
          {props.searchText && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs max-h-7 cursor-pointer bg-black text-white"
                    onClick={props.removeSearchText}
                  >
                    {props.searchText}
                    <CloseModal className=" closefilter mt-1 ml-3" />
                  </div>
                )}
                {props.url && (
                  <div className="flex px-2 py-1 m-1  max-h-7 text-xs cursor-pointer bg-black text-white">
                    <a href={props.url} target="blank">
                      {props.url}
                    </a>{" "}
                    <CloseModal

                      className="closefilter mt-1 ml-3"
                      onClick={props.removeUrl}
                    />
                  </div>
                )}
                {props.searchTitle?.length > 0 && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
                    onClick={props.removeSearchTitle}
                  >
                    {props.searchTitle}
                    <CloseModal className="closefilter mt-1 ml-3" />
                  </div>
                )}
                
               
                {selectedTags.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex px-5 max-h-7 max-w-xs py-1 m-1 text-xs cursor-pointer syncbutton text-white"
                      onClick={() =>
                        props.removeSearchTag(
                          item.split("-")[0],
                          item.split("-")[1],
                          item.split("-")[2]
                        )
                      }
                    >
                      {item.split("-")[1]}
                      <CloseModal className="closefilter mt-1 ml-3"  />
                    </div>
                  );
                })}
                {props.selectedChildTags?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex px-2 py-1 m-1 max-h-7 max-w-xs text-xs cursor-pointer syncbutton text-white "
                      onClick={() =>
                        props.removeSearchChildTag(
                          item.split("-")[0],
                          item.split("-")[1]
                        )
                      }
                    >
                      {item.split("-")[1]}
                      <CloseModal className="closefilter mt-1 ml-4 " />

                    </div>
                  );
                })}

                {props.duration.length > 0 && (
                <div
                  className="flex px-2 py-1 m-1 max-w-xs text-xs cursor-pointer bg-black text-white"
                  onClick={props.removeDuration}
                >Duration <br></br>
                  {`${new Date(props.duration[0] * 1000)
                    .toISOString()
                    .substr(14, 5)}-${new Date(props.duration[1] * 1000)
                    .toISOString()
                    .substr(14, 5)}`}
              <CloseModal className="closefilter mt-3 ml-3 " />

                  
                </div>
              )}
              
      
              {props.bpm.length > 0 && (
                <div
                  className="flex px-2 py-1 m-1 text-xs max-w-xs cursor-pointer bg-black text-white"
                  onClick={props.removeBpm}
                > BPM <br></br>
                  {`${props.bpm[0]}-${props.bpm[1]}`}
              <CloseModal className="closefilter mt-3 ml-3 " />
                </div>
              )}
              </div>
          </div>

 
          {selectedTags?.length || props.duration?.length ||props.bpm.length ? (
            <div
              disabled={trackContext.loading ? true : false}
              onClick={() => {
              props.setMobileFilters(false)
              props.setIsSlider(false)  
              }}
              className="fixed bottom-0 left-0 w-full syncpink text-white whitespace-nowrap px-20 text-center py-6 "
            >
              <button onClick={props.handleMobileFiltersClose}>
                {trackContext.loading
                  ? "Loading..."
                  : `VIEW ${trackContext.totaltracks} RESULTS`}
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default MobileFilters;