import { CircularProgress, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import API from '../../services/api';
import Validator from '../../services/validator';
import Helper from '../../services/helper';
import axios from "axios";

export default function SetNewPassword(props) {

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [helperText, setHelperText] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [savingPassword, setSavingPassword] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  let validator = new Validator();

  useEffect(() => {
    (async()=> {
      let url = process.env.REACT_APP_BASE_URL + "api/users/set-pwd-link-validity/" + props.match.params.resetCode;
      await axios({
        method: "GET",
        url: url,
        headers : {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        }
      }).then(resp => {
        if (resp.data.success) {
          setShowForm(true);
        }else{
          toast(({ closeToast }) => <ErrorToast message={resp.data.message}/>);
          setTimeout(() => {
            window.location = '/signin';
          }, 4000);
        }
      }).catch(err => {
        toast(({ closeToast }) => <ErrorToast message={"Link Validation Error  : " + err?.response?.data?.message}/>);
        setTimeout(() => {
          window.location = '/signin';
        }, 4000);
      });
    })()
  }, [])

  const handleInputChange = (e, type) => {
    let disableButton = true;
    let {value} = e.target;
    if (type === 'password') {
      setPassword(value);
      let resPass = validator.setValue(value).isNotEmpty().checkPasswordStrength();
      let temp = {...helperText};
      temp['password'] = resPass.mssg;
      setHelperText(temp);
    }
    if (type === 'newPassword') {
      setNewPassword(value);
      if (password !==  value) {
        let temp = {...helperText};
        temp['newPassword'] = "Passwords doesn't match";
        setHelperText(temp);
      } else {
        let temp = {...helperText};
        temp['newPassword'] = "";
        setHelperText(temp);
        disableButton = false;
      }
    }
    let valid = validator.setValue(password).isNotEmpty().checkPasswordStrength();
    if(valid.status && password == newPassword){
      setButtonDisabled(false);
    }else{
      setButtonDisabled(true)
    }
  };

  const handleSetPassword = async() => {
    console.log(buttonDisabled);

    setSavingPassword(true);
    let resetCode = props.location.pathname;
    await API.setNewPassword(props.match.params.resetCode, Helper.encode(password)).then(res => {
      setSavingPassword(false);
      setNewPassword('');
      setPassword('');
      if (res.data.success) {
        toast(({ closeToast }) => <SuccessToast message={res.data.message}/>);
        setTimeout(() => {
          window.location = '/signin';
        }, 4000);
      } else {
        toast(({ closeToast }) => <ErrorToast message={res.data.message}/>);
        setTimeout(() => {
          window.location = '/signin';
        }, 4000);
      }
    }).catch(err => {
      Helper.handleError(err);
    })
    setSavingPassword(false);
  }

  const validatePassword = ()=> {
    let valid = validator.setValue(password).isNotEmpty().checkPasswordStrength();
    if(valid.status && password == newPassword){
      setButtonDisabled(false);
    }else{
      setButtonDisabled(true);
    }
  }

  return (
    <div className='pt-24 px-32 flex ' style={{minHeight: '520px'}}>
      {
        showForm ?
      
      <div className=' w-1/3 self-center'>
        <h1 className='font-bold text-lg'>Set New Password</h1>
        <div className='py-4 flex w-full flex-wrap'>
          <div className='w-full py-2'>
            <TextField
              disabled={savingPassword}
              error={ helperText['password'] === '' ? false : true}
              type='password'
              size='small'
              className='w-full'
              label="New Password"
              variant="outlined"
              value={password}
              onChange={(e) => handleInputChange(e, 'password')}
              onBlur={() => validatePassword()}
              helperText={helperText['password']}
            />
          </div>
          <div className='w-full'>
            <TextField
              disabled={savingPassword}
              error={ helperText['newPassword'] === '' ? false : true}
              type='password'
              size='small'
              className='w-full my-2'
              label="Confirm New Password"
              variant="outlined"
              value={newPassword}
              onChange={(e) => handleInputChange(e, 'newPassword')}
              onBlur={() => validatePassword()}
              helperText={helperText['newPassword']}
            />
          </div>
          <div className='w-full'>
            <button onClick={handleSetPassword} className={buttonDisabled ? 'mt-2' : "greenbuttonwide font-bold mt-2"}
            type="button"
          
            disabled = {buttonDisabled}>
              Set Password
            </button>
          </div>
        </div>
      </div>
      :
      <div>
        <CircularProgress />
      </div> 
      }

    </div>
  )
}