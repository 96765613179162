import React, { useContext } from 'react';
import './style.css';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';
import ReactPlayer from 'react-player';


export default function GifPopup(props) {

  return (
    <div className='flex fixed top-0 h-screen w-full subscribe-modal justify-center' style={{zIndex: '9'}}>
      <div className='self-center w-8/12 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' onClick={() => props.setPopup(false)}/>
        </div>
        
        <div className='border-b border-gray-300 px-10 pt-2'>
                <ReactPlayer
                className='react-player fixed-bottom'
                url= {props.GIFLink}
                width='100%'
                height='100%'
                controls = {true}

                />
            
        </div>
        
      </div>
    </div> 
  )
}