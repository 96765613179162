import React, { useContext, useState } from "react";
import API from "../../services/api";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnFavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ReactComponent as CreateNewFolderIcon } from "../../assets/folder1.svg";
import { ReactComponent as GetAppIcon } from "../../assets/download.svg";
import { ReactComponent as Activedownload } from "../../assets/activedownload.svg";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TrackContext from "../../contexts/track-context";
import Helper from "../../services/helper";
import Constants from "../../services/constants";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";

function TrackAction(props) {
  let trackContext = useContext(TrackContext);
  const [hdmp3File, setHdmp3File] = useState(false);
  const [originalFile, setOriginalFile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  // const [favs, setFavs] = useState([]);

  
  const handleDownloadTrack = async(event, trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      return;
    }
    let currentTarget = event.currentTarget;
    await API.getDownloadTracks(trackId).then((res) => {
      if (res?.audiofile !== undefined && res.audiofile !== null) {
        setHdmp3File(res.audiofile?.hd_mp3.url);
        setOriginalFile(res.audiofile?.original.url);
        setShowDownloadModal(currentTarget);
      } else {
        Helper.handleError(res);
      }
    });

  };

  const handleDownloadArchive = (trackId, quality) => {
    API.downloadArchiveTrack(trackId, quality).then((res) => { });
  };

  const handleArchiveDownload = async(trackId, quality) => {
    await API.downloadArchiveTrack(trackId, quality, props.projectId).then(async(res) => {
      //success : response  = ["taskId"]
      //fail : response : { "status": 400, "message": "\"documentIds\" is required. \"docId\" is not allowed.", "key": "validation_error", "stack": {}, "reqId": "9022499d-9b2b-4edc-9397-c8f8372a83ea" }
      if (res) {
        if(props.projectList?.length > 0){
          let tempProjects =  props.projectList.map(element => {
            let tempdata = {...element };
            if(tempdata?._id === res._id){
              for(var i=0;i<tempdata.trackIds.length;i++){
                tempdata.trackIds[i]["downloadCount"] = res.trackIds[i]?.downloadCount;
              }
              return tempdata;
            }
            return tempdata;
          })
          //console.log("updated list : ", tempProjects)
          props.setProjectList(tempProjects);
        }
        await setDownloadHistory(trackId);
        toast(({ closeToast }) => (
          <SuccessToast message="Check your email, you have been sent a link to download the files." />
        ));
      } else {
      toast(({ closeToast }) => <ErrorToast message={res.message} />);
      }
    setShowDownloadModal(false);
    });
  };

  const handleDownloadClick = async(trackId, fileType) => {
    if(fileType === 'original'){
      window.open(originalFile, '_blank');
    }else{
      window.open(hdmp3File, '_blank');
    }
    setShowDownloadModal(false);
    await setProjectTrackDownloadCount(trackId);
    await setDownloadHistory(trackId);
  }

  const setProjectTrackDownloadCount = async(trackId) => {
    if(props.projectId?.length > 0){
      await API.setProjectTrackDownloadCount(trackId, props.projectId).then((res) => {
        //console.log("download count incremented resp : ", res)
        if(props.projectList?.length > 0){
          let tempProjects =  props.projectList.map(element => {
            let tempdata = {...element };
            if(tempdata?._id === res.data._id){
              for(var i=0;i<tempdata.trackIds.length;i++){
                tempdata.trackIds[i]["downloadCount"] = res.data.trackIds[i]?.downloadCount;
              }
              return tempdata;
            }
            return tempdata;
          })
          //console.log("updated list : ", tempProjects)
          props.setProjectList(tempProjects);
        }
      });
    }
  };

  const setDownloadHistory = async(trackId) => {
    await API.setDownloads(trackId).then((res) => {
      const downloadlist = JSON.parse(localStorage.getItem("download"));
      downloadlist.push(trackId);
      props.setDownload(downloadlist);
      localStorage.setItem("download", JSON.stringify(downloadlist));
    });
  };
  const handleClose = () => {
    setShowDownloadModal(false);
  };
  const handleProjectModal = (trackId, projectTrackId) => {
    if (!trackContext.validateUser(Constants.ACTION.ADD_TO_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.ADD_TO_PROJECT);
      return;
    }
    props.setTrackToBeAddedToProject(trackId);
    props.setTrackToBeRemoveToProject(projectTrackId);
    props.setShowProjectModal(true);

  };

  return (
    <>
      {props.favs.indexOf(props.trackId) !== -1 ? (
        <FavoriteIcon
          onClick={() => props.handleAddTrackToFavorites(props.trackId)}
          style={{ fontSize: "20px" }}
          className="m-1 self-center right-0 cursor-pointer text-ctaRed hover:scale-50"
        />
      ) : (
        <UnFavoriteIcon
          onClick={() => props.handleAddTrackToFavorites(props.trackId)}
          style={{ fontSize: "20px" }}
          className="m-1 self-center right-0 cursor-pointer text-gray-600  hover:text-ctaRed"
        />
      )}
      <CreateNewFolderIcon
        style={{ fontSize: "20px" }}
        onClick={() => handleProjectModal(props.trackId, props.projectTrackId)}
        className="w-5 sm:w-8 m-2 mt-4 self-center right-0 cursor-pointer text-gray-700 hover:text-blue-600"
      />
      {/* <GetAppIcon
        onClick={(event) => handleDownloadTrack(event, props.trackId)}
        style={{ fontSize: '16px' }}
        className='hidden sm:block mt-2 self-center right-0 cursor-pointer text-gray-700 hover:text-blue-600'
      /> */}
      {props.download.indexOf(props.trackId) !== -1 || props.downloadCount ? (
        <>
        <Activedownload
          onClick={(event) => handleDownloadTrack(event, props.trackId)}
          style={{ fontSize: "30px", marginRight: "-10px" }}
          className="hidden sm:-1 sm:w-8 sm:self-center sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block mr-5"
        />
        {props.downloadCount && <span className="text-xs mt-1">{props.downloadCount}</span>}
        </>
      ) : (
        <GetAppIcon
          onClick={(event) => handleDownloadTrack(event, props.trackId)}
          style={{ fontSize: "20px" }}
          className="hidden sm:-1 sm:mt-0 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block"
        />
      )}
      <Menu
        id="customized-menu"
        anchorEl={showDownloadModal}
        open={Boolean(showDownloadModal)}
        onClose={handleClose}
        style={{ width: "320px" }}
      >
        <MenuItem
          onClick={() => handleDownloadClick(props.trackId, "original")}
          className="card-action"
        >
          <span className="card-action-text">
            Original HD - Single track
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleArchiveDownload(props.trackId, "original")}
          className="card-action"
        >
          <span className="card-action-text">
            Original HD - All versions
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleDownloadClick(props.trackId, "hdMp3")}
          className="card-action"
        >
          <span className="card-action-text">
           MP3 - Single track
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => handleArchiveDownload(props.trackId, "hd_mp3")}
          className="card-action"
        >
          <span className="card-action-text">
            MP3 - All versions
          </span>
        </MenuItem>
      </Menu>
    </>
  );
}

export default TrackAction;
