import React from 'react';
import './style.css';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';

export default function ConfirmationPopup(props) {
  //console.log(props.giveStyle);
  const marginleft=props.giveStyle ? props.giveStyle :"";
  return (
    <div className={`flex fixed top-0 h-screen w-full subscribe-modal justify-center lg:"-ml-20"}`} 
    style={{zIndex: '9',marginLeft:marginleft}} >
      <div className='self-center w-full sm:w-2/5 shadow-xl'>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' onClick={() => props.cancelAction()}/>
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
  <h1 className='text-lg uppercase font-semibold py-2'>{props.title}</h1>
          <p className='pt-6 pb-12 text-sm'>
          {props.msg}
          </p>
        </div>
        <div className='flex flex-wrap justify-center pt-6 pb-8'>
          <div className='w-1/2 text-center cursor-pointer'>
            <button onClick={() => props.cancelAction()} className='w-1/2 cancel' >
              CANCEL
            </button>
          </div>
          <div className='w-1/2 text-center cursor-pointer'>
            <button onClick={() => props.yesAction()} className='w-1/2  yes' >
             YES
            </button>
          </div>
        </div>
      </div>
    </div> 
  )
}