import React, { useState, useEffect } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Validator from '../../services/validator';
import { TextField } from '@material-ui/core';
import ConfirmationPopup from '../../pure-components/ConfirmationPopup';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import CookieManager from '../../services/CookieManager';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import CorporateUserForm from './corporate-user-form';
import { BouncingLoader } from '../../pure-components/Loader';
import { Link } from 'react-router-dom';


export default function Corporate(props) {

  const [userList,setUserList]=useState([]);
  const [corpUser,setCorpUser] =useState([]);
  const [user, setUser] = useState({});
  const [helperText, setHelperText] = useState({})
  let validator = new Validator();
  let [showAddNewAdminForm, setShowAddNewAdminForm] = useState(false);
  let [showAddCorpUserForm, setShowAddCorpUserForm] = useState(false);
  const [showConfirmPopup,setShowConfirmPopup]=useState(false)
  const [selectedAdmin,setSelectedAdmin]=useState({});
  const [popupObj,setPopupObj]=useState({});
  const [sendRequset,setSendRequset]=useState({});
  const [isLoading, setLoading] = useState(true);
  const profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    
  let [showUserDetails,setShowUserDetails]=useState(false);
  useEffect(() => {
    if(profileData?.userType === "ADMIN")
      fetchCopAdmin();
  },[]);

  useEffect(() => {
    if(profileData?.userType === "SUPER_ADMIN" && props?.admin?.length)
      fetchCopAdmin();
  }, [props.admin]);


  const fetchCopAdmin =()=>{
    setLoading(true);
    let filter = {userType : "CORPORATE"};
    if(profileData?.userType === "ADMIN")
      filter["admin"] = profileData.email;
    else{
      if(props.admin?.length)
        filter["admin"] = props.admin;
    }

    API.getUserList({filter:filter}).then(res => {
      if (res.success)
        setUserList(res.data)
      else
        toast(({ closeToast }) => <SuccessToast  message={res.msg}/>);

      setLoading(false);
    })
  }
  const handleInputChange = (e, key) => {
    let value = key === 'tos' ? e : e.target.value;
    if (key === 'first_name') {
      let res = validator.setValue(value).isNotEmpty();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'email') {
      let res = validator.setValue(value).isNotEmpty().isEmail();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'companyName') {
        let res = validator.setValue(value).isString().doesNotContainSpecialChars();
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }
      if (key === 'taxNumber') {
        let res = validator.setValue(value).isString().isGSTIN();
        let temp = { ...helperText };
        temp[key] = res.mssg;
        setHelperText(temp);
      }
    let tempUser = { ...user };
    tempUser[key] = value;
    setUser(tempUser);
  }
 
  const removeUser =(id)=>{
    API.deleteUser(selectedAdmin.id).then(res => {
      if (res.success) {
        toast(({ closeToast }) => <SuccessToast  message="Admin deleted successfully "/>);
                   setShowConfirmPopup(false);
        if(selectedAdmin.parentId)
              fetchCopUser(selectedAdmin.parentId);
        else
          fetchCopAdmin();
      }
    })
  }
  const fetchCopUser =(id)=>{
    API.getCorpUser(id).then(res => {
      if (res.success) {
        setShowUserDetails(true)
        setCorpUser(res.data);
        setHelperText({});
      }
    })
  }
  const createCorporateUser=()=>{
    let counter = 0;
    let {taxNumber,companyName,startDate,_id}={...corpUser};
    let temp = {...helperText};
    let resFirstName = new Validator(user.first_name).isNotEmpty().isString();
    temp['first_name'] = resFirstName.mssg;
    resFirstName.status === false && counter++;
    let resEmail = new Validator(user.email).isNotEmpty().isString().isEmail();
    temp['email'] = resEmail.mssg;
    resEmail.status === false && counter++;
    setHelperText(temp);
   if(counter===0)
   {
     let userData={...user,userType:"CORPORATE USER"};
    API.createCorporateUser({...userData,taxNumber,companyName,startDate,parentId:_id}).then(data => {
      if(data?.success){  
        toast(({ closeToast }) => <SuccessToast  message="Corporate user created successfully "/>);
        setShowAddCorpUserForm(false);
        setUser({})
        fetchCopUser(corpUser._id);
        setHelperText({});
      }else{
        toast(({ closeToast }) => <ErrorToast message={(data?.mssg?.length ? data?.mssg : "") +  (data?.message?.length ? data?.message : "")}/>);
      }
    })
  }
  }
  const suspendUser =(id,isSuspended)=>{
    API.suspendUser(selectedAdmin.id,{isSuspended:!selectedAdmin.isSuspended}).then(res => {
      if (res.success) {
        setShowConfirmPopup(false);
       fetchCopUser(selectedAdmin.parentId);
      }
    })
  }
  const suspendAdmin =()=>{
    //check for available License to assign with admin & process accordingly
      let profileData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
      //let assignedLicenseCount = getAssignedLicenseCount(); 
      let assignedLicenseCount = userList?.reduce((totalCount, user)=>{
          if(!user.isSuspended)
            totalCount ++;
          return totalCount;
        }, 0);
      //check for Available License Count to assign, if its within limit proceed further else throw an Error
       if(selectedAdmin.isSuspended && Number(profileData?.totalLicenseCount)-assignedLicenseCount <= 0){
         toast(({ closeToast }) => <ErrorToast  message={`You only have ${profileData?.totalLicenseCount - assignedLicenseCount} available license to assign.`}/>);
         setShowConfirmPopup(false);
         return;
       }     
      API.suspendUser(selectedAdmin.id,{isSuspended:!selectedAdmin.isSuspended}).then(res => {
        if (res.success) {
          toast(({ closeToast }) => <SuccessToast  message={`You have successfully ${selectedAdmin.isSuspended ? "revoked" : "suspended"} user with Email : ${selectedAdmin.email}.`}/>);
          setShowConfirmPopup(false);
          fetchCopAdmin();
        }
      })
  }
  const updateCorpUser=(e,index)=>{
    let newUserList=userList;
    let obj={...newUserList[selectedAdmin.index]};
    // console.log(obj)

    // console.log(selectedAdmin)
    API.suspendUser(selectedAdmin.id,{numberUser:selectedAdmin.numberUser}).then(res => {
      if (res.success) {
        setShowConfirmPopup(false);
        fetchCopAdmin();
      }
    })

  }
  const setConfirmPopup=()=>{
    setShowConfirmPopup(false)
  }
  const cancelUpdateCorp=()=>{
    fetchCopAdmin();
    setShowConfirmPopup(false);
  }
  
  
  const addNewCorpUser = () => {
    if(profileData?.isSuspended || (new Date(profileData.renewalDate).getTime() < new Date().getTime())){
      toast(({ closeToast }) => <ErrorToast  message={`Can't add user, Admin's account suspended!`}/>);
      return;
    }

    setShowAddNewAdminForm(true)
  }

  const changeCorpUserStatus = (corpUser) => {
    if(profileData?.isSuspended || (new Date(profileData.renewalDate).getTime() < new Date().getTime())){
      toast(({ closeToast }) => <ErrorToast  message={`Can't change user's status, Admin's account suspended!`}/>);
      return;
    }
    setSendRequset("Suspend")
    setSelectedAdmin({id:corpUser._id, email : corpUser.email, isSuspended:corpUser.isSuspended});
    setPopupObj({title:`${corpUser.isSuspended ? "Unsuspend" :"Suspend"} Corporate Users`,msg:`Are you sure, you want to ${corpUser.isSuspended ? "Unsuspend" :"Suspend"} this user?`})
    setShowConfirmPopup(true);
  }

  return (
    <>
      <div className='flex'>
        <div className='text-lg w-4/5'>Corporate Users</div>
        {profileData?.userType === "ADMIN" &&
        <div className='m-1 align-center text-center bg-black text-white text-xs w-1/5 cursor-pointer'
        onClick={addNewCorpUser} >
          Add New
        </div>}
        {profileData?.userType === "ADMIN" &&    
            <Link className='m-1 align-center text-center bg-black text-white text-xs w-1/5 cursor-pointer'
            to={`/insights`}>
              View Insights
            </Link>
          // </Link>
        }
      </div>
      <div className='overflow-y-auto' style={{ height: "50vh" }}>
        {isLoading ?
          <div className='w-full'>
            <BouncingLoader />
          </div>:
        <>  
        {userList?.length === 0 && <div className="text-xl text-center text-red-500">No User Found</div>}
          
        {userList?.map((copuser, index) =>
          <div key={copuser._id} className='flex w-full border-b border-symStrokeGrey last:border-b-0' >
          <div className='w-4/5 self-center p-4 pl-0 cursor-pointer' 
            onClick={()=>fetchCopUser(copuser._id)}>
            <h1 className='text-sm mr-2 text-black ' > {copuser.first_name}</h1>
            <h2 className='text-xs self-center text-textGray-100 '>{copuser.email} </h2>
            <div className="hidden sm:block text-xs self-center text-textGray-100">
              {copuser.companyName}
            </div>
            <div className="hidden sm:block text-xs self-center text-textGray-100">
              {dayjs(copuser.startDate).format("D MMM, YYYY")} - {dayjs(copuser.renewalDate).format("D MMM, YYYY")}
            </div>
          </div>
          <div className='{`hidden sm:block row w-1/5 py-1 text-xs text-symLink self-center`}' >
          
          {profileData?.userType === "ADMIN" ? 
          <>
          <div className={`cursor-pointer mt-4`}
            onClick={() => changeCorpUserStatus(copuser)}
          >{copuser.isSuspended ? "Unsuspend" :"Suspend"}
          </div>
          {/* <Delete height="18" width="18" className="hidden lg:block mt-4 cursor-pointer"
            onClick={(e) => {
              // removeAdmin(admin._id)
              setSendRequset("Remove")
              setSelectedAdmin({id:copuser._id});
              setPopupObj({title:"Delete Corporate Users",msg:"Are you sure, you want to delete this user?"})
              setShowConfirmPopup(true);

              }}
          /> */}
          </> : <>
            <h1 className={`text-sm `} style = {{color : copuser.isSuspended ? "red" : "green"}} > {copuser.isSuspended ? "Suspended" :"Active"}</h1>
          </>}
          </div>
        </div>
          )}
        </>} 
      </div>   
      {
        showAddNewAdminForm &&
        <CorporateUserForm setShowAddNewUserForm = {setShowAddNewAdminForm} fetchUserList = {fetchCopAdmin} />
      }
      {
        showUserDetails &&
        <div 
          className='fixed top-0 w-full h-full left-0 justify-center flex' 
          style={{zIndex: 1, background: 'rgba(250,250,250,0.6)', backdropFilter: 'blur(5px)' }} >
          <div className='w-1/3 bg-white shadow-xl p-4 mt-20 mb-10 overflow-y-auto' style={{height: '450px'}}>
          <div className='flex'>
              <div className='text-lg w-full'>{corpUser.first_name}</div>
              <button className='px-2' onClick={() =>{ setShowUserDetails(false)
            fetchCopAdmin();
            }} >X</button>

              {/*  */}
            </div>
            <div className='flex mt-2'>
                <div className="w-4/5">{corpUser.companyName}</div> 
                {
             !showAddCorpUserForm && corpUser.childId?.length < corpUser.numberUser?<div 
                className='p-1 align-center text-center bg-black text-white text-xs w-1/5 cursor-pointer'
                onClick={() => setShowAddCorpUserForm(true)}>
                Add User
              </div>
              :
              <div 
                className='p-1 align-center text-center bg-black text-white text-xs w-1/5 cursor-pointer'
                onClick={() => setShowAddCorpUserForm(false)}>
                List User
              </div>
                }
                
            </div>
            {
             !showAddCorpUserForm && corpUser.childId?.map((user, index) =>
              <div key={user._id} className='flex w-full border-b border-symStrokeGrey last:border-b-0' >
              <div className='w-3/5 self-center p-4 pl-0 cursor-pointer' 
              >
                <h1 className='text-sm mr-2 text-black ' > {user.first_name}</h1>
                <h2 className='text-xs self-center text-textGray-100 '>{user.email} </h2>
              </div>             
              <div className="hidden sm:block text-center text-xs w-1/5 self-center text-textGray-100">
                {dayjs(user.updatedAt).format("D MMM, YYYY")}
              </div>
              
              <div className={`hidden sm:block  py-1 text-xs cursor-pointer text-symLink self-center`}
            //  onClick={(e) => suspendUser(user._id,user.isSuspended)}
            onClick={(e) =>{ 
              // suspendUser(copuser._id,copuser.isSuspended)
              setSendRequset("Suspend User")
              setSelectedAdmin({id:user._id,isSuspended:user.isSuspended,parentId:corpUser._id});
              setPopupObj({title:`${user.isSuspended ? "Unsuspend" :"Suspend"} Corporate Users`,msg:`Are you sure, you want to ${user.isSuspended ? "Unsuspend" :"Suspend"} this user?`})
              setShowConfirmPopup(true);
             }}
              >{user.isSuspended ? "Unsuspend" :"Suspend"}
                </div>
              <div className={`hidden sm:block ml-4 py-1 text-xs cursor-pointer text-ctaRed self-center`}
              //  onClick={(e) => removeUser(user._id)}
              onClick={(e) => {
                // removeAdmin(admin._id)
                setSendRequset("Remove")
                setSelectedAdmin({id:user._id,parentId:corpUser._id});
                setPopupObj({title:"Delete Corporate Users",msg:"Are you sure, you want to delete this user?"})
                setShowConfirmPopup(true);

               }}
                >Delete
                </div>
            </div>
              )} 
              {showAddCorpUserForm && 
              <>
            <div className='flex my-4 flex-wrap'>
              <label className='w-full text-xs text-gray-700'>Full Name</label>
              <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'first_name')}
              error={helperText.first_name !== undefined && helperText.first_name !== '' ? true : false}
              type="text"
              name="first_name"
            //  placeholder='Full Name'
              className="w-full  outline-none border border-textGray-300 text-textGray-500"
              value={user.first_name}
              helperText={helperText.first_name}
            />
            </div>
            <div className='flex my-4 flex-wrap'>
              <label className='w-full text-xs text-gray-700'>Email Address</label>
              {/* <input className='border border-gray-400 w-full p-2 focus:outline-none'/> */}
              <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'email')}
              error={helperText.email !== undefined && helperText.email !== '' ? true : false}
              type="text"
              name="email"
            //  placeholder='Full Name'
              className="w-full  outline-none border border-textGray-300 text-textGray-500"
              value={user.email}
              helperText={helperText.email}
            />
            </div>
            <div>
              <button className='bg-black text-white py-2 px-6 text-sm' 
               onClick={createCorporateUser} >
                Save
              </button>
            </div></>}   
            
          </div>
        </div>
      }
      {
        showConfirmPopup ? 
        <ConfirmationPopup giveStyle="-10rem" yesAction={sendRequset==="Remove" ? removeUser :sendRequset==="NumberOfUser"? updateCorpUser : sendRequset==="Suspend" ? suspendAdmin :suspendUser}
        cancelAction={sendRequset==="NumberOfUser"? cancelUpdateCorp : setConfirmPopup}
        //setShowConfirmPopup={setShowConfirmPopup}

         title={popupObj.title} msg={popupObj.msg}/>
        : 
        null 
      }
    </>
  )
}