import React, {useState, useEffect, useRef} from 'react';
import {Checkbox} from '@material-ui/core';
import API from '../../services/api';
import { toast } from 'react-toastify';

import Amplitude from 'amplitudejs';
import CancelIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {ReactComponent as Error } from '../../assets/error.svg';
import {ReactComponent as Success } from '../../assets/success.svg';
import { ReactComponent as Add } from '../../assets/GreenButtonRoundPlusSmall.svg';
import './style.css';


function AddTrackToProject(props) {
    const [projectTitle, setProjectTitle] = useState('');
    const [projects, setProjects] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [tempProjects, setTempProjects] = useState([]);
    const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
    // const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
    const [editable, setEditable] = useState(false);
    let createProjectRef = useRef(null);

    useEffect(() => {
        console.log('called')
        setTrackToBeAddedToProject(props.trackToBeAddedToProject);
        fetchProjects();
      }, []);
    
    const createProject = (value) => {
        API.createProject({title:value}).then(res => {
          setProjectTitle('');
          setEditable(true);
          fetchProjects();
        });
      }
      const fetchProjects = () => {
        API.getProjects().then(data => {
          let listproject=data.data?.map(item=>{
              if(item.trackIds.some(item1 => item1.trackId.mewoTrackId===props.trackToBeAddedToProject))
                item.isChecked=true; 
            return item;
          })
          // console.log(listproject)
          setProjects(listproject);
          setTempProjects(listproject);
        })
      }
      const handleProjectTitle = (e) => {
      // let {value} = e.target;
       // setProjectTitle(value);
        if (e.key === 'Enter') {
       createProject(projectTitle);
        }
      }
    
      const handleAddTrackToProject = (event,projectId,index) => {
     //   setLoaderAddTrackToProject(true);
    //  console.log('[event] : ', event);
    //  console.log('[project id] : ', projectId);
    //  console.log('[index] : ', index);

     let isChecked=event.target.checked;
    //  console.log({isChecked});
     if(isChecked)
     {
      // console.log('[trackToBeAddedToProject] : ', trackToBeAddedToProject);
      API.addTrackToProject(trackToBeAddedToProject, projectId).then(res => {
        let newprojects= [...projects];
        newprojects[index].isChecked= true;
        setProjects(newprojects);        
        // setTimeout(() => {
        //   toast(({ closeToast }) => <div class="flex  m-1"><Success className="p-3 mr-2 border-r border-gray-500"/>
        //   <div className="self-center">
        //     <h1 className='text-base text-black ' > Success. Great job!</h1>
        //     <h2 className='text-xs self-center text-gray-700 '> {res.mssg} </h2>
        //   </div> </div>);
        // }, 700);
      });
     }
     else
     {
      //  console.log('props.projectTrackId : ', props)
      API.deleteTrackFromProject(props.trackToBeAddedToProject, projectId).then(res => {
        let newprojects = [...projects];
        newprojects[index].isChecked = false;
        setProjects(newprojects);        
        // setTimeout(() => {
        //   toast(({ closeToast }) => <div class="flex  m-1"><Success className="p-3 mr-2 border-r border-gray-500"/>
        // <div className="self-center">
        //   <h1 className='text-base text-black ' > Success. Great job!</h1>
        //   <h2 className='text-xs self-center text-gray-700 '> {res.message} </h2>
        //   </div> </div>);
        //   // toast.success(res.message, {
        //   //   position: toast.POSITION.BOTTOM_LEFT
        //   // });
        // }, 700);
      });
     }
      }
    
      const handleSearchProjects = (e) => {
        let {value} = e.target;
        setSearchKeyword(value);
        if (value !== '' && value.length > 1) {
          let filteredProjects = projects.filter( project =>  project.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
          setProjects(filteredProjects);
        }
    
        if(value === '') {
          setProjects(tempProjects);
        }
      }

  const handleCreateProject = (e) => {
    // console.log('createProjectRef : ', createProjectRef);
    if (createProjectRef !== null) createProjectRef.current.focus();
    setEditable(true);
  }

  return(
    <div className='project-modal fixed right-0 top-0 shadow-md' style={{height: Amplitude.getSongs().length ?"82%" :"92%"}} >
    <div className='p-4 mt-4 mr-4 pb-0 flex justify-end'>
      <CancelIcon 
        onClick={props.closeProjectModal} 
        className='text-white cursor-pointer' 
        style={{fontSize: '28px'}}
      />
    </div>

    {/* {
      loaderAddTrackToProject
      ? 
      <div className='p-2  mt-20 flex justify-center flex-wrap '>
        <div className='w-full'>
          <RippleLoader className='mx-auto'/>
        </div>
        <p className='w-full text-center text-xl text-gray-500'>Please wait... </p>
        <p className='w-full text-center text-xl text-gray-500'>Adding Track to Project </p>
      </div>
      :
      <> */}
        <div className=' w-full p-4'>
          <div className='w-full px-8'>
            <input
              onChange={handleSearchProjects}
              className='p-2  mb-2 w-full text-2xl focus:outline-none bg-black border-b placeholder-white text-white'
              placeholder='Search projects'
            />
            <div style={{overflowY:"auto",height:"70vh"}}>
            {
              projects.map( (project,index) => 
                <div
                  key={project._id}
                  className='pt-2 pb-2 pr-2 pl-2 
                  text-white 
                  hover:bg-gray-800 cursor-pointer'>
                  <Checkbox 
                    label='My checkbox' 
                    labelstyle={{color: 'white'}}
                    checked={project.isChecked }
                    onClick={(e) => handleAddTrackToProject(e,project._id,index)}
                    iconstyle={{fill: 'white'}}
                    inputstyle={{color:'white'}}
                    style={{color:'white'}}
                  />
                  {project.title}
                </div>
              )
            }
            </div>
          </div>
          
        </div>
        <div className="mb-0 w-full">
        <button className='pt-2 px-12 border-t border-gray-600 w-full fixed flex outline-none focus:outline-none text-gray-500' style={{bottom:0,backgroundColor:"#1A1A1A" }}>
        <Add className="mr-2 ml-5 addbutton mt-2" onClick={handleCreateProject} />
        <input
          ref={createProjectRef}
          className='p-2  mb-2 w-full  focus:outline-none placeholder-white text-white'
          placeholder={editable ? 'Enter project title' : 'Create Project'}
          onChange={event => {setProjectTitle(event.target.value)}}
          onKeyDown={handleProjectTitle}
          readOnly={!editable}
          style={{backgroundColor:"#1A1A1A" }}
          autoFocus={editable}
          value={projectTitle}
        />
        </button>
        </div>
        {/* </>
    } */}
  </div>
  )
}

export default AddTrackToProject;