import React, { useEffect, useState, useContext } from "react";
import CardContent from "@material-ui/core/CardContent";
import API from "../../../services/api";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnFavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ReactComponent as CreateNewFolderIcon } from "../../../assets/folder.svg";
import { ReactComponent as GetAppIcon } from "../../../assets/download.svg";
import { ReactComponent as Activedownload } from "../../../assets/activedownload.svg";
import { ReactComponent as ThumbPrint } from "../../../assets/auto_fix_high_black_18dp.svg";
import "./style.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Amplitude from "amplitudejs";
import TrackContext from "../../../contexts/track-context";
import Tabs from "@material-ui/core/Tabs";
import { ReactComponent as Play } from "../../../assets/play.svg";
import { ReactComponent as Pause } from "../../../assets/pause.svg";
import { ReactComponent as Close } from "../../../assets/close.svg";
import CookieManager from "../../../services/CookieManager";
import { Link, useHistory, useParams } from 'react-router-dom';
import { BouncingLoader } from "../../../pure-components/Loader";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../../pure-components/CustomToast";
import RippleLoader from "../../../assets/loader-2.gif";
import Helper from "../../../services/helper";
import defaultImage from "../../../assets/default_music.png";
import Constants from "../../../services/constants";
import SvgInline from "../../../pure-components/SvgInLine";
import MewoService from "../../../services/mewoService";
import AlternateTracks from "../../TrackItem/alternate-tracks";


export default function Track(props) {

  let trackContext = useContext(TrackContext);
  const { filterMenu, setFilterMenu, setFilterSubMenu, setFilterSubMenuchild } = trackContext;
  const [favs, setFavs] = useState([]);
  const [download, setDownload] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadModalPosition, setDownloadModalPosition] = useState(null);
  const [ChidDownloadModalPosition, setChilddownloadModalPosition] = useState(null);
  const [hdmp3File, setHdmp3File] = useState(false);
  const [originalFile, setOriginalFile] = useState(false);
  const [alternateVersions, setAlternateVersions] = useState([]);
  const [expand, setExpand] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const { searchTags } = trackContext.trackFilter;
  const history = useHistory(); 
  const {playlistId,albumId}=useParams()

  useEffect(() => {
    if (localStorage.getItem("favs") !== "undefined") {
      const favorites = JSON.parse(localStorage.getItem("favs"));
      favorites !== null && setFavs(favorites);
    }
    if (localStorage.getItem("download") !== "undefined") {
      const download = JSON.parse(localStorage.getItem("download"));
      download !== null && setDownload(download);
    }
  }, []);

  const fetchTrack = async(trackId, isAltVersion = false) => {
    // if (!trackContext.validateUser(Constants.ACTION.PLAY)) {
    //   trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction(Constants.ACTION.PLAY);
    //   return;
    // }
    if (trackId === trackContext.currentTrackDetail.id && trackContext.playingFrom === props.pageId) {
      trackContext.setPlayPause(!trackContext.playPause);
      return;
    }
    if (Amplitude.getSongs().length) {
      Amplitude.stop();
      Amplitude.removeSong(0);
    }
    trackContext.setpreviousTrack(props.previousTrack);
    // props.setFetchingTrack(true);
    await API.playTrack(trackId).then((res) => {
    //   props.setFetchingTrack(false);
      if (res?.data?.success) {
        trackContext.setPlayingFrom(props.pageId)
        trackContext.setalltrackData(trackContext.trackData)
        trackContext.setCurrentTrack(res.data.data.audiofile);
        trackContext.setCurrentTrackDetail({
          ...res.data.data,
          tags: props.tags,
          artist: props.artist,
          artist_type: props.rights_type,
        });
      } else {
        Helper.handleError(res?.data);
      }
    });
  };

  const fetchAltVersion = async(trackId, versions = 0, expandval = false) => {
    if(!(versions > 0) && expandval){
      let altVer = {
        album: { title: props.album }
      };
      setAlternateVersions([{ ...altVer }]);
      setExpand(expandval);
      props.setExpandedTrackId(trackId);
      return;
    }
    if (expandval && versions > 0) {
      let reqObj = {
        url : `public/tracks/${trackId}/versions`, 
        method : 'get'
      };
  
      await MewoService.loadMewoData(reqObj).then((res) => {
        if(res){
            if (res?.length > 0) {
              setAlternateVersions(res);
            } else {
              let altVer = {
                album: { title: props.album },
              };

              setAlternateVersions([{ ...altVer }]);
            }
            setExpand(expandval);
            props.setExpandedTrackId(trackId);
        }else{
            Helper.handleError(res);
          }
        })
        .catch((err) => {
          toast(({ closeToast }) => (
            <ErrorToast message={"Error Loading Track Versions : " + err?.response?.data?.message} />
          ));
          
        });
    } else {
      setExpand(expandval);
    }
  };
  const handleProjectModal = (trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.ADD_TO_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.ADD_TO_PROJECT);
      return;
    } 
    props.displayProjectModal(trackId);

  };

  
  const handleThumbPrintSearch = async(props) => {
    // if(!trackContext.validateUser(Constants.ACTION.SIMILARITY_SEARCH)){
    //   trackContext.setShowLoaderMagic(false);
    //   trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction(Constants.ACTION.SIMILARITY_SEARCH);
    //   return;
    // }
    trackContext.setShowLoaderMagic(true);

    let reqObj = {  
      url : 'public/search', 
      method : 'post', 
      data : {
        "query": "",
        "options": 
        {
            "trackId" : props.trackId,
            "filters": {
              exclude: [],
              ...props.searchTags,
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions":trackContext.searchDescription ? trackContext.searchDescription : false
        }
      }
    };
    await MewoService.loadMewoData(reqObj).then(res => {
      if (res?.hits) {
        let newRes = res.hits;
        let [, ...magicWand] = newRes;
        trackContext.setIsLoading(false);
        trackContext.setShowLoaderMagic(false);
        trackContext.setTrackData(magicWand);
        trackContext.setTotaltracks(res.total.value);
        
      } else {
        Helper.handleError(res);
      }
    }).finally(() => {
      trackContext.setShowLoaderMagic(false);
    });

    let filter=trackContext.trackFilter
    filter.selectedChildTags=[]
    filter.selectedTags=[]
    filter.url=""
    filter.searchId="";
    filter.fileName="";
    filter.searchText = "";
    filter.searchTitle = props.title;
    filter.trackId=props.trackId;
    trackContext.setTrackFilter(filter);
    if(trackContext.path === "/"  || 
      trackContext.path.startsWith("/search/track/") ||
      trackContext.path.startsWith("/artist/") ||
      trackContext.path.startsWith("/playlists/")||
      trackContext.path.startsWith("/album/") 
   ){
    localStorage.setItem("filter_music", JSON.stringify(filter));
   }
   if (trackContext.path === "/sound-design"  ||  trackContext.path.startsWith("/sound-design/search/track/") 
   ) {
    localStorage.setItem("filter_soundfx", JSON.stringify(filter));
    
   }

   if (trackContext.path.startsWith("/playlists/")
   ) {
    localStorage.setItem("filter_playlist", JSON.stringify(filter));
   }

   if (trackContext.path.startsWith("/album/")
   ) {
    localStorage.setItem("filter_album", JSON.stringify(filter)); 
   }

  };

  const handleAddTrackToFavorites = async(trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.FAVOURITE)){
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.FAVOURITE);
      return;
    } 
    await API.addTrackToFavorites(trackId).then((res) => {
      if (res.success) {
        localStorage.setItem("favs", JSON.stringify(res.favorites));
        setFavs(res.favorites);
      } else {
        Helper.handleError(res);
      }
    });

  };

  const handleClose = () => {
    setShowDownloadModal(false);
  };

  const handleDownloadTrack = async (event, trackId) => {
    let cachedUserData = JSON.parse(CookieManager.getCookie("user-data"));
    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      return;
    }
    let currentTarget = event.currentTarget;

    let userData = {id : cachedUserData._id};
    if(cachedUserData?.userType === "ADMIN" || cachedUserData?.userType === "SUPER_ADMIN")
      userData = {id : cachedUserData.email, userType : "ADMIN"};
    await API.validateSubscription(userData.id, userData.userType).then(async(resp) => {
      if (resp?.success) {
        if (resp.data?.userType === "FREE" || !resp.data?.subscribed) {
          trackContext.setShowSubscribePopup(true);
          trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
        } else {
          CookieManager.setCookie("user-data", JSON.stringify(resp.data));
          await API.getDownloadTracks(trackId).then((res) => {
            if (res?.audiofile !== undefined && res.audiofile !== null) {
              setHdmp3File(res.audiofile?.hd_mp3?.url);
              setOriginalFile(res.audiofile?.original.url);
              setDownloadModalPosition(currentTarget);
              setChilddownloadModalPosition(currentTarget);
              setShowDownloadModal(true);
            } else {
              Helper.handleError(res);
            }
          });
        }
      } else {
        toast(({ closeToast }) => <ErrorToast message={resp.mssg} />);
      }
    });

  };

  const handleDownloadArchive = async(trackId, quality) => {
    await API.downloadArchiveTrack(trackId, quality).then((res) => {
        if (res?.length > 0) {
          toast(({ closeToast }) => (
            <SuccessToast message="Check your email, you have been sent a link to download the files" />
          ));
        } else {
          toast(({ closeToast }) => <ErrorToast message={res.message} />);
        }
      })
      .catch((err) => {
        Helper.handleError(err);
       
      });
  };

  const handleArchiveDownload = async(trackId, quality) => {
    await API.downloadArchiveTrack(trackId, quality).then((res) => {
      //success : response  = ["taskId"]
      //fail : response : { "status": 400, "message": "\"documentIds\" is required. \"docId\" is not allowed.", "key": "validation_error", "stack": {}, "reqId": "9022499d-9b2b-4edc-9397-c8f8372a83ea" }
      if (Array.isArray(res) && res.length) {
        toast(({ closeToast }) => (
          <SuccessToast message="Check your email, you have been sent a link to download the files." />
        ));
      } else {
      toast(({ closeToast }) => <ErrorToast message={res.message} />);
      }

    });
  };

  const setDownloadHistory = async(trackId) => {
    await API.setDownloads(trackId).then((res) => {
      const downloadlist = JSON.parse(localStorage.getItem("download")) || [];
      downloadlist.push(trackId);
      setDownload(downloadlist);
      localStorage.setItem("download", JSON.stringify(downloadlist));
    });
  };


  const addSearchTag = (tagId, tagName) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let tags_not = newTags.tags_not || [];
    let Contexttags = newTags.tags || [];
    Contexttags.push(tagId);
    tags_not = tags_not.filter((itemnot) => itemnot !== tagId);
    newTags.tags = Contexttags;
    newTags.tags_not = tags_not;
    newTrackFilter.searchTags = newTags;
    newTrackFilter.fetchList = true;
    newTrackFilter.url = "";
    trackContext.setTrackFilter(newTrackFilter);
  };
  const removeSearchTag = (tagId, tagName) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    let tags_not = newTags.tags_not || [];
    if (tags_or.some((item1) => item1.includes(tagId))) {
      tags_not.push(tagId);
      tags_or = tags_or.filter((item) => {
        if (item.every((subItem) => tags_not.includes(subItem))) {
          tags_not = tags_not.filter((itemnot) => !item.includes(itemnot));
          return false;
        }
        return true;
      });
    }
    if (contexttags.includes(tagId)) {
      contexttags = contexttags.filter((item) => item !== tagId);
    }
    newTags.tags_or = tags_or;
    newTags.tags_not = [...tags_not];
    newTags.tags = contexttags;
    newTrackFilter.searchTags = newTags;
    trackContext.setTrackFilter(newTrackFilter);
  };

  const handleMagicWandClick = (trackData) => {
    //TODO : change this as we change musicFilter obj
    const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
    tempFilter.type = Constants.SEARCH_TYPE.track;
    tempFilter.searchText = trackData.title;
    tempFilter.searchRef = trackData.id;
    tempFilter.segment = null;
    CookieManager.setCookie("harmix-music-filter", JSON.stringify(tempFilter));
    history.push(`/library/${trackData.id}`)
  }

  const alternateVersionsProps = {
    alternateVersions,
    fetchTrack,
    favs,
    setFavs,
    handleAddTrackToFavorites,
    handleProjectModal,
    handleDownloadTrack,
    showDownloadModal,
    handleClose,
    hdmp3File,
    originalFile,
    handleDownloadArchive,
    setDownloadHistory,
    download,
    ChidDownloadModalPosition,
    handleMagicWandClick
  };

  const handleLoaderWithThumbPrint = () => {
    // if(!trackContext.validateUser(Constants.ACTION.SIMILARITY_SEARCH)){
    //   trackContext.setShowLoaderMagic(false);
    //   trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction(Constants.ACTION.SIMILARITY_SEARCH);
    //   return;
    // }
    let newFilter = filterMenu;
    newFilter = newFilter.map((item) => {
      item.count = 0
      return item;
    })
    trackContext.setFilterMenu(newFilter);
    localStorage.setItem("filterMenu", JSON.stringify(newFilter));

    setFilterSubMenu([]);
    setFilterSubMenuchild([]);
    //trackContext.setClearData(true)
    // setShowMayaLoader(true)
    handleThumbPrintSearch(props)
    
    let searchTitle=""
    if(props){
      
      searchTitle=props.title
    }else{

      searchTitle = trackContext.trackFilter.searchTitle
    }
 
    if(trackContext.path.startsWith("/search")
    ||trackContext.path==="/"
    ||trackContext.path.startsWith("/album") 
    || trackContext.path.startsWith("/artist")
    || trackContext.path.startsWith("/playlists")
    )
    {
      //setting filter data
      if(searchTitle){
        let tempData = JSON.parse(localStorage.getItem("filterMenu") || {});        tempData.searchTitle = searchTitle;
        localStorage.setItem("filterMenu", JSON.stringify(tempData));
      }
      history.push( `/search/track/`);
      
    }
    else{(
    trackContext.path.startsWith("/playlists/")?history.push( `/playlists/${playlistId}`)
    :
     trackContext.path.startsWith("/album/")?history.push( `/album/${albumId}`):
    history.push( `/sound-design/search/track/`))} 
  
    // toast(({ closeToast }) => (

    //   <SuccessToast page='music' message="This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you." />
    // ));
    // setTimeout(()=>{setShowLoader(false);
    //   trackContext.setClearData(false)
    // },3000)

  }

  const loadAlbumData = (albumId) => {
    if(!albumId?.length){
      toast(({ closeToast }) => <ErrorToast message={"Album Id not specified."} />);
      return;
    }else{
      history.push(`/album/${albumId}`);
    }
  }

  const loadArtistData = (artistId) => {
    if(!artistId?.length){
      toast(({ closeToast }) => <ErrorToast message={"Artist Id not specified."} />);
      return;
    }else{
      history.push(`/artist/${artistId}`);
    }
  }
  return (
    <div
      id={props.trackId}
      className="track-item flex flex-wrap bg-white cursor-mouse hover:bg-gray-200 text-red-500 border-b border-symStrokeGrey transition ease-in-out "
    >
      <div className="flex w-full">
        <div className="w-5/12 self-center row-line">
          <div className="w-2/12 self-center">
            {props.albumId !=null && props.image != null ? (
              <Link className="self-center" to={`/album/${props.albumId}`}>
                <img
                  src={props.image || defaultImage }
                  alt="Album image"
                  className="hidden sm:flex sm:cursor-pointer"
                  width="85px"
                  onError={()=>{this.src = defaultImage}}
                  />
              </Link>) : (
                <img
                  alt="Album image"
                  className="hidden sm:flex sm:cursor-pointer"
                  width="85px"
                  src={defaultImage}
                />)}
            </div>
            <div className="w-1/12 self-center">
                <div
                aria-label="play/pause"
                className="p-1 outline-none focus:outline-none play-button border-symStrokeGrey cursor-pointer"
                onClick={() => fetchTrack(props.trackId)}
                >
                {trackContext.playPause &&
                props.trackId === trackContext.currentTrackDetail.id && trackContext.playingFrom === props.pageId ? (
                    <Pause className="outline-none" />
                ) : (
                    <Play className="outline-none" />
                )}
                </div>
            </div>
            <div className=" w-9/12 px-1 m-1 self-center cursor-pointer"
                onClick={() => fetchAltVersion(props.trackId, props.versions-1, !expand)}
                style={{
                    overflow: "hidden"
                }}>
                <div className="track-title self-center">
                    {" "}
                    {props?.title?.toLowerCase()}
                </div>
                <div className="track-artist self-center">
                    {" "}
                    {Helper.getArtistNameFromList(props.artists)?.toLowerCase()}{" "}
                </div>
                <div className={"text-description self-center flex " + (expand ? "text-all" : "desc-style")}>
                    {props?.description !== undefined && props?.description?.length > 0 ? props.description : ""}
                </div>
            </div>
        </div>
        
        {/* Tags placement */}
        {/* <div className="hidden lg:text-left sm:text-center sm:w-1/12 sm:text-sm sm:self-center sm:text-textGray-100 lg:block">
          {props.similarity !== undefined && props.similarity > 0 ? parseFloat(props.similarity).toFixed(0) + "%" : null}
        </div> */}
        <div className="w-4/12 self-center ">
          {/* <svg className="p-1 w-full" src={require(props.waveform).default} alt="TEST" width="100" height='50' /> */}
          <SvgInline url = {props.waveform} width="98%"/>
        </div>
        <div className="hidden row-line text-center w-3/12 text-textGray-500 block self-center ">
            <div className="p-1 w-2/12 self-center pl-3">
              {props.versions !== undefined && (props.versions-1) !==0 &&
                
                <div className={"w-6 h-6 text-white bg-black text-xs flex items-center justify-center rounded-full"} >
                
                  <>
                  <span className="mb-1" >+</span>
                  <span>{props.versions-1}</span>
                  </>
                </div>
              }
            </div>
          
          <div className="p-1 w-3/12 text-description self-center row-line">
            {props.bpm !== undefined ? Math.round(props.bpm) + " BPM" : null}
          </div>
          <div className="p-1 w-2/12 text-description self-center ">
            {props.duration &&
              new Date(props.duration * 1000).toISOString().substr(14, 5)}
          </div>
          {/* <div className="w-1/12">&nbsp;</div> */}
          <ThumbPrint
              className="w-1/12 trackicon thumbprint-icon w-8 sm:w-6 sm:mb-4 sm:mt-5 md:ml-2 sm:right-0 self-center cursor-pointer thumb-print"
              onClick={() => handleMagicWandClick({id : props.trackId, title : props.title})}
            />
          
          {favs.indexOf(props.trackId) !== -1 ? (
            <FavoriteIcon
              onClick={() => handleAddTrackToFavorites(props.trackId)}
              style={{ fontSize: "20px" }}
              className=" w-1/12 fav-icon ml-3 sm:m-1 sm:mr-2 self-center sm:right-0 cursor-pointer text-ctaRed"
            />
          ) : (
            <UnFavoriteIcon
              onClick={() => handleAddTrackToFavorites(props.trackId)}
              style={{ fontSize: "20px" }}
              className=" w-1/12 trackicon unfav-icon ml-3 sm:m-1 sm:mr-2 self-center sm:right-0 cursor-pointer text-gray-600 hover:text-ctaRed"
            />
          )}
          <CreateNewFolderIcon
            style={{ marginTop: "12px" }}
            onClick={() => handleProjectModal(props.trackId)}
            className=" w-1/12 trackicon folder-icon ml-4 mt-2 sm:m-1 sm:mt-2 self-center sm:right-0 cursor-pointer text-gray-700 hover:text-blue-600"
          />
          {download.indexOf(props.trackId) !== -1 ? (
            <Activedownload
              onClick={(event) => handleDownloadTrack(event, props.trackId)}
              style={{ fontSize: "20px", marginRight: "5px" }}
              className=" w-1/12 trackicon hidden sm:-1 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block"
            />
          ) : (
            <GetAppIcon
              onClick={(event) => handleDownloadTrack(event, props.trackId)}
              style={{ fontSize: "20px" }}
              className=" w-1/12 trackicon hidden sm:-1 sm:mt-6 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block"
            />
          )}
          {expand && props.expandedTrackId === props.trackId ? (
            <ExpandLessIcon
              onClick={() => fetchAltVersion(props.trackId, props.versions-1, !expand)}
              style={{ fontSize: "35px" }}
              className="w-1/12 trackicon self-center cursor-pointer text-gray-700"
            />
          ) : (
            <ExpandMoreIcon
              onClick={() => fetchAltVersion(props.trackId, props.versions-1, !expand)}
              style={{ fontSize: "35px" }}
              className="w-1/12 trackicon self-center cursor-pointer sm:text-gray-700"
            />
          )}
        </div>
        {
          <Menu
            id="customized-menu"
            anchorEl={downloadModalPosition}
            open={Boolean(showDownloadModal)}
            onClose={handleClose}
            style={{ width: "320px" }}
          >
            <MenuItem className="card-action">
              <a
                href={originalFile}
                target="_blank"
                rel="noopener noreferrer"
                download=""
              >
                Original HD - Single track
              </a>
            </MenuItem>
            <MenuItem
              onClick={() => { handleArchiveDownload(props.trackId, "original"); setShowDownloadModal(false) }}
              className="card-action"
            >
              <span className="card-action-text">
                Original HD - All versions
              </span>
            </MenuItem>
            <MenuItem
              onClick={() => { setDownloadHistory(props.trackId); setShowDownloadModal(false) }}
              className="card-action"
            >
              <a
                href={hdmp3File}
                target="_blank"
                rel="noopener noreferrer"
                download=""
              >
                MP3 - Single track
              </a>
            </MenuItem>
            <MenuItem
              onClick={() => { handleArchiveDownload(props.trackId, "hd_mp3"); setShowDownloadModal(false) }}
              className="card-action"
            >
              <span className="card-action-text">
                MP3 - All versions
              </span>
            </MenuItem>
          </Menu>
        }
      </div>
      {expand && props.expandedTrackId === props.trackId && (
        <div className="alternative-versions-wrapper w-full">
          <div className="flex flex-wrap w-1/2 mt-4">
            <div className="sm:hidden text-center ml-2 w-2/12 text-sm self-center block text-black font-medium">
              {props.bpm !== undefined ? Math.round(props.bpm) + "BPM" : null}
            </div>
            <div className="sm:hidden text-center ml-12 w-2/12 text-sm self-center block text-black font-medium">
              {props.duration &&
                new Date(props.duration * 1000).toISOString().substr(14, 5)}
            </div>
          </div>
          <div className="px-10 w-full flex flex-wrap mb-4">
            <div className="flex flex-wrap mt-2 w-full">
              <div className="pr-8 pb-2">
                <p className="text-title text-gray-600">Album</p>
                <h3 className="track-artist hover:underline font-bold">
                  <div className="cursor-pointer" onClick={() => loadAlbumData(props.albumId)}>{props.album}</div>
                </h3>
              </div>
              <div className="pr-8 pb-2">
                <p className="text-title text-gray-600">Artists</p>

                {props.artists?.map((elem) => {
                  return(
                    <div className="track-artist font-bold cursor-pointer hover:underline " onClick={() => loadArtistData(elem?.id)}>
                      {
                        elem?.name
                      }
                    </div>)
                })}
              </div>
              <div className='pr-8 pb-2'>
                <p className="text-title text-gray-600">Producers</p>
                {props.producers?.map((elem) => {
                  return(
                    <div className="track-artist font-bold ">
                      {
                        elem?.name
                      }
                    </div>)
                })}
              </div>
              
            </div>
           
          </div>
          {alternateVersions[0]?.id && (
            <div className="px-10 w-full flex flex-wrap">
              <p className="text-title text-gray-600">
                Alternative versions and Edits
              </p>
              <div
                className="px-3 py-2 w-full"
                style={{ backgroundColor: "#F5F5F5" }}
              >
                <AlternateTracks {...alternateVersionsProps} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}