import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./style.css";

export default function FAQPage() {
  const [expanded, setExpanded] = React.useState(false);

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)};
  const [value, setValue] = React.useState('Controlled');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className='flex text-center flex-wrap'>
      <div className='self-center text-center w-full mt-10 p-5'>
        <h1 className='w-full mt-5 text-2xl font-synthese400'>Frequently Asked Questions</h1>
        <div className='self-center text-left w-full main-content'>
        <form className="SyncForm mt-10 mx-10 self-center text-left" noValidate autoComplete="off">
          <Accordion className="SyncAccordion" expanded={expanded === 'panel1'} onChange={handlePanelChange('panel1')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">How do I renew my subscription?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Subscriptions are automatically setup for recurring billing unless you cancel your subscription. You will get a 5 days advance notice before we debit your account.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel2'} onChange={handlePanelChange('panel2')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Can I cancel my subscription?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            You can cancel your subscription at any time; you can continue to use your account until the last day of your current subscription period.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel3'} onChange={handlePanelChange('panel3')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Refund policy</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            There is no refund policy. After cancellation, your subscription will remain active until its term.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel4'} onChange={handlePanelChange('panel4')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">How many compositions can I download for a project?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            There is no limitation to the number of downloads per project.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel5'} onChange={handlePanelChange('panel5')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Why did I get a download warning?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Our algorithms detected an unusual download activity in your account. Just reply to the warning to confirm that your account has not been hacked.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel6'} onChange={handlePanelChange('panel6')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Can I use the same composition in multiple projects?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Yes, you can. But every new usage gives you a license for that particular usage.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel7'} onChange={handlePanelChange('panel7')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Can I edit the composition to fit my project?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Yes, you can edit it, or mix it with other musical elements, as long as you do not change the name of the composition. Changing the name requires prior permission from SyncMama.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel8'} onChange={handlePanelChange('panel8')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">What is a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            A license is insurance issued by the owner/agent of the music that your usage of the composition has been authorized. 
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel9'} onChange={handlePanelChange('panel9')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Why do I need a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Our license is the passport for global commercial exploitation of our musicin your work. This license is enforceable against any and all infringement claims.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel10'} onChange={handlePanelChange('panel10')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Does downloading a composition give me a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            No. Downloads are only for your personal usage. Any commercial usage needs a license.
            </div>
          </AccordionDetails>
          </Accordion>

          <Accordion className="SyncAccordion" expanded={expanded === 'panel11'} onChange={handlePanelChange('panel11')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">How do I create a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Once your project is completed, fill out the Project Information and generate the license for the project.
            </div>
          </AccordionDetails>
          </Accordion>

          <Accordion className="SyncAccordion" expanded={expanded === 'panel12'} onChange={handlePanelChange('panel12')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Is subscription equivalent to a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            No. A license is always for a given project. After filling out the Project Information, you will obtain the license for that project.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel13'} onChange={handlePanelChange('panel13')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Do I have to make an extra payment for obtaining a license?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            No, there is no additional payment to be made.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel14'} onChange={handlePanelChange('panel14')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Is my license valid worldwide?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            Yes.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel15'} onChange={handlePanelChange('panel15')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Will my licenses also be cancelled when I cancel my subscription?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            No. Your licenses are for the duration of copyright (perpetuity).
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel16'} onChange={handlePanelChange('panel16')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">How to find my licenses?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            All your licenses are kept in your account and can be downloaded any time.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel17'} onChange={handlePanelChange('panel17')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">How long can I access my licenses (archiving policy)?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            You can access your archives as long as your subscription is active. If your subscription has been cancelled, you can access your archives by reactivating your subscription using your original ID.
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel18'} onChange={handlePanelChange('panel18')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">What can I not do?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
              <p>There are some common-sense restrictions:</p>
              <p className="font-bold">One Account = One User</p>
              <p>You cannot share your account with anyone else, even within the same organization or business. Every license is in your name.</p>

              <p className="font-bold">Claim ownership of a composition</p>
              <p>You cannot claim ownership of a composition. A license is a right to use, not a transfer of ownership. Composers retain ownership of their compositions.</p>

              <p className="font-bold">No modification</p>

              <p>You can edit a composition but you cannot modify it to create a different version.</p>

              <p className="font-bold">Compositions can only be used for lawful content</p>
              <p>Compositions must not be used in content that is defamatory, pornographic, obscene, racist, etc.</p>
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel19'} onChange={handlePanelChange('panel19')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">Is the music royalty-free?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            <p>No. Royalties are the salary and the pension of the artists who create the music. Our collection of their royalties is totally transparent and does not affect you in any way.
            </p>
            </div>
          </AccordionDetails>
          </Accordion>
          <Accordion className="SyncAccordion" expanded={expanded === 'panel20'} onChange={handlePanelChange('panel20')}>
          <AccordionSummary className="SyncAccordionSum" expandIcon={<ExpandMoreIcon />}>
          <h4 className="SectionTitle">What is royalty-free music?</h4>
          </AccordionSummary>
          <AccordionDetails className="SyncAccordionDetails">
            <div className="SyncQuestion">
            <p>Music is royalty-free when an unscrupulous producer hires artists to compose music and forces them to waive their copyright. It is what is called a buy-out, or a work-for-hire. This practice is unlawful in India and in many countries.
            </p>
            <p>It is as if someone says that he will buy your song for the value of one million streams. This offer is interesting as long as your songs don’t have more than a few thousand streams, but not anymore when they have tens or hundreds of millions of streams.
            </p>
            </div>
          </AccordionDetails>
          </Accordion>
        </form>
      </div>
    
    
    </div>
    </div>
  )
}