import React, { useState, useEffect, useContext, useRef } from "react";
import TrackContext from "../../contexts/track-context";
import TrackItem from "../TrackItem";
import AddTrackToProject from "../Projects/addTrackToProject";
import API from "../../services/api";
import Helper from "../../services/helper";

const Inspiration = (props) => {
  let trackContext = useContext(TrackContext);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [excludedTrack, setExcludedTrack] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const firstMount = useRef(true);
  const params = new URLSearchParams(window.location.search);
  const playlistid = params.get("playlistid");
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [expandedTrackId, setExpandedTrackId] = useState("");

  useEffect(() => {
    if (window.location.pathname === "/inspiration") {
      let tempFilter = JSON.parse(JSON.stringify(trackContext.trackFilter));
      let InspirationTagID = "5d36d75104a4286541ea2d1d";
      tempFilter.searchTags.tags = [InspirationTagID];
      if (tempFilter?.searchTags?.tags_not !== undefined) {
        const tagArrarInspiration = tempFilter?.searchTags?.tags?.filter(
          (eachTags) => eachTags === InspirationTagID
        );

        tempFilter.searchTags.tags = tagArrarInspiration;
        tempFilter.searchTags.tags_not = [];
      }
   
      trackContext.setTrackFilter(tempFilter);
    }
  }, []);

  useEffect(() => {
    if (trackContext.trackFilter.fetchList) {
      if (
        trackContext?.trackFilter?.searchTags?.tags?.length &&
        trackContext?.trackFilter?.searchTags?.tags?.indexOf(
          "5d36d75104a4286541ea2d1d"
        ) !== -1
      ) {
        trackContext.setIsLoading(true);
        setNoDataFound(false);
        API.getPlaylist(trackContext.trackFilter.searchText, playlistid).then(
          (res) => {
            if(res?.success){
              trackContext.setIsLoading(false);
              trackContext.setTrackData(res.data.hits);
              trackContext.setTotaltracks(res.data.total.value);
              let newTracks = [...res.data.hits];
              let tracksToExclude = newTracks.map((track) => track.ref_id);
              setExcludedTrack(tracksToExclude);
              if (res.data.hits.length === 0) setNoDataFound(true);
            }
            else{
              Helper.handleError(res);
            }
          }
        );
      }
    }
  }, [
    trackContext.trackFilter.searchTags,
    trackContext.trackFilter.searchText,
    trackContext.trackFilter.url,
  ]);

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };

  return (
    <>
      {
        //  trackContext.trackData.filter( ( eachTrack ) => eachTrack.tags.some( ( eachTag ) => eachTag.key === 'contemporary-classical') ).map((track,index,arr) =>
        trackContext.trackData.map((track, index, arr) => (
          <>
            {track.duration && (
              <TrackItem
                //   setSearchTags={props.setSearchTags}
                tags={track.tags}
                image={track.album.image}
                //   searchTags={props.searchTags}
                key={track.id}
                title={track.display_title}
                albumId={track.album}
                duration={track.duration}
                previousTrack={index}
                bpm={track?.bpm}
                artist={
                  track.artists_publishing_ownerships[0]?.artist?.full_name
                }
                rights_type={
                  track.artists_publishing_ownerships[0]?.rights_type?.key
                }
                album={track.album.title}
                trackId={track.id}
                waveform={track?.waveform?.small?.url}
                setTrackUrl={props.setTrackUrl}
                setFetchingTrack={props.setFetchingTrack}
                changeCurrentTrackImage={props.changeCurrentTrackImage}
                displayProjectModal={displayProjectModal}
                handleCurrentTrackDetail={props.handleCurrentTrackDetail}
                similarity={track?.similarity}
                description = {track?.descriptions[0]?.value}
                expandedTrackId = {expandedTrackId}
                setExpandedTrackId = {setExpandedTrackId}
                artists={
                  track.display_artists
                }
              />
            )}
          </>
        ))
      }
      {showProjectModal && (
        <AddTrackToProject
          trackToBeAddedToProject={trackToBeAddedToProject}
          closeProjectModal={closeProjectModal}
        />
      )}
    </>
  );
};

export default Inspiration;
