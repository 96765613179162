import React, { useState, useEffect} from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Validator from '../../services/validator';
//import {ReactComponent as Success } from '../../assets/success.svg';
import { ErrorToast, SuccessToast } from '../../pure-components/CustomToast';
import ConfirmationPopup from '../../pure-components/ConfirmationPopup';
import { ReactComponent as Delete } from '../../assets/delete.svg';
import CorporateUserForm from './corporate-user-form';
import { BouncingLoader } from '../../pure-components/Loader';
import { ToggleOff, ToggleOn } from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from '@material-ui/core';

export default function AdminList(props) {

  const [adminList,setAdminList]=useState([]);
  const [isLoading, setLoading] = useState(true);
  let [showAddNewAdminForm, setShowAddNewAdminForm] = useState(false);
  const [showConfirmPopup,setShowConfirmPopup]=useState(false)
  const [selectedAdmin,setSelectedAdmin]=useState({});

  useEffect(() => {
    if(props?.adminList?.length){
      setAdminList(props.adminList);
      setLoading(false);
    }
    else
      fetchAdmin();
  }, []);

  const fetchAdmin =()=>{
    setLoading(true);
    API.getAdminList().then(res => {
      if (res.success) {

        setAdminList(res.data)
        if(adminList?.length)
          props.setSelectedAdmin(res.data[0]?.email);
      }
      else
        toast(({ closeToast }) => <ErrorToast  message={res.msg}/>);

      setLoading(false);
    })
  }

  const suspendAdmin =()=>{
    API.suspendAdmin(selectedAdmin.id, {isSuspended : selectedAdmin.isSuspended}).then(res => {
      if (res.success) {
        toast(({ closeToast }) => <SuccessToast  message={res.msg}/>);
        fetchAdmin();
      }
      else
        toast(({ closeToast }) => <ErrorToast  message={res.msg}/>);
      setShowConfirmPopup(false);
    })
  }

  const setConfirmPopup=()=>{
    setShowConfirmPopup(false)
  }
  return (
    <>
            <div className='flex'>
              <div className='text-lg w-2/3'>Admins</div>
              <div 
                className='p-1 align-center text-center bg-black text-white text-xs w-1/3 cursor-pointer'
                onClick={() => setShowAddNewAdminForm(true)}>
                Add New
              </div>
            </div>
            <div className='overflow-y-auto' style={{ height: "50vh" }}>
            {isLoading ?
              <div className='w-full'>
                <BouncingLoader />
              </div>:
            <>  
              {adminList?.length === 0 && <div className="text-xl text-center text-red-500">No User Found</div>}
              {
                adminList?.map((admin, index) =>
                <div key={admin._id} className='flex w-full border-b border-symStrokeGrey last:border-b-0 p-2' style={{background : props.selectedAdmin===admin.email ? 'lightgray' : ''}} >
                  <div className='w-full self-center p-4 pl-0 cursor-pointer' onClick={() => props.setSelectedAdmin(admin.email)}>
                    <h1 className={`text-sm mr-2 ${admin.isSuspended ? "text-red-500" : "text-black"}`} > {admin.first_name}</h1>
                    <h2 className='text-xs self-center text-textGray-100 '>{admin.email} </h2>
                    <h2 className='text-xs self-center text-textGray-100 '>{admin.companyName} </h2>
                    <h2 className='text-xs self-center text-textGray-100 '>No of Users : <b>{admin.totalLicenseCount}</b> </h2>
                  
                    <div className="hidden sm:block text-xs self-center text-textGray-100">
                    {dayjs(admin.createdAt).format("D MMM, YYYY")} - {dayjs(admin.renewalDate).format("D MMM, YYYY")}
                    </div>
                  </div>
                  <div height="18" width="18" className={`cursor-pointer mt-4`}
                    onClick={(e) => {
                      // removeAdmin(admin._id)
                      setSelectedAdmin({id : admin._id, isSuspended : !admin.isSuspended});
                      setShowConfirmPopup(true)
                      }}
                  >{admin.isSuspended ? 
                    (<>
                      <Tooltip title="Suspended, click to Activate">
                        <IconButton>
                          <ToggleOff />
                        </IconButton>
                      </Tooltip>
                    </>) : (<>
                      <Tooltip title="Active, click to Suspend">
                        <IconButton>
                          <ToggleOn />
                        </IconButton>
                      </Tooltip>
                    </>)}
                  </div>
                </div>)
              }
            </>}  
            </div>
             
      {
        showAddNewAdminForm &&
        <CorporateUserForm setShowAddNewUserForm = {setShowAddNewAdminForm} fetchUserList = {fetchAdmin} />
      }
      {
        showConfirmPopup ? 
        <ConfirmationPopup giveStyle="-5rem" yesAction={suspendAdmin}
        cancelAction={setConfirmPopup}
        title={`${selectedAdmin?.isSuspended ? "Suspend" : "Activate"} Admin`} msg={`Are you sure, you want to ${selectedAdmin?.isSuspended ? "Suspend" : "Activate"} this admin?`}/>
        : 
        null 
      }

    </>
  )
}