import React, { useEffect, useState, useContext } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { RippleLoader } from '../../pure-components/Loader';
import TrackAction from '../TrackItem/trackAction';
import AddTrackToProjectPopup from '../Projects/addTrackToProjectPopup';
import TrackContext from '../../contexts/track-context';
import Amplitude from 'amplitudejs';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Pause } from '../../assets/pause.svg';
import CardContent from '@material-ui/core/CardContent';
import dayjs from 'dayjs';
import Pagination from '@material-ui/lab/Pagination';
import Constants from '../../services/constants';
import Helper from '../../services/helper'

export default function PlayHistory(props) {
  let trackContext = useContext(TrackContext);
  const [history, setHistory] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);

  const [currentTrack, setCurrentTrack] = useState('')
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [trackToBeRemoveToProject, setTrackToBeRemoveToProject] = useState('');
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [favs, setFavs] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_PAGE_SIZE);
  const [searchKey, setSearchKey] = useState('')
  const [download, setDownload] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('favs') !== 'undefined') {
      const favorites = JSON.parse(localStorage.getItem('favs'));
      // const favArray = favorites.map( track => {
      //   return track.trackId.mewoTrackId;
      // });
      favorites !== null && setFavs(favorites);
    };
    if (localStorage.getItem('download') !== 'undefined') {
      const download = JSON.parse(localStorage.getItem('download'));
      download !== null && setDownload(download);
    };
  }, []);

  useEffect(() => {
    API.getHistoryTracks(1, pageSize, searchKey).then(res => {
      // console.log({ res });
      setSearchHistory(res.history)
      setHistory(res.history);
      setTotalPages(Math.ceil(res.totalCount / pageSize));
      setTrackData(res.history);
    })
  }, []);

  useEffect(() => {
    API.getHistoryTracks(currPage, pageSize, searchKey).then(res => {
      // console.log({ res });
      setSearchHistory(res.history);
      setHistory(res.history);
      // setTotalPages(Math.ceil(res.totalCount/pageSize));
      setTrackData(res.history);
    })
  }, [currPage]);

  const handlePagination = (e, pagenumber) => {
    //console.log(e.target);
    //let temp = e.target.innerText ? e.target.innerText :currPage+1  ;
    setCurrPage(pagenumber);
  }

  const handleAddTrackToFavorites = (trackId) => {
    API.addTrackToFavorites(trackId).then(res => {
      if(res?.success){
        localStorage.setItem('favs', JSON.stringify(res.favorites));
        setFavs(res.favorites);
      }else{
        Helper.handleError(res);
      }
    })
  }
  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  }
  const fetchTrack = async (trackId, historyId,index) => {
    if (trackId + index === currentTrack) {
      trackContext.setPlayPause(!trackContext.playPause);
      return;
    }
    if (Amplitude.getSongs().length) {
        Amplitude.stop()
        Amplitude.removeSong(0)
    }
    trackContext.setLoadingTrack(true);
    await API.playTrack(trackId).then(res => {
      trackContext.setLoadingTrack(false);
      if(res?.data?.success){
        trackContext.setalltrackData(trackContext.trackData)
        setCurrentTrack(trackId + historyId);
        res.data.data['historyId']=historyId
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail({...res.data.data,...res.data.tags,
          artist: res.data.data?.artists_publishing_ownerships[0]?.artist?.full_name,
          artist_type: res.data?.data?.artists_publishing_ownerships[0]?.rights_type?.key,})
        trackContext.setCurrentTrackImage(res.data.data?.album?.image?.xsmall?.url)

        //trackContext.setPlayPause(true);

        // if (Amplitude.getSongs().length) {
        //   Amplitude.stop()
        //   Amplitude.removeSong(0)
        //   let artistName = ''
        //   if (typeof res.data.data.display_artists !== 'undefined') {
        //     artistName = res.data.data.display_artists[0].artist.full_name
        //   }
        //   let volume = Amplitude.getVolume() > 0.5 ? Amplitude.getVolume() : 20;

        //   Amplitude.init({
        //     continue_next: false,
        //     volume: volume,
        //     songs: [
        //       {
        //         name: res.data.data.display_title,
        //         artist: artistName,
        //         album: '',
        //         url: res.data.data.audiofile,
        //         cover_art_url: res.data.data.album.image.xsmall.url
        //       }
        //     ],
        //     waveforms: {
        //       sample_rate: 70
        //     }
        //   })
        //   Amplitude.play();

        // }
      }else{
        Helper.handleError(res?.data);
      } 
    });
  }
  const handleSearch = Helper.debounce((searchText) => {
    setCurrPage(1)
    setSearchKey(searchText)
    API.getHistoryTracks(1, pageSize, searchText).then(res => {
      if (res.success) {
        setHistory(res.history);
        setSearchHistory(res.history);
        setTotalPages(Math.ceil(res.totalCount / pageSize));
      }
    });
  }, 500)

  //rerendering track ui on song prev or next
  useEffect(() => {
    const {currentTrackDetail}=trackContext
    let index=trackContext?.alltrackData?.findIndex(track=>track?.trackId?.mewoTrackId === currentTrackDetail?.id && track._id === currentTrackDetail?.historyId )
    setCurrentTrack(trackContext?.alltrackData[index]?.trackId?.mewoTrackId + currentTrackDetail?.historyId);  
  }, [trackContext.currentTrackDetail,currPage,trackContext.alltrackData]);
  
  //adding trackdata to trackContext.trackData to play previous and next track
  const setTrackData=(data)=>{
    const trackIds=data?.map((track)=>{
     return {
        "id":track?.trackId?.mewoTrackId,
        "_id":track?._id,
        ...track
      }
    })
    trackContext.setTrackData(trackIds);
    currentTrack!=='' && trackContext.setalltrackData(trackIds)
  }

  return (
    <div className='flex h-full justify-center lg:w-3/4' style={{ minHeight: '500px' }}>
      <div className=' px-4 md:px-0 sm:mt-6 w-full sm:w-full overflow-auto hide-scroll'>
        <div className="text-sm lg:flex-grow flex">
          <h1 className='hidden sm:block w-1/3 pl-6 text-lg font-bold text-black' > History</h1>
          {/* <input
            onChange={handleSearch}
            className='w-1/3 p-2 rounded bg-gray-800 outline-none text-gray-400'  
            type="text" 
            placeholder='search favorites'
          /> */}
          <div className="w-2/3 justify-end flex">
            <input
              onChange={(e) => { handleSearch(e.target.value) }}
              //  onChange={handleSearch}
              type="text"
              placeholder='Search History'
              className="w-full sm:w-2/5 input-search h-8"
            />
          </div>
        </div>
        <div className='mt-4'>
          {
            searchHistory.length ?
              <>
                {
                  searchHistory.map((track, index) =>

                    <div className='flex w-full border-b border-symStrokeGrey'>
                      <div className='self-center play-button'>
                        <div
                          aria-label="play/pause"
                          className='sm:pl-6 outline-none focus:outline-none play-button border-symStrokeGrey cursor-pointer'
                          onClick={() => fetchTrack(track.trackId.mewoTrackId, track._id,index)}
                        >
                          {
                            (trackContext.playPause && currentTrack === track.trackId.mewoTrackId + track._id) ?
                               <Pause className='outline-none' />
                              :
                              <Play className='outline-none' />
                          }
                        </div>
                      </div>
                      <CardContent className='w-4/6 sm:w-2/6 self-center'>
                        <h1 className='text-base mr-2 text-black track-title ' > {track.trackId.title?.toLowerCase()}</h1>
                        <h2 className='text-xs self-center text-textGray-100 track-artist'>{track.trackId.album?.title?.toLowerCase()} </h2>
                      </CardContent>
                      <h2 className='hidden sm:block w-2/6 text-xs self-center text-black '> Last played on {dayjs(track.updatedAt).format('DD.MM.YYYY')} </h2>
                      <div className="hidden sm:block text-right text-sm w-1/6 self-center justify-end text-textGray-100">
                        {track.trackId.duration && new Date(track.trackId.duration * 1000).toISOString().substr(14, 5)}
                      </div>
                      <div className="w-2/6 sm:w-1/6 flex self-center justify-end items-baseline">

                        <TrackAction setShowProjectModal={setShowProjectModal}
                          setTrackToBeAddedToProject={setTrackToBeAddedToProject}
                          setTrackToBeRemoveToProject={setTrackToBeRemoveToProject}
                          trackId={track.trackId.mewoTrackId}
                          projectTrackId={track.trackId._id}
                          handleAddTrackToFavorites={handleAddTrackToFavorites}
                          favs={favs}
                          download={download}
                          setDownload={setDownload}
                        />
                      </div>
                    </div>
                  )
                }
                <div className='mt-8'>
                  <Pagination count={totalPages} onChange={handlePagination} variant="outlined" shape="rounded" />
                </div>
              </>
              :
              <div className='text-red-500 p-6'>
                No Tracks Found
              </div>
          }
        </div>
      </div>
      {
        showProjectModal &&

        <AddTrackToProjectPopup trackToBeAddedToProject={trackToBeAddedToProject} closeProjectModal={closeProjectModal} />
      }
    </div>
  )
}
