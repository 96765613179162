import React from "react";
import { useEffect, useState } from "react";
import "./style.css";
import TrackContext from "../../contexts/track-context";
import { useContext } from "react";
import Playlist from "./Playlist";
import { BouncingLoader } from "../../pure-components/Loader";
import QueueMusicIcon from '@material-ui/icons/QueueMusic'

const NewInspiration = (props) => {

  const trackContext=useContext(TrackContext)

  return (
    <div className="lg:px-8 items-center flex-1 pt-20 lg:pt-3 sm:mx-8 mx-8 lg:mx-20 playlist">
      <div className="text-sm  font-medium dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px border-b pb-3 ">
          <li className="mr-2 text-left  w-60 rounded-lg  ">
            <QueueMusicIcon className='playlist-icon' />
            <span
              className="inline-block p-3 font-semibold text-xl ml-2 "
            >
              Playlists
            </span>
          </li>
        </ul>
      </div>
      <div className=" d-help mb-20 ">
        <div className="insp-head  font-bold inspirationbox">
          <h2 className=" lg:text-2xl md:text-xl sm:text-xl " >LET MAMA BE YOUR INSPIRATION</h2>
        </div>
        <div className="inspirationbox">
          <p className="inspiration-main-content top-content-design">
            These carefully curated thematic playlists are designed to give you a feel of our massive and highly diverse catalog.
             They can be a source of inspiration for your creative ideas. Whenever you hear a track that you like,
              click on the `magic wand` and Mama's
              <strong> Al-powered </strong>
              search engine, Maya, will suggest similar music.
          </p>
        </div>
      </div>
      <div className="w-full">
      {trackContext.clearData ? '' : (!props.maiaOnWork && trackContext.loading) && <BouncingLoader />}
          <Playlist maiaOnWork={props.maiaOnWork} />
      </div>
    </div>
  );
};

export default NewInspiration;