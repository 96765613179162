import React from "react";
import "./style.css"
import { LazyLoadImage } from "react-lazy-load-image-component";
import step1Img from "../../assets/landingPage/step_1.png";
import step2Img from "../../assets/landingPage/step_2.png";
import step3Img from "../../assets/landingPage/step_3.png";

export default function GIFContainer (props) {
   

    const getStepImage = (step) => {
        switch(step){
            case 0 : 
                return(<LazyLoadImage
                    src={step1Img}
                    className="rounded-2xl h-24"
                    />)
            case 1 : 
                return(<LazyLoadImage
                    src={step2Img}
                    className="rounded-2xl h-24"
                    />)
            case 2 : 
                return(<LazyLoadImage
                    src={step3Img}
                    className="rounded-2xl h-24"
                    />)
        }
    }

    return(
    <div className="w-1/3 flex">
        <div className="border gif-box rounded-2xl mx-2" style={{height : "auto"}}>
            <div className="w-full flex justify-center" style={{height : "auto", marginTop: "-44px"}}>
                    {getStepImage(props.idx)}
            </div>
            {props.desc && 
            <div className="w-full flex justify-center">
                <span className="desc-text p-6" dangerouslySetInnerHTML={{__html : props.desc}} />
            </div>}
        </div>
    </div>);
}