import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../pure-components/CustomToast';
import API from '../../services/api';
import Validator from '../../services/validator';

import { BouncingLoader } from '../../pure-components/Loader';

function Signin(props) {

  const userDetails = { 'email': '', 'password': ''};
  const [userData, setUserData] = useState({...userDetails});
  const [helperText, setHelperText] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [signingIn, setSigningIn] = useState(false);

  // console.log('window : ', window.screen.width);

  const validator = new Validator();
  const handleInputChange = (e, key) => {
    let value = key === 'tos' ? e : e.target.value;
    if (key === 'email') {
      let res = validator.setValue(value, 'Email ').isNotEmpty().isEmail();
      let temp = {...helperText};
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'password') {
      let res = validator.setValue(value, 'Password ').isNotEmpty().isString();
      let temp = {...helperText};
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if(helperText['email']?.length > 0 || helperText['password']?.length > 0)
      setIsDisabled(true);
    else
      setIsDisabled(false)

    let tempUserData = {...userData};
    tempUserData[key] = value;
    setUserData(tempUserData);
  };

  const handleSignIn = async(e) => {
    e.preventDefault();
    let counter = 0;
    let temp = {...helperText};
    let resEmail = new Validator(userData.email, 'Email ').isNotEmpty().isString();
    temp['email'] = resEmail.mssg;
    resEmail.status === false && counter++;
    let resValidator = new Validator(userData.password, 'Password ').isNotEmpty().isString();
    temp['password'] = resValidator.mssg;
    resValidator.status === false && counter++;
    setHelperText(temp);
    if(counter > 0){
      setIsDisabled(true);
      return;
    }
    // console.log({helperText});
    let payload = { email: userData.email, password: userData.password };
    if (counter === 0) {
      setIsDisabled(true);
      setSigningIn(true);
    
      await API.signIn(payload).then(res => {
        setIsDisabled(false);
        // console.log('[USER LOGGED IN]',JSON.stringify(res));
        if (!res?.success) {
          toast(({ closeToast }) => <ErrorToast message={res.mssg}/>);
          setSigningIn(false)
        }
      })
      .catch((err) => {
        console.log("Sign In Error : ", err);    
        setSigningIn(false);
        setIsDisabled(false);
      });
    }
  }

  return(
    <>
    {signingIn ?
    <div className='w-full'>
      <div className='w-full pt-20'>
        <BouncingLoader />
        <span className='w-full flex justify-center text-green-500'>Signing In, Please Wait...</span>
      </div>
    </div>
    :
    <div className='md:flex'>
      <div className='flex md:w-1/2 mx-auto pt-16 p-4 md:px-16 md:pt-32 h-full' style={{background: '#FFF'}}>
        <div className='mt-24 w-full  lg:w-2/3'>
          <div className='p-2'>
            <h1 className='text-3xl campaign font-bold' style={{lineHeight: '40px'}}>Sign in to</h1>
            <h1 className='text-3xl campaign font-bold' style={{lineHeight: '40px'}}>SyncMama</h1>
          </div>
          <form onSubmit={handleSignIn}>
          <div className='w-full p-2 mt-4'>
            <TextField
              autoFocus={window.screen.width > 767 ? true : false}
              error={helperText.email !== undefined && helperText.email !== '' ? true : false}
              size='small'
              className='w-full'
              label="Email"
              variant="outlined"
              value={userData.email}
              onChange={(e) => handleInputChange(e, 'email')}
              onBlur={(e) => handleInputChange(e, 'email')}
              helperText={helperText.email}
            />
          </div>
          <div className='w-full p-2 mt-2'>
            <TextField
              error={helperText.password !== undefined && helperText.password !== '' ? true : false}
              type='password'
              size='small'
              className='w-full'
              label="Password"
              variant="outlined"
              value={userData.password}
              onChange={(e) => handleInputChange(e, 'password')}
              onBlur={(e) => handleInputChange(e, 'password')}
              helperText={helperText.password}
            />
          </div>
          <div className='flex w-full items-center'>
            
            <div className="w-2/3 flex p-2 pt-8 justify-end" >
            <button className={(isDisabled ? "button" : "greenbutton") + " w-1/3 flex justify-center" }
            disabled={isDisabled} >
              LOG IN
            </button>
            </div>
            <div className='w-1/3 flex text-gray-500 text-xs p-2 pt-8'>
              <Link to='/forgot-password'>
                <span className='cursor-pointer px-1'>Forgot Password?</span>
              </Link>
            </div>
          </div>
          
          </form>
          <div className='flex w-full items-center'>
          <div className='flex justify-start text-gray-500 text-xs w-1/2 p-2 pt-8'>
              Don't have an account? 
          </div>
          <div className='flex justify-end w-1/2 p-2 pt-8'>
              <div className='pinkbutton'>
                <a className='text-black' href={process.env.REACT_APP_MKT_SITE_PRICING}>
                  SIGN UP
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hidden md:flex md:w-1/2' style={{color: '#FFF'}}>
          <div className='timessquare'>
          </div>
      </div> 
    </div>}
    </>
  )
}

export default Signin;