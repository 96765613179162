import React, { useEffect } from 'react';

import { useState } from "react";
import Slider from '@mui/material/Slider';
import Constants from '../../../services/constants';
import CookieManager from '../../../services/CookieManager';


export default function SegmentSelector(props) {

  const getMax = () => {
    return props.trackData.duration ? props.trackData.duration : 0;
  }
  const getStart = () => {
    let start = 0;
    const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
    if(tempFilter?.type === Constants.SEARCH_TYPE.track){
      // start = tempFilter.segment?.start;
      start = ((tempFilter?.segment?.start * 100)/props.trackData.duration);
    }
    return start;
  }

  const getEnd = () => {
    let end = getMax();
    const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
    if(tempFilter?.type === Constants.SEARCH_TYPE.track){
      // end = tempFilter.segment?.end;
      end = ((tempFilter?.segment?.end * 100)/props.trackData.duration);
    }
    return end;
  }

  const calculateRange = (value) => {
    return (((value * props.trackData.duration)/100))
  }

  const getValue = () => {
    const tempValue = [0, 100];
    const start = getStart();
    const end = getEnd();
    if(start)
      tempValue[0] = start;
    if(end)
      tempValue[1] = end;
    return tempValue;
  }
  
  const [value, setValue] = useState(getValue());
  const [start, setStart] = useState(getStart());
  const [end, setEnd] = useState(getEnd());
  
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  const handleChange = (event, newValue) => {
    setStart(newValue[0]);
    setEnd(newValue[1]);
    setValue(newValue);
    setFilterData();
  };

  
  const loadSVG = async()=>{
    await fetch(props.trackData?.waveform?.large?.url)
            .then(res => res.text())
            .then((res) => {
                if(!res?.includes("<Error>"))
                    setSvg(res)
            })
            .catch((err) => {
                setIsErrored(true);
            })
            .then(() => setIsLoaded(true))
  }

  useEffect(() => {
    (async() => {
      await loadSVG();
      setValue(getValue());
      setStart(getStart());
      setEnd(getEnd());
    })()
  },[props.trackData])


  const setFilterData = () =>{
    const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
    if(tempFilter?.type === Constants.SEARCH_TYPE.track){
      tempFilter.segment = {
        "start" : calculateRange(start),
        "end" : calculateRange(end)
      }
      CookieManager.setCookie("harmix-music-filter", JSON.stringify(tempFilter));
    }
  }

  // const setStartEnd = () =>{
  //   const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
  //   if(tempFilter?.type === Constants.SEARCH_TYPE.track){
  //     setStart(tempFilter.segment?.start || 0);
  //     setEnd(tempFilter.segment?.end || getMax());
      
  //   }
  // }
  return (
    <div>
        {isErrored ? <></> : 

        <>
        <div 
            className={`w-full flex svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${isErrored ? 'svgInline--errored' : ''}`}
            dangerouslySetInnerHTML={{ __html: svg }}
            style={{"margin-bottom": '-66px'}}
        />
          <Slider
            sx={(t) => ({
              color: 'rgba(0,0,0,0.87)',
              '& .MuiSlider-track': {
                color:"#e30185",
                opacity: "30%",
              },
              '& .MuiSlider-rail': {
                color:"#e30185",
                opacity: "8%",
              },
              '& .MuiSlider-thumb' : {
                color:"#e30185",
                opacity: "80%",
              }
            })}
            style={{height : "100px",
                "margin-top": '-55px'
            }}
            getAriaLabel={() => 'Select range'}
            value={value}
            onChange={handleChange}
            // valueLabelDisplay="auto"
            // getAriaValueText={valuetext}
          />
          </>}
    </div>
  );
}