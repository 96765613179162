import React, { useContext, useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import Constants from '../../services/constants';
import SignUpContext from '../../contexts/signup-context';
import Validator from '../../services/validator';
import API from '../../services/api';
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";
import { toast } from 'react-toastify';
import CookieManager from '../../services/CookieManager';
// import API from '../../services/api';
import { Checkbox } from "@material-ui/core";


const FreeSignUp = (props) => {
   const signUpContext = useContext(SignUpContext);
   const [disableButton, setDisableButton] = useState(false);

   useEffect(() => {
      let isDisabled = fieldCheck();
      setDisableButton(isDisabled);
   }, [signUpContext]);


   function fieldCheck() {
      if (
         signUpContext.helperText.first_name === "" &&
         signUpContext.helperText.email === "" &&
         signUpContext.helperText.confirmEmail === "" &&
         signUpContext.helperText.password === "" &&
         signUpContext.helperText.confirmPassword === "" &&
         signUpContext.helperText.check === ""

      )
         return false;
      else
         return true;
   }
   const handleSubmit = event => {
      event.preventDefault();
      //signUpContext.handleSubmit({});

      let counter = 0;
      let temp = signUpContext.helperText;
      // console.log('[start] : ', counter);

      let resFirstName = new Validator(signUpContext.userData.first_name, 'Full Name').isNotEmpty().isString();
      temp['first_name'] = resFirstName.mssg;
      resFirstName.status === false && counter++;

      let resEmail = new Validator(signUpContext.userData.email, 'Email').isNotEmpty().isString().isEmail();
      temp['email'] = resEmail.mssg;
      resEmail.status === false && counter++;

      let resPassword = new Validator(signUpContext.userData.password, 'Password').isNotEmpty().checkPasswordStrength();
      temp['password'] = resPassword.mssg;
      resPassword.status === false && counter++;

      if (signUpContext.userData.password !== signUpContext.userData.confirmPassword) {
         temp['confirmPassword'] = "Passwords don't match";
         counter++;
      }

      if (signUpContext.userData.password !== signUpContext.userData.confirmPassword) {
         temp['confirmEmail'] = "Email addresses don't match";
         counter++
      }

      let resChecked = new Validator(signUpContext.userData.check, 'check ').isNotEmptycheck()
      temp['check'] = resChecked.mssg; 
      resChecked.status === false && counter++;

      signUpContext.setHelperText(temp);
      signUpContext.setShowOverlayLoader(true);
      API.createStripeSubscription({ ...signUpContext.userData }).then(res => {
         if (res.success === false) {
            signUpContext.setShowOverlayLoader(false);
            if (res.status === 400) {
               toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
               CookieManager.setCookie('target-location', process.env.REACT_APP_MKT_SITE_PRICING);
               setTimeout(() => {
                  window.location = "/signin";
               }, 3000)
            } else {
               toast(({ closeToast }) => <ErrorToast message={res.mssg} />);
            }
            //window.location.reload()
         } else {
            toast(({ closeToast }) => <SuccessToast message="User created successfully :)" />);
            signUpContext.setUserData(signUpContext.userDetails);
            signUpContext.setShowOverlayLoader(false);
            setTimeout(() => {
               //window.location.reload();
               window.location = process.env.REACT_APP_CONFIRMATION;
            }, 3000)
         }
      });

   }

   return (
      <div className='md:flex' style={{ background: '#F2F5F6' }}>
         <div className='md:w-1/2 mx-auto pt-16 p-4 md:p-10 md:pt-32 md:pl-16' >
            <div className="flex flex-wrap p-2">
               {
                  (signUpContext.userType === (JSON.parse(process.env.REACT_APP_USER_TYPE)).FREE)
                  &&
                  <>
                     <h1 className='text-3xl font-bold w-1/2 md:p-4' style={{ lineHeight: '40px' }}> Free Membership </h1>
                     <div className='w-full p-2'>
                        <TextField
                           required
                           name='signup-full-name'
                           autoFocus={true}
                           error={signUpContext.helperText.first_name !== undefined && signUpContext.helperText.first_name !== '' ? true : false}
                           size='small'
                           className='w-full lg:w-2/3 signup-full-name'
                           label="Full Name"
                           variant="outlined"
                           value={signUpContext.first_name}
                           onChange={(e) => signUpContext.handleInputChange(e, 'first_name')}
                           helperText={signUpContext.helperText.first_name}
                        />
                     </div>
                     <div className='w-full p-2'>
                        <TextField
                           required
                           name='signup-email'
                           error={signUpContext.helperText.email !== undefined && signUpContext.helperText.email !== '' ? true : false}
                           size='small'
                           className='w-full lg:w-2/3 signup-email'
                           label="Email"
                           variant="outlined"
                           value={signUpContext.email}
                           onChange={(e) => signUpContext.handleInputChange(e, 'email')}
                           helperText={signUpContext.helperText.email}
                        />
                     </div>
                     <div className='w-full p-2'>
                        <TextField
                           required
                           name='confirm-email'
                           error={signUpContext.helperText.confirmEmail !== undefined && signUpContext.helperText.confirmEmail !== '' ? true : false}
                           size='small'
                           className='w-full lg:w-2/3 confirm-email'
                           label="Confirm Email"
                           variant="outlined"
                           value={signUpContext.confirmEmail}
                           onChange={(e) => signUpContext.handleInputChange(e, 'confirmEmail')}
                           helperText={signUpContext.helperText.confirmEmail}
                        />
                     </div>
                     <div className='w-full p-2'>
                        <TextField
                           required
                           name='signup-password'
                           error={signUpContext.helperText.password !== undefined && signUpContext.helperText.password !== '' ? true : false}
                           type='password'
                           size='small'
                           className='w-full lg:w-2/3 signup-password'
                           label="Password"
                           variant="outlined"
                           value={signUpContext.password}
                           onChange={(e) => signUpContext.handleInputChange(e, 'password')}
                           helperText={signUpContext.helperText.password}
                        />
                     </div>
                     <div className='w-full p-2'>
                        <TextField
                           required
                           name='signup-confirm-password'
                           error={signUpContext.helperText.confirmPassword !== undefined && signUpContext.helperText.confirmPassword !== '' ? true : false}
                           type='password'
                           size='small'
                           className='w-full lg:w-2/3 signup-confirm-password'
                           label="Confirm Password"
                           variant="outlined"
                           value={signUpContext.confirmPassword}
                           onChange={(e) => signUpContext.handleInputChange(e, 'confirmPassword')}
                           helperText={signUpContext.helperText.confirmPassword}
                        />
                     </div>


                     <div className='p-2 w-full'>
                        <TextField
                           
                           name='signup-company-name'
                           error={signUpContext.helperText.companyName !== undefined && signUpContext.helperText.companyName !== '' ? true : false}
                           size='small'
                           className='w-full lg:w-2/3 signup-company-name'
                           label="Company Name"
                           variant="outlined"
                           value={signUpContext.companyName}
                           onChange={(e) => signUpContext.handleInputChange(e, 'companyName')}
                           helperText={signUpContext.helperText.companyName}
                        />
                     </div>
               <div>
               <div className='flex'>
                   <div>
                    <Checkbox
                     style={{color: "#e30185"}}
                     error={
                            signUpContext.helperText.check? true: false
                        }
                        onChange={(e) =>
                        signUpContext.handleInputChange(e, "check")
                      }
                      helperText={signUpContext.helperText.check}
                      inputProps={{ 'aria-label': 'controlled' }}
                   />
                   </div>
                   <div className='lg:p-2'> 
                     <span className="text-xs p-4 pl-1" style={{ color: "#797979" }}>
                        I have read and agreed to the&nbsp;
                        <a
                           target="_blank"
                           rel="noopener noreferrer"
                           className="underline text-blue-500"
                           href={`${process.env.REACT_APP_MKT_SITE}/terms`}   
                        >
                        Terms of Service </a> of SyncMama
                     </span>
                  </div>
               </div>
                  <p className='text-xs font-light pl-4' style={{color:"#f44336"}}>{signUpContext.helperText.check}</p>
                     <div className='flex justify-center p-4 pt-8'>
                        <button
                           onClick={handleSubmit}
                           className={"pt-2 pb-2 pl-4 pr-4 font-bold text-sm " + (disableButton ? "" : "signin-btn")}
                           type="button"
                           disabled={disableButton}
                           >
                           SUBSCRIBE
                        </button>
                     </div>                       
               </div>
                  </>
               }
            </div>
         </div>
      </div>
   );
};


export default FreeSignUp;