import React, { useEffect } from "react";
import defaultImage from "../../assets/default_music.png";
import "./style.css"
import rightTickIcon from "../../assets/correct-checkbox.png";
import xIcon from "../../assets/x.png"
import { LazyLoadImage } from "react-lazy-load-image-component";
import startForFreeImg from "../../assets/landingPage/start_for_free.png";
import getStartedImg from "../../assets/landingPage/get_started.png";
import requestQuoteImg from "../../assets/landingPage/request_a_quote.png";




export default function PlanContainer (props) {

    useEffect(() => {
        // console.log("Plan data", props.plan)
    })

    const getCost = (cost) => {
        //reducing cost by 18%, as price included GST amount also
        if(cost > 0){
            return parseInt(cost / 1.18);
        }
        return 0;
    }
    return(
    <div className="w-1/4 flex">
    <div class="w-full mx-2 plan-box rounded-2xl " style={{height : "auto", display: "grid"}}>
        <div className="w-full">
        <div className="w-full self-center py-2" style={{height : "auto", alignSelf: "flex-start"}}>
            <span className="w-full flex justify-center title-text">
                <b>{props.plan.planName}</b>
            </span>
            <div className="w-full py-2">
            {props.plan.planDescriptions?.length > 0 ? 
            props.plan.planDescriptions?.map(desc => 
            (<span className="w-full flex justify-center offer-text px-2">
                    <b>{desc}</b>
                </span>
            )) : 
            <span className="w-full">
                <span className="w-full text-sm flex">&nbsp;&nbsp;</span>
                <span className="w-full text-sm flex">&nbsp;&nbsp;</span>
            </span>}
            </div>
            {props.plan.id==="CORPORATE" ? 
            <span className="w-full flex justify-center pt-8 pb-6">
                <a className="w-full flex justify-center"
                    href={props.plan.link}
                >
                <LazyLoadImage
                    src={requestQuoteImg}
                    className="rounded-2xl h-14 cursor-pointer"
                />
                </a>
            </span> :
            <span className="w-full flex justify-center">
                <span className="pt-4" style={{fontSize: "28px"}}>₹</span> 
                <span style={{fontSize: "76px"}}><b>{getCost(props.plan.cost)}</b></span>
                <span className="pt-16" style={{fontSize: "20px"}}><b>/MONTH</b></span>
            </span>}
            {/* {props.plan.offer?.length > 0 ?
                <span className="w-full flex justify-center text-sm px-4" style={{color : "#8865e9"}}>
                <b>{props.plan.offer}</b>
            </span> : 
            <span className="w-full text-sm flex">&nbsp;</span>}
            {props.plan.benefit?.length > 0 ?
                <span className="w-full flex justify-center text-sm px-4" style={{color : "#29eb96"}}>
                <b>{props.plan.benefit}</b>
            </span> : 
            <span className="w-full text-sm flex">&nbsp;</span>} */}
            <span className="w-full flex justify-center offer-text">
                <b>&nbsp;{props.plan.offer}&nbsp;</b>
            </span>
            <span className="w-full flex justify-center benefit-text px-4 py-1">
                <b>&nbsp;{props.plan.benefit}&nbsp;</b>
            </span>
        </div>
            
        <div className="w-full pl-4 mt-4" style={{height : "auto", alignSelf: "flex-start"}}>
            {/* {props.plan.planDescriptions?.map(desc => 
            (<span className="w-full flex justify-start desc-text pb-1">
                    {desc}
                </span>
            ))} */}
            {props.plan.acceptedTerms?.map(desc => 
            (<ul className="w-full flex justify-start desc-text pb-1">
                <img
                src={rightTickIcon}
                alt="logo"
                className="h-7 pr-1"
                />
                <li>{desc}</li>
            </ul>
            ))}
            {props.plan.rejectedTerms?.map(desc => 
            (<ul className="w-full flex justify-start desc-text text-gray-400 pb-1">
                <img
                src={xIcon}
                alt="logo"
                className="h-5 pt-1 px-2"
                />
                <li>{desc}</li>
            </ul>)
            )}
        </div>
        </div>
        <div className="w-full flex justify-center py-2" style={{"alignSelf": "flex-end"}}>
            <a className="w-full flex justify-center"
                href={props.plan.link}
            >
            {(props.plan.id==="SOLO" || props.plan.id === "PRO") ? 
            <LazyLoadImage
            src={getStartedImg}
            className="rounded-2xl h-14 cursor-pointer"
            /> : 
            props.plan.id === "FREE" && <LazyLoadImage
            src={startForFreeImg}
            className="rounded-2xl h-14 cursor-pointer"
            />}
            </a>
        </div>
    </div>
    </div>);
}