import React, { useState, useEffect, useContext, useRef } from "react";
import TrackContext from "../../contexts/track-context";
import TrackItem from "../TrackItem";
import API from "../../services/api";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import Helper from "../../services/helper";
import "./style.css";
import { useHistory, useParams } from "react-router-dom";
import Amplitude from "amplitudejs";
import "./style.css";
import { BouncingLoader } from '../../pure-components/Loader';
import MewoService from "../../services/mewoService";
import Constants from "../../services/constants";
import { toast } from "react-toastify";
import { ErrorToast } from "../../pure-components/CustomToast";

const ArtistTrackView = (props) => {
  let ref = useRef(null);
  let trackContext = useContext(TrackContext);
  const history=useHistory()
  const [artistTracks, setArtistTracks] = useState([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const pageId='artist'
  const [isLoading, setIsLoading]=useState(false);
  const [artistData, setArtistData] = useState(null);
  const [allTracksLoaded, setAllTracksLoaded] = useState(false);
  const [excludedArr, setExcludedArr]  = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [initialized, setInitialized] = useState(false);

  

  const {artistId}=useParams();
  useEffect(() => {
    // console.log("1st loading", loadMore, allTracksLoaded)
    getAllArtistTracks();
  }, [artistId]);

  const getAllArtistTracks=async()=>{
    // console.log("loading data")
    if(!isLoading && !allTracksLoaded){
      setIsLoading(true)
      if(artistTracks?.length > 0){
        let excludeArr = artistTracks.reduce((arr, item) => {
          if(!arr.includes(item.id))
            arr.push(item.id);
          return arr;
        }, excludedArr)
        setExcludedArr(excludeArr)
      }
      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": "",
          "options": 
          {
              "filters": {
                exclude : excludedArr,
                artists : [artistId]
              },
              "size": Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE,
              "search_descriptions": false
          }
        },
      };
      await MewoService.loadMewoData(reqObj).then(res => {
        if(res){
          if(res.hits?.length > 0)
          {
            setLoadMore(false)
            setAllTracksLoaded(Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE > res.hits.length);
            setArtistTracks([...artistTracks, ...res.hits]);
            // trackContext.setTrackData([...trackContext.trackData, ...res.hits]);
          }
          //initial loading done
          if(!excludedArr || excludedArr.length === 0)
            setInitialized(true);
        }else{
          Helper.handleError(res);
        }
        setIsLoading(false)
      }).catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={"Artists Tracks Loading Failed : " + err?.response?.data?.message}/>);
        setAllTracksLoaded(true);
        setIsLoading(false);
        // trackContext.setTrackData([]);
        setArtistTracks([]);
      })
    }
  }

  const getTracks=()=>{
    trackContext.setIsLoading(true);
    API.getAllArtistTracks(artistId).then(res => {
      if(res?.success){
        if(res?.data?.length)
        {
          setArtistTracks(res.data);
          trackContext.setTrackData(res.data);
          trackContext.setSongData(res.data)
        }
      }else{
        Helper.handleError(res);
      }
      trackContext.setIsLoading(false);
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if(artistTracks?.length > 0){
      let tempData = artistTracks[0]?.display_artists?.filter((elem) => {
        return elem.artist.id === artistId
      })
      setArtistData(tempData?.length > 0 ? {id : tempData[0].artist.id, name : tempData[0].artist.full_name} : null)
    }
  },[artistTracks])
 

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };

  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreTracks, options);
      let target = document.querySelector("#load-more-tracks");
      observer.observe(target);
    }
  });


  const loadMoreTracks = (entries, observer) => {
    // console.log("loading more",allTracksLoaded, loadMore, isLoading)
    entries.map((entry) => {
      if (entry.isIntersecting && initialized) {
        if (allTracksLoaded) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
      }
    });
  };

  useEffect(() => {
    if(loadMore)
      getAllArtistTracks();
  },[loadMore])
  return (
    <>
      <div className="sm:flex sm:flex-col sm:w-full m-10">
             
        {artistData &&
          <div className="flex w-full">
            <div className=" sm:w-10/12 lg:block">
              <span
                className="text-base "
              >
                Results
              </span>
             <div className="flex mt-3 pb-4 ">
                <div
                  className="flex px-1 py-1 max-h-7 text-xs cursor-pointer bg-black text-white"
                >
                  Artist : {artistData.name}
                </div>
            </div>
            </div>
          </div>
        }

        {isLoading && !initialized && (
          <div className='w-full'>
            <BouncingLoader />
          </div>
        )}

        {(!isLoading && artistTracks?.length == 0) && (
          <div className="text-xl text-center text-red-500">
            No Data Found
          </div>
        )}

        {artistTracks?.length > 0 &&
          artistTracks?.map((track, index, arr) => {
            return (
              track.duration && (
                <TrackItem
                  //   setSearchTags={props.setSearchTags}
                  tags={track.tags}
                  image={track.album.image}
                  //   searchTags={props.searchTags}
                  key={track.id}
                  albumId={track.album}
                  title={track.display_title}
                  duration={track.duration}
                  previousTrack={index}
                  bpm={track?.bpm}
                  versions={track?.versions}
                  producer={track.master_ownerships[0]?.label?.label_name}
                  artist={
                    track.artists_publishing_ownerships[0]?.artist?.full_name
                  }
                  rights_type={
                    track.artists_publishing_ownerships[0]?.rights_type?.key
                  }
                  album={track.album.title}
                  trackId={track.id}
                  waveform={track?.waveform?.small?.url}
                  // setTrackUrl={props.setTrackUrl}
                  setFetchingTrack={trackContext.setFetchingTrack}
                  // changeCurrentTrackImage={props.changeCurrentTrackImage}
                  displayProjectModal={displayProjectModal}
                  // handleCurrentTrackDetail={props.handleCurrentTrackDetail}
                  similarity={track?.similarity}
                  description={track?.descriptions[0]?.value}
                  expandedTrackId={expandedTrackId}
                  setExpandedTrackId={setExpandedTrackId}
                  pageId={pageId}
                  artists={
                    track.display_artists
                  }
                />
              )
            );
          })
        }
        {!allTracksLoaded && (
          <div id="load-more-tracks" ref={ref} className="p-2">
           { loadMore ? <BouncingLoader /> : ""}
          </div>
        )}

        {showProjectModal && (
          <AddTrackToProjectPopup
            trackToBeAddedToProject={trackToBeAddedToProject}
            closeProjectModal={closeProjectModal}
          />
        )}

        
      </div>
    </>
  );
};

export default ArtistTrackView;