import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Amplitude from "amplitudejs";
import FavoriteIcon from "@material-ui/icons/Favorite";
import UnFavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import { ReactComponent as CreateNewFolderIcon } from "../../assets/folder_mediaPlayer.svg";
import { ReactComponent as GetAppIcon } from "../../assets/download_mediaPlayer.svg";
import { ReactComponent as Activedownload } from "../../assets/activedownload.svg";

// import {ReactComponent as Success}  from '../../assets/success.svg';
import { ReactComponent as Points } from "../../assets/points.svg";
import API from "../../services/api";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import { toast } from 'react-toastify';
import TrackContext from "../../contexts/track-context";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import { CardContent } from "@material-ui/core";
import { ReactComponent as Play } from "../../assets/player-play.svg";
import { ReactComponent as Pause } from "../../assets/pause.svg";
//import Rewind from '@material-ui/icons/Replay10';
//import Skip from '@material-ui/icons/Forward10';
import { ReactComponent as Rewind } from "../../assets/Rewind.svg";
import { ReactComponent as Skip } from "../../assets/Skip.svg";
import CookieManager from "../../services/CookieManager";
import TrackDetail from "./track-detail";
import { ReactComponent as ThumbPrint } from "../../assets/auto_fix_high_black_18dp.svg";
import { BouncingLoader } from "../../pure-components/Loader";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";
import RippleLoader from "../../assets/loader-2.gif";
import Helper from "../../services/helper";
import defaultImage from "../../assets/default_music.png";
import Constants from "../../services/constants";
import { Link } from "react-router-dom";
import { SkipNext, SkipPrevious } from "@material-ui/icons";
import MewoService from "../../services/mewoService";
import { useHistory } from "react-router-dom";


export default function MediaPlay(props) {
  let trackContext = useContext(TrackContext);
  // console.log('[context] : ', trackContext.playPause);

  const history = useHistory();
  const [currentTrack, setCurrentTrack] = useState(trackContext.currentTrack);
  const [play, setPlay] = useState(true);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [hdmp3File, setHdmp3File] = useState(false);
  const [originalFile, setOriginalFile] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const [download, setDownload] = useState(false);
  const [showTrackDetail, setTrackDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const filterMenu = trackContext.filterMenu;
  const [filterSubMenu, setFilterSubMenu] = useState([]);
  const [filterSubMenuChild, setFilterSubMenuchild] = useState([]);
  const [subMenu, setSubMenu] = useState("");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (showTrackDetail) {
      let navTag = document.getElementsByTagName("nav");
      navTag[0].style.zIndex = 1;

      let sidebarTag = document.querySelector(".sidebar");
      if (sidebarTag !== null) {
        sidebarTag.style.zIndex = 1;
      }
    } else {
      let navTag = document.getElementsByTagName("nav");
      navTag[0].style.zIndex = 10;

      let sidebarTag = document.querySelector(".sidebar");
      if (sidebarTag !== null) {
        sidebarTag.style.zIndex = 0;
      }
    }
  }, [showTrackDetail, showProjectModal]);

  useEffect(() => {
    // console.log('MEDIA PLAY CALLED',trackContext.previousTrack);
    let artistName = "";
    if (
      typeof trackContext.currentTrackDetail.display_artists !== "undefined"
    ) {
      artistName = trackContext.currentTrackDetail?.artist;
    }
    let volume = Amplitude.getVolume() > 0.5 ? Amplitude.getVolume() : 50;

    if (Amplitude.getSongs().length) {
      // console.log(' IF ')
      Amplitude.stop();
      Amplitude.removeSong(0);
      Amplitude.init({
        continue_next: false,
        volume: volume,
        songs: [
          {
            name: trackContext.currentTrackDetail.display_title,
            artist: artistName,
            album: "",
            url: currentTrack,
            cover_art_url: trackContext.currentTrackImage,
          },
        ],

        callbacks: {
          stop: function () {
            trackContext.setPlayPause(false)
          }
        },
        waveforms: {
          sample_rate: 1000
        }
      });
      Amplitude.bindNewElements();
      trackContext.setPlayPause(true);
      if (play) Amplitude.play();
    } else {
      setPlay(true);
      Amplitude.init({
        continue_next: false,
        volume: volume,
        songs: [
          {
            name: trackContext.currentTrackDetail.display_title,
            artist: artistName,
            album: "",
            url: trackContext.currentTrack,
            cover_art_url: trackContext.currentTrackImage,
          },
        ],

        callbacks: {
          stop: function () {
            trackContext.setPlayPause(false);
          }
        },
        waveforms: {
          sample_rate: 1000,
        },
      });
      Amplitude.pause();
      Amplitude.play();
      trackContext.setPlayPause(true);
    }
  }, [trackContext.currentTrackDetail]);

  useEffect(() => {
    let artistName = "";
    if (
      typeof trackContext.currentTrackDetail.display_artists !== "undefined"
    ) {
      artistName = trackContext.currentTrackDetail?.artist;
    }
    Amplitude.setSongMetaData(0, {
      name: trackContext.currentTrackDetail.display_title,
      artist: artistName,
      cover_art_url: trackContext.currentTrackImage,
      id:trackContext.currentTrackDetail.id,
      album_id:trackContext?.currentTrackDetail?.album?.id
    });
    let favs = JSON.parse(localStorage.getItem("favs"));
    if (favs !== null) {
      if (favs.indexOf(trackContext.currentTrackDetail.id) !== -1) {
        setIsFav(true);
      } else {
        setIsFav(false);
      }
    }
    const downloadlist = JSON.parse(localStorage.getItem("download"));
    if (downloadlist !== null) {
      if (downloadlist.indexOf(trackContext.currentTrackDetail.id) !== -1) {
        setDownload(true);
      } else {
        setDownload(false);
      }
    }
    // console.log(download)
  }, [trackContext.currentTrackDetail, trackContext.currentTrackImage]);

  // console.log("Amplitude", Amplitude)
  useEffect(() => {
    trackContext.playPause ? Amplitude.play() : Amplitude.pause();
    //  trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction('Play');
  }, [trackContext.playPause]);

  useEffect(() => {
    if(Amplitude.getPlayerState() === 'stopped'){
      let index=trackContext.songData.findIndex(track=>track.id === Amplitude.getSongs()[0]?.id)
      if(index !== -1 && index <= trackContext.songData.length - 1){
        if(index === trackContext.songData.length - 1){
          return
        }
        Amplitude.stop();
        Amplitude.removeSong(0);
        playNextTrack(trackContext.songData[index + 1]?.id);
      }
    }
}, [trackContext.playPause])

//fetch and autoplay next track
const playNextTrack=async(trackId)=>{
  trackContext.setFetchingTrack(true);
  await API.playTrack(trackId).then(res => {
    if(res?.data?.success){
      if (Amplitude.getSongs().length) {
        Amplitude.stop();
        Amplitude.removeSong(0);
      }
      trackContext.setCurrentTrack(res.data.data.audiofile);
      trackContext.setCurrentTrackDetail({...res.data.data,...res.data.data.tags,
        artist: res.data.data.artists_publishing_ownerships[0]?.artist?.full_name,
        artist_type: res.data.data.artists_publishing_ownerships[0]?.rights_type?.key,});
      trackContext.setCurrentTrackImage(res.data.data.album?.image?.original?.url);
      trackContext.setPlayPause(true);
    }else{
      Helper.handleError(res);
    }
    trackContext.setFetchingTrack(false);
  })
  .catch(error=>{
    Helper.handleError(error)
  })
}

//function to fetch track and play next or prev track on button click
const handlePlayPrevNextTrack=async(action)=>{
  let trackId='';
  let songIndex=0;
  const {alltrackData,currentTrackDetail}=trackContext;

  let trackIndex=alltrackData.findIndex((track)=>{
    if(track?._id !== undefined){
      // checking this to work next prev functionality in history page and projects as there are duplicate tracks
      return track?.id ===currentTrackDetail?.id && track?._id === currentTrackDetail?.historyId  
    }
    else{
      return track?.id === currentTrackDetail?.id
    }
  });
  if(trackIndex === -1){
    return
  }
    if(action==="prev" && trackIndex !== 0)
    {
      trackId=alltrackData[trackIndex - 1]?.id;
      songIndex=trackIndex - 1
    }
    else if(action==="next" && trackIndex !== alltrackData.length - 1)
    {
      trackId=alltrackData[trackIndex + 1]?.id;
      songIndex=trackIndex + 1
    }
    else{
      return
    }
    trackContext.setFetchingTrack(true);
    await API.playTrack(trackId).then((res) => {
        trackContext.setFetchingTrack(false);
      if(res?.data?.success){
        if (Amplitude.getSongs().length) {
          Amplitude.stop();
          Amplitude.removeSong(0);
        }
          res.data.data['historyId']=alltrackData[songIndex]._id
          trackContext.setCurrentTrack(res.data.data.audiofile);
          trackContext.setCurrentTrackDetail({
            ...res.data.data,
            tags: res.data.data?.tags,
            artist: res.data.data?.artists_publishing_ownerships[0]?.artist?.full_name,
            artist_type: res.data.data?.artists_publishing_ownerships[0]?.rights_type?.key,
          });
      }else{
          Helper.handleError(res?.data);
        }
      })
      .catch((err) => {
        Helper.handleError(err); 
      });
}

  const handlePlayOrPause = () => {
    // play ? Amplitude.pause() : Amplitude.play();
    // setPlay(!play);
    trackContext.setPlayPause(!trackContext.playPause);
  };
  const handleClose = () => {
    setShowDownloadModal(false);
  };

  const handleDownloadTrack = (event, trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      return;
    }
    let currentTarget = event.currentTarget;
    API.getDownloadTracks(trackId).then((res) => {
      if (res?.audiofile !== undefined && res.audiofile !== null) {
        setHdmp3File(res.audiofile.hd_mp3.url);
        setOriginalFile(res.audiofile.original.url);
        setShowDownloadModal(currentTarget);
      } else {
        Helper.handleError(res);
      }
    });

  };

  const handleDownloadArchive = (trackId, quality) => {
    API.downloadArchiveTrack(trackId, quality).then((res) => { });
  };

  const handleArchiveDownload = (trackId, quality) => {
    API.downloadArchiveTrack(trackId, quality).then((res) => {
      //success : response  = ["taskId"]
      //fail : response : { "status": 400, "message": "\"documentIds\" is required. \"docId\" is not allowed.", "key": "validation_error", "stack": {}, "reqId": "9022499d-9b2b-4edc-9397-c8f8372a83ea" }
      if (Array.isArray(res) && res.length) {
        toast(({ closeToast }) => (
          <SuccessToast message="Check your email, you have been sent a link to download the files." />
        ));
      } else {
      toast(({ closeToast }) => <ErrorToast message={res.message} />);
      }

    });
  };

  const setDownloadHistory = (trackId) => {
    API.setDownloads(trackId).then((res) => {
      //  console.log({ res });
      const downloadlist = JSON.parse(localStorage.getItem("download"));
      downloadlist.push(trackId);
      setDownload(downloadlist);
      localStorage.setItem("download", JSON.stringify(downloadlist));
    });
  };
  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };

  const handleProjectModal = (trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.ADD_TO_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.ADD_TO_PROJECT);
      return;
    }

    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);

  };

  const handleThumbPrintSearch = async(props) => {
    // if (!trackContext.validateUser(Constants.ACTION.SIMILARITY_SEARCH)) {
    //   trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction(Constants.ACTION.SIMILARITY_SEARCH);
    //   return;
    // }
    let filter=trackContext.trackFilter
    filter.selectedChildTags=[]
    filter.selectedTags=[]
    filter.url=""
    filter.searchId="";
    filter.fileName="";
    filter.searchText = "";
    filter.searchTitle = props.currentTrackDetail.title;
    // filter.trackId=trackId;
    trackContext.setTrackFilter(filter);
    localStorage.setItem("filter_music" , JSON.stringify(filter))
    trackContext.setShowLoaderMagic(true);
    trackContext.setTrackData([]);
    
    if(trackContext.path.startsWith("/search")||
      trackContext.path==="/"|| 
      trackContext.path.startsWith("/albums") ||
      trackContext.path.startsWith("/artist") || 
      trackContext.path.startsWith("/playlists"))
    {
      history.push( `/search/track/`); 
    }
    let reqObj = {  
      url : 'public/search', 
      method : 'post', 
      data : {
        "query": "",
        "options": 
        {
            "trackId" : props.currentTrackDetail.id,
            "filters": {
              exclude: [],
              ...trackContext.trackFilter.searchTags,
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions":trackContext.searchDescription ? trackContext.searchDescription : false
        }
      }
    };
    await MewoService.loadMewoData(reqObj).then(res => {
          
      if (res?.hits) {
        let newRes = res.hits.filter(
          (item) => item.id !== props.currentTrackDetail.id
        );
        trackContext.setIsLoading(false);
        trackContext.setTrackData(newRes);
        trackContext.setTotaltracks(res.total.value);
      } else {
        Helper.handleError(res);
      }
    }).catch(err => {
      toast(({ closeToast }) => (
        <ErrorToast message={"Similarity Search Error : " + err?.response?.data?.message} />
      ));
    });
    trackContext.setShowLoaderMagic(false);
  };

  const handleAddTrackToFavorites = (trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.FAVOURITE)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.FAVOURITE);
      return;
    }

    API.addTrackToFavorites(trackId).then((res) => {
      if (res?.success) {
        localStorage.setItem("favs", JSON.stringify(res.favorites));
        setIsFav(true);
      } else {
        Helper.handleError(res);
      }
    });

  };

  const playNextPrevious = (skip) => {
    let playper =
      (Amplitude.getSongPlayedPercentage() / 100) *
      trackContext.currentTrackDetail.duration;
    let duration = playper + skip;
    let playerState = Amplitude.getPlayerState();
    let per = (duration / trackContext.currentTrackDetail.duration) * 100;
    /*if(duration<0)
    {
       Amplitude.skipTo(0,0, null);
    }
    else if(per>100)
    {
        Amplitude.setSongPlayedPercentage(99.99)
       trackContext.setPlayPause(false);
    }
    else
       Amplitude.setSongPlayedPercentage(per)
    if(!trackContext.playPause ){
      Amplitude.stop();
      Amplitude.skipTo(duration,0, null)
    }
    else
      Amplitude.play()*/
    // console.log(Amplitude)
    if (playerState === "playing" && per < 100) {
      Amplitude.setSongPlayedPercentage(duration < 0 ? 0.1 : per);
    } else {
      Amplitude.skipTo(
        per >= 100
          ? trackContext.currentTrackDetail.duration
          : per < 0
          ? 0
          : duration,
        0,
        null
      );
      trackContext.setPlayPause(false);
      Amplitude.stop();
    }

    //   Amplitude.skipTo(duration,0, null)
    /* if(trackIndex >-1 && trackIndex < trackContext.trackData.length)
    {
      if (Amplitude.getSongs().length) {
        Amplitude.stop()
        Amplitude.removeSong(0)
      }
      
  
    //  props.setFetchingTrack(true);
      API.playTrack(trackContext.trackData[trackIndex].id).then(res => {
       // props.setFetchingTrack(false);
       trackContext.setpreviousTrack(trackIndex--);
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail(res.data.data)
        trackContext.setCurrentTrackImage(res.data.data.album.image.xsmall.url);
      });
    }*/
  };


  const handleTrackDetail = () => {
    setTrackDetail(true);
    let body = document.getElementsByTagName("body");
    let navTag = document.getElementsByTagName("nav");
    navTag[0].style.zIndex = 10;
    // console.log({body});
    body[0].style.overflow = "hidden";
  };

  const CloseTrackDetail = () => {
    setTrackDetail(false);
    let body = document.getElementsByTagName("body");
    let navTag = document.getElementsByTagName("nav");
    navTag[0].style.zIndex = 20;
    // console.log({body});
    body[0].style.overflow = "auto";
  };

  const handleLoaderWithThumbPrint = () => {

    setShowLoader(true)
    trackContext.setClearData(true)
    handleThumbPrintSearch(props)
    trackContext.setClearData(false)


    // toast(({ closeToast }) => (
    //   <SuccessToast page='music' message="This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you." />
    // ));

    // setTimeout(()=>setShowLoader(false),3000)
    setTimeout(() => {
      setShowLoader(false);
      // trackContext.setClearData(false)
    }, 4000)
    // let newTrackFilter = { ...trackContext.trackFilter };
    // newTrackFilter.searchText = "";
    // newTrackFilter.fetchList = true;
    // trackContext.setTrackFilter(newTrackFilter);
    // console.log('Remove Search Filter:', newTrackFilter);
    // localStorage.setItem("filter", JSON.stringify(newTrackFilter));
  }

  const handleMagicWandClick = (trackData) => {
    //TODO : change this as we change musicFilter obj
    const tempFilter = CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : Constants.SEARCH_TYPE.text};
    tempFilter.type = Constants.SEARCH_TYPE.track;
    tempFilter.searchText = trackData.title;
    tempFilter.searchRef = trackData.id;
    tempFilter.segment = null;
    CookieManager.setCookie("harmix-music-filter", JSON.stringify(tempFilter));
    history.push(`/library/${trackData.id}`)
  }
  // const clearSearchTag = () => {
  //   let newTrackFilter = { ...trackContext.trackFilter };
  //   newTrackFilter.searchTags = {};
  //   newTrackFilter.selectedTags = [];
  //   newTrackFilter.duration = [];
  //   newTrackFilter.bpm = [];
  //   newTrackFilter.url = "";
  //   newTrackFilter.searchText = "";
  //   newTrackFilter.fetchList = true;
  //   trackContext.setTrackFilter(newTrackFilter);

  //   let newFilter = filterMenu.map((item) => {
  //     item.count = 0;
  //     return item;
  //   });
  //   trackContext.setFilterMenu(newFilter);
  //   localStorage.setItem("filterMenu", JSON.stringify(newFilter));
  //   setValue("");
  //   setFilterSubMenu([]);
  //   setFilterSubMenuchild([]);
  //   setSubMenu("");
  // };
  return (
    <>
      <div className="player-wrapper shadow-sm">
        {currentTrack !== "" ? (
          <div
            id="blue-playlist-container"
            className="w-full flex items-center sm:px-6 mx-auto"
          >
            <div
              id="progress-container"
              className="absolute top-0 md:hidden"
              style={{ marginTop: "-8px" }}
            >
              <input
                type="range"
                className="amplitude-song-slider block w-full"
              />
              {/* <progress
                id="song-played-progress"
                //className="amplitude-song-played-progress"
              ></progress>
              <progress
                id="song-buffered-progress"
                //className="amplitude-buffered-progress"
                value="0"
              ></progress> */}
            </div>
            <Link className="pr-4" to={`/album/${trackContext.currentTrackDetail.album?.id}`}>
            {
              trackContext.currentTrackDetail?.album ? 
              (<img
                  src={trackContext.currentTrackDetail.album?.image.original.url}
                  className="hidden sm:flex sm:cursor-pointer"
                  alt="music-img"
                  width="100px"
                onError={(e)=>trackContext.setDefaultImage(e)}
              />):
              ( <img
                      src={defaultImage}
                      className="hidden sm:flex sm:cursor-pointer"
                      alt="music-img"
                      width="100px"
              />)
            }
            </Link>
            <div id="amplitude-player" className="w-full">
              <div id="amplitude-left" className="w-full">
                <div className="hidden sm:flex sm:w-2/12 ">
                  {/* <img data-amplitude-song-info='cover_art_url' className="w-1/3 " style={{margin:"5px"}}/> */}
                  {/* <div id='meta-container'>
                  <h1
                    data-amplitude-song-info='name'
                    className='song-name text-base mr-2 text-black capitalize'
                  ></h1>
                  <div className='song-artist-album'>
                    <h2 data-amplitude-song-info='artist' className="text-xs self-center text-gray-700 capitalize"></h2>
                  </div>
                </div> */}
                  <CardContent
                    className="w-full self-center pb-16 text-left"
                    style={{ paddingLeft: 0, paddingBottom: "15px", paddingRight: 0 }}
                  >
                    <div
                      data-amplitude-song-info="name"
                      className="text-base mr-2 track-name text-black truncate"
                    >
                      {" "}
                    </div>
                    <div
                      data-amplitude-song-info="artist"
                      className="text-xs track-artist self-center text-gray-700"
                    >
                      {" "}
                    </div>
                  </CardContent>
                </div>
                <div id="player-left-bottom" className="w-9/12 md:pr-0">
                  <div
                    id="control-container "
                    className="ml-4 w-2/12 self-center sm:ml-0"
                  >
                    <div id="central-control-container" className="w-full">
                      <div id="central-controls" className="flex w-full ">
                        <div
                          className="track-play-pause flex w-full justify-center"
                          id="playpause"
                        >
                          <div
                            aria-label="play/pause"
                            className="hidden sm:w-1/6 sm:outline-none sm:focus:outline-none sm:play-button sm:self-center sm:block"
                            //  onClick={handlePlayOrPause}
                          >
                          <SkipPrevious
                              onClick={()=>handlePlayPrevNextTrack("prev")}
                              className="outline-none cursor-pointer hover:text-gray-500"
                              style={{
                                width: "20px",
                              }}
                              titleAccess="Previous"
                            />
                          </div>
                          <div
                            aria-label="play/pause"
                            className="hidden sm:w-1/6 sm:outline-none sm:focus:outline-none sm:play-button sm:self-center sm:block"
                            //  onClick={handlePlayOrPause}
                          >
                            <Rewind
                              onClick={() => playNextPrevious(-10)}
                              className="outline-none cursor-pointer"
                              style={{
                                color: trackContext.previousTrack ? "" : "gray",
                                width: "20px",
                              }}
                            />
                          </div>
                          <div
                            aria-label="play/pause"
                            className="w-1/5 mr-3 outline-none focus:outline-none play-button self-center sm:ml-3"
                            onClick={handlePlayOrPause}
                          >
                            {trackContext.playPause ? (
                              <Pause className="outline-none cursor-pointer" />
                            ) : (
                              <Play className="outline-none cursor-pointer" />
                            )}
                          </div>
                          <div
                            aria-label="play/pause"
                            className="ml-4 sm:w-1/6 outline-none focus:outline-none play-button self-center sm:ml-1"
                            //  onClick={handlePlayOrPause}
                          >
                            <Skip
                              className="outline-none cursor-pointer"
                              onClick={() => playNextPrevious(10)}
                              style={{
                                color:
                                  trackContext.previousTrack <
                                  trackContext.trackData.length - 1
                                    ? ""
                                    : "gray",
                                width: "20px",
                              }}
                            />
                          </div>
                          <div
                            aria-label="play/pause"
                            className="hidden sm:w-1/6 sm:outline-none sm:focus:outline-none sm:play-button sm:self-center sm:block"
                            //  onClick={handlePlayOrPause}
                          >
                            <SkipNext
                              onClick={()=>handlePlayPrevNextTrack("next")}
                              className="outline-none cursor-pointer hover:text-gray-500 "
                              style={{
                                width: "20px",
                              }}
                              titleAccess="Next"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-2/4 block sm:hidden">
                    <CardContent
                      className="w-full self-center text-left md:pl-0"
                      style={{ paddingBottom: "15px" }}
                    >
                      <div
                        data-amplitude-song-info="name"
                        className="track-name text-base sm:mr-2 text-black truncate"
                      >
                        {" "}
                      </div>
                      <div
                        data-amplitude-song-info="artist"
                        className="track-artist text-xs self-center text-gray-700"
                      >
                        {" "}
                      </div>
                    </CardContent>
                  </div>
                  <span className="duration w-12 sm:w-0 sm:hidden self-center">
                    <span className="amplitude-duration-minutes"></span>:
                    <span className="amplitude-duration-seconds"></span>
                  </span>
                  <div
                    className="flex justify-end py-8 text-center w-10 sm:w-0 sm:hidden cursor-pointer"
                    onClick={handleTrackDetail}
                  >
                    <Points className="cursor-pointer" />
                  </div>
                  <div
                    id="time-container"
                    className="hidden sm:w-7/12 md:block"
                  >
                    <span className="current-time font-light text-ctaRed">
                      <span className="amplitude-current-minutes"></span>:
                      <span className="amplitude-current-seconds"></span>
                    </span>
                    <div id="progress-container">
                      <div className="amplitude-wave-form"></div>
                      <input type="range" className="amplitude-song-slider" />
                      <progress
                        id="song-played-progress"
                        className="amplitude-song-played-progress"
                      ></progress>
                      <progress
                        id="song-buffered-progress"
                        className="amplitude-buffered-progress"
                        value="0"
                        style={{display : 'none'}}
                      ></progress>
                    </div>
                    <span className="duration">
                      <span className="amplitude-duration-minutes"></span>:
                      <span className="amplitude-duration-seconds"></span>
                    </span>
                  </div>
                  <div
                    id="control-container"
                    className="hidden sm:w-1/12 sm:block md:hidden lg:block pt-2"
                  >
                    <div id="volume-container">
                      <div className="volume-controls">
                        <div className="amplitude-mute amplitude-not-muted"></div>
                        <input
                          type="range"
                          className="amplitude-volume-slider"
                        />
                        <div className="ms-range-fix"></div>
                      </div>
                      <div
                        className="amplitude-shuffle amplitude-shuffle-off"
                        id="shuffle-right"
                      ></div>
                    </div>
                  </div>
                  <div
                    className="hidden  sm:self-center sm:justify-left sm:flex sm:h-4"
                    // style={{paddingTop:"15px", marginLeft:"30px"}}
                  >
                    {
                      <ThumbPrint
                        className="thumbprint-icon w-8 sm:w-6 sm:mb-4 sm:mt-5 sm:ml-2 sm:right-0 self-center cursor-pointer"
                        // onClick={
                        //   handleLoaderWithThumbPrint
                        //   // handleThumbPrintSearch(props.currentTrackDetail.id)

                        // }
                        onClick={() => {handleMagicWandClick({title : trackContext.currentTrackDetail.display_title, id:trackContext.currentTrackDetail.id})}}
                      />
                    }

                    {showLoader ?
                      // (
                      //   <div class="modal-busy" overflow="hidden">
                      //     <div class="center-busy" overflow="hidden">
                      //       <BouncingLoader className="onMobile" page='music' />
                      //     </div>
                      //   </div>
                      // )
                      (
                      <div class="modal-busy" overflow="hidden">
                        <div>
                            <div class="center-busy loader-text-tag" overflow="hidden">
                            <div className="loader-text">
                                This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you
                            </div>
                              <img src={RippleLoader} alt="loading..." className="onMobile img-tag" />

                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}



                    {isFav ? (
                      <FavoriteIcon
                        onClick={() =>
                          handleAddTrackToFavorites(props.currentTrackDetail.id)
                        }
                        style={{ fontSize: "20px" }}
                        className="m-1 self-center right-0 cursor-pointer text-red-600 hover:scale-50 block"
                      />
                    ) : (
                      <UnFavoriteIcon
                        onClick={() =>
                          handleAddTrackToFavorites(props.currentTrackDetail.id)
                        }
                        style={{ fontSize: "20px" }}
                        className="m-1 self-center right-0 cursor-pointer text-gray-600  hover:text-red-600 block"
                      />
                    )}
                    <CreateNewFolderIcon
                      style={{ fontSize: "20px" }}
                      onClick={() =>
                        handleProjectModal(props.currentTrackDetail.id)
                      }
                      className="m-2 mt-4 self-center right-0 cursor-pointer text-gray-700 hover:text-blue-600 block"
                    />
                    {/* <GetAppIcon
                      onClick={(event) => handleDownloadTrack(event, props.currentTrackDetail.id)}
                      style={{ fontSize: '20px' }}
                      className='mt-2 self-center right-0 cursor-pointer text-gray-700 hover:text-blue-600 block'
                    /> */}
                    {download ? (
                      <Activedownload
                        onClick={(event) =>
                          handleDownloadTrack(
                            event,
                            props.currentTrackDetail.id
                          )
                        }
                        style={{ fontSize: "20px" }}
                        className="hidden sm:-1 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block"
                      />
                    ) : (
                      <GetAppIcon
                        onClick={(event) =>
                          handleDownloadTrack(
                            event,
                            props.currentTrackDetail.id
                          )
                        }
                        style={{ fontSize: "20px" }}
                        className=" sm:-1 sm:mt-2 sm:self-center sm:right-0 sm:cursor-pointer sm:text-gray-700 sm:hover:text-blue-600 lg:block"
                      />
                    )}
                    <Menu
                      id="customized-menu"
                      anchorEl={showDownloadModal}
                      open={Boolean(showDownloadModal)}
                      onClose={handleClose}
                      style={{ width: "320px" }}
                    >
                      <MenuItem className="card-action">
                        <a
                          href={originalFile}
                          target="_blank"
                          rel="noopener noreferrer"
                          download=""
                        >
                          Original HD - Single track
                        </a>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleArchiveDownload(
                            props.currentTrackDetail.id,
                            "original"
                          )
                        }
                        className="card-action"
                      >
                        <span className="card-action-text">
                          Original HD - All versions
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          setDownloadHistory(props.currentTrackDetail.id)
                        }
                        className="card-action"
                      >
                        <a
                          href={hdmp3File}
                          target="_blank"
                          rel="noopener noreferrer"
                          download=""
                        >
                          MP3 - Single track
                        </a>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleArchiveDownload(
                            props.currentTrackDetail.id,
                            "hd_mp3"
                          )
                        }
                        className="card-action"
                      >
                        <span className="card-action-text">
                          MP3 - All versions
                        </span>
                      </MenuItem>
                      
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {showProjectModal && (
        <AddTrackToProjectPopup
          trackToBeAddedToProject={trackToBeAddedToProject}
          closeProjectModal={closeProjectModal}
        />
      )}
      {showTrackDetail && (
        <TrackDetail
          currentTrackDetail={props}
          handleProjectModal={handleProjectModal}
          CloseTrackDetail={CloseTrackDetail}
        />
      )}
    </>
  );
}
