import React from 'react';
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";



  
  const AirbnbSlider = withStyles({
    root: {
      color: "red",
      height: 3
     
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      border: "1px solid currentColor",
      marginTop: -6,
      marginLeft: -6,
    },
    rail: {
      color: "#d8d8d8",
      opacity: 1,
      height: 3
    }
  })(Slider);

function SliderCustom(props){
    props.ValueLabelComponent.propTypes = {
        children: PropTypes.element.isRequired,
        value: PropTypes.number.isRequired
      };
    return ( <AirbnbSlider
    ValueLabelComponent={props.ValueLabelComponent}
    aria-label="custom thumb label"
    marks={ props.marksDuration}
    onChange={(event,newValue)=>{ 
        props.handleSlider(event,newValue,props.sliderkey)
    }}
    onChangeCommitted={props.onChangeCommitted}
    defaultValue={props.duration.length ? props.duration:[props.marksDuration[0].value, props.marksDuration[1].value]}
    value={props.duration.length ? props.duration:[props.marksDuration[0].value, props.marksDuration[1].value]}
    min={props.min}
    max={props.max}
  />)
}

export default SliderCustom;