import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import TrackContext from "../../contexts/track-context";
import { ReactComponent as CloseModal } from "../../assets/modal-close.svg";
import { ReactComponent as Close } from "../../assets/BlackButtonRoundMinusSmall.svg";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SliderCustom from "./sliderCustom";
import MobileFilters from "./mobileFilters";
import Helper from "../../services/helper";
import AISearchMobile from "./aiSearchMobile";
import API from "../../services/api";
import Constants from "../../services/constants";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";
import { useHistory } from "react-router-dom";
import RippleLoader from "../../assets/loader-2.gif";
import MewoService from "../../services/mewoService";
let selectFilterTag = [];

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip
      title={value && new Date(value * 1000).toISOString().substr(14, 5)}
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
}
function ValueLabelBPMComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip title={value} placement="top" arrow>
      {children}
    </Tooltip>
  );
}
const marksDuration = [
  {
    value: 0,
    label: "00:00",
  },
  {
    value: 600,
    label: "10:00",
  }
];
const marksBpm = [
  {
    value: 30,
    label: "30",
  },
  {
    value: 220,
    label: "220",
  },
];
function Sidebar(props) {
  let trackContext = useContext(TrackContext);
  const {
    filterSubMenu,
    setFilterSubMenu,
    filterSubMenuChild,
    setFilterSubMenuchild,
    searchkey,
  } = trackContext;
  let filterMenu = [];

  if (trackContext.path === "/" ||
      trackContext.path.startsWith(`/search/track/`) ||
      window.location.pathname.startsWith( `/search/albums/`) 
      ) {
       filterMenu =
       JSON.parse(localStorage.getItem("filterMenu_music")) ||
       trackContext.filterMenu;
  } else if (trackContext.path === "/sound-design" ||
             trackContext.path.startsWith(`/sound-design/search/track/`) ||
             window.location.pathname.startsWith(`/sound-design/search/albums/`)
             ) {
    filterMenu =
      JSON.parse(localStorage.getItem("filterMenu_soundfx")) ||
      trackContext.filterMenu;
  }
  const history = useHistory();
  const {
    searchTags,
    selectedTags,
    bpm,
    duration,
    searchText,
    url,
    fileName,
    searchTitle,
    selectedChildTags,
  } = trackContext.trackFilter;
  const outerDiv = useRef(null);
  const innerDiv = useRef(null);
  const [tags, setAllTags] = useState([]);
  const [Filtertags, setFilterAllTags] = useState([]);
  const [subMenu, setSubMenu] = useState("");
  const [value, setValue] = useState("");
  const [isSlider, setIsSlider] = useState(false);
  const [showEllipsis, setShowEllipsis] = useState(false);
  const [showMobileFilters, setMobileFilters] = useState(false);
  const [showMobileMenu, setMobileMenu] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const [excludedTrack, setExcludedTrack] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [showLevel1, setShowLevel1] = useState(true);
  const [showLevel2, setShowLevel2] = useState(false);
  let soundDesignTagID = "5d36d75104a4286541ea2d1d";
  //Gothic Tool Works
  let labelTagID1 = "613e35c6b52f5e75a7fabf2b";
  // Strike Audio
  let labelTagID2 = "5f105463dd200590c481c317";
  // Aura
  let labelTagID3 = "602ea745357d1e5f788b0559";

  const handleMobileFilters = () => {
    setMobileFilters(true);
    setShowLevel1(true)
    setShowLevel2(false)
  };
  const handleMobileFiltersClose = () => {
    let body = document.getElementsByTagName("body");
    body[0].style.overflow = "auto";
    setMobileFilters(false);
  };

  useEffect(() => {
    //  API.getAllTags().then(res => {
    //    let data = res.data;
    setAllTags(props.tags);
    filterMenu.forEach((item) => {
      let tag = props.tags?.filter(
        (item1) => item1.key === item.key && !item.isSlider
      );
      if (tag?.length) {
        if (tag[0]?.sub_categories?.length) {
          tag[0].sub_categories.forEach((cat) => {
            let subTag = cat.tags?.map((leveltag) => {
              return {
                ...leveltag,
                parentkey: item.key,
                mainkey: cat.key,
                mainid: cat.id,
                mainTag: cat.names[0].value,
              };
            });
            selectFilterTag = [...selectFilterTag, ...subTag];
          });
        } else {
          let subTag = tag[0].tags?.map((leveltag) => {
            return {
              ...leveltag,
              parentkey: item.key,
              mainkey: tag[0].key,
              mainid: tag[0].id,
              mainTag: tag[0].names[0].value,
            };
          });
          selectFilterTag = [...selectFilterTag, ...subTag];
        }
      }
    });
    setFilterAllTags(selectFilterTag);
    if (value) {
      let selectedTag = props.tags.filter((item) => {
        return item.key === value;
      });
      setIsSlider(["duration", "bpm"].includes(value));
      let subtag = selectedTag.length
        ? selectedTag[0].sub_categories.length
          ? selectedTag[0].sub_categories
          : selectedTag[0].tags
        : [];
      setFilterSubMenu(subtag);
    }
    //  });
  }, [props.tags]);

  useEffect(() => {
    let mainmenu = "";
    if (trackContext.path === "/"||trackContext.path.startsWith(`/search/track/`) ) {
            setFilterAllTags(selectFilterTag);
            mainmenu = JSON.parse(localStorage.getItem("mainmenu_music")) || "";
    } else if (trackContext.path === "/sound-design"||trackContext.path.startsWith(`/sound-design/search/track/`)) {
      setFilterAllTags(selectFilterTag);

      mainmenu = JSON.parse(localStorage.getItem("mainmenu_soundfx")) || "";
    }
    setValue(mainmenu);
    trackContext.setTrackData([])
  }, [trackContext.path]);

  useEffect(() => {
    setIsSlider(["duration", "bpm"].includes(value));
  }, [value, trackContext.path]);

  useEffect(() => {
    if (innerDiv.current === null || outerDiv.current === null) {
      return;
    }
    if (innerDiv.current.clientHeight > outerDiv.current.clientHeight) {
      setShowEllipsis(true);
    } else {
      setShowEllipsis(false);
    }
  }, [value]);

  const handleShowMoretags = () => {
    setShowEllipsis(false);
    outerDiv.current.style.maxHeight = innerDiv.current.clientHeight + "px";
  };
  const addSearchTag = (tagId, tagName, key) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    newTrackFilter.fetchList = true;
    newTrackFilter.url = "";
    newTrackFilter.searchText ="";
    newTrackFilter.searchTitle = "";
    newTrackFilter.trackId = "";
    newTrackFilter.fileName="";
    newTrackFilter.searchId="";

    let disSelectedTag = newTrackFilter.selectedTags || [];
    let selectsubmenu = filterSubMenu.filter((item) => {
      return item.id === tagId;
    });
    let submenutag = selectsubmenu.length ? selectsubmenu[0].tags || [] : [];
    setSubMenu(`${tagId}-${tagName}`);
    setFilterSubMenuchild(submenutag);
    if (
      !(
        disSelectedTag.includes(`${tagId}-${tagName}-${key}`) ||
        disSelectedTag.includes(`${tagId}-${tagName}`)
      )
    ) {
      let newfilterMenu = filterMenu;
      newfilterMenu = newfilterMenu?.map((item) => {
        if (item.key === key) {
          item.count = (item.count || 0) + 1;
        }
        return item;
      });
      trackContext.setFilterMenu(newfilterMenu);

      let subTag = [];
      if (submenutag.length) {
        submenutag.forEach((item) => {
          subTag.push(item.id);
          contexttags = contexttags.filter((id) => item.id !== id);
        });
        tags_or.push(subTag);
      } else {
        contexttags.push(tagId);
      }
      disSelectedTag.push(`${tagId}-${tagName}-${key}`);

      newTags.tags_or = tags_or;
      newTags.tags = contexttags;
      newTrackFilter.searchTags = newTags;
      newTrackFilter.selectedTags = disSelectedTag;
      trackContext.setTrackFilter(newTrackFilter);
      loadTrackData(newTrackFilter)
    }
  };

  const addSearchChildTag = (tagId, tagName, key) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    newTrackFilter.fetchList = true;
    newTrackFilter.url = "";
    newTrackFilter.searchText ="";
    newTrackFilter.searchTitle = "";
    newTrackFilter.trackId = "";
    newTrackFilter.fileName="";
    newTrackFilter.searchId="";
    let disSelectedTag = newTrackFilter.selectedTags || [];
    let selectedChildTags = newTrackFilter.selectedChildTags || [];
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    let tags_not = newTags.tags_not || [];
    let childkey = key.split("-")[1];
    let childkeyOne = key.split("-")[0];
    let newChildKey = (childkey[0].toUpperCase() + childkey.slice(1))
    for (var i = 0; i < disSelectedTag.length; i++) {
      if (disSelectedTag[i].includes(newChildKey)) {
        var newdisSelectedTag = disSelectedTag[i].concat("-", tagName)
        console.log(newdisSelectedTag)
      }
    }

    for (var i = 0; i < filterSubMenu.length; i++) {
      var newFilterSubMenu = filterSubMenu[i];
      if (newFilterSubMenu.key == key) {
        var filterSubMenuIndex = filterSubMenu[i].tags
      }
    }
    var compaArry = [];
    for (var i = 0; i < filterSubMenuIndex.length; i++) {
      compaArry.push(filterSubMenuIndex[i].id)
    }
    for (var i = 0; i < tags_or.length; i++) {
      var newsTags_or = tags_or[i]
      if (newsTags_or.includes(tagId)) {
        if ((compaArry.includes(tagId)) && compaArry.length === newsTags_or.length) {
          tags_or[i] = [];
          tags_or[i].push(tagId);
          break
        }
      }
      else if (!newsTags_or.includes(tagId) && compaArry.some((Element) => newsTags_or.includes(Element))) {
        tags_or[i].push(tagId)
      }
    }
    selectedTags.forEach((item, index) => {

      if (item.includes(newChildKey) && !selectedChildTags.includes(`${tagId}-${tagName}`)) {
        selectedChildTags.push(`${tagId}-${tagName}`)
      }

    })
    newTags.tags_or = tags_or;
    newTags.tags_not = tags_not;
    newTags.tags = contexttags;
    newTrackFilter.selectedChildTags = selectedChildTags
    newTrackFilter.searchTags = newTags;
    newTrackFilter.selectedTag = disSelectedTag
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter)
  };


  const removeFilterCount = (key) => {
    let newfilterMenu = [...filterMenu];
    newfilterMenu = newfilterMenu?.map((item) => {
      let newitem = { ...item };
      if (newitem.key === key) {
        newitem.count = (newitem.count || 0) - 1;
      }
      return newitem;
    });
    trackContext.setFilterMenu(newfilterMenu);
  };
  const removeSearchTag = (tagId, tagName, key) => {
    removeFilterCount(key);
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let selectedChildTags = newTrackFilter.selectedChildTags || [];
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    let disSelectedTag = newTrackFilter.selectedTags || [];
    if (tagId) {
      if (contexttags.includes(tagId)) {
        contexttags = contexttags.filter((id) => tagId !== id);
      } else {
        let selectedmainTag = tags.filter((item) => {
          return item.key === key;
        });
        selectedmainTag = selectedmainTag[0]?.sub_categories?.filter((item) => {
          return item.id === tagId;
        });
        let subtag = selectedmainTag[0]?.tags || [];
        var newSubtag = []
        for (var i = 0; i < subtag.length; i++) {
          newSubtag.push(subtag[i].id)
        }
        if (subtag.length) {
          tags_or = tags_or.filter((item) => {
            return !item.some(ele => newSubtag.includes(ele));
          });
          contexttags = contexttags.filter((item) => {
            return !subtag.filter((item1) => item === item1.id).length > 0;
            //return !item.includes(subtag[1].id)
          });

          newSubtag.forEach((subTag, subIndex) => {
            selectedChildTags.forEach((data, index) => {
              if (subTag === data.split("-")[0]) {
                selectedChildTags.splice(index, 1)
              }
            })
          })
        }
      }
    } else {
      newTrackFilter.fetchList = true;
    }
    disSelectedTag = disSelectedTag.filter(
      (tag) => !tag.includes(`${tagId}-${tagName}`)
    );
    for (var i = 0; i < tags_or.length; i++) {
      var selectedTags = tags_or;
      if (selectedTags[i].length <= 0) {
        selectedTags.splice(i, 1);
        break;
      }
    }
    newTags.tags_or = tags_or;
    newTags.tags = contexttags;
    newTrackFilter.searchTags = newTags;
    newTrackFilter.selectedChildTags = selectedChildTags
    newTrackFilter.selectedTags = disSelectedTag;
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter)
  };

  const removeSearchChildTag = (tagId, tagName) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let selectedChildTags = newTrackFilter.selectedChildTags || []
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    let tags_not = newTags.tags_not || [];
    let disSelectedTag = newTrackFilter.selectedTags || [];
    if (selectedChildTags.indexOf(`${tagId}-${tagName}`) > -1) {
      selectedChildTags.splice(selectedChildTags.indexOf(`${tagId}-${tagName}`), 1)

    }
    if (tags_or.some((item1) => item1.includes(tagId))) {
      // tags_not.push(tagId);
      tags_or = tags_or.filter((item) => {
        if (item.every((subItem) => tags_not.includes(subItem))) {
          removeFilterCount(value);
          disSelectedTag = newTrackFilter.selectedTags.filter(
            (item) => !item.includes(subMenu)
          );
          tags_not = tags_not.filter((itemnot) => !item.includes(itemnot));
          newTrackFilter.selectedTags = disSelectedTag;
          return false;
        }
        return true;
      });
    }
    if (contexttags.includes(tagId)) {
      contexttags = contexttags.filter((item) => item !== tagId);
    }



    var selectedTags = [];
    for (var i = 0; i < tags_or.length; i++) {
      var selectedTags = tags_or[i];
      if (selectedTags.indexOf(tagId) > -1) {
        selectedTags.splice(selectedTags.indexOf(tagId), 1);
        break;
      }
    }

    for (var i = 0; i < tags_or.length; i++) {
      var selectedTags = tags_or;
      if (selectedTags[i].length <= 0) {
        selectedTags.splice(i, 1);
        break;
      }
    }

    newTags.tags_or = tags_or;
    newTags.tags_not = [...tags_not];
    newTags.tags = contexttags;
    newTrackFilter.searchTags = newTags;
    newTrackFilter.selectedChildTags = selectedChildTags;
    newTrackFilter.selectedTags = disSelectedTag;
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter)
  };


  const clearSearchTag = () => {
    let newTrackFilter = trackContext.trackFilter;
    
    trackContext.setSearchTime(0);
    newTrackFilter.searchTags = {};
    newTrackFilter.selectedChildTags = [];
    newTrackFilter.selectedTags = [];
    newTrackFilter.duration = [];
    newTrackFilter.bpm = [];
    newTrackFilter.url = "";
    newTrackFilter.searchId="";
    newTrackFilter.fileName="";
    newTrackFilter.searchText = "";
    newTrackFilter.trackId="";
    newTrackFilter.searchTitle = "";
    newTrackFilter.fetchList = true;
    localStorage.setItem("filter_music", JSON.stringify(newTrackFilter))
    trackContext.setTrackFilter(newTrackFilter);
    trackContext.setSearchkey("")
    trackContext.setSearchAlbums(true);
    
    let newFilter = filterMenu?.map((item) => {
      item.count = 0;
      return item;
    });
    trackContext.setFilterMenu(newFilter);
    if (trackContext.path === "/"||trackContext.path.startsWith(`/search/track/`)) {
      localStorage.removeItem("mainmenu_music");
    }
    setValue("");
    setIsSlider(false);
    setFilterSubMenu([]);
    setFilterSubMenuchild([]);
    setSubMenu("");
    setIsSlider(false);
    if(trackContext.path.includes("/search")){
      history.push("/") 
    }
    // trackContext.setFilterMenu(newFilter);
    loadTrackData(newTrackFilter);
    if(showMobileFilters){
      setShowLevel1(true)
      setShowLevel2(false)
    }
   
  };

  const loadTrackData = async(trackFilter = null) => {
    let filter = {};
    if(trackFilter)
      filter = trackFilter;
    else
      filter = JSON.parse(localStorage.getItem("filter_music") || "{}")
    trackContext.setIsLoading(true);
    trackContext.setTrackData([]);
    if ((window.location.pathname === "/" ||window.location.pathname.startsWith( `/search/track/`)) && !filter?.trackId) {
      filter.searchTags ? filter.searchTags.tags_not = ["5d36d75104a4286541ea2d1d"] : filter.searchTags = {"tags_not" : ["5d36d75104a4286541ea2d1d"]};
        //alert("api call for reload")
      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": filter.searchText?.length > 0 ? filter.searchText : "",
          "options": 
          {
            "filters": {
              ...filter.searchTags,
              exclude : []
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      if(filter.searchId)
        reqObj.data.options.searchId = filter.searchId;

      await MewoService.loadMewoData(reqObj).then(res => {
          if (res) {
            trackContext.setTrackData(res.hits);
            trackContext.setTotaltracks(res.total.value);
            trackContext.setIsLoading(false);
            trackContext.setShowMayaLoader(false);
            trackContext.setSearchAlbums(false);
          if (Constants.MEWO_TRACK_SEARCH_BATCH_SIZE > res.hits?.length)
              trackContext.setAllTracksLoaded(true);
          else
            trackContext.setAllTracksLoaded(false);
          } else {
            trackContext.setTrackData([]);
            trackContext.setTotaltracks(0);
            trackContext.setIsLoading(false);
            trackContext.setShowMayaLoader(false);
            Helper.handleError(res);
          }
        })
        .catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
        });
    }
    if(window.location.pathname.startsWith( `/search/albums/`) ||
    window.location.pathname.startsWith(`/sound-design/search/albums/`) 
    ){
      trackContext.setTrackData([]);
      if((trackContext?.trackFilter?.searchText!== "" )){
        let reqObj = {
          url : `public/search/panel`,
          method : 'post',
          data: JSON.stringify({
            query : trackContext?.trackFilter?.searchText,
            albums: { page: 0, max: Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE, search_descriptions: trackContext.searchDescription },
          }),
        };
        await MewoService.loadMewoData(reqObj).then((res) => {
          if (res) {
            trackContext.setShowProjectModal(false);
            props.handleMaiaOnWork(false);
            trackContext.setSearchAlbums(true)
            props.handleResultfromMaia(res.albums.hits);
            trackContext.setTotaltracks(res.albums.total?.value);
            trackContext.setAllTracksLoaded(true);
            trackContext.setIsLoading(false);
            trackContext.setShowMayaLoader(false);
            if (
              process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE >
              res.albums.hits?.length
            )
              trackContext.setAllTracksLoaded(true);
            else{ 
              trackContext.setAllTracksLoaded(false);
            props.handleMaiaOnWork(false);

            }
          } else {
            Helper.handleError(res);
          }
        }).catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Error Loading Albums : " + err?.response?.data?.message}/>);
        });
    }
    }
  if((window.location.pathname === "/sound-design" ||  window.location.pathname.startsWith(`/sound-design/search/track/`))&& !filter.trackId){
      
      trackContext.setTrackData([])
      trackContext.setSearchAlbums(false);
      trackContext.trackFilter.searchTags.labels = [labelTagID1, labelTagID2, labelTagID3];
      trackContext.trackFilter.searchTags.tags = [soundDesignTagID];
      trackContext.trackFilter.searchTags.tags_not = [];
      trackContext.trackFilter.fetchList = true; 
    
        if (
          trackContext?.trackFilter?.searchTags?.tags?.length &&
          trackContext?.trackFilter?.searchTags?.tags?.indexOf(
            "5d36d75104a4286541ea2d1d"
          ) !== -1
        ) {
          if(trackContext.trackFilter.searchId){
            trackContext.trackFilter.searchTags.labels = [];
            trackContext.trackFilter.searchTags.tags = [];
            trackContext.trackFilter.searchTags.tags_not = []
            trackContext.trackFilter.fetchList = false;
          }
          trackContext.setIsLoading(true);
          trackContext.setSearchkey("")
          trackContext.setTrackData([])
          setNoDataFound(false);
          filter.labels = [labelTagID1, labelTagID2, labelTagID3];
          filter.searchTags.tags = [soundDesignTagID];
          filter.searchTags.tags_not = [];

          let reqObj = {
            url : `public/search`,
            method : 'post',
            data: {
              "query": filter.searchText,
              "options": 
              {
                "filters": {
                  ...filter.searchTags,
                  exclude : []
                },
                "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
                "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
              }
            },
          };
          if(filter.searchId)
            reqObj.data.options.searchId = filter.searchId;
          await MewoService.loadMewoData(reqObj).then(res => {
            if(res){
          
                trackContext.setShowProjectModal(false);
                trackContext.setIsLoading(false);
                trackContext.setTrackData(res.hits);
                trackContext.setTotaltracks(res.total.value);
                let newTracks = [...res.hits];
                let tracksToExclude = newTracks.map((track) => track.ref_id);
                setExcludedTrack(tracksToExclude);
                if (res.hits.length === 0) setNoDataFound(true);
              } else {              
                Helper.handleError(res);
              }
            })
            .catch((err) => {
              toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
            });
        }
      
    }
  
    if((window.location.pathname.startsWith(`/search/track/`)||
     window.location.pathname.startsWith(`/sound-design/search/track/`))&&filter.trackId!==""){
      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": "",
          "options": 
          {
            trackId : filter.trackId,
            "filters": {
              ...filter.searchTags,
              exclude : []
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      await MewoService.loadMewoData(reqObj).then(res => {
        if (res) {
          let newRes = res.hits;
          let [, ...magicWand] = newRes;
          trackContext.setIsLoading(false);
          trackContext.setTrackData(magicWand);
          trackContext.setTotaltracks(res.total.value);
          setShowLoader(false);
        } else {
          Helper.handleError(res);
        }
      }).catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={"Track Loading Error : " + err?.response?.data?.message}/>);
      });
  }

  };

  const getTracks=async()=>{

    if(trackContext.trackFilter.trackId) {
     trackContext.setIsLoading(true);
    }
      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": "",
          "options": 
          {
            "filters": {
              ...trackContext.trackFilter.searchTags
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      if(trackContext.trackFilter.trackId)
        reqObj.data.options.trackId = trackContext.trackFilter.trackId;

      await MewoService.loadMewoData(reqObj).then(res => {
           if (res) { 
             let newRes = res.hits;
                 let [, ...magicWand] = newRes;
             trackContext.setTrackData(magicWand);
             trackContext.setTotaltracks(res.total.value);
             trackContext.setShowLoaderMagic(false);
             trackContext.setIsLoading(false);
             trackContext.setShowMayaLoader(false);
           if (Constants.MEWO_TRACK_SEARCH_BATCH_SIZE > res.hits?.length){
               trackContext.setAllTracksLoaded(true);
           }else
             trackContext.setAllTracksLoaded(false);
             trackContext.setShowLoaderMagic(false);
 
           } else {
             trackContext.setTrackData([]);
             trackContext.setTotaltracks(0);
             trackContext.setShowLoaderMagic(false);
 
             trackContext.setIsLoading(false);
             trackContext.setShowMayaLoader(false);
 
             Helper.handleError(res);
           }
         })
         .catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
         });
     }
 

  const handleChange = (newValue, isSlider) => {
    if (innerDiv.current !== null || outerDiv.current !== null) {
      outerDiv.current.style.maxHeight = "82px";
    }
    if (trackContext.path === "/"||trackContext.path.startsWith(`/search/track/`)) {
      localStorage.setItem("mainmenu_music", JSON.stringify(newValue));
    }
    if (trackContext.path === "/sound-design" ||trackContext.path.startsWith(`/sound-design/search/track/`)) {
      localStorage.setItem("mainmenu_soundfx", JSON.stringify(newValue));
    }

    setValue(newValue);
    setFilterSubMenuchild([]);
    setSubMenu("");
    setIsSlider(isSlider);
    let selectedTag = tags.filter((item) => {
      return item.key === newValue;
    });
    let subtag = selectedTag?.length
      ? selectedTag[0]?.sub_categories?.length
        ? selectedTag[0]?.sub_categories
        : selectedTag[0]?.tags
      : [];
    setFilterSubMenu(subtag);
  };
  const clearSearchdata=(newTrackFilter)=>{  
    if(trackContext.path.startsWith("/search/track/")||
       trackContext.path.startsWith("/search/albums/")||
       trackContext.path.startsWith("/sound-design/search/track/") ||
      trackContext.path.startsWith("/sound-design/search/albums/"))
      {
          clearSearchTag(newTrackFilter)
       }
       if(trackContext.path==="/" ||trackContext.path==="/sound-design"){
       loadTrackData(newTrackFilter)
       }
  }
  const handleSliderChange = Helper.debounce((event, newValue) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    newTags.duration = newValue;
    newTrackFilter.searchTags = newTags;
    setShowLevel1(false)
    setShowLevel2(true)
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter);
  }, 500);

  const handleSliderBPMChange = Helper.debounce((event, newValue) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    newTags.bpm = newValue;
    newTrackFilter.searchTags = newTags;
    setShowLevel1(false)
    setShowLevel2(true)
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter);
  }, 500);
  const removeDuration = () => {
    let newTrackFilter = { ...trackContext.trackFilter };

    let newTags = { ...newTrackFilter.searchTags };
    newTags.duration = [];
    newTrackFilter.searchTags = newTags;
    newTrackFilter.duration = [];
    trackContext.setTrackFilter(newTrackFilter);

    loadTrackData(newTrackFilter);
  };
  const removeBpm = () => {
    let newTrackFilter = { ...trackContext.trackFilter };

    let newTags = { ...newTrackFilter.searchTags };
    newTags.bpm = [];
    newTrackFilter.searchTags = newTags;
    newTrackFilter.bpm = [];
    trackContext.setTrackFilter(newTrackFilter);
    loadTrackData(newTrackFilter);
  };
  const removeSearchText = () => {
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.searchText = "";
    trackContext.setSearchkey("")
    trackContext.setSearchAlbums(false);
    newTrackFilter.fetchList = true;
    if(trackContext.path.includes("/search")){
      if(trackContext.path.includes("/sound-design")){
        history.push("/sound-design")
      }
      else{
        history.push("/")
      }
    }
    newTrackFilter.fetchList = true;
    trackContext.setTrackFilter(newTrackFilter);
    // loadTrackData(newTrackFilter);
    clearSearchdata(newTrackFilter)
  };
  const removeSearchTitle = () => {
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.searchTitle = "";
    newTrackFilter.fetchList = true;
    newTrackFilter.trackId="";
    if(trackContext.path.includes("/search")){
      if(trackContext.path.includes("/sound-design")){
        history.push("/sound-design")
      }
      else{
        history.push("/")
      }
    }
    trackContext.setTrackFilter(newTrackFilter);
    // loadTrackData(newTrackFilter);
    clearSearchdata(newTrackFilter)
  };
  const removeUrl = (e) => {
    e.stopPropagation();
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.url = "";
    newTrackFilter.searchId="";
    newTrackFilter.fetchList = true;
    trackContext.setTrackFilter(newTrackFilter);
    // loadTrackData(newTrackFilter);
    clearSearchdata(newTrackFilter)
  };

  const removeAudioFileName = (e) => {
    e.stopPropagation();
    trackContext.setFileUpload(false);
    trackContext.handleMaiaOnWork(false);
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter.fileName="";
    newTrackFilter.searchId="";
    newTrackFilter.url = "";
    newTrackFilter.fetchList = true;
    trackContext.setTrackFilter(newTrackFilter);
    // localStorage.setItem("filter", JSON.stringify(newTrackFilter));
    loadTrackData(newTrackFilter);
    clearSearchdata(newTrackFilter)
  };
  const handleSlider = (event, newValue, key) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    newTrackFilter[key] = newValue;
    trackContext.setTrackFilter(newTrackFilter);
  };

  const onTagsChange = (event, tagvalue) => {
    if (tagvalue) {
      setValue(tagvalue.parentkey);
      let selectedTag = tags.filter((item) => {
        return item.key === tagvalue.parentkey;
      });
      let subtag = selectedTag.length
        ? selectedTag[0].sub_categories.length
          ? selectedTag[0].sub_categories
          : selectedTag[0].tags
        : [];
      setFilterSubMenu(subtag);
      let selectsubmenu = subtag.filter((item) => {
        return item.id === tagvalue.mainid;
      });
      let submenutag = selectsubmenu.length ? selectsubmenu[0].tags || [] : [];
      setSubMenu(`${tagvalue.mainid}-${tagvalue.mainTag}`);
      setFilterSubMenuchild(submenutag);
      let newTrackFilter = { ...trackContext.trackFilter };
      let newTags = { ...newTrackFilter.searchTags };
      let disSelectedTag = newTrackFilter.selectedTags || [];
      let tags_or = newTags.tags_or || [];
      let contexttags = newTags.tags || [];
      let tags_not = newTags.tags_not || [];
      if (tags_or.some((item1) => item1.includes(tagvalue.id))) {
        tags_not = tags_not.filter((itemnot) => itemnot !== tagvalue.id);
      } else {
        tags_not = tags_not.filter((itemnot) => itemnot !== tagvalue.id);
        contexttags.push(tagvalue.id);
      }
      if (
        !(
          disSelectedTag.includes(
            `${tagvalue.mainid}-${tagvalue.mainTag}-${tagvalue.parentkey}`
          ) || disSelectedTag.includes(`${tagvalue.mainid}-${tagvalue.mainTag}`)
        )
      ) {
        let newfilterMenu = filterMenu;
        newfilterMenu = newfilterMenu?.map((item) => {
          if (item.key === tagvalue.parentkey) {
            item.count = (item.count || 0) + 1;
          }
          return item;
        });
        trackContext.setFilterMenu(newfilterMenu);

        disSelectedTag.push(
          `${tagvalue.mainid}-${tagvalue.mainTag}-${tagvalue.parentkey}`
        );
      }
      newTrackFilter.selectedTags = disSelectedTag;
      newTags.tags_not = tags_not;
      newTags.tags = contexttags;
      newTrackFilter.searchTags = newTags;
      newTrackFilter.url = "";
      newTrackFilter.searchText ="";
      newTrackFilter.searchTitle = "";
      trackContext.setTrackFilter(newTrackFilter);
      loadTrackData(newTrackFilter)
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMenu(false);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
  };

  useEffect(() => {
    if (
      trackContext.path === "/" ||
      trackContext.path === "/inspiration" ||
      trackContext.path.startsWith("/album/") ||
      trackContext.path.startsWith("/playlists/")||
      trackContext.path.startsWith("/artist/") 
    ) {
      // let temp = JSON.parse(JSON.stringify(trackContext.trackFilter));
      let temp = JSON.parse(localStorage.getItem("filter_music") || 'null')
      if(temp){
        temp.searchTags = {};
        trackContext.setTrackFilter(temp)
      }
      // clearSearchTag()
      // removeSearchTag()
      // removeSearchChildTag()
      searchTitle !== "" ? getTracks() : loadTrackData();
    }
  }, [trackContext.path]);

  useEffect(() => {
    if (trackContext.clearData === true) {
      clearSearchTag()
    }
  }, [trackContext.clearData]);

  return (
    <div className="sidebar bg-white w-full sticky" style={{ zIndex: "0" }}>
      <div className="hidden md:flex lg:w-full">
      {trackContext.searchAlbums ? (
          ""
        ) : (
          <div className="md:hidden lg:block  lg:flex sm:flex flex-wrap">
            {filterMenu?.map((item, index) => (
              <div
                key={index}
              className={`relative px-6 mr-2 py-1 text-xs cursor-pointer flex-wrap syncbuttonlight filterplus ${value === item.key
                    ? "bg-ctaRed syncbutton filterselected text-white font-medium"
                : item.count > 0 ? 'syncbutton filterselected' : ''
                }`}
                onClick={() => handleChange(item.key, item.isSlider)}
              >
                {item.value}
                {item.count > 0 && (
                  <div
                    key={index}
                  className={`${value === item.key
                        ? "non-select-menu-count"
                        : "select-menu-count"
                    }`}
                  >
                    {item.count}
                  </div>
                )}{" "}
              </div>
            ))}
          </div>)}
        <div className=" hidden  sm:w-full rounded-full text-sm lg:flex-grow justify-end flex ml-4">
          <Autocomplete
            id="grouped-demo"
            options={Filtertags.sort(
              (a, b) => -b.mainTag.localeCompare(a.mainTag)
            )}
            groupBy={(option) => option.mainTag}
            getOptionLabel={(option) =>
              [...option.names].filter(
                (eachOptions) => eachOptions.locale === "en"
              )[0].value
            }
            style={{ width: 160 }}
            onChange={onTagsChange}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  type="text"
                  //      style={{padding:"5px 5px 5px 15px",background:"#F5F5F5", borderRadius:"30px"}}
                  placeholder="Keywords"
                  {...params.inputProps}
                />
              </div>
            )}
          />
        </div>
      </div>
      <AISearchMobile
        props={props}
        closeProjectModal={closeProjectModal}
        // setShowProjectModal={trackContesetShowProjectModal}
        handleMobileMenuClose={handleMobileMenuClose}
        // handleAISearch={handleAISearch}
        tags={tags}
      />
      <div
        className={`${showMobileFilters ||trackContext?.searchAlbums ? "hidden" : ""
        } lg:hidden greenbuttonwide w-1/2 ml-5 text-white text-md text-center`}
        onClick={handleMobileFilters}
      >
        <button>Add Filters</button>
      </div>
      <div className="lg:hidden  flex flex-wrap mt-3 px-4 lg:px-0">
        {selectedTags?.map((item, index) => {
          return (
            <div
              key={index}
              className="filterselected flex max-h-7 px-4 py-1 m-1 text-xs cursor-pointer filterminus text-white"
              onClick={(e) => {
                e.stopPropagation();
                {!trackContext.loading&& removeSearchTag(
                  item.split("-")[0],
                  item.split("-")[1],
                  item.split("-")[2]
                );
              }}}
            >
              {item.split("-")[1]}
              <CloseModal className="closefilter mt-1 ml-3 " />

              {/* <Close className="filterclose mt-1 ml-1" /> */}
            </div>
          );
        })}
        {duration.length > 0 && (
          <div
            className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
            onClick={removeDuration}
          >Duration <br></br>
            {`${new Date(duration[0] * 1000)
              .toISOString()
              .substr(14, 5)}-${new Date(duration[1] * 1000)
              .toISOString()
              .substr(14, 5)}`}
              <CloseModal className="closefilter mt-3 ml-3 " />

            {/* <Close className="filterclose mt-1 ml-1" /> */}
          </div>
        )}
        {bpm.length > 0 && (
          <div
            className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
            onClick={removeBpm}
          >BPM <br></br>
            {`${bpm[0]}-${bpm[1]}`}
            <CloseModal className="closefilter mt-3 ml-3 " />

            {/* <Close className="filterclose mt-1 ml-1" /> */}
          </div>
        )}
        {searchText && (
          <div
            className="flex px-2 py-1 m-1 max-h-7 text-xs cursor-pointer bg-black text-white"
            onClick={removeSearchText}
          >
            {searchText}
            <CloseModal className="closefilter mt-1 ml-3 " />
          </div>
        )}      
        {url && (
          <div className="flex px-2 py-1 m-1 max-h-7  text-xs cursor-pointer bg-black text-white">
            <a className="break-all" href={url} target="blank">
              {url}
            </a>{" "}

            <CloseModal className="closefilter mt-1 ml-3 "  onClick={removeUrl}/>

            {/* <Close className=" filterclose mt-1 ml-1" onClick={removeUrl} /> */}
          </div>
        )}
        {fileName && (
                  <div className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white">
                    <a  target="blank">
                      {fileName}
                    </a>{" "}
                    <CloseModal
                      className="closefilter mt-1 ml-3"
                      onClick={removeAudioFileName}
                    />
                  </div>
                )}
        {searchTitle?.length > 0 && (
                <div
                    className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
                    onClick={removeSearchTitle}
                  >
                    {searchTitle}
                    <CloseModal className="closefilter mt-1 ml-3" />
                  </div>
                )}  
      </div>
      {showMobileFilters && (
        <MobileFilters
          trackContext={trackContext}
          filterMenu={filterMenu}
          handleMobileFiltersClose={handleMobileFiltersClose}
          tags={props}
          setFilterSubMenu={setFilterSubMenu}
          filterSubMenu={filterSubMenu}
          clearSearchTag={clearSearchTag}
          setMobileFilters={setMobileFilters}
          ValueLabelComponent={ValueLabelComponent}
          ValueLabelBPMComponent={ValueLabelBPMComponent}
          marksDuration={marksDuration}
          marksBpm={marksBpm}
          handleSlider={handleSlider}
          handleSliderBPMChange={handleSliderBPMChange}
          handleSliderChange={handleSliderChange}
          duration={duration}
          bpm={bpm}
          loadTrackData={loadTrackData}
          selectedChildTags={selectedChildTags}
          filterSubMenuChild={filterSubMenuChild}
          addSearchChildTag={addSearchChildTag}
          removeSearchChildTag={removeSearchChildTag}
          searchTags={searchTags}
          showLevel1={showLevel1}
          setShowLevel1={setShowLevel1}
          showLevel2={showLevel2}
          setShowLevel2={setShowLevel2}
          removeSearchTag={removeSearchTag}
          addSearchTag={addSearchTag}
          setValue={setValue}
          value={value}
          handleChange={handleChange}
          setIsSlider={setIsSlider}
          isSlider={isSlider}
          removeDuration={removeDuration}
          removeBpm={removeBpm}
          searchText={searchText}
          removeSearchText={removeSearchText}
          url={url}
          removeUrl={removeUrl}
          fileName={fileName}
          removeAudioFileName={removeAudioFileName}
          searchTitle={searchTitle}
          removeSearchTitle={removeSearchTitle}
        />
      )}
      {isSlider ? (
        <div className=" w-1/2 mt-1 flex-wrap pl-5 hidden lg:flex">
          {value === "duration" ? (
            
            <SliderCustom
              ValueLabelComponent={ValueLabelComponent}
              duration={duration}
              marksDuration={marksDuration}
              key="duration"
              min={0}
              max={600}
              sliderkey="duration"
              onChangeCommitted={handleSliderChange}
              handleSlider={handleSlider}
            />
          ) : (
            <SliderCustom
              ValueLabelComponent={ValueLabelBPMComponent}
              duration={bpm}
              marksDuration={marksBpm}
              key="bpm"
              sliderkey="bpm"
              min={30}
              max={220}
              onChangeCommitted={handleSliderBPMChange}
              handleSlider={handleSlider}
            />
          )}
        </div>
      ) : (
        <div
          ref={outerDiv}
          className="relative flex w-full flex-wrap"
          style={{ maxHeight: "85px", overflow: "hidden" }}
        >
        {trackContext.searchAlbums ?"":
          <div
            className=" sm:flex-wrap bg-white hidden md:hidden lg:block  lg:flex "
            ref={innerDiv}
          >
            {filterSubMenu?.map((item, index) => {
              return (
                <div
                  key={index}
                  // style={{ height: "28px" }}
                  className={`relative flex px-5 mr-2 py-1 my-1 text-xs cursor-pointer syncbuttonlight rounded-full ${selectedTags.includes(
                    `${item.id}-${[...item.names].filter(
                          (eachItem) => eachItem.locale === "en"
                        )[0].value
                      }-${value}`
                    )
                      ? "bg-black text-white syncbutton"
                      : "bg-white filterplus mr-1 "
                  } 
                  } 
                    }                                      
                        `}
                  onClick={() =>
                    addSearchTag(
                      item.id,
                      [...item.names].filter(
                        (eachItem) => eachItem.locale === "en"
                      )[0].value,
                      value
                    )
                  }
                >
                  {
                    [...item.names].filter(
                      (eachItem) => eachItem.locale === "en"
                    )[0].value
                  }{" "}
                  {selectedTags.includes(
                    `${item.id}-${[...item.names].filter(
                        (eachItem) => eachItem.locale === "en"
                      )[0].value
                    }-${value}`
                  ) && (
                    <Close
                      onClick={(e) => {
                        e.stopPropagation();
                        removeSearchTag(
                          item.id,
                          [...item.names].filter(
                            (eachItem) => eachItem.locale === "en"
                          )[0].value,
                          value
                        );
                      }}
                      className="filterclose ml-3"
                    />
                  )}
                  <div>
                    {selectedTags.includes(
                      `${item.id}-${[...item.names].filter(
                          (eachItem) => eachItem.locale === "en"
                        )[0].value
                      }-${value}`
                    )}
                  </div>
                </div>
              );
            })}
          </div>}
          {showEllipsis && (
            <span
              onClick={handleShowMoretags}
              className="w-8 show-more-tags cursor-pointer"
            >
              ...
            </span>
          )}
        </div>
      )}
      {trackContext.searchAlbums ?"":
      <div className="flex w-full flex-wrap hidden lg:flex">
        {filterSubMenuChild?.map((item, index) => (
          <div
            key={index}
            className={`flex px-2 py-1 mx-1 my-1 text-xs text-symTextGrey syncbutton cursor-pointer rounded-full ${(searchTags.tags_or?.some((item1) => item1.includes(item.id)) &&
                !searchTags.tags_not?.includes(item.id)) ||
              searchTags.tags?.includes(item.id)
                ? " px-4 pr-4 bg-symbgGray "
                : "filterplus px-4 pl-6  syncbuttongrey hover:bg-symbgGray hover:scale-75"
            }`}
            onClick={() => {
              addSearchChildTag(
                item.id,
                [...item.names].filter(
                  (eachItem) => eachItem.locale === "en"
                )[0].value,
                item.sub_category_key,
              );
            }}
          >
            {
              [...item.names].filter((eachItem) => eachItem.locale === "en")[0]
                .value
            }{" "}
            {((searchTags.tags_or?.some((item1) => item1.includes(item.id)) &&
              !searchTags.tags_not?.includes(item.id)) ||
              searchTags.tags?.includes(item.id)) && (
              <Close
                onClick={(e) => {
                  e.stopPropagation();
                  removeSearchChildTag(
                    item.id,
                    [...item.names].filter(
                      (eachItem) => eachItem.locale === "en"
                    )[0].value
                  );
                }}
                className="filterclose ml-3 mt-0.5"
              />
            )}
          </div>
        ))}
      </div>}
      {
      !trackContext.path.includes("/album/") && (
        <div className="flex w-full mt-5 border-b">
          {(!trackContext.maiaOnWork && !trackContext.isFileUpload)&&(
          <div className="hidden sm:w-10/12 lg:block">
            {/* <span className="text-base ml-2">
              Filter ({trackContext.totaltracks} results)
            </span> */}
           
            {!trackContext.loading && 
            <button
              onClick={clearSearchTag}
              className="inline-block  navPinkbuttonwide mt-4 lg:mt-0" >
              Clear Filter
            </button>}

            {(!trackContext.loading || !trackContext.showLoaderMagic) && (
            <div className="flex mt-3 pb-4 ">
              {(selectedTags?.length > 0 ||
                duration?.length > 0 ||
                bpm?.length > 0 ||
                searchText?.length > 0 ||
                url?.length > 0 ||
                fileName?.length > 0 ||
                searchTitle?.length > 0) && (
                <b className="text-base ml-2 mt-1">Search Result for:</b>
              )}
              <div className="flex">
                {selectedTags?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex px-2 py-1 m-1 max-h-7 max-w-xs text-xs cursor-pointer syncbutton text-white"
                      onClick={() =>
                        removeSearchTag(
                          item.split("-")[0],
                          item.split("-")[1],
                          item.split("-")[2]
                        )
                      }
                    >
                      {item.split("-")[1]}
                       <CloseModal className="closefilter mt-1 ml-3 " />

                      {/* <Close className="filterclose mt-1 ml-1" /> */}
                    </div>
                  );
                  })

                  }
                {selectedChildTags?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex px-2 py-1 m-1 max-h-7 max-w-xs text-xs cursor-pointer syncbutton text-white "
                      onClick={() =>
                        removeSearchChildTag(
                          item.split("-")[0],
                            item.split("-")[1],
                        )
                      }
                    >
                      {item.split("-")[1]}
                        <CloseModal className="closefilter mt-1 ml-3 " />
                       
                      {/* <Close className="filterclose mt-1 ml-1" /> */}
                    </div>
                    )
                  })}
                </div>
                {duration?.length > 0 && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
                    onClick={removeDuration}
                  >Duration<br></br>
                    {`${new Date(duration[0] * 1000)
                      .toISOString()
                      .substr(14, 5)}-${new Date(duration[1] * 1000)
                        .toISOString()
                        .substr(14, 5)}`}
                        <CloseModal className=" closefilter mt-3 ml-3" />
                  </div>
                )}
                {bpm?.length > 0 && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
                    onClick={removeBpm}
                  >BPM<br></br>
                    {`${bpm[0]}-${bpm[1]}`}
                    <CloseModal className=" closefilter mt-3 ml-3" />
                  </div>
                )}
                {searchText?.length > 0 && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs max-h-7 cursor-pointer bg-black text-white"
                    onClick={removeSearchText}
                  >
                    {searchText}
                    <CloseModal className=" closefilter mt-1 ml-3" />
                  </div>
                )}
                {url?.length > 0 && (
                  <div className="flex px-2 py-1 m-1  max-h-7 text-xs cursor-pointer bg-black text-white">
                    <a href={url} target="blank">
                      {url}
                    </a>{" "}
                    <CloseModal

                      className="closefilter mt-1 ml-3"
                      onClick={removeUrl}
                    />
                  </div>
                )}
                {fileName?.length > 0 && (
                  <div className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white">
                    <a  target="blank">
                      {fileName}
                    </a>{" "}
                    <CloseModal
                      className="closefilter mt-1 ml-3"
                      onClick={removeAudioFileName}
                    />
                  </div>  
                )}
                {searchTitle?.length > 0 && (
                  <div
                    className="flex px-2 py-1 m-1 text-xs cursor-pointer bg-black text-white"
                    onClick={removeSearchTitle}
                  >
                    {searchTitle}
                    <CloseModal className="closefilter mt-1 ml-3" />
                  </div>
                )}
              </div>)}
            </div>

            )}
            {/* <div className="w-2/12 self-end p-3 text-xs" style={{color:"#404040"}} >
                <Checkbox label='My checkbox' 
                        labelstyle={{color: 'black'}}
                        checked={searchTags.stems}
                        onClick={handleStemOnly}
                        iconstyle={{fill: '#8C8C8C'}}
                        inputstyle={{color:'#8C8C8C'}}
                        style={{color:'#8C8C8C'}}/>
                Stems Only
                </div> */}
        </div>
        )
      }
      {trackContext.showLoaderMagic ?
            (
            <div class="modal-busy" overflow="hidden">
              <div>
                <div class="center-busy loader-text-tag" overflow="hidden">
                  <div className="loader-text">
                   This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you
                  </div>
                    <img src={RippleLoader} alt="loading..." className="onMobile img-tag" />

                </div>
              </div>
            </div>
            )
            : (
            ""
          )}
    </div >

  );
}

export default Sidebar;
