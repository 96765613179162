import React, { useContext } from 'react';
import './style.css';
import Constants from '../../services/constants';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';
import TrackContext from '../../contexts/track-context';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CookieManager from '../../services/CookieManager';



export default function SubscribePopup(props) {
  const history = useHistory();
  let trackContext = useContext(TrackContext);
  const userData = JSON.parse(CookieManager.getCookie("user-data") || "null");
  return (
    <div className='flex fixed top-0 h-screen w-full subscribe-modal justify-center' style={{zIndex: '9'}}>
      {(userData && userData?.userType !== 'FREE' && !userData?.suscribed) ? 
      <div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' 
            onClick={() => {
              trackContext.setShowSubscribePopup(false)
            }}
          />
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
          <h1 className='text-lg uppercase font-semibold py-2'>Your subscription got expired.</h1>
          <h3 className='text-sm' style={{color: '#8C8C8C'}}>Please renew to continue the {props.action} service</h3>
          <p className='pt-6 pb-12 text-sm'>
          Become a subscribed member to access this and other features on our app. Affordable monthly and yearly plans are available for individuals and companies. Check our pricing page for more information.
          </p>
        </div>
        <div className='flex flex-wrap justify-center pt-6 pb-8'>
          <div className='w-full text-center'>
            <a href={process.env.REACT_APP_MKT_SITE_PRICING} className='get-started' >
            Renew Your Subscription
            </a>
          </div>
        </div>
      </div>
    :
     trackContext.userDetails.userType === 'FREE'?
      <div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
          <CloseModal className='cursor-pointer' 
            onClick={() => {
              trackContext.setShowSubscribePopup(false)
            }}
          />
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
          <h1 className='text-lg uppercase font-semibold py-2'>Subscribe To {props.action}</h1>
          <h3 className='text-sm' style={{color: '#8C8C8C'}}>Premium Feature</h3>
          <p className='pt-6 pb-12 text-sm'>
          Become a subscribed member to access this and other features on our app. Affordable monthly and yearly plans are available for individuals and companies. Check our pricing page for more information.
          </p>
        </div>
        <div className='flex flex-wrap justify-center pt-6 pb-8'>
          <div className='w-full text-center'>
            <a href={process.env.REACT_APP_MKT_SITE_PRICING} className='get-started' >
            Subscribe Now
            </a>
          </div>
          {
            trackContext.userDetails.email === undefined &&
            <div className='w-full text-center text-sm text-gray-500 pt-4'>
              Already a member?
              <Link 
                onClick={() => trackContext.setShowSubscribePopup(false)}
                className='text-ctaRed' 
                to='/signin'>
                {' Sign In'}
              </Link>
            </div>
          }
        </div>
      </div>
      :<div className='self-center w-full sm:w-2/5 shadow-xl' style={{background: 'rgba(255,255,255, 0,5)'}}>
        <div className='flex justify-end px-10 pt-10'>
        
          <CloseModal className='cursor-pointer' 
            onClick={() => {
              trackContext.setShowSubscribePopup(false)
            }}
          />
        </div>
        <div className='border-b border-gray-300 px-10 pt-2'>
          <h1 className='text-lg uppercase font-semibold py-2'>CREATE A FREE ACCOUNT</h1>
          <p className='pt-6 pb-2 text-sm'>Create a free account to access all features on our site, except download
          </p>
          <p className='pb-12 text-xs' style={{color : 'green'}}>No card details required
          </p>
        </div>
        <div className='flex flex-wrap justify-center pt-6 pb-8'>
          <div className='w-full text-center'>
            <a href={process.env.REACT_APP_MKT_SITE_PRICING} className='get-started' >
            Create A Free Account
            </a>
          </div>
          {
            trackContext.userDetails.email === undefined &&
            <div className='w-full text-center text-sm text-gray-500 pt-4'>
              Already a member?
              <Link 
                onClick={() => trackContext.setShowSubscribePopup(false)}
                className='text-ctaRed' 
                to='/signin'>
                {' Sign In'}
              </Link>
            </div>
          }
        </div>
      </div>
     }
    </div> 
  )
}