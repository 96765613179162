import axios from "axios";
import CookieManager from "./CookieManager.js";
import Helper from "./helper.js";
import Constants from "./constants.js";
import MewoService from "./mewoService.js";

const xAccessTokenOne = CookieManager.getCookie("x-access-token");
// console.log({xAccessTokenOne});

const getTracks = async () => {
  const xAuth = "";
  const URL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_PUBLIC + process.env.REACT_APP_SEARCH;
  let query = {
    query: "",
    options: { filters: { exclude: [] }, size: 30, search_descriptions: false },
  };
  return await axios({
    method: "post",
    url: URL,
    headers: {
      "x-host": "tele-music.mewo.io",
      "x-auth": xAuth,
      "x-preferred-language": "en",
      "Content-Type": "application/json",
    },
    data: JSON.stringify(query),
  })
    // axios.get(URL)
    .then(function (response) {
      // handle success
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      // handle error
      // console.log(error);
    })
    .finally(function () {
      // always executed
    });
};

const playTrack = async (trackId) => {
  let reqObj = {
    url : `public/play/track/${trackId}`, 
    method : 'get'
  };

  return await MewoService.loadMewoData(reqObj).then((res) => {
    if(res){
      //Track data to store
      let trackData = {
          mewoTrackId: res.id,
          mewoTrackUrl: res.audiofile,
          title: res.display_title,
          duration: res.duration,
          album: res.album,
          waveform: res.album
        }; 
      addTrackToPlayHistory(trackData);
      return {data : {data : res, success : true}}
    }
  }).catch((err) => {
    return {data : {...err.response, message : `Error Loading Track : ${err.response.data.message}`}}
  })
  
};

const addTrackToPlayHistory = async (trackData) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  if(!email){
    console.log(`Can't add played track : "${trackData.title}" to play history as user not logged-in`);
    return;
  }
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = `${process.env.REACT_APP_BASE_URL}api/users/play-history`;
  
  return await axios({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": xAccessToken,
    },
    data : JSON.stringify({email, trackData}),
    maxContentLength: Infinity,
    maxBodyLength: Infinity,

  }).then(resp => {
    // console.log("play history resp data : " + resp)
    return resp.data ? resp.data : resp;
  })
  .catch(err => {
    console.log("Error adding track to play history : "+ err);
  });
}
const createStripeSubscription = async (userData) => {
  //encoding password field
  if(userData?.password?.trim().length){
    userData.password = Helper.encode(userData.password);
  }
  if(userData?.confirmPassword?.trim().length){
    userData.confirmPassword = Helper.encode(userData.confirmPassword);
  }
  let url = process.env.REACT_APP_BASE_URL + "api/users/create-stripe-subscription";
  let response = await fetch(url, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  });
  return response.json();
};

const createMewoUser = async (userData) => {
  // console.log('[createUser called] : ', userData);
  //encoding password field
  if(userData?.password?.trim().length){
    userData.password = Helper.encode(userData.password);
  }
  if(userData?.confirmPassword?.trim().length){
    userData.confirmPassword = Helper.encode(userData.confirmPassword);
  }
  let url = process.env.REACT_APP_BASE_URL + "api/users/create-mewo-user";
  let response = await fetch(url, {
    method: "post",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  });
  return response.json();
};

const signIn = async (userData) => {
  //encoding password field
  if(userData?.password?.trim().length){
    userData.password = Helper.encode(userData.password);
  }
  CookieManager.setCookie("x-auth", "");
  CookieManager.setCookie("x-access-token", "");
  let url = process.env.REACT_APP_BASE_URL + "api/users/signin";
  let response = await axios({
    url: url,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: userData,
  }).then((res) => {
    return res;
  }).catch((err) => {
    return err?.response;
  });
  if (response?.data?.success) {
    // console.log({ signin : response});
    CookieManager.setCookie("x-auth", response.headers["x-auth"]);
    CookieManager.setCookie(
      "x-access-token",
      response.headers["x-access-token"]
    );

    // console.log('token access: ', JSON.stringify(response.headers));

    const {
      first_name,
      last_name,
      email,
      _id,
      favorites,
      userType,
      subscribed,
      productDesc,
      admin,
      totalLicenseCount,
      companyName,
      taxNumber,
      isSuspended,
      renewalDate,
      stripeCustomerId,
      subscriptionStatus
    } = response.data.data;
    localStorage.setItem("favs", JSON.stringify(favorites));
    CookieManager.setCookie(
      "user-data",
      JSON.stringify({
        first_name,
        last_name,
        email,
        _id,
        userType,
        subscribed,
        productDesc,
        admin,
        totalLicenseCount,
        companyName,
        taxNumber,
        isSuspended,
        renewalDate,
        stripeCustomerId,
        subscriptionStatus
      })
    );
    CookieManager.setCookie("last-seen", Date.now());

    if(userType !== "SUPER_ADMIN"){
      if (response.data.data?.voicegen_config !== undefined &&
      response.data.data?.voicegen_config !== null) {
        localStorage.setItem(
          "voicegen-TTS-text",
          response.data.data.voicegen_config.ssml
        );
        localStorage.setItem(
          "voicegen-selected-language",
          response.data.data.voicegen_config.language
        );
        localStorage.setItem(
          "voicegen-selected-voice",
          response.data.data.voicegen_config.voice
        );
      }

      let promiseData = await Promise.all([
        getFavoriteTracks(),
        getDownloadTrackList(),
      ]);
      let res = promiseData[0] || [];
      let resdownLoad = promiseData[1] || [];

      // console.log('fav res : ', res)
      if (res.success) {
        let favs = res.data.map((tracks) => {
          return tracks.trackId.mewoTrackId;
        });

        localStorage.setItem("favs", JSON.stringify(favs));
      }
        // console.log('download arr =>',resdownLoad)
      if(resdownLoad.success){
        let mewoTrackId = [];
        Object.keys(resdownLoad.data).map((key) => {
          if (key === "mewoTrackIdList") {
            mewoTrackId = resdownLoad.data[key];
          }
        });

        // console.log('Mewo Track Id: ',mewoTrackId);
        // let download1 = resdownLoad?.data?.map(tracks => {
        //   return tracks.trackId.mewoTrackId
        // });

        localStorage.setItem("download", JSON.stringify(mewoTrackId));
      }
    }
    //after login, check for "target-location" in local storage, if there is anything, redirect to that
    let targetLocation = CookieManager.getCookie("target-location");
    if(targetLocation?.length){
      window.location = targetLocation;
      CookieManager.deleteCookie("target-location");
    }else if(userType === "SUPER_ADMIN" || userType === "ADMIN")
      window.location.pathname = "/admin";
    else
      window.location.pathname = "/projects";


    /* getFavoriteTracks().thenfiltersres => {
      if (res.success) {
      let favs = res.data.map(tracks => {
          return tracks.trackId.mewoTrackId
        });

        localStorage.setItem('favs', JSON.stringify(favs));
        window.location.pathname = '/projects';

      }
    });*/

    return response.data;
  } else {
    // window.location.pathname = '/signin';
    return response.data;
  }
};

const searchTracks = async (searchText = "", searchId="",tags = {}, exclude = [], searchDesc = false, artistIds = []) => {
  // console.log(' Before Search Track ID: ', searchText);

  let url = process.env.REACT_APP_BASE_URL + "api/track/search";
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let filter = {
    ...tags,
    exclude: exclude,
  };
  if(artistIds?.length > 0)
    filter["artists"] = artistIds
  let body = {
    searchId,
    searchText,
    filters: filter,
    size: process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE,
    search_descriptions : searchDesc
  };
  // console.log('Body:-',body);
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const getPlaylist = async (searchText = "", playlists = "", exclude = []) => {
  // console.log(' Before Search Track ID: ', searchText);

  let url = process.env.REACT_APP_BASE_URL + "api/track/search";
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let body = {
    searchText,
    filters: {
      "playlists": [playlists],
      exclude: exclude,
    },
    size: 70,
  };
  // console.log('Body:-',body);
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const getSimilarTracks = async (trackId,tags = {},desc) => {
  let url = process.env.REACT_APP_BASE_URL + "api/track/search";
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let body = {
    searchText: "",
    filters: {
      exclude: [],
      ...tags,
    },
    trackId: trackId,
    // size: 30,
    size: process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE,
    search_descriptions: desc,
  };
  // console.log('Body:-',body);
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    body: JSON.stringify(body),
  });
  return response.json();
};

const getAllTags = async () => {
  let url = process.env.REACT_APP_BASE_URL + "api/track/get-all-tags";
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let response = await fetch(url, {
    method: "post",
    headers
  });
  return response.json();

};

const activateUser = async (token) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/activate/" + token;
  let response = await fetch(url, {
    method: "get",
  });
  return response.json();
};

const createProject = async (project) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/project/create";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ project, email }),
  });
  return response.json();
};

const getProjects = async (pageNum, pageSize, searchText) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/project/get-all-projects";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": xAccessToken,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
    body: JSON.stringify({ email, pageNum, pageSize, searchText }),
  });
  return response.json();
};

const addTrackToProject = async (trackId, projectId) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "x-access-token": xAccessToken,
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = process.env.REACT_APP_BASE_URL + "api/project/add-track/" + projectId;
  let response = await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify({ trackId }),
  });
  return response.json();
};

const addTrackToFavorites = async (trackId) => {
  
  let { email, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/favorites/${trackId}`;
  // let url = process.env.REACT_APP_BASE_URL + 'api/users/favorites/' + trackId;
  let response = await axios({
    url: url,
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": xAccessToken,
    },
    data: JSON.stringify({ trackId, email }),
  });
  return response.data;
};

const getFavoriteTracks = async (pageNum, pageSize, searchText) => {

  let { email, userType } = JSON.parse(
    CookieManager.getCookie("user-data") || "{}"
  );
  let xAccessToken = CookieManager.getCookie("x-access-token");
  // console.log({userType});
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/favorites`;
  // let url = process.env.REACT_APP_BASE_URL + 'api/users/favorites';
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ email, pageNum, pageSize, searchText }),
  });
  return response.json();
};

const getDownloadTracks = async (trackId) => {
  let reqObj = {
    url : `public/tracks/${trackId}/files`,
    method : 'get'
  };

  return await MewoService.loadMewoData(reqObj).then(res => {
    return res;
  }).catch(err => {
    return {...err.response.data, message : `Error Creating Download Link : ${err.response.data.message} `};
  })
};
const downloadTrackArchive = async (reqData) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = `${process.env.REACT_APP_BASE_URL}api/track/download-archive`;

  let response = await fetch(url,{

    method: "post",
    headers: headers,
    body : JSON.stringify(reqData)
  });
  return response.json();
};

const downloadArchiveTrack = async (trackId, quality, projectId = null) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = `${process.env.REACT_APP_BASE_URL}api/track/archive-track`;
  return await axios(url, {
    method: "Post",
    headers: headers,
    data: JSON.stringify({
      context: "track",
      documentIds: [trackId],
      quality: quality,
      withVersions: true,
      projectId : projectId
    }),
  })
  .then((response)=>{
    return response?.data?.data
  })
  .catch((error)=>{
    console.log(error)
  })
};

const getHistoryTracks = async (pageNum, pageSize, searchText) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let { email, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/history`;
  // let url = process.env.REACT_APP_BASE_URL + 'api/users/history';
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ email, pageNum, pageSize, searchText }),
  });
  // console.log({response});
  return response.json();
};

const downloadLicenseUrl = async (key) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let { email, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = `${process.env.REACT_APP_BASE_URL}api/license/download-license/${key}`;

  let response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
  }).then(function(res) {
    if (res.status === 200) {
      res.json().then(function(resbody){
        window.open(resbody.signedUrl, '_blank');
      });
    }
    else {
      res.json().then(function(resbody){
        //@Todo: Handle this better by using react-toastify.
        console.log(resbody?.message);
      });
    }
  })
};

const getDownloadTrackList = async (searchText = "") => {
  const xAuth = CookieManager.getCookie("x-auth");

  let { email, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/downloads/list`;
  // let url = process.env.REACT_APP_BASE_URL + 'api/users/downloads/list';
  //  console.log('Path:', url);
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ email, searchText }),
  });
  return response.json();
};

const setDownloads = async (trackId) => {
  const xAuth = CookieManager.getCookie("x-auth");

  const xAccessToken = CookieManager.getCookie("x-access-token");
  let { email, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/downloads`; //
  //  let url = process.env.REACT_APP_BASE_URL + 'api/users/downloads';
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ email, trackId }),
  });
  return response.json();
};

const keywordsAndMaiaSearch = async (keywords) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = process.env.REACT_APP_BASE_URL + "api/track/search/url";
  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    body: JSON.stringify({keywords: keywords }),
  });
  return response.json();
};

const searchTracksByMaiaId = async (id) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = process.env.REACT_APP_BASE_URL + "api/track/search";

  let body = {
    searchText: "",
    filters: { exclude: [] },
    searchId: id,
    size: process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE,
    search_descriptions: false,
  };

  let response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    body: JSON.stringify(body),
  }).then((res) => res.json());
  return response;
};

const createLicense = async (projectId) => {
  // console.log("createLicense")
  const xAuth = CookieManager.getCookie("x-auth");

  let { email } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/license";
  let response = await axios({
    method: "post",
    url: url,
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    data: { email, projectId },
  }).then((res) => {
    // console.log(res);
    return res;
  });
  // console.log(response);

  return response.data;
};

const generatePdf = async (data, detail) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let { email } = JSON.parse(CookieManager.getCookie("user-data"));
  let xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/license/generate-pdf";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ pdf: data, detail }),
  });
  // console.log({response});
  return response.json();
};

const createStripeUser = async (email) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/stripe";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email }),
  });
  // console.log({response});
};

const deleteTrackFromProject = async (trackId, projectId) => {
  const xAuth = CookieManager.getCookie("x-auth");

  const xAccessToken = CookieManager.getCookie("x-access-token");
  let url = `${process.env.REACT_APP_BASE_URL}api/project/${projectId}/${trackId}`;
  let response = await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
  });
  return response.json();
};
const deleteProject = async (projectId) => {
  const xAuth = CookieManager.getCookie("x-auth");

  const xAccessToken = CookieManager.getCookie("x-access-token");
  let url = `${process.env.REACT_APP_BASE_URL}api/project/${projectId}`;
  let response = await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
  });
  return response.json();
};
const updateProject = async (projectId, obj) => {
  const xAuth = CookieManager.getCookie("x-auth");

  const xAccessToken = CookieManager.getCookie("x-access-token");
  let url = `${process.env.REACT_APP_BASE_URL}api/project/${projectId}`;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};

const getAlternateVersions = async (trackId) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = `${process.env.REACT_APP_BASE_URL}api/track/get/${trackId}/versions`;
  let response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
  });
  return response.json();
};

const getAllAlbumTracks = async (albumId, pageNo = 0) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = `${process.env.REACT_APP_BASE_URL}api/track/get/${albumId}/tracks?page=${pageNo}&max=${Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE}`;
  let response = await fetch(url, {
    headers: headers,
  });

  return response.json();
};

const getAllArtistTracks = async (artistId) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = `${process.env.REACT_APP_BASE_URL}api/track/get/artist/${artistId}`;
  let response = await fetch(url, {
    headers: headers,
  });

  return response.json();
};

const getAlbumDetails = async (albumId) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = `${process.env.REACT_APP_BASE_URL}api/track/get/${albumId}`;
  let response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
  });

  return response.json();
};

const getProducts = async () => {
  let url = `${process.env.REACT_APP_BASE_URL}api/product/`;
  let response = await fetch(url);
  // let resJSON = response.json()

  // console.log('[ All Products ]:=>', response);

  return response.json();
};

const getUser = async () => {
  //const xAuth = CookieManager.getCookie("x-auth");
  const xAuth = CookieManager.getCookie("x-access-token");
  let userData = CookieManager.getCookie("user-data");
  if (userData !== undefined && userData !== null && userData !== "null") {
    let { _id, userType } = JSON.parse(CookieManager.getCookie("user-data"));
    let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
    //user profile returning from disk cache, to get current profile data, disabling cache
    let url = `${process.env.REACT_APP_BASE_URL}api/${path}/profile/${_id}`;
    let response = await fetch(url, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": xAuth,
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
    });
    // let resJSON = response.json()
    // console.log({resJSON});
    return response.json();
  }else{
    return {success : false, mssg : "user data not found on client side, please log-out and login again! "};
  }
};


const getAdminByEmail = async (email) => {
  const xAuth = CookieManager.getCookie("x-auth");
  let url = `${process.env.REACT_APP_BASE_URL}api/admin/user/${Helper.encode(email)}`;
  //let url = userType.toLowerCase()=="admin" ? process.env.REACT_APP_BASE_URL + 'api/admin/profile/' + _id : process.env.REACT_APP_BASE_URL + 'api/users/profile/' + _id;
  let response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    }
  });
  return response.json();
};

const updateUser = async (obj) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let { _id, userType } = JSON.parse(CookieManager.getCookie("user-data"));
  let path = userType?.toLowerCase() === "admin" || userType?.toLowerCase() === 'super_admin' ? "admin" : "users";
  let url = `${process.env.REACT_APP_BASE_URL}api/${path}/profile/${_id}`;
  //let url = userType.toLowerCase()=="admin" ? process.env.REACT_APP_BASE_URL + 'api/admin/profile/' + _id : process.env.REACT_APP_BASE_URL + 'api/users/profile/' + _id;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};
const getAISearchPanel = async (query, desc, pageNo,) => {
  const xAuth = CookieManager.getCookie("x-auth");
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = `${process.env.REACT_APP_BASE_URL}api/track/panel`;
  return await axios(url, {
    method: "post",
    headers: {
      "x-host": process.env.REACT_APP_X_HOST,
      'x-auth': xAuth,
      "x-preferred-language": "en",
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    data: JSON.stringify({
      query,
      albums: { page: pageNo, max: 70, search_descriptions: desc },
      // "catalogs":{"page":0,"max":10,"search_descriptions":false},
      //"playlists":{"page":0,"max":10,"search_descriptions":false},
      //"artists":{"page":0,"max":10,"search_descriptions":false},
      //  "labels":{"page":0,"max":10,"search_descriptions":false},
      //    "publishers":{"page":0,"max":10,"search_descriptions":false}
    }),
  })
  .then((response)=>{
    return response?.data?.data
  })
  .catch((error)=>{
    console.log(error)
  })
};

const getAISearchTrack = async (query,desc) => {
  const xAuth = CookieManager.getCookie("x-auth");
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = `${process.env.REACT_APP_BASE_URL}api/track/track`;
  return await axios(url, {
    method: "post",
    headers: {
      "x-host": process.env.REACT_APP_X_HOST,
      'x-auth': xAuth,
      "x-preferred-language": "en",
      "Content-Type": "application/json",
      "userId" : email?.length > 0 ? Helper.encode(email) : ""
    },
    data: JSON.stringify({
      query,
      options: { size: 10, search_descriptions: desc },
    }),
  })
  .then((response)=>{
    return response?.data?.data
  })
  .catch((error)=>{
    console.log(error)
  })
};
const getAISearchTag = async (query) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_MEWO_URL}public/search/tags`;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "x-host": process.env.REACT_APP_X_HOST,
      "x-auth": xAuth,
      "x-preferred-language": "en",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, max: 10, tags: [] }),
  });
  return response.json();
};

const sendForgotPasswordEmail = async (email) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/forgot-password/" + email;
  let response = await axios({
    method: "get",
    url: url,
  });
  // console.log(response);
  return response;
};

const setNewPassword = async (resetCode, password) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/set-password";
  let response = await axios({
    method: "post",
    url: url,
    data: { resetCode, password },
  });
  // console.log(response);
  return response;
};

const forgotPasswordLinkValidation = async (code) => {
  // console.log('CODE : ', code);
  let url = process.env.REACT_APP_BASE_URL + "api/users/set-pwd-link-validity/" + code;
  let response = await axios({
    method: "GET",
    url: url,
  });
  // console.log('forgotPasswordLinkValidation 1: ', response)
  return response;
};

const getBillingInfo = async (_id) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/billing-info/" + _id;
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let response = await axios({
    method: "GET",
    url: url,
    headers: {
      "x-access-token": xAccessToken,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  });
  // console.log('billing info 1: ', response)
  return response;
};

const createSubscription = async (
  productId,
  paymentMethodId,
  customerId,
  taxId
) => {
  // console.log('taxId : ', taxId)
  let url = process.env.REACT_APP_BASE_URL + "api/users/create-subscription";
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let response = await axios({
    method: "POST",
    url: url,
    headers: {
      "x-access-token": xAccessToken,
    },
    data: { productId, paymentMethodId, customerId, taxId },
  });
  // console.log('billing info 1: ', response)
  return response;
};

const getAdminList = async () => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/admin/list/";
  let response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
      'Cache-Control': 'no-cache',
    },
  });
  return response.json();
};
const createAdmin = async (obj) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/admin/";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // 'x-auth': xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};
const deleteAdmin = async (id) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/admin/profile/${id}`;
  let response = await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    //  body: JSON.stringify({...obj})
  });
  return response.json();
};
const getUserList = async (query, searchText) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/users/list";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ ...query, searchText }),
  });
  return response.json();
};

const getLicenses = async (filter) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  const url = `${process.env.REACT_APP_BASE_URL}api/license/list`;
  
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify(filter),
  });
  return response.json();
};

const loadProjects = async (filter) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  const url = `${process.env.REACT_APP_BASE_URL}api/project/list`;
  
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify(filter),
  });
  return response.json();
};

const createCorporateAdmin = async (obj) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/users/corporate";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // 'x-auth': xAuth,
      // 'x-access-token':xAccessToken
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};
const createCorporateUser = async (obj) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/users/corporateuser";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      // 'x-auth': xAuth,
      // 'x-access-token':xAccessToken
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};

const getCorpUser = async (id) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/users/${id}`;
  let response = await fetch(url, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
  });
  return response.json();
};

const suspendUser = async (id, obj) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/users/suspend/${id}`;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};
const deleteUser = async (id) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/users/${id}`;
  let response = await fetch(url, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    //  body: JSON.stringify({...obj})
  });
  return response.json();
};

const suspendAdmin = async (id, obj) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/admin/suspend/${id}`;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};

const getUserAggregate = async (query) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/users/aggregate";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ filter: query }),
  });
  return response.json();
};
const getLicAggregate = async (query) => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/license/aggregate";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
    },
    body: JSON.stringify({ filter: query }),
  });
  return response.json();
};
const cancelSubscription = async (subscriptionId, customerId) => {
  let url =
    process.env.REACT_APP_BASE_URL +
    "api/users/cancel-subscription/" +
    subscriptionId +
    "/" +
    customerId;
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let response = await axios({
    url: url,
    method: "delete",
    headers: {
      "x-access-token": xAccessToken,
    },
  }).then(res => res)
  .catch(err => {
    return err.response;
  });

  // console.log('[res after delete ] : ', response);
  return response;
};

const getInvoices = async (customerId) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/invoices/" + customerId;
  const xAccessToken = CookieManager.getCookie("x-access-token");
  return await axios({
    url: url,
    method: "get",
    headers: {
      "x-access-token": xAccessToken,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  })
  .then(function (response) {
    // handle success
    //console.log(response);
    return response;
  })
  .catch(function (error) {
    // handle error
    //console.log(error);
    return error?.response;
  });
};

const updatePaymentMethod = async (email, paymentMethodId) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/users/payment-method/update";
  let response = await axios({
    url: url,
    method: "post",
    headers: {
      "x-access-token": xAccessToken,
    },
    data: { email, paymentMethodId },
  });
  // console.log('[res after getting invoices ] : ', response);
  return response;
};

const updateBillingDetail = async (paymentMethodId, billingDetails) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  const url = process.env.REACT_APP_BASE_URL + "api/users/billing-info/" + paymentMethodId;
  let response = await axios({
    url: url,
    method: "put",
    headers: {
      "x-access-token": xAccessToken,
    },
    data: { billingDetails },
  });
  // console.log('[res after getting invoices ] : ', response);
  return response;
};

const updateGST = async (id, GST) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  const url = process.env.REACT_APP_BASE_URL + "api/users/profile/" + id;
  let response = await axios({
    url: url,
    method: "post",
    headers: {
      "x-access-token": xAccessToken,
    },
    data: { GST },
  });
  // console.log('[res after getting invoices ] : ', response);
  return response;
};
const updateCorpUser = async (obj) => {
  const xAuth = CookieManager.getCookie("x-auth");

  let url = `${process.env.REACT_APP_BASE_URL}api/users/profile/${obj._id}`;
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "x-auth": xAuth,
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};
const createHubspotTicket = async (obj) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/ticket";
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...obj }),
  });
  return response.json();
};

const getCorpUserBillingInfo = async (id) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  // console.log('user id : ', id);
  let url = `${process.env.REACT_APP_BASE_URL}api/users/renewal-date/corporate/${id}`;
  let response = await axios({
    url: url,
    method: "get",
    headers: {
      "x-access-token": xAccessToken,
    },
  });
  // console.log('[corp user billing] : ', response);
  return response.data;
};

const updateUserCookie = async () => {
  let User = await getUser();
  // console.log({UserUpdated: User});
  let subscribed = User?.data?.subscribed;
  let isSuspended = User?.data?.isSuspended;
  if (User?.data?.userType!== "ADMIN" && isSuspended) {
    Helper.signOut();
  }
  // console.log({subscribed});
  let tempUserCookie = JSON.parse(CookieManager.getCookie("user-data"));
  tempUserCookie.subscribed = subscribed;
  tempUserCookie.isSuspended = isSuspended;
  // console.log('------ tempUserCookie -------', tempUserCookie);
  CookieManager.setCookie("user-data", JSON.stringify(tempUserCookie));
  return User;
};

const getTaxRate = async (countryName, countryTaxName) => {
  // let country = 'India';
  // let taxName = 'GST';
  // console.log('Inside Tax rates:', countryName, '=>', countryTaxName);
  let url = `${process.env.REACT_APP_BASE_URL}api/tax/${countryName}/${countryTaxName}`;
  // let url = `${process.env.REACT_APP_BASE_URL}api/tax/${country}/${taxName}`;
  let response = await axios({
    url,
    method: "GET",
  });

  return response.data;
};

const saveConfig = async (language, text, voice) => {
  let var2 = {
    ssml: text,
    language: language,
    voice: voice,
  };
  // console.log(var2);
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let url = process.env.REACT_APP_BASE_URL + "api/voicegen/save";
  console.log(url);
  let response = await fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: xAccessToken,
    },
    body: JSON.stringify(var2),
  });
  //console.log("[res after getting saveConfig ] : ", response.json());
  return response.json();
};

const createPaymentIntent = (amount, currency) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/payment-intent";

  return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({amount: amount, currency: currency})
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw new Error("PaymentIntent API Error");
      } else {
        return data.client_secret;
      }
    });
};

const getProductDetails = options => {
  console.log(options);
  let url = process.env.REACT_APP_BASE_URL + "api/users/product-details";

  return fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(options)
    })
    .then(res => {
      if (res.status === 200) {
        return res.json();
      } else {
        return null;
      }
    })
    .then(data => {
      if (!data || data.error) {
        console.log("API error:", { data });
        throw Error("API Error");
      } else {
        return data;
      }
    });
};

const validateSubscription = async (userId, userType) => {
  let url = process.env.REACT_APP_BASE_URL + "api/users/validate-subscription/" + userId + `/${userType}`;  
  const xAccessToken = CookieManager.getCookie("x-access-token");
  return await axios({
    url: url,
    method: "get",
    headers: {
      "x-access-token": xAccessToken,
    },
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    //console.log(error);
    return error?.response?.data;
  });
};

//not in use
const uploadAudioFile = async (data,onUploadProgress,source ) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = process.env.REACT_APP_BASE_URL + "api/track/upload"
  return await axios({
    url:url,
    method: "post",
    headers: headers,
    data : data,
    onUploadProgress,
    cancelToken:source.token
  }).then(function (response) {
    // handle success
    return response?.data;
  })
  .catch(function (error) {
    if (axios.isCancel(error)) {
      return error;
    }
    return error?.response?.data
  });
};

const uploadAudioFileToMEWO = async (data,onUploadProgress,source ) => {
  let headers =  {
    "x-host" : process.env.REACT_APP_X_HOST,
    "Content-Type": "application/json",
    "x-auth" : CookieManager.getCookie("x-auth")
  };
  let url = process.env.REACT_APP_MEWO_URL + "public/search/upload"
  return await axios({
    url:url,
    method: "post",
    headers: headers,
    data : data,
    onUploadProgress,
    cancelToken:source.token
  }).then(function (response) {
    // console.log("file upload response : ",response)
    return response;
  })
  .catch(function (error) {
    // console.log("file upload error : ", error.response)
    if (axios.isCancel(error)) {
      return error;
    }
    return error?.response;
  });
};

// const voice=async()=>{
//   let url= `${process.env.REACT_APP_VOICEGEN_URL}`;
//   let response = await axios({
//     url,
//     method: 'POST'
//   });
//   console.log(response,'voicegen')
// }

const getAllPlaylist=async()=>{
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url =  process.env.REACT_APP_BASE_URL + 'api/track/playlists';
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  return await axios({
    url: url,
    method: "post",
    headers:headers,
    data:JSON.stringify({
      "query" : "",
    "playlists" :
     {
    "page": 0,
    "max": 100
    }})
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  });
}

const getOnePlaylist=async(id)=>{
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url =  process.env.REACT_APP_BASE_URL + `api/track/get-playlist/${id}`;
  
  return await axios({
    url: url,
    method: "get",
    headers:headers
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  });
}

const getPlaylistDetails=async(id)=>{
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url =  process.env.REACT_APP_BASE_URL + `api/track/playlist-details/${id}`;
  
  return await axios({
    url: url,
    method: "get",
    headers: headers
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  });
}


const getPollyVoices = async (langCode) => {
  var url = process.env.REACT_APP_BASE_URL + `api/voicegen/voices${langCode?.length ? ("/" + langCode) : ""}`;
  return await axios({
    url : url,
    method : "get",
    headers: {
      'Content-Type': 'application/json',
    }
  }).then((resp) => {
    return resp?.data;
  }).catch((err)=>{
    return err?.response?.data;
  })
}

const synthesizeText = async(body) => {
  let url = process.env.REACT_APP_BASE_URL + `api/voicegen/synth`;
  return await axios.post(url, body, {
    headers: {
      Authorization: CookieManager.getCookie("x-access-token"),
    },
  }).then((res) => {
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    throw err.response.data;
  })
}

const getPlayAlbumTracks=async(id)=>{
  const xAuth = CookieManager.getCookie("x-auth");
  let url =  process.env.REACT_APP_BASE_URL + `api/track/albumTracks/${id}`;
  return await axios({
    url: url,
    method: "get",
    headers: {
      'Content-Type': 'application/json',
      "x-host": process.env.REACT_APP_X_HOST,
      "x-auth":xAuth
    }
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  });
}

const getPlayListTracks=async(id)=>{
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url =  process.env.REACT_APP_BASE_URL + `api/track/playListTracks/${id}`;
  return await axios({
    url: url,
    method: "get",
    headers: headers
  }).then(function (response) {
    // handle success
    //console.log(response);
    return response?.data;
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  });
}

const getTotalLicenseCount = async () => {
  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");

  let url = process.env.REACT_APP_BASE_URL + "api/license/count";
  let headers = {
    "Content-Type": "application/json",
    "x-auth": xAuth,
    "x-access-token": xAccessToken,
  }
  return await axios.post(url, {}, {
    headers: headers,
  }).then((res) => {
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    throw err.response.data;
  })

};

const getProjectTracksZipped = async (projectId, fileType) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let url = `${process.env.REACT_APP_BASE_URL}api/project/download-project/${projectId}/${fileType}/${Helper.encode(email)}`;
  return await axios({
    url: url,
    method: "get",
    headers: {
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    },
  })
  .then(function (response) {
    // handle success
    //console.log(response);
    return response;
  })
  .catch(function (error) {
    // handle error
    //console.log(error);
    return error?.response;
  });
};

const setProjectTrackDownloadCount = async (trackId, projectId) => {
  const xAccessToken = CookieManager.getCookie("x-access-token");
  let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
  let headers =  {
    "Content-Type": "application/json",
    "x-access-token": xAccessToken,
    "userId" : email?.length > 0 ? Helper.encode(email) : ""
  };
  let url = process.env.REACT_APP_BASE_URL + "api/project/download-count/" + projectId;
  let response = await fetch(url, {
    method: "post",
    headers: headers,
    body: JSON.stringify({ trackId }),
  });
  return response.json();
};

const getProjectWithLicenseData = async (projectId) => {
  let { email } = JSON.parse(CookieManager.getCookie("user-data"));

  const xAuth = CookieManager.getCookie("x-auth");
  const xAccessToken = CookieManager.getCookie("x-access-token");
  

  const url = `${process.env.REACT_APP_BASE_URL}api/project/get-project/${projectId}/${email?.length > 0 ? Helper.encode(email) : ""}/true`
  let response = await axios({
    url: url,
    method: "get",
    headers: { 
      "Content-Type": "application/json",
      "x-auth": xAuth,
      "x-access-token": xAccessToken,
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
      'Expires': '0',
    }
  }).then((res) => {
    return res;
  }).catch((err) => {
    return err?.response;
  });
  if (response?.data?.success) {
    
    return response.data;
  } else {
    return response.data;
  }
};

const createAdminSession = async() => {
  const headers = {
    "Content-Type": "application/json",
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  }
  return await axios({
    method: "get",
    url: process.env.REACT_APP_BASE_URL + "api/admin/create-session",
    headers: headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,

  }).then(resp => {
    // console.log(" token response : ", resp)
    return resp.data;
  })
  .catch(err => {
    throw err;
  });
}
const API = {
  getProductDetails,
  createPaymentIntent,
  getTracks,
  playTrack,
  createStripeSubscription,
  createMewoUser,
  signIn,
  searchTracks,
  getPlaylist,
  getAllTags,
  activateUser,
  createProject,
  getProjects,
  addTrackToProject,
  addTrackToFavorites,
  getFavoriteTracks,
  getDownloadTracks,
  downloadTrackArchive,
  downloadArchiveTrack,
  getHistoryTracks,
  getDownloadTrackList,
  setDownloads,
  keywordsAndMaiaSearch,
  searchTracksByMaiaId,
  createLicense,
  generatePdf,
  createStripeUser,
  deleteTrackFromProject,
  getAlternateVersions,
  getProducts,
  deleteProject,
  updateProject,
  getUser,
  updateUser,
  getAISearchPanel,
  getAISearchTrack,
  getAISearchTag,
  sendForgotPasswordEmail,
  setNewPassword,
  forgotPasswordLinkValidation,
  getBillingInfo,
  createSubscription,
  getAdminList,
  createAdmin,
  deleteAdmin,
  getUserList,
  createCorporateAdmin,
  getCorpUser,
  createCorporateUser,
  suspendUser,
  deleteUser,
  getUserAggregate,
  cancelSubscription,
  getInvoices,
  getLicAggregate,
  updatePaymentMethod,
  updateBillingDetail,
  updateGST,
  updateCorpUser,
  getCorpUserBillingInfo,
  updateUserCookie,
  createHubspotTicket,
  getTaxRate,
  getSimilarTracks,
  getAllAlbumTracks,
  saveConfig,
  downloadLicenseUrl,
  validateSubscription,
  uploadAudioFile,
  uploadAudioFileToMEWO,
  // voice
  getAllPlaylist,
  getOnePlaylist,
  getPlaylistDetails,
  getAdminByEmail,
  suspendAdmin,
  getPollyVoices,
  synthesizeText,
  getAlbumDetails,
  getPlayAlbumTracks,
  getPlayListTracks,
  getTotalLicenseCount,
  getProjectTracksZipped,
  getLicenses,
  loadProjects,
  getAllArtistTracks,
  setProjectTrackDownloadCount,
  getProjectWithLicenseData,
  createAdminSession
};

export default API;