import React from "react";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import { BouncingLoader } from "../../pure-components/Loader";
import API from "../../services/api";
import { Link } from "react-router-dom";
import Helper from "../../services/helper";
import defaultImage from "../../assets/default_music.png";
import CookieManager from "../../services/CookieManager";
import { ReactComponent as Close } from "../../assets/BlackButtonRoundMinusSmall.svg";
import MewoService from "../../services/mewoService";
import Constants from "../../services/constants";

const Catalog = (props) => {
  
  const ref = useRef(null);
  
  const [searchText,setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [catalogList, setCatalogList] = useState([]);

  const pageSize = 200;
  
  useEffect(() => {
      (async() => {
        await loadCatalogList(searchText)
      })()
    
  }, [])

  useEffect(() => {
    console.log(pageNo)
  }, [pageNo])

  useEffect(() => {
    if(loadMore)
      (async() => {
        await loadCatalogList(searchText)
      })()
    
  }, [loadMore])

  const loadCatalogList = async(query = "") => {
    setLoading(true);
    await getAuthTokenForLoadingCatalogList().then(async token => {
      //loading catalog list from MEWO
      await loadCatalogListFromMEWO(query, token);
    }).catch(err => {
      console.log("Admin auth token loading Error : ", err);
    })

    //stored all catalog list data in DB with auth token, 
    //loading catalog list from DB, change it after we get API from MEWO
    // let url = process.env.REACT_APP_BASE_URL + `api/users/auth-token/` + Helper.encode("catalogs");
    // let response = await fetch(url, {
    //   method: "get",
    //   headers: { "Content-Type": "application/json" }
    // }).then((resp) => {
    //   return resp.json();
    // }).catch((err) => {
    //   console.log("Error loading catalog list : ", err)
    // });

    // let tempDataList = response.data?.token?.length > 0 ? JSON.parse(response.data?.token) : [];
    // setCatalogList(tempDataList);
    
    setLoadMore(false)
    setLoading(false);
  }

  const getAuthTokenForLoadingCatalogList = async(vaild = true) =>{
    if(vaild && CookieManager.getCookie("adminAuthToken")){
      return CookieManager.getCookie("adminAuthToken");
    }
    return await API.createAdminSession().then(resp => {
      CookieManager.setCookie("adminAuthToken", resp?.token);
      return resp?.token;
    }).catch(err => {
      console.log("Admin auth token loading error : ", err)
    })
  }

  const loadCatalogListFromMEWO = async(query, token) =>{
    if(token){
      let reqObj = {
        url : `meta/catalogs/search`,
        method : 'post',
        data: JSON.stringify({
          query,
          page : pageNo,
          size : pageSize
        }),
        token : token
      };
      await MewoService.executeRequest(reqObj).then((resp) => {
        // console.log("response data : ", resp)
        let tempDataList = pageNo > 0 ? [...catalogList] : [];
        if(resp?.hits?.length > 0){
          //tempDataList.push(...resp?.hits)
          //filtering catalog on the basis of showcase
          resp.hits.map((cat) => {
            if(cat?.showcase?.find((e) => e?.website_title === process.env.REACT_APP_MEWO_SHOWCASE_TITLE))
              tempDataList.push(cat);
          })
        }
        if(resp?.hits?.length > 0 && resp?.hits?.length === pageSize){
          setAllDataLoaded(false);
          setPageNo(pageNo+1);
        }else
          setAllDataLoaded(true);

        setCatalogList(tempDataList);
      }).catch(async(err) => {
        console.log("Catalog List Loading Error : ", err.response);
        if((err?.response?.status === 400) || (err?.response?.key === "invalid_token")){
          await getAuthTokenForLoadingCatalogList(false).then(async (token) => {
            await loadCatalogListFromMEWO(query, token);
          })
        }else{
          Helper.handleError(err);
        }
      });
    }else{
      console.log("Admin auth token not found!");
    }
  }
  const handleSearch = async(e) => {
    
    if(e.key === 'Enter' && !loading){
      console.log(e.key, searchText);
      setPageNo(0);
      setCatalogList([])
      await loadCatalogList(searchText);
    }
  };

  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreData, options);
      let target = document.querySelector("#load-more-data");
      observer.observe(target);
    }
  });


  const loadMoreData = async(entries, observer) => {
    entries.map(async(entry) => {
      if (entry.isIntersecting) {
        if (!allDataLoaded) {
          setLoadMore(true);
        }
      }
    });
  };

  return (
    <div className="lg:px-8 items-center flex-1 pt-20 lg:pt-3 sm:mx-8 mx-8 lg:mx-20 playlist">
      
      <div className="w-full rounded-full aisearch border-pink  flex">
        <input
          onChange={event => { setSearchText(event.target.value) }}
          onKeyDown={handleSearch}
          className={`self-center xl:w-3/4 lg:w-2/3 md:w-2/3 md:truncate bg-white outline-none cursor-pointer placeholder-textGray-600 rounded-full pl-2`}
          // type="text"
          placeholder="Search Catalog"
          disabled={loading}
          value={searchText}
        />
      </div>
      <div className="border-t my-2"></div>
      <div className="w-full">
        {loading &&  
          <div className='w-full'>
            <BouncingLoader />
          </div>
        }
        
        {!loading && !(catalogList  ?.length > 0) ? (
          <div className="text-xl text-center text-red-500 my-5">
            No Catalog Found
          </div>
        ) : 
        (<div className="bg-white grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-10">
          {catalogList?.map((elem, index, arr) => (
            <>
            <Link to={`/catalog/albums/${elem?.id}`}>
                <div className=" music-tile" key={elem?.id}>
                  <div className="image-container">
                    {elem != null && elem?.image != null ? (
                      <img
                        src={elem?.image?.large?.url || defaultImage}
                        alt="Album img"
                        className=" sm:flex border sm:cursor-pointer rounded-2xl "
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <img
                        src={defaultImage}
                        alt="Album img"
                        className="sm:flex border-b sm:cursor-pointer music-image justify-center align-middle "
                        width="100%"
                        height="100%"
                      />
                    )}
                    <span className="play_icon" title="Play"></span>
                  </div>
                  <div className=" p-2 ">
                    <p className="text-sm"><b>{elem?.name}</b> </p>

                    {elem.descriptions?.length > 0 &&
                      <p className="text-sm font-light text-left description"
                        title={elem.descriptions[0]?.value}
                      >
                        {elem.descriptions[0]?.value || elem?.title}
                      </p>
                    }
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>)
        }
        {!allDataLoaded &&!loading && (
          <div id="load-more-data" ref={ref} className="p-2">
           <BouncingLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default Catalog;