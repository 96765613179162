import React, { useEffect, useState } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { ReactComponent as Edit } from '../../assets/editProfile.svg';
import { ReactComponent as DisEdit } from '../../assets/diseditPro.svg';
import { TextField } from '@material-ui/core';
import Constants from '../../services/constants';
import Validator from '../../services/validator';
import { ReactComponent as Success } from '../../assets/success.svg';
import Helper from '../../services/helper';
import { ErrorToast } from '../../pure-components/CustomToast';
import { BouncingLoader } from '../../pure-components/Loader';

export default function Profile(props) {
  const [editProfile, setEditProfile] = useState(false);
  const [user, setUser] = useState({});
  const [helperText, setHelperText] = useState({})
  const [loading, SetLoading] = useState(true);
  let validator = new Validator();
  let userType = { ...JSON.parse(process.env.REACT_APP_USER_TYPE) };

  useEffect(() => {
    API.getUser().then(res => {
      if (res.success) {
        let tempUserData = res.data;
        setUser(res.data);
        //if userType is CORPORATE, load company Profile data from admin;
        if(res.data?.userType === "CORPORATE"){
          API.getAdminByEmail(res.data.admin).then(res => {
            if (res.success) {
              tempUserData["companyAddress"] = res.data.companyAddress;
              tempUserData.city = res.data.city;
              tempUserData.postalCode = res.data.postalCode;
              tempUserData.state = res.data.state;
              tempUserData.country = res.data.country;
              tempUserData.companyName = res.data.companyName;
              tempUserData.taxNumber = res.data.taxNumber;
              setUser(tempUserData);
              SetLoading(false);
            }else{
              toast(({ closeToast }) => <ErrorToast message={res?.message + res?.mssg} />);
            }

          })
        }else{
          SetLoading(false);
        }
      }else{
        toast(({ closeToast }) => <ErrorToast message={res?.message + res?.mssg} />);
      }

    })

  }, []);
  const handleInputChange = (e, key) => {
    let value = key === 'tos' ? e : e.target.value;
    if (key === 'first_name') {
      let res = validator.setValue(value).isNotEmpty();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'email') {
      let res = validator.setValue(value).isNotEmpty().isEmail();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'oldPassword') {
      // console.log(user.password,"   ",user.oldPassword)
      if (value !== Helper.decode(user.password)) {
        let temp = { ...helperText };
        temp[key] = "Current password doesn't match";
        setHelperText(temp);
      } else {
        let temp = { ...helperText };
        temp[key] = "";
        setHelperText(temp);
      }
    }
    if (key === 'newPassword') {
      let res = validator.setValue(value,"New password").isNotEmpty().isString().checkPasswordStrength();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }

    if (key === 'confirmPassword') {
      if (user.newPassword !== value) {
        let temp = { ...helperText };
        temp[key] = "Password doesn't match";
        setHelperText(temp);
      } else {
        let temp = { ...helperText };
        temp[key] = "";
        setHelperText(temp);
      }
    }
    if (key === 'companyName') {
      let res = validator.setValue(value).isString().doesNotContainSpecialChars();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }
    if (key === 'taxNumber') {
      let res = validator.setValue(value).isString().isGSTIN();
      let temp = { ...helperText };
      temp[key] = res.mssg;
      setHelperText(temp);
    }

    let tempUser = { ...user };
    tempUser[key] = value;
    setUser(tempUser);
  };
  const changePassword = () => {
    if (user.oldPassword !== Helper.decode(user.password)) {
      let temp = {};
      temp["oldPassword"] = "Current password doesn't match";
      setHelperText(temp);
      return;

    }
    if (user.newPassword !== user.confirmPassword) {
      let temp = {}
      temp['confirmPassword'] = "Password doesn't match";
      setHelperText(temp);
      return;
    }
    API.updateUser({ password: Helper.encode(user.newPassword) }).then(data => {
      //   setTimeout(() => {
      toast(({ closeToast }) => <div class="flex  m-1"><Success className="p-3 mr-2 border-r border-gray-500" />
        <div className="self-center">
          <h1 className='text-base text-black ' > Success. Great job!</h1>
          <h2 className='text-xs self-center text-gray-700 '> Password change successfully </h2>
        </div> </div>);
      setTimeout(() => {
        Helper.signOut()
      }, 700);

    })
  }
  const updateProfile = () => {
    let counter = 0;
    let temp = { ...helperText };
    let resFirstName = new Validator(user.first_name).isNotEmpty().isString();
    temp['first_name'] = resFirstName.mssg;
    resFirstName.status === false && counter++;
    let resEmail = new Validator(user.email).isNotEmpty().isString().isEmail();
    temp['email'] = resEmail.mssg;
    resEmail.status === false && counter++;
    // COMPANY NAME IS NOT COMPULSORY FOR SOLO USER
    if (user.userType !== userType.FREE && user.userType !== userType.SOLO) {
      let resCompanyName = new Validator(user.companyName, "").isNotEmpty().doesNotContainSpecialChars();
      temp['companyName'] = resCompanyName.mssg;
      resCompanyName.status === false && counter++;
      let resTaxNumber = new Validator(user.taxNumber, "GST No.").isNotEmpty().isGSTIN();
      temp['taxNumber'] = resTaxNumber.mssg;
      resTaxNumber.status === false && counter++;
    }
    setHelperText(temp);

    if (counter === 0) {
      let userData = {};
      if(user.userType!== "CORPORATE"){
        userData = { ...user };
      }else{
        userData["first_name"] = user.first_name;
      }
      if(userData?.password?.length)
        delete userData.password;
      API.updateUser({ ...userData }).then(data => {
        toast(({ closeToast }) => <div className="flex  m-1"><Success className="p-3 mr-2 border-r border-gray-500" />
          <div className="self-center">
            <h1 className='text-base text-black ' > Success. Great job!</h1>
            <h2 className='text-xs self-center text-gray-700 '> User update successfully </h2>
          </div> </div>);

        setEditProfile(false);
      })
    }
  }
  return (
    <div className="lg:w-3/4">
      <div className="flex sm:mt-8 mb-4 ml-4 items-baseline	 self-center">
        <h1 style={{ fontSize: "28px" }} className='font-bold text-black' > My Profile</h1>
        {
          !editProfile && <Edit className="cursor-pointer ml-6"
            onClick={() => setEditProfile(true)} />}
        {
          editProfile && <DisEdit className="cursor-pointer ml-6"
            onClick={() => setEditProfile(false)} />}
      </div>
      {loading ?
              <div className='w-full'>
                <BouncingLoader />
              </div>:
            <>
        {!editProfile &&
          <div className="w-full sm:w-3/4 ml-4  mb-20">
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Full Name</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.first_name}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Email</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.email}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Address</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.companyAddress}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> City</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.city}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Postal Code</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.postalCode}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> State</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.state}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Country</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.country}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Company Name</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> {user.companyName}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> GST No.</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'>{user.taxNumber}</div>
            </div>
            <div className='sm:flex py-6 border-b border-textGray-300'>
              <div className='w-full sm:w-1/3 text-base self-center'> Password</div>
              <div className='w-full sm:w-2/3 text-sm self-center text-textGray-500'> ******** </div>
            </div>
          </div>
        }
      </>
      }
      {
        editProfile &&
        <div className="w-full sm:w-3/4 px-4  mb-20">
          <div className='sm:flex pt-6 '>
            <div className='w-full sm:w-1/3 text-base self-center'> Full Name</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'first_name')}
              error={helperText.first_name !== undefined && helperText.first_name !== '' ? true : false}
              type="text"
              name="first_name"
              placeholder='Full Name'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.first_name}
              helperText={helperText.first_name}
            />
          </div>
          <div className='sm:flex  my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> Email</div>
            <TextField variant="outlined"
              type="email"
              name="email"
              placeholder='Email'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.email}
              disabled = {true}

            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> Address Line</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'companyAddress')}
              name="companyAddress"
              type="text"
              placeholder='Address'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.companyAddress}
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> City</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'city')}
              type="text"
              name="city"
              placeholder='City'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.city}
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> Postal Code</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'postalCode')}
              type="text"
              name="postalCode"
              placeholder='Postal Code'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.postalCode}
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'>State</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'state')}
              type="text"
              placeholder='State'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.state}
              name="state"
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'>Country</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'country')}
              type="text"
              placeholder='Country'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.country === "IN" || !user.country ? "India" : user.country}
              name="country"
              disabled
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> Company Name</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'companyName')}
              error={helperText.companyName !== undefined && helperText.companyName !== '' ? true : false}
              type="text"
              placeholder='Company Name'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.companyName}
              name="companyName"
              helperText={helperText.companyName}
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> GST No.</div>
            <TextField variant="outlined"
              onChange={(e) => handleInputChange(e, 'taxNumber')}
              error={helperText.taxNumber !== undefined && helperText.taxNumber !== '' ? true : false}
              type="text"
              placeholder='GST No.'
              className="w-full sm:w-2/3  p-3 outline-none border border-textGray-300 text-textGray-500"
              value={user.taxNumber}
              name="taxNumber"
              helperText={helperText.taxNumber}
              disabled = {user.userType === "CORPORATE"}
            />
          </div>
          <div className='flex my-6 w-4/6'>
            <div className='w-full sm:w-3/6 text-base self-center'></div>
            <div className={`greenbutton cursor-pointer`}
              // style={{width:"12%"}}
              onClick={updateProfile}
            > Save
            </div>
          </div>
          <h1 className='w-full sm:w-1/3 pt-10 pb-4 text-base font-bold text-black' > Change Password</h1>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> Current Password</div>
            <TextField variant="outlined"
              // onChange={(e) => { handleSearch(e.target.value) }}
              // onChange={handleSearch}
              error={helperText.oldPassword !== undefined && helperText.oldPassword !== '' ? true : false}
              type="password"
              placeholder='Current Password'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              value={user.oldPassword || ''}
              helperText={helperText.oldPassword}
              onChange={(e) => handleInputChange(e, 'oldPassword')}

            />
          </div>
          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'> New Password</div>
            <TextField variant="outlined"
              error={helperText.newPassword !== undefined && helperText.newPassword !== '' ? true : false}
              type="password"
              placeholder='New Password'
              className="w-full sm:w-2/3  p-4 outline-none border border-textGray-300 text-textGray-500"
              helperText={helperText.newPassword}
              onChange={(e) => handleInputChange(e, 'newPassword')}

            />
          </div>

          <div className='sm:flex my-3'>
            <div className='w-full sm:w-1/3 text-base self-center'>  Confirm Password</div>
            <TextField variant="outlined"
              error={helperText.confirmPassword !== undefined && helperText.confirmPassword !== '' ? true : false}
              type="password"
              placeholder=' Confirm Password'
              className="w-full sm:w-2/3  p-3 outline-none border border-textGray-300 text-textGray-500"
              helperText={helperText.confirmPassword}
              onChange={(e) => handleInputChange(e, 'confirmPassword')}

            />

          </div>
          <div className='flex my-6 w-4/6'>
            <div className='w-3/6 text-base self-center'></div>
            <div className={`greenbutton cursor-pointer`}
              onClick={changePassword}
            > Save
            </div>
          </div>
        </div>
      }
    </div>
  )
}
