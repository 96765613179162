import React,{useContext,useEffect } from "react";
import defaultImage from "../../assets/default_music.png";
import TrackContext from "../../contexts/track-context";
import { Pause } from "@material-ui/icons";
import { PlayArrow } from "@material-ui/icons";
import Helper from "../../services/helper";

const PlaylistTile = (props) => {
    const trackContext = useContext(TrackContext);
    const {tracks,setPlayAll,pageId}=props;

    useEffect(()=>{
      //to show play or pause button 
      let index=tracks?.findIndex((track)=> track.id === trackContext.currentTrackDetail.id)
      if(index !== -1 && index!==undefined  && trackContext.playPause && trackContext.playingFrom === pageId){
        setPlayAll(true)
      }
      else {
        setPlayAll(false)
      }
    },[trackContext.playPause,tracks])

  return (
    <>
    <div className="py-3 border-b">
      <div className="w-full flex">
        <div className="w-full lg:w-2/6 rounded-md" >
          {props.playlistDetails?.image?.large?.url && (
            <img
              className="w-full rounded-md lg:w-11/12"
              onError={(e) => trackContext.setDefaultImage(e)}
              src={props.playlistDetails?.image?.large?.url || defaultImage}
              alt="playlistInfo"
            />
          )}
        </div>
        <div className="w-full lg:w-4/6 mt-5 lg:mt-0 ">
          <h1 className="mb-2 text-xl" >{props.playlistDetails?.title}</h1>
          {/* <p>Playlist by 
            <b> {props?.playlistDetails?.created_by?.first_name} {props?.playlistDetails?.created_by?.last_name} ({props?.playlistDetails?.created_by?.company_name})</b>
          </p>
          {props?.playlistDetails?.created_at && 
            <p>Creatd on 
              <b> {new Date(props?.playlistDetails?.created_at).toDateString()}</b>
            </p>
          } */}
          {props.playlistDetails?.descriptions && (
            <p className="mb-2 text-description self-center text-all w-full lg:w-3/5 text-justify ">
              {props.playlistDetails?.descriptions[0]?.value}
            </p>
          )}
          
        </div>
      </div>
      <div className="row flex" style={{"marginTop": "-36px"}}>
        <div className="w-full lg:w-2/6 "></div>
        <div className="w-full lg:w-4/6 ">
        {
          props.tracks.length ?
          (
            <div className=" cursor-pointer">
          {(!props.playAll || !trackContext.playPause) ? (
            <button  className="album-play-button" onClick={props.playPlaylistTracks}>
              <span>
                <PlayArrow className="outline-none cursor-pointer" />
              </span>
              <span>Play</span>
            </button>
          ) : (
            <button className="album-pause-button" onClick={props.handlePause}>
              <span>
                <Pause className="outline-none cursor-pointer hover:bg-pink-500 " />
              </span>
              <span>Pause</span>
            </button>
          )}
        </div>
          ):null
        }
        </div>
      </div>
    </div>
      
    </>
  );
};

export default PlaylistTile;