import React from "react";
import "./style.css";
import bannerImage from "../../../assets/kb-sol/About-Us-Banner-01.jpg"
import founderImage from "../../../assets/kb-sol/Achille-new.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AboutUs() {
  return (
    <div className="yellowbg flex flex-wrap mx-5">
      <div className="hidden banner-wrapper sm:flex sm:w-full sm:relative">
        <div
          className="hidden sm:flex sm:bg-black"
          style={{ width: "100%", minHeight: "500px" }}
        >
          <img
            src={bannerImage}
            effect="blur"
            className="hidden sm:flex  bannerImage"
            alt="Banner Image"
            width="100%"
            style={{ height: "100%" }}
          />
          <img
            src={founderImage}
            effect="blur"
            className="hidden sm:flex   aboutFounder "
            alt="Founder Image"
            width="20%"
            style={{ height: "40%" }}
          />
        </div>
      </div>
      <div className="flex text-center flex-wrap">
        <div className="self-center text-left w-full">
          <div className="self-center text-left w-full main-content p-2">
            <h1 className="">About Us</h1>

            <p>
              {" "}
              <span className="pinkhighlight">
                Creators who enrich our lives deserve to be remunerated fairly.
              </span>
            </p>
            <p>
              It has always seemed unfair, immoral, and self-defeating to me
              that the entertainment industries relegate the creators, who are
              the source of their business, to the bottom when it comes to
              rights and payments.
            </p>
            <p>
              When artists claim royalties, it is “an obstacle to business,” we
              are told. But after the artists have abandoned their rights to
              corporations that can’t write a lyric or compose a tune, these
              “obstacles to business” evaporate. By what magic?
            </p>
            <p>
              <span className="pinkhighlight">
                Let me be clear: ‘royalty-free’ is another word for slavery.
                When you use ‘royalty-free’ music, you pay the slave-drivers and
                not the artists who work for them.
              </span>{" "}
              Buy-out contracts dispossess artists from ownership of their work
              and chain them to a treadmill of endless gigs. Without the benefit
              of royalties, artists’ careers are an education in survival while
              enriching the new owners of their copyright.
            </p>

            <p>
              {" "}
              <span className="pinkhighlight">
                The right of creators to derive income from the use of their
                work is a fundamental human right.
              </span>
              An artistic creation must remain the ultimate property of the
              creator despite the temporary assignment of rights for its
              communication because ownership is the very condition for getting
              paid. If you have no title of ownership, you must rely on charity.
              There can be no freedom of expression without economic
              independence.
            </p>
            <p>
              {" "}
              <span className="pinkhighlight">
                SyncMama provides you access to a massive library of pre-cleared
                high- quality music composed and owned by professional
                composers.
              </span>
              When a great soundtrack increases the commercial appeal of your
              video, every stakeholder wins. Our licenses give you total freedom
              over what you can do with your productions, everywhere and for all
              time.
            </p>
            <p>
              SyncMama operates according to industry standards and best
              practices such as CISAC, ISO, and DDEX.
              <span className="pinkhighlight">
                {" "}
                We pay the highest share in the music business from our gross
                income to the creators. Using SyncMama ensures that your money
                goes to those who made the music that powers your productions.
              </span>
            </p>
            <p>
              SyncMama collects the music royalties from YouTube, Facebook, and
              others in complete transparency. The royalties that these services
              would pay otherwise to the owners of royalty-free music, SyncMama
              pays to those who composed the music you use.
            </p>
            <p>
              SyncMama ensures a fair income distribution across the value
              chain, with no leakages.
            </p>
            <br />
            <br />

            <p className="pink name">Achille Forler</p>
            <i className="black name">Founder</i>
          </div>
        </div>
      </div>
    </div>
  );
}
