import React, { useState, useEffect, useContext, useRef } from "react";
import API from "../../services/api";
import TrackItem from "../TrackItem";
import "./style.css";
import { BouncingLoader } from "../../pure-components/Loader";
import AddTrackToProject from "../Projects/addTrackToProject";
import TrackContext from "../../contexts/track-context";
import CookieManager from "../../services/CookieManager";
import RippleLoader from "../../assets/loader-2.gif";
import SoundDesign from "../SoundDesign";
import Inspiration from "../Inspiration";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import Constants from "../../services/constants";
import Helper from "../../services/helper";
import { Link, useHistory } from "react-router-dom";
import MewoService from "../../services/mewoService";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";

export default function ListTracks(props) {
  let ref = useRef(null);
  const [noDataFound, setNoDataFound] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tempProjects, setTempProjects] = useState([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [excluded, setExcluded] = useState([]);
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [excludedTrack, setExcludedTrack] = useState([]);
  // const [noDataFound, setNoDataFound] = useState(false);
  const pageId='music'

  let soundDesignTagID = "5d36d75104a4286541ea2d1d";
  //Gothic Tool Works
  let labelTagID1 = "613e35c6b52f5e75a7fabf2b";
  // Strike Audio
  let labelTagID2 = "5f105463dd200590c481c317";
  // Aura
  let labelTagID3 = "602ea745357d1e5f788b0559";

  let trackContext = useContext(TrackContext);
  const history=useHistory()

  useEffect(() => {
    //loading favs if not there
    const favs = JSON.parse(localStorage.getItem("favs") || 'null');
    if((trackContext?.userDetails !== undefined && trackContext?.userDetails !== null
      && Object.keys(trackContext.userDetails)?.length !== 0) && !favs ){
      (async() => {
        await API.getFavoriteTracks().then((res) => {
          if (res?.success) {
            let favs = res.data.map((tracks) => {
              return tracks.trackId.mewoTrackId;
            });
  
            localStorage.setItem("favs", JSON.stringify(favs));
          }else{
            Helper.handleError(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      })();
    }

    setNoDataFound(false);
    if (window.location.pathname === "/" || window.location.pathname.includes("/search/track/")) {

      let tempFilter = JSON.parse(JSON.stringify(trackContext.trackFilter));
      let soundDesignTagID = "5d36d75104a4286541ea2d1d";

      tempFilter.searchTags.tags_not = [soundDesignTagID];
      if (tempFilter.searchTags.tags?.length === 1) {
        if (tempFilter.searchTags.tags[0] === "5d36d75104a4286541ea2d1d") {
          tempFilter.searchTags.tags = [soundDesignTagID];
        }
      }
      if (tempFilter?.searchTags?.tags !== undefined) {
        const tagArrarSoundDesign = tempFilter?.searchTags?.tags?.filter(
          (eachTags) => eachTags !== soundDesignTagID
        );
        tempFilter.searchTags.tags = tagArrarSoundDesign;
      }
      trackContext.setTrackFilter(tempFilter);

      //   API.searchTracks(trackContext.trackFilter.searchText, trackContext.trackFilter.searchTags).then(res => {
      //   trackContext.setIsLoading(false);
      //   trackContext.setTrackData(res.data.hits);

      //   let newTracks = [...res.data.hits];
      //   let tracksToExclude = newTracks.map( track => track.ref_id);
      //   setExcluded(tracksToExclude);

      // });

      let userData = CookieManager.getCookie("user-data");
      if (userData !== undefined && userData !== null && userData !== "null") {
      }
    }
  }, []);

  useEffect(() => {
    if ((showLoader || trackContext.showMayaLoader) && !trackContext.trackFilter.searchTitle?.length) {
      let tracksToExclude = [];
      if (trackContext.trackData.length > 0) {
        let newTracks = [...trackContext.trackData];
        tracksToExclude = newTracks.map((track) => track.id);
      }
      if (!trackContext.allTracksLoaded) {
        if (trackContext.searchAlbums) {
          setPageNo(pageNo + 1);
          let num = pageNo + 1;

          let reqObj = {
            url : `public/search/panel`,
            method : 'post',
            data: JSON.stringify({
              query : trackContext.trackFilter.searchText,
              albums: { page: num, max: Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE, search_descriptions: trackContext.searchDescription },
            }),
          };
          (async () => {
            await MewoService.loadMewoData(reqObj).then(
            (res) => {
              if (res) {
                setShowLoader(false);
                trackContext.setShowMayaLoader(false);

                let newTracks = [...trackContext.trackData, ...res.albums.hits];
                trackContext.setTrackData(newTracks);
                trackContext.setalltrackData(newTracks);
                trackContext.setalltrackData(newTracks);
                if (
                  process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE >
                  res.albums.hits?.length
                ) {
                  trackContext.setAllTracksLoaded(true);
                } else {
                  trackContext.setAllTracksLoaded(false);
                }
              } else {
                Helper.handleError(res);
              }
            }
          )})();
        } else {
          let reqObj = {
            url : `public/search`,
            method : 'post',
            data: {
              "query": trackContext.trackFilter.searchText,
              "options": 
              {
                "filters": {
                  ...trackContext.trackFilter.searchTags,
                  exclude : tracksToExclude
                },
                "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
                "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
              }
            },
          };
          if(trackContext.trackFilter.searchId)
            reqObj.data.options.searchId = trackContext.trackFilter.searchId;
    
          (async () => {
            await MewoService.loadMewoData(reqObj).then(res => {
             if (res) {
                setShowLoader(false);
                trackContext.setShowMayaLoader(false);
                let newTracks = [...trackContext.trackData, ...res.hits];
                trackContext.setTrackData(newTracks);
                trackContext.setalltrackData(newTracks);
                if (Constants.MEWO_TRACK_SEARCH_BATCH_SIZE > res.hits?.length)
                  trackContext.setAllTracksLoaded(true);
              }else{
                Helper.handleError(res);
              }
            })
            .catch((err) => {
              toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
            });
          })();
      } }else {
        setShowLoader(false);
      }
    }
    else {
      setShowLoader(false);
      trackContext.setShowMayaLoader(false);
    }
  }, [showLoader, trackContext.showMayaLoader]);

  useEffect(() => {
    if (ref.current !== null && !(trackContext.trackFilter.url?.length || trackContext.trackFilter.searchTitle?.length)) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreTracks, options);
      let target = document.querySelector("#load-more-tracks");
      observer.observe(target);
    }
  });


  const loadMoreTracks = (entries, observer) => {
    entries.map((entry) => {
      if (entry.isIntersecting) {
        if (trackContext.allTracksLoaded) {
          setShowLoader(false);
        } else {
          setShowLoader(true);
        }
      }
    });
  };


  const fetchProjects = () => {
    API.getProjects().then((data) => {
      setProjects(data.data);
      setTempProjects(data.data);
    });
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  useEffect(() => {},
   [props.maiaOnWork]);


  useEffect(() => {
  }, [props.showProgress]);

  useEffect(() => {
    trackContext.setTrackData(props.resultFromMaia);
  }, [props.resultFromMaia]);

  const cancelSearch=async()=>{
    CookieManager.setCookie("stopAISearch", true);
    clearInterval(trackContext.intervalID)
    trackContext.setSearch(false)
    trackContext.setIntervalId(null)
    trackContext.setFileUpload(false);
    trackContext.setSearchTime(0);
    trackContext.setProgressValue(0);
    props.handleMaiaOnWork(false)
    let newTrackFilter = trackContext.trackFilter;
    newTrackFilter.url = "";
    newTrackFilter.searchId="";
    newTrackFilter.fileName="";
    if(trackContext.path.includes("/search")){
      if(trackContext.path.includes("/sound-design")){    
        newTrackFilter.url = ""; 
        history.push("/sound-design")
        localStorage.setItem("filter_soundfx", JSON.stringify(newTrackFilter));      
      }
      else{
        newTrackFilter.url = "";
        localStorage.setItem("filter_music", JSON.stringify(newTrackFilter));
         history.push("/") 

      }
    }
    trackContext.setTrackFilter(newTrackFilter);
    let filter = trackContext.trackFilter;
    trackContext.setIsLoading(true);
    trackContext.setTrackData([]);
    if ((window.location.pathname === "/"||trackContext.path.includes("/search/track/")) && (!filter.trackId)) {
      filter.searchTags.tags_not = ["5d36d75104a4286541ea2d1d"];
      let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": filter.searchText,
          "options": 
          {
            "filters": {
              ...filter.searchTags,
              exclude : []
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      if(filter.searchId)
        reqObj.data.options.searchId = filter.searchId;

      await MewoService.loadMewoData(reqObj).then(res => {
        if(res){
            trackContext.setTrackData(res.hits);
            trackContext.setTotaltracks(res.total.value);
            trackContext.setIsLoading(false);
            trackContext.setShowMayaLoader(false);
            if(process.env.REACT_APP_MEWO_TRACK_SEARCH_BATCH_SIZE > res.hits?.length)
              trackContext.setAllTracksLoaded(true);
            else
              trackContext.setAllTracksLoaded(false);
          }else{
            trackContext.setTrackData([]);
            trackContext.setTotaltracks(0);
            trackContext.setIsLoading(false);
            trackContext.setShowMayaLoader(false);

            Helper.handleError(res);
          }
        })
        .catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
        });
    }
    if(window.location.pathname === "/sound-design" ||
    window.location.pathname.startsWith(`/sound-design/search/track/`)
 ){
   trackContext.setTrackData([])
   trackContext.setSearchAlbums(false);
   trackContext.trackFilter.searchTags.labels = [labelTagID1, labelTagID2, labelTagID3];
   trackContext.trackFilter.searchTags.tags = [soundDesignTagID];
   trackContext.trackFilter.searchTags.tags_not = [];
   trackContext.trackFilter.fetchList = true; 
   if (
     trackContext.trackFilter.fetchList &&
     !trackContext.trackFilter.searchTitle?.length
   ) {
     if (
       trackContext?.trackFilter?.searchTags?.tags?.length &&
       trackContext?.trackFilter?.searchTags?.tags?.indexOf(
         "5d36d75104a4286541ea2d1d"
       ) !== -1
     ) {
       if(trackContext.trackFilter.searchId){
         trackContext.trackFilter.searchTags.labels = [];
         trackContext.trackFilter.searchTags.tags = [];
         trackContext.trackFilter.searchTags.tags_not = []
         trackContext.trackFilter.fetchList = false;
       }
       trackContext.setIsLoading(true);
       trackContext.setSearchkey("")
       trackContext.setTrackData([])
       setNoDataFound(false);

       let reqObj = {
        url : `public/search`,
        method : 'post',
        data: {
          "query": filter.searchText,
          "options": 
          {
            "filters": {
              ...filter.searchTags,
              exclude : []
            },
            "size": Constants.MEWO_TRACK_SEARCH_BATCH_SIZE,
            "search_descriptions": trackContext.searchDescription ? trackContext.searchDescription : false
          }
        },
      };
      if(filter.searchId)
        reqObj.data.options.searchId = filter.searchId;

      await MewoService.loadMewoData(reqObj).then(res => {
         if(res){
             trackContext.setShowProjectModal(false);
             trackContext.setIsLoading(false);
             trackContext.setTrackData(res.hits);
             trackContext.setTotaltracks(res.total.value);
             let newTracks = [...res.hits];
             let tracksToExclude = newTracks.map((track) => track.ref_id);
             setExcludedTrack(tracksToExclude);
             if (res.hits.length === 0) setNoDataFound(true);
           } else {              
             Helper.handleError(res);
           }
         })
         .catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Error Loading Tracks : " + err?.response?.data?.message}/>);
         });
     }
 }
}

  }

  //cancelling file upload
  const cancelUpload=()=>{
    if(trackContext.path.includes("/search")){
      if(trackContext.path.includes("/sound-design")){    
        history.push("/sound-design")
      }
      else{
         history.push("/") 
      }
    }
    const source = trackContext.cancelToken;
    source.cancel(Constants.FILE_UPLOAD_CANCELLED)
    API.uploadAudioFileToMEWO('','',source)
  }
  
  // const handlePagination = (e) => {
  //   let currPage = e.target.innerText;
  //   console.log({currPage});
  //   trackContext.setCurrPage(currPage);
  // }

  // useEffect(() => {
  //   API.searchTracks().then(res => {
  //     setIsLoading(false);
  //     console.log({res});
  //   });
  // }, [trackContext.currPage])

  return (
    <div className={`listtracks w-full mb-5 z-0 lg:pt-0`}>
      {(!trackContext.showProgress && props.maiaOnWork && !trackContext.resultFromMawoSearch) && (
        <div>
          <div className="mt-6 flex text-center">
            <div className="w-1/4"></div>
            {trackContext.searchTime <= 30 ? (
              <div className="w-2/4">
              Maya is Mama's self-learning neural search engine.
              It finds the most relevant music among hundreds of thousands of compositions.
              The more you use it, and the more accurate its results will become.
              If your search is taking time, please check the link submitted has enough music for Maya to process.
              </div>
             ) :trackContext.searchTime > 30 && trackContext.searchTime < 90 ?
            <div className="w-2/4">
              Hold on, I need to expand my search.
            </div>:
            trackContext.searchTime >= 90?
              <div className="w-2/4">
                This is a tough one, give me a few minutes.
            </div>:null}
            <div className="w-1/4"></div>
          </div>
          <div className="flex justify-center">
            <img src={RippleLoader} alt="loading..." />
          </div>
          <div className="w-1/4"></div>
          <div className="flex justify-center">
            <button
            onClick={()=>
              cancelSearch()
            }
            className='pt-2 pb-2 pl-4 border-0 pr-4 cancel-btn font-bold text-sm mt-3'
            type='button'>
              Cancel
            </button>
          </div>
        </div>
      )}
      {props.maiaOnWork && trackContext.resultFromMawoSearch && (
        <div>
          <div className="mt-6 flex text-center">
          </div>
          <div className="flex justify-center">
            <BouncingLoader />
          </div>
        </div>
      )}
      {(trackContext.showProgress)  && (
        <div>
        <div className="mt-6 flex text-center ">

        </div>
        <div className="progressbar-main">
        <h3 className="progress-header">Your file is {trackContext.progress}% uploaded, please wait...</h3>
            <div className="progressbar-container">
              <div >
                <progress className="progressbar" value={trackContext.progress} max="100" min="0" ></progress>
              </div>
              <div>
                <button
                onClick={() => cancelUpload()}
                className="pt-2 pb-2 pl-4 border-0 cancel-upload pr-4 cancel-btn signin-btn font-bold text-sm mt-3"
                type="button"
              >Cancel Upload</button>
              </div>
            </div>
        </div>
      </div>
       )}

       {trackContext.clearData ? '' : (!props.maiaOnWork && trackContext.loading &&!trackContext.showProgress && !trackContext.showLoaderMagic) && <><div className="row"/><BouncingLoader /></>}
      {!trackContext.loading && noDataFound && (
        <div className="text-xl text-center text-red-500">
          No Tracks Found
        </div>
      )}
      {trackContext.path !== "/sound-design" &&
        trackContext.path !== "/inspiration" && !trackContext.path.includes('/album/') ? (
            <>
          {(!trackContext.loading && trackContext.trackData.length>0 && !trackContext.isFileUpload &&!trackContext.maiaOnWork ) ? ( 
            <>
              {trackContext.trackData.map((track, index, arr) => (
                <>
                  {track.duration && (
                    <TrackItem
                      //   setSearchTags={props.setSearchTags}
                      tags={track.tags}
                      image={track.album?.image}
                      albumId={track.album}
                      //   searchTags={props.searchTags}
                      key={track.id}
                      title={track.display_title}
                      duration={track.duration}
                      previousTrack={index}
                      bpm={track?.bpm}
                      versions={track?.versions}
                      producer={track.master_ownerships[0]?.label?.label_name}
                      artist={
                        track.artists_publishing_ownerships[0]?.artist
                          ?.full_name
                      }
                      rights_type={
                        track.artists_publishing_ownerships[0]?.rights_type?.key
                      }
                      album={track.album?.title}
                      trackId={track.id}
                      waveform={track?.waveform?.small?.url}
                      setTrackUrl={props.setTrackUrl}
                      setFetchingTrack={props.setFetchingTrack}
                      changeCurrentTrackImage={props.changeCurrentTrackImage}
                      displayProjectModal={displayProjectModal}
                      handleCurrentTrackDetail={props.handleCurrentTrackDetail}
                      similarity={track?.similarity}
                      description={track?.descriptions[0]?.value}
                      expandedTrackId={expandedTrackId}
                      setExpandedTrackId={setExpandedTrackId}
                      pageId={pageId}
                      artists={
                        track.display_artists
                      }
                    />
                  )}
                </>
              ))}

              
              
             {trackContext?.searchAlbums &&
              <div className="bg-white  grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5  gap-8 mt-10 ">
                {trackContext?.searchAlbums &&
                  trackContext?.trackData.map((track, index, arr) => (
                    <>
                      <div>
                        <>
                          <Link
                            className="self-center"
                            to={`/album/${track?.id}`}
                          >
                            <div className=" music-tile" key={track?.id}>
                              <div className="album_image-container">
                                {/* {track != null && track?.image != null ? (
                              <img
                                src={track?.image?.small?.url || defaultImage}
                                alt="Album img"
                                className=" sm:flex border sm:cursor-pointer rounded-2xl "
                                width="100%"
                                height="100%"
                              />
                            ) : (
                              <img
                                src={defaultImage}
                                alt="Album img"
                                className="sm:flex border-b sm:cursor-pointer music-image justify-center align-middle "
                                width="100%"
                                height="100%"
                              />
                            )} */}
                                <img
                                  src={track?.image?.original?.url}
                                  alt="Album img"
                                  className=" sm:flex border sm:cursor-pointer rounded-2xl "
                                  width="100%"
                                  height="100%"
                                />
                                <span
                                  className="album_play_icon"
                                  title="Play"
                                ></span>

                              </div>

                              <div className=" p-2 ">
                                <p
                                  className="album-title_name"
                                  title={track?.title}
                                >
                                  {track?.title}
                                </p>

                                <p
                                  className=" text-xs font-light text-left album_description "
                                  title={track?.descriptions[0]?.value}
                                >
                                  {track?.descriptions[0]?.value ||
                                    track?.title}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </>
                      </div>
                    </>
                  ))}
              </div>}

              {!trackContext.allTracksLoaded && (
                <div id="load-more-tracks" ref={ref} className="p-2">
                  {trackContext.clearData
                    ? ""
                    : showLoader && <BouncingLoader />}
                </div>
              )}
            </>
            ) : (!trackContext.loading && !trackContext.maiaOnWork && !trackContext.isFileUpload && !trackContext.showLoaderMagic) && (
            <div className="text-xl text-center text-red-500">No Tracks Found</div>
          )}

          {showProjectModal && (
            <AddTrackToProjectPopup
              trackToBeAddedToProject={trackToBeAddedToProject}
              closeProjectModal={closeProjectModal}
            />
          )}
        </>
      ) : (
        <>
          {trackContext.path === "/sound-design" && (
            <SoundDesign
              setFetchingTrack={props.setFetchingTrack}
              maiaOnWork={props.maiaOnWork}
              resultFromMaia={props.resultFromMaia}
            />
          )}
          {trackContext.path === "/inspiration" && (
            <Inspiration
              setFetchingTrack={props.setFetchingTrack}
              maiaOnWork={props.maiaOnWork}
              resultFromMaia={props.resultFromMaia}
            />
          )}
        </>
      )}
    </div>
  );
}