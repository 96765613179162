//  PRODUCTION

const Constants = Object.freeze({

  //  PRODUCTION
  MARKETING_SITE_URL: "https://syncmama.com/",
  BASE_URL: "https://api.syncmama.com/",
  STRIPE_PUBLISH_KEY:
  "pk_live_51IV6qCEUafrAYNCVAyNQ73C5qDy8ud46fC4LjFgw1tT5hPcgGsRdUsQUXo8DC8dVMwFPSqmxdIkFH76jNzRLwlQg00OeOODKFM",
  MKT_SITE_PRICING: "https://syncmama.com/#card-container", //changes
  MKT_SITE_ABOUT: "https://syncmama.com/about/",
  MKT_SITE_BLOG: "https://syncmama.com/blogs/",
  MKT_SITE_INSPIRATION: "https://syncmama.com/#instpiration-main-container",
  MKT_SITE_PRIVACY: "https://syncmama.com/privacy",
  MKT_SITE_TERMS: "https://syncmama.com/terms-and-conditions",
  MKT_SITE: "https://syncmama.com",
  X_HOST: "portal.syncmama.com", // pre-prod

  // //DEVELOPMENT 
  // MARKETING_SITE_URL: "https://dev.syncmama.com/",
  // BASE_URL: "https://dev-api.syncmama.com/",

  // STRIPE_PUBLISH_KEY:
  // "pk_test_51IV6qCEUafrAYNCVfIGG5xS5cH1grUSh4beQSPRsGNS49JElawGVYVen9xqdUr6bVmo1rI0ELHdASpgBerBCX50x00DBE4F8XA",
  // MKT_SITE_PRICING: "https://dev.syncmama.com/#card-container", //changes

  // MKT_SITE_ABOUT: "https://dev.syncmama.com/about/",
  // MKT_SITE_BLOG: "https://dev.syncmama.com/blogs/",
  // MKT_SITE_INSPIRATION: "https://dev.syncmama.com/#instpiration-main-container",
  // MKT_SITE_PRIVACY: "https://dev.syncmama.com/privacy",
  // MKT_SITE_TERMS: "https://dev.syncmama.com/terms-and-conditions",
  // MKT_SITE: "https://dev.syncmama.com",
  // X_HOST: "dev-portal.syncmama.com", // pre-prod 

  // UNIVERSAL
  MEWO_URL: "https://api-v2.mewo.io/",
  PUBLIC: "public/",
  SEARCH: "search",
  CONFIRMATION: "/confirmation",
  USER_TYPE: {
    SOLO: "SOLO",
    ORGANIZATION: "ORGANIZATION",
    SOLO_PRO: "SOLO_PRO",
    ADMIN: "ADMIN",
    FREE: "FREE",
  },
  country: { India: "India", Bangladesh: "Bangladesh", Nepal: "Nepal" },
  indianCountryCode: "IN",
  USD: "USD",
  MIN_PASSWORD_LENGTH: 8,
  MKT_SITE_404: "/404page/",
  MKT_SITE_SOUND_DESIGN: "/sound-design",
  MKT_SITE_SOUND_DESIGN_LABEL: "SOUNDFX",
  MKT_SITE_VOICEGEN: "/voicegen",
  MKT_SITE_VOICEGEN_LABEL: "VOICE",
  MKT_SITE_PRICING_LABEL: "PRICING",
  MKT_SITE_ABOUT_LABEL: "ABOUT",
  MKT_SITE_BLOG_LABEL: "BLOG",
  MKT_SITE_INSPIRATION_LABEL: "INSPIRATION",
  MKT_SITE_MUSIC_LABEL: "MUSIC",
  PAGE_SIZE: 10,
  SALT: "Z7",
  RECAPTCHA_SITE_KEY: "6LfEGcwZAAAAAA3DsSBMyLvRt_kQcNPfkjYxA6PG",
  CANCEL_SUBSC_MSG: "Are you sure you want to cancel subscription!",
  VOICEGEN_URL: "http://localhost:4000/api/voicegen",
  MEWO_TRACK_SEARCH_BATCH_SIZE : 70,
  ACTION : {
    SEARCH : "AI SEARCH",
    SIMILARITY_SEARCH : "SEE SIMILAR SONGS",
    AUDIO_SEARCH : "AUDIO SEARCH",
    DOWNLOAD : "DOWNLOAD",
    FAVOURITE : "FAVOURITE",
    PLAY : "PLAY",
    CREATE_PROJECT : "CREATE PROJECT",
    ADD_TO_PROJECT :  "ADD TO PROJECT",
    REMOVE_FROM_PROJECT : "REMOVE FROM PROJECT",
    GENERATE_LICENSE : "GENERATE LICENSE",
    TTS : "USE VOICEGEN"

  },
  YEARLY_PLAN : "YEARLY PLAN",
  MONTHLY_PLAN : "MONTHLY PLAN",
  MISSING_LANG_REF : [{"langId" : "hi-IN", "langName" : "Hindi"}],
  DEFAULT_PAGE_SIZE: 20,
  ALBUM_TRACKS_DEFAULT_PAGE_SIZE : 100,
  
  FILE_UPLOAD_CANCELLED : 'File Upload Cancelled.',

  HARMIX_BASE_URL : "https://api.harmix.ai/",
  HARMIX_API_KEY : "CcKU93j3ABenXaJfDmRsXEgJDHmTXd",

  SM_TWITTER_LINK : "https://x.com/SyncMama",
  SM_YOUTUBE_LINK : "https://www.youtube.com/@SyncMama",
  SM_FACEBOOK_LINK : "https://www.facebook.com/SyncMama",
  SM_INSTA_LINK : "https://www.instagram.com/syncmama/",

  GA_MEASURMENT_ID : 'G-YCBW7MWWGJ',
  SUBSCRIPTION_TYPE : {
    "FREE" : "FREE",
    "SOLO" : "SOLO",
    "PRO" : "PRO",
    "CORPORATE" : "CORPORATE",
  },

  KB_LINKS : ['/about', '/terms', '/privacy', '/cookies', '/contact', '/faqs'],
  CHANNELS : {
    "youtube" : "Youtube",
    "facebook" : "Facebook",
    "instagram" : "Instagram",
    "snapchat" : "Snapchat",
    "x" : "X (ex-Twitter)",
    "website" : "Website",
    "rss" : "RSS"
  },

  SEARCH_TYPE : {
    "text" : "text",
    "youtube" : "youtube",
    "spotify" : "spotify",
    "audio_file" : "audio_file",
    "video_file" : "video_file",
    "track" : "track"
  },

  GA_MEASURMENT_ID : 'G-YCBW7MWWGJ'
});


export default Constants;
