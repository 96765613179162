import React, { useState, useEffect, useContext } from "react";
import TrackContext from "../../contexts/track-context";
import TrackItem from "../TrackItem";
import API from "../../services/api";
import Helper from "../../services/helper";
import { BouncingLoader } from "../../pure-components/Loader";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import { ReactComponent as CloseModal } from "../../assets/modal-close.svg";
import PlaylistTile from "./playlistTile";
import { useHistory, useParams } from "react-router-dom";
import RippleLoader from "../../assets/loader-2.gif";
import Amplitude from "amplitudejs";
import Constants from "../../services/constants";
import MewoService from "../../services/mewoService";
import { toast } from "react-toastify";
import { ErrorToast, SuccessToast } from "../../pure-components/CustomToast";

const PlaylistDetails = (props) => {
  const trackContext = useContext(TrackContext);
  const {playlistId} = useParams();
  const [playListTracks,setPlayListTracks]=useState([]);
  const [playlistDetails, setPlaylistDetails] = useState([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const history=useHistory()
  const [playAll,setPlayAll]=useState(false);
  const pageId='playlist'

  useEffect(() => {
    (async() => {
      await getPlaylistDetails();
      await getPlaylistTracks();
    })()
  },[]);
  useEffect(() => {
    if(trackContext.path.startsWith("/new-inspiration")){
    let filter=trackContext.trackFilter;
    filter.searchTitle="";
    trackContext.setTrackFilter(filter);
    localStorage.setItem("filter_playlist", JSON.stringify(filter));
    }
  },[trackContext.path]);

  const getPlaylistDetails = async() => {
    trackContext.setIsLoading(true);
    let reqObj = { 
      url : `public/playlists/${playlistId}`,
      method : 'get'
    };
    await MewoService.loadMewoData(reqObj).then((res) => {
        if (res) {
          setPlaylistDetails(res);
        }
      })
      .catch((err) => {
        trackContext.setIsLoading(false);
        toast(({ closeToast }) => <ErrorToast message={"Error Loading Playlist : " + err?.response?.data?.message}/>);
      });
  };

  const clearFilter = () => {
    setPlaylistDetails([]);
    trackContext.setTrackData([]);
    let filter=trackContext.trackFilter;
    filter.searchTitle="";
    trackContext.setTrackFilter(filter);
    if (trackContext.path.startsWith("/playlists/")
    ) {
     localStorage.setItem("filter_playlist", JSON.stringify(filter));
    }
   history.push( `/playlists/${playlistId}`)
    getPlaylistDetails();
    getPlaylistTracks();
  };

  const getPlaylistTracks = async() => {
    trackContext.setTrackData([]);
    trackContext.setIsLoading(true);
    let reqObj = {
      url : `public/playlists/${playlistId}/tracks/?page=0&max=100`,
      method : 'get'
    };
    await MewoService.loadMewoData(reqObj).then(res => {
        if (res?.data) {
          trackContext.setTrackData(res.data);
          setPlayListTracks(res.data);
          trackContext.setSongData(res.data);
        }
        trackContext.setIsLoading(false);
      })
      .catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={"Error Loading Playlist : " + err?.response?.data?.message}/>);
      });
  };

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
  };

  //to pause current playing track
  const handlePause=()=>{
    setPlayAll(false);
    trackContext.setPlayPause(false);
  }
  
  //function to fetch and play track one by one
  const playPlaylistTracks = async(id) => {
    if (!trackContext.validateUser(Constants.ACTION.PLAY)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.PLAY);
      return;
    }
    if (trackContext.trackData.length > 0) {
      let index = trackContext.trackData.findIndex(
        (track) => track.id === Amplitude.getSongs()[0]?.id
      );
      if (index !== -1 && trackContext.playingFrom === pageId) {
        setPlayAll(true);
        trackContext.setPlayPause(true);
        return;
      }
    }
    trackContext.setFetchingTrack(true);
    let reqObj = {
      url : `public/play/track/${playListTracks[0]?.id}`, 
      method : 'get'
    };
  
    await MewoService.loadMewoData(reqObj).then(res => {
        if (res) {
          trackContext.setalltrackData(trackContext.trackData);
          trackContext.setPlayPause(true);
          // let index=res.data.id === Amplitude.getSongs()[0]?.id;
          if (Amplitude.getSongs().length) {
            Amplitude.stop();
            Amplitude.removeSong(0);
          }
          trackContext.setPlayingFrom(pageId);
          trackContext.setCurrentTrack(res.audiofile);
          trackContext.setCurrentTrackDetail({
            ...res,
            ...res.tags,
            artist:
              res.artists_publishing_ownerships[0]?.artist?.full_name,
            artist_type:
              res.artists_publishing_ownerships[0]?.rights_type?.key,
          });
          trackContext.setCurrentTrackImage(res.album?.image?.xsmall?.url);
          setPlayAll(true);
        } else {
          Helper.handleError(res);
        }
        trackContext.setFetchingTrack(false);
      })
      .catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={"Error Loading Playlist Track : " + err?.response?.data?.message}/>);
      });
  };

  return (
    <>
      <div className="lg:px-8 items-center flex-1 pt-20 lg:pt-3 sm:mx-8 mx-8 lg:mx-20 mt-20">
        <PlaylistTile
            playlistDetails={playlistDetails} 
            playPlaylistTracks={playPlaylistTracks}
            playAll={playAll}
            setPlayAll={setPlayAll}
            handlePause={handlePause}
            tracks={playListTracks}
            pageId={pageId}
          />
       
        {
          trackContext.trackData?.map((track, index, arr) => {
            return (
              track.duration && (
                <TrackItem
                  tags={track.tags}
                  image={track.album.image}
                  key={track.id}
                  albumId={track.album}
                  title={track.display_title}
                  duration={track.duration}
                  previousTrack={index}
                  bpm={track?.bpm}
                  versions={track?.versions}
                  producer={track.master_ownerships[0]?.label?.label_name}
                  // artist={
                  //   track.artists_publishing_ownerships[0]?.artist?.full_name
                  // }
                  rights_type={
                    track.artists_publishing_ownerships[0]?.rights_type?.key
                  }
                  album={track.album.title}
                  trackId={track.id}
                  waveform={track?.waveform?.small?.url}
                  setFetchingTrack={trackContext.setFetchingTrack}
                  displayProjectModal={displayProjectModal}
                  similarity={track?.similarity}
                  description={track?.descriptions[0]?.value}
                  expandedTrackId={expandedTrackId}
                  setExpandedTrackId={setExpandedTrackId}
                  pageId={pageId}
                  artists={
                    track.display_artists
                  }
                  artist = {
                    track.display_artists[0]?.artist
                  }
                />
              )
            );
          })
        }
        {
          !trackContext.loading && trackContext.trackData?.length === 0 &&
          <div className="text-xl text-center text-red-500 ">No Tracks Found</div>
        }
        {trackContext.loading && <BouncingLoader />}
        {showProjectModal && (
          <AddTrackToProjectPopup
            trackToBeAddedToProject={trackToBeAddedToProject}
            closeProjectModal={closeProjectModal}
          />
        )}
        {trackContext.showLoaderMagic ?
            (
            <div class="modal-busy" overflow="hidden">
              <div>
                <div class="center-busy loader-text-tag" overflow="hidden">
                  <div className="loader-text">
                   This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you
                  </div>
                    <img src={RippleLoader} alt="loading..." className="onMobile img-tag" />

                </div>
              </div>
            </div>
            )
            : (
            ""
          )}
      </div>
    </>
  );
};

export default PlaylistDetails;