import React from 'react';
import './style.css';

export function RippleLoader({className}) {
  return (
    <div className={`ripple-loader ${className}`}>
      <div></div>
      <div></div>
    </div>
  )
}

export function BouncingLoader({page}) {
  return (<>
    <div className='bouncing-loader'>
      <div></div>
      <div></div>
      <div></div>
    </div>
    {page==='music'?
    <div className=" w-screen text-center  md:-ml-8 sm:-ml-20 lg:-ml-28   " style={{color:'darkgray'}}>
            <div className="sm:w-1/4 md:w-1/4 w-1/2" >
             This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you.
            </div>
          </div>
          :''}
    </>
  )
}

export function BarLoader() {
  return (
    <div className='bar-loader-wrapper'>
      <div className="bar-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export function PlayAnimation({children}) {
  return (
    <div class='v-loader'>
      <div></div>
      <div></div>
      <div></div>
      {children}
    </div>
  )
}

