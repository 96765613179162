import React, { useState, useEffect, useContext, useRef } from "react";
import TrackContext from "../../contexts/track-context";
import TrackItem from "../TrackItem";
import API from "../../services/api";
import AddTrackToProjectPopup from "../Projects/addTrackToProjectPopup";
import Helper from "../../services/helper";
import "./style.css";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as CloseModal } from "../../assets/modal-close.svg";
import RippleLoader from "../../assets/loader-2.gif";
import Amplitude from "amplitudejs";
import { Pause } from "@material-ui/icons";
import { PlayArrow } from "@material-ui/icons";
import "./style.css";
import { BouncingLoader } from '../../pure-components/Loader';
import Constants from "../../services/constants";
import MewoService from "../../services/mewoService";
import { toast } from "react-toastify";
import { ErrorToast } from "../../pure-components/CustomToast";

const AlbumDetails = (props) => {
  let ref = useRef(null);
  let trackContext = useContext(TrackContext);
  const history=useHistory()
  const [albumData, setAlbumData] = useState([]);
  const [albumDetails, setAlbumDetails] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [expandedTrackId, setExpandedTrackId] = useState("");
  const [playAll,setPlayAll]=useState(false);
  const [isFetching,setisFetching]=useState(false);
  const pageId='album'

  const [pageNo, setPageNo] = useState(0);
  const [allTracksLoaded, setAllTracksLoaded] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [initialized, setInitialized] = useState(false);


  const {albumId}=useParams();
  useEffect(() => {
    (async() => {
      await getAlbumDetails();
      await getAllAlbumTrack();
    })();
    
  }, [albumId]);

  const clearFilter = async() => {
    trackContext.setTrackData([]);
    let filter=trackContext.trackFilter;
    filter.searchTitle="";
    trackContext.setTrackFilter(filter);
    if (trackContext.path.startsWith("/album/")
    ) {
     localStorage.setItem("filter_album", JSON.stringify(filter)); 
    }
    history.push( `/album/${albumId}`)
    await getAlbumDetails();
    await getAllAlbumTrack();
  };

  const getAllAlbumTrack=async()=>{
    if(allTracksLoaded)
      return;

    //setting loader only for first time ie. page no : 0
    pageNo === 0 && trackContext.setIsLoading(true);

    let reqObj = { 
      url : `public/albums/${albumId}/tracks?page=${pageNo}&max=${Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE}`,
      method : 'get'
    };

    await MewoService.loadMewoData(reqObj).then(res => {
    // await API.getAllAlbumTracks(albumId, pageNo).then(res => {
      if(res?.data?.length)
      {
        let originals = res.data.filter(elem => {return elem?.version?.key === "original"});
        setAlbumData([...albumData, ...originals]);
        trackContext.setTrackData(res.data);
        trackContext.setSongData(res.data)
      }
      else if(!albumData){
        setNoDataFound(true);
      }
      //if list length is less than page size, its last page
      if(res?.data?.length < Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE){
        setAllTracksLoaded(true);
      }else{
        setPageNo(pageNo + 1);
      }
      trackContext.setIsLoading(false);
      setInitialized(true)
      setLoadMore(false)
    }).catch((err) => {
      trackContext.setIsLoading(false);
      setInitialized(true)
      setAllTracksLoaded(true);
      toast(({ closeToast }) => <ErrorToast message={"Album Tracks Loading Error : " + err?.response?.data?.message}/>);
    })
  }

  const getAlbumDetails=async()=>{
    trackContext.setIsLoading(true);
    let reqObj = {
      url : `public/albums/${albumId}`,
      method : 'get'
    };
    await MewoService.loadMewoData(reqObj).then(res => {
      if(res)
        setAlbumDetails(res);
      else
        setNoDataFound(true);

    }).catch((err) => {
      trackContext.setIsLoading(false);
      setInitialized(true)
      setAllTracksLoaded(true);
      toast(({ closeToast }) => <ErrorToast message={"Album Details Loading Error : " + err?.response?.data?.message}/>);
    })
  };

  useEffect(()=>{
    //to show play or pause button 
    let index=albumData?.findIndex((track)=> track.id === trackContext?.currentTrackDetail?.id)
    if(index !== -1 && index!==undefined  && trackContext.playPause && trackContext.playingFrom === pageId){
      setPlayAll(true)
    }
    else {
      setPlayAll(false)
    }
},[trackContext.playPause,albumData])

  //to pause current playing track
  const handlePause=()=>{
    setPlayAll(false);
    trackContext.setPlayPause(false);
  }

    //function to fetch track for albums and play tracks
    const playAlbumTracks=async()=>{
      if(trackContext.trackData.length > 0){
        let index=trackContext.trackData.findIndex(track=>track.id === Amplitude.getSongs()[0]?.id)
        if(index !== -1 && trackContext.playingFrom === pageId)
        {
          setPlayAll(true);
          trackContext.setPlayPause(true);
          return
        }
      }

      trackContext.setFetchingTrack(true);
      setisFetching(true)
      await API.playTrack(albumData[0].id).then(res => {
        if(res?.data?.success){
          trackContext.setalltrackData(trackContext.trackData)
          trackContext.setPlayPause(true);
          let index=res.data.id === Amplitude.getSongs()[0]?.id;
          if (Amplitude.getSongs().length && index!==0) {
            Amplitude.stop();
            Amplitude.removeSong(0);
          }
          trackContext.setPlayingFrom(pageId)
          trackContext.setCurrentTrack(res.data.data.audiofile);
          trackContext.setCurrentTrackDetail({...res.data.data,...res.data.data.tags,
            artist: res.data.data.artists_publishing_ownerships[0]?.artist?.full_name,
            artist_type: res.data.data.artists_publishing_ownerships[0]?.rights_type?.key,});
          trackContext.setCurrentTrackImage(res.data.data.album?.image?.xsmall?.url);
          setPlayAll(true);
        }else{
          Helper.handleError(res.data);
        }
        trackContext.setFetchingTrack(false);
        setisFetching(false);
      })
      .catch(err=>{
        Helper.handleError(err);
      })
  }



  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  };

  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreTracks, options);
      let target = document.querySelector("#load-more-tracks");
      observer.observe(target);
    }
  });


  const loadMoreTracks = (entries, observer) => {
    // console.log("loading more",allTracksLoaded, loadMore, isLoading)
    entries.map((entry) => {
      if (entry.isIntersecting) {
        if (allTracksLoaded) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
      }
    });
  };

  useEffect(() => {
    if(loadMore){
      (async()=>{
        await getAllAlbumTrack();
      })()
    }
  },[loadMore])

  return (
    <>
      <div className=" sm:flex sm:flex-col sm:w-full p-5">
      {noDataFound && (
        <div className="text-xl text-center text-red-500">
          No Data Found
        </div>
        )}
      
      {
        (albumData?.length > 0 && <div className="pb-3 border-b">
          <div className="w-full row">
            <div className=" w-full lg:w-2/6 rounded-md " >
              <img className="w-full  rounded-md" src={albumDetails?.image?.large?.url} alt="albumInfo" onError={(e)=>trackContext.setDefaultImage(e)} />
            </div>
            <div className="w-full lg:w-4/6 px-3 pl-10 ">
              <h1 className="mb-2 text-xl" >{albumDetails?.title}</h1>
              <h1 className=" mb-1 sm:flex text-albuminfo text-sm"> Album by <span className="text-albuminfo-data flex-row self-center text-all"> {Helper.getArtistName(albumDetails?.display_artists)?.toLowerCase()}</span></h1>
              <h1 className=" mb-1 sm:flex text-albuminfo text-sm"> Label: <span className="text-albuminfo-data flex-row self-center text-all" >{albumDetails?.catalog?.name}</span></h1>
              <h1 className=" mb-1 sm:flex text-albuminfo text-sm"> Ref: <span className="text-albuminfo-data flex-row self-center text-all" >{albumDetails?.album_ref}</span></h1>
              {/* <h1 className="ml-6  text-albuminfo "> Album id: <span className="text-albuminfo-data flex-row self-center text-all">{albumData?.data[0]?.album?.id}</span></h1> */}
              <h1 className="w-full lg:w-3/5 text-justify text-description flex-row self-center text-all"> {albumDetails?.descriptions[0]?.value}</h1>
              
            </div>
          </div>
          <div className="w-full row" style={{"marginTop": "-36px"}}>
            <div className=" w-full lg:w-2/6"></div>
            <div className=" w-full lg:w-4/6 px-3 pl-10 ">
              <div className="w-full" >
                <div className=" cursor-pointer album-button-container"   >
                {(!playAll) ? (<button disabled={isFetching} className="album-play-button" onClick={playAlbumTracks} >
                  <span><PlayArrow className="outline-none cursor-pointer" /></span>
                  <span>Play</span>
                  </button>)
                  :( <button className="album-pause-button"  onClick={handlePause}>
                  <span><Pause className="outline-none cursor-pointer" /></span>
                  <span>Pause</span>
                  </button>)}
                </div>
              </div>
            </div>
          </div>
        </div>)}
        
        {
          //  trackContext.trackData.filter( ( eachTrack ) => eachTrack.tags.some( ( eachTag ) => eachTag.key === 'contemporary-classical') ).map((track,index,arr) =>
          albumData?.length > 0 && albumData?.map((track, index, arr) => {
            return (
              !trackContext.loading && track.duration && (
                <TrackItem
                  //   setSearchTags={props.setSearchTags}
                  tags={track.tags}
                  image={track.album.image}
                  //   searchTags={props.searchTags}
                  key={track.id}
                  albumId={track.album}
                  title={track.display_title}
                  duration={track.duration}
                  previousTrack={index}
                  bpm={track?.bpm}
                  versions={track?.versions}
                  producer={track.master_ownerships[0]?.label?.label_name}
                  artist={
                    track.artists_publishing_ownerships[0]?.artist?.full_name
                  }
                  rights_type={
                    track.artists_publishing_ownerships[0]?.rights_type?.key
                  }
                  album={track.album.title}
                  trackId={track.id}
                  waveform={track?.waveform?.small?.url}
                  // setTrackUrl={props.setTrackUrl}
                  setFetchingTrack={trackContext.setFetchingTrack}
                  // changeCurrentTrackImage={props.changeCurrentTrackImage}
                  displayProjectModal={displayProjectModal}
                  // handleCurrentTrackDetail={props.handleCurrentTrackDetail}
                  similarity={track?.similarity}
                  description={track?.descriptions[0]?.value}
                  expandedTrackId={expandedTrackId}
                  setExpandedTrackId={setExpandedTrackId}
                  pageId={pageId}
                  artists={
                    track.display_artists
                  }
                />
              )
            );
          })
        }
        {!allTracksLoaded && initialized && (
          <div id="load-more-tracks" ref={ref} className="p-20">
           { loadMore ? <BouncingLoader /> : ""}
          </div>
        )}
        {showProjectModal && (
            <AddTrackToProjectPopup
              trackToBeAddedToProject={trackToBeAddedToProject}
              closeProjectModal={closeProjectModal}
            />
          )}
       
        {trackContext.showLoaderMagic ?
            (
            <div class="modal-busy" overflow="hidden">
              <div>
                <div class="center-busy loader-text-tag" overflow="hidden">
                  <div className="loader-text">
                   This is Maya, your self-learning neural engine. I am searching the most relevant tracks for you
                  </div>
                    <img src={RippleLoader} alt="loading..." className="onMobile img-tag" />

                </div>
              </div>
            </div>
            )
            : (
            ""
          )}
      </div>
    </>
  );
};

export default AlbumDetails;