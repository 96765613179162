import React, { useEffect, useState } from 'react';
// import TrackContext from '../../contexts/track-context';
import API from '../../services/api';

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function Admin() {
  const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [users, setUsers] = useState({});
  const [countUser, setCountUser] = useState([])

  useEffect(() => {
    /* let query=[
         {
           '$project': {
             'lable': 'count', 
             'downloadedTracks': {
               '$sum': {
                 '$size': '$downloadedTracks'
               }
             }
           }
         }, {
           '$group': {
             '_id': '$lable', 
             'total': {
               '$sum': '$downloadedTracks'
             }
           }
         }
       ]*/
    let query =  [
        {
            '$project': {
                'month': {
                    '$month': '$createdAt'
                },
                'year': {
                    '$year': '$createdAt'
                }
            }
        }, {
            '$group': {
                '_id': {
                    'month': '$month',
                    'year': '$year'
                },
                'total': {
                    '$sum': 1
                },
                'month': {
                    '$first': '$month'
                },
                'year': {
                    '$first': '$year'
                }
            }
        }, {
            '$match': {
                'year': new Date().getFullYear()
            }
        }
    ]
    API.getLicAggregate(query).then(res => {
      if (res.success) {
        setCountUser(res.data);
        chartData(new Date().getFullYear(),res.data)
      }
    })
  },[]);

  const chartData=(year,countUser=countUser) => {
    let filterdata=countUser.filter(item=>item.year===year);
    // console.log(filterdata)
    if(filterdata.length){
      filterdata =  filterdata.sort((a,b)=>(a.month > b.month) ? 1 : -1);
      let xaxis=month.splice(0,filterdata[filterdata.length-1].month);
      let ydata=[];
      // filterdata.forEach(item=>{
      //   ydata[item.month]=item.total;
      // });
      let i=1;
      while(i<=filterdata[filterdata.length-1].month)
      {
        let filtermonth=filterdata.filter(item=>item.month===i);
        ydata[i-1]=filtermonth.length? filtermonth[0].total: 0;
        i++;
      }
      // console.log(ydata,xaxis)
      let chartopt = {
      chart: {
        height:"350px"
      },
      title: {
        text: "Number of Licenses generated"
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      },
      xAxis: {
        categories: xaxis
      },
      yAxis: {
        title: {
          text: 'No. of License generated'
        }
      },
      series: [
        {
          name: 'Month',
          data: ydata,
          type: 'column'

        }]
    };
    setUsers(chartopt);
  }
  }
  // let trackContext = useContext(TrackContext);
  return (
    
          <HighchartsReact highcharts={Highcharts} options={users}  />
       
  )
}
