import React from "react";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import HarmixService from "../../../services/harmixService";
import { toast } from "react-toastify";
import { ErrorToast } from "../../../pure-components/CustomToast";
import CookieManager from "../../../services/CookieManager";
import { BouncingLoader } from "../../../pure-components/Loader";
import { ReactComponent as ThumbPrintIcon } from '../../../assets/auto_fix_high_black_36dp.svg';
import AddTrackToProjectPopup from "../../Projects/addTrackToProjectPopup";
import Track from "./track";
import { ReactComponent as CloseModal } from "../../../assets/modal-close.svg";
import { useParams, useHistory } from "react-router-dom";

import textFileLogo from "../../../assets/logo/text-file-icon.png";
import spotifyLogo from "../../../assets/logo/spotify-icon.png";
import youtubeLogo from "../../../assets/logo/youtube-icon.png";
import fileUploadLogo from "../../../assets/Upload Icon.png";
import Constants from "../../../services/constants";
import axios from 'axios';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload_icon.svg';
import { ReactComponent as AIIcon } from '../../../assets/icons/ai-rectangle_icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/search.svg';

import SegmentSelector from "./segmentSelector";
import MewoService from "../../../services/mewoService";
import defaultImage from "../../../assets/default_music.png";
import { Link } from 'react-router-dom';
import Helper from "../../../services/helper";
import ToggleSwitch from "../../../pure-components/ToggleSwitch/ToggleSwitch";
import UploadIconGIF from "../../../assets/icons/upload_icon.gif"
const HarmixMusic = (props) => {
  
  const ref = useRef(null);
  const hiddenFileInput = useRef(null);
  const history=useHistory()
  const videoRef = React.createRef();
 
  const filterType = Constants.SEARCH_TYPE;
  const [searchText,setSearchText] = useState("");
  
  const getMusicFilter = () => {
    return CookieManager.getCookie("harmix-music-filter")?.length > 0 ? JSON.parse(CookieManager.getCookie("harmix-music-filter")) : { "searchText" : "", "type" : filterType.text};
    
  }
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const pageSize = 30;
  const [allDataLoaded, setAllDataLoaded] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [trackList, setTrackList] = useState([]);
  const [musicFilter, setMusicFilter] = useState(getMusicFilter());
  const {assetId}=useParams();

  const [processing, setProcessing] = useState(false);

  const [showProjectModal, setShowProjectModal] = useState(false);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState("");
  
  const [expandedTrackId, setExpandedTrackId] = useState("");
  
  const [showProgress,setShowProgress]=useState(false);
  const [progress,setProgressValue]=useState(0);
  const [cancelToken,setCancelToken]=useState(); // token for cancel file upload request 
  const [searchedTrackData, setSearchedTrackDate] = useState(null);

  const setDefaultMusicFilter = () => {
    if(!musicFilter){
      setMusicFilter({ "searchText" : "", "type" : filterType.text});
      CookieManager.setCookie("harmix-music-filter", JSON.stringify({ "searchText" : "", "type" : filterType.text}));
    }
  } 

  const setMusicFilterData = (filter) => {
    // console.log("setting filter : ", filter)
    if(filter){
      setMusicFilter(filter);
      CookieManager.setCookie("harmix-music-filter", JSON.stringify(filter));
    }
  } 

  useEffect(() => {
    const filter = getMusicFilter();

    //if assetId and filter data not matching
    //setting page default  
    if(filter?.type === filterType.track && assetId !== filter?.searchRef){
      setMusicFilter({ "searchText" : "", "type" : filterType.text})
      CookieManager.setCookie("harmix-music-filter", JSON.stringify({ "searchText" : "", "type" : filterType.text}));
      history.push("/library");
      return;
    }
    if(assetId){
      //get track data for segment selection
      (async() => {
          await getTrackInfo(assetId).then((resp) => {
            // console.log("searchedTrackData : ", searchedTrackData)
            setSearchedTrackDate(resp);
          });
      })();
    }
    setMusicFilter(filter);
    (async() => {
      await loadTrackList(getFilterObj(filter), true);
    })();
    
  }, [assetId]);

  

  useEffect(() => {
    (async() => {
      await loadTrackList(getFilterObj());
    })()
    
  }, [])

  useEffect(() => {
    if(musicFilter.type === filterType.text || musicFilter.type === filterType.youtube || musicFilter.type === filterType.spotify)
      setSearchText(musicFilter.searchText);
    // console.log("useEffect MF: ", musicFilter)
  }, [musicFilter])

    
  useEffect(() => {
    // console.log(pageNo)
  }, [pageNo])

  useEffect(() => {
    if(loadMore)
      (async() => {
        await loadTrackList(getFilterObj());
      })()
    
  }, [loadMore])

  // useEffect(() => {
  //   if(musicFilter){
  //     let filter = {};
  //     switch(musicFilter.type){
  //       case "text": filter = {"prompt" : musicFilter.searchText};
  //       break;
  //       case "youtube":
  //       case "spotify":
  //       case "file": 
  //         if(musicFilter.searchRef)
  //           filter = {
  //               "audio_reference": {
  //               "external": {
  //                   "upload_id": musicFilter.searchRef
  //               }
  //             }
  //           }
  //       break;

  //     }
  //     if((musicFilter.type==='text' && !loading) || (musicFilter.type !== 'text' && musicFilter.searchRef))
  //       (async() => {
  //         await loadTrackList(filter, true);
  //       })();
  //   }
  // }, [musicFilter]);

  const getTrackInfo = async(trackId) => {
      if(!trackId?.length)
        return null;

      let reqObj = {  
        url : `public/play/track/${trackId}`, 
        method : 'get',
      };
      
      return await MewoService.loadMewoData(reqObj).then(async function(resp) {
        return resp;
      }).catch(function(error){
        console.log(`TrackDataLoadingError for Track : ${trackId}`, error);
        return;
      });
  }


  const getFilterObj = (filter = null) => {
    if(filter === null)
      filter = {...musicFilter};
    if(filter){
      let tempfilter = null;
      switch(filter.type){
        case filterType.text: tempfilter = {"prompt" : filter.searchText};
        break;
        case filterType.youtube:
        case filterType.spotify:
        case filterType.audio_file: 
          if(filter.searchRef)
            tempfilter = {
                "audio_reference": {
                "external": {
                    "upload_id": filter.searchRef
                }
              }
            }
        break;
        case filterType.video_file: 
          if(filter.searchRef)
            tempfilter = {
                "video_reference": {
                  "upload_id": filter.searchRef
                }
              }
        break;

        case filterType.track: 
          if(filter.searchRef)
            tempfilter = {
              "audio_reference": {
                "internal": {
                  "primary_key": {
                      "source_id": filter.searchRef,
                      // "version_tag": "<version_tag>",
                      // "vocal": true
                  }
                }
              }
            }
          if(filter.segment)
            tempfilter.audio_reference.segment = getselectedSegment(filter);
          tempfilter.audio_reference.ignore_vocals = filter.ignore_vocals || false;
          tempfilter.audio_reference.prioritize_bpm = filter.prioritize_bpm || false;
        break;

      }
      return tempfilter;
    }
  }
  // useEffect(() => {

  // }, [trackList])

  const getselectedSegment = (filterObj) => {

    if(filterObj?.segment && filterObj.segment?.start && filterObj.segment?.end){
      return {start : parseInt(filterObj.segment?.start), end : parseInt(filterObj.segment?.end) }
    }
    return {start : 0, end : parseInt(searchedTrackData.duration)}
  }
  const loadTrackList = async(filter, initial = false) => {
    if(!filter){
      console.log("No filter found to load the track list");
      return;
    }
    setLoading(true);
    // console.log("page no : ", pageNo)
    let offset = pageNo*pageSize;
    if(initial){
      setPageNo(0);
      offset = 0;
      setTrackList([])
      setAllDataLoaded(false);
    }
    let reqObj = {
      url : `search?offset=${offset}&limit=${pageSize}`,
      method : 'post',
      data: JSON.stringify( filter )
    };
    await HarmixService.executeRequest(reqObj).then((resp) => {
      // console.log("response data : ", resp)
      let tempDataList = pageNo > 0 ? [...trackList] : [];
      if(initial)
        tempDataList = [];

      if(resp?.tracks?.length > 0)
        tempDataList.push(...resp?.tracks)

      
      if(resp?.tracks?.length < pageSize || resp.tracks_number < pageSize){
        setAllDataLoaded(true);
      }else{
        setAllDataLoaded(false);
        setPageNo(pageNo + 1);
      }

      setTrackList(tempDataList);
      // console.log("track list : ", trackList);
    }).catch((err) => {
      console.log("Track List Loading Error : ", err);
      //Modify the error handling when array returned as error
      toast(({ closeToast }) => <ErrorToast  message={err?.detail?.message ? `${err.detail.code} : ${err.detail.message}` : `${err.detail}`}/>);
      setAllDataLoaded(true);
    });
    setLoadMore(false)
    setLoading(false);
  }

  const getFilter = () => {
    //store filters with types/category in local storage, add them as needed
    //track all filter separatly, 
    //like audio/video upload search, prompt, youtube/spotify link search
    //pagination should follow the searh_id returned in response [need to discuss to Harmix]
    //if want to keep tags search from MEWO, maintain it separatly.

    
    //default filter as nothing in prompt

    //checking for link
    if(searchText && searchText.indexOf('https://') !== -1){
      //youtube/spotify link, search like that

    }else if(searchText?.length > 0){
      //prompt search 
      return {
        "prompt" : searchText
      }  
    }else{
      //default filter
      return {
        "prompt" : ""
      }
    }
  }



  const handleSearch = async(e) => {
    
    // searchText = setSearchText(searchText + e.target.value)
    if(e.key === 'Enter' && !loading){
      // console.log(e.key, searchText);
      //resetting routes
      history.push("/library");
      setPageNo(0);
      setTrackList([])
      if(searchText?.indexOf('https://') !== -1){
        //youtube/spotify link, search via upload
        await searchViaUpload(searchText);
      }else{
        setMusicFilterData({"searchText" : searchText, type : filterType.text});
        await loadTrackList({"prompt" : searchText}, true);
      }
    }
  };

  const handleSearchClick = async() => {
    
    if(!loading){
      //resetting routes
      history.push("/library");
      setPageNo(0);
      setTrackList([])
      if(searchText?.indexOf('https://') !== -1){
        //youtube/spotify link, search via upload
        await searchViaUpload(searchText);
      }else{
        setMusicFilterData({"searchText" : searchText, type : filterType.text});
        await loadTrackList({"prompt" : searchText}, true);
      }
    }
  };

  const handleUploadClick = event => {
    hiddenFileInput.current.value=null;
    hiddenFileInput.current.click();
  };

  const uploadAudioFile=async(file)=>{
    setSearchText("");
    let fileName=""
    if(file){
      //resetting routes
      history.push("/library");
      
      fileName=file.name
      let data=new FormData();
      data.append('file',file);
      if(file.type.indexOf("video") > -1){
        const videoURL = URL.createObjectURL(file);
        document.getElementById("video-player").setAttribute("src", videoURL);
        let filterData = {};
        filterData["type"] = filterType.video_file;
        filterData["searchText"] = file.name;
        setMusicFilterData(filterData);
      }else{
        let filterData = {};
        filterData["type"] = filterType.audio_file;
        filterData["searchText"] = file.name;
        setMusicFilterData(filterData);
        await searchViaUpload("", file)
      }
    }else{
      console.log("File processing error");
    }
  } 

  const searchViaUpload = async(url = "", fileData = null) => {
    setProcessing(true);
    let data = new FormData();
    let source;
    //youtube/spotify URL
    if(url?.length > 0){
      let filterData = {};

      if(url.indexOf('youtube') > -1){
        data.append("type", "youtube-audio");
        filterData["type"] = filterType.youtube; 
      }else{
        data.append("type", "spotify");
        filterData["type"] = filterType.spotify;
      }
      data.append("reference", url)
      filterData["searchText"] = url;
      
      setMusicFilterData(filterData);
    }else{
      //TODO : segrigate payload logic for audio or video upload
      // if(fileData?.type?.indexOf("video") > -1){
      //   let duration;
      //   const videoURL = URL.createObjectURL(fileData);
      //   // var video = document.getElementById('video-player');
      //   // video.preload = 'metadata';
      //   // video.src = URL.createObjectURL(fileData);
        
      //   // video.onloadedmetadata = function() {
      //   //   window.URL.revokeObjectURL(video.src);
      //   //   duration = video.duration;
      //   // }

      //   // // video.src = URL.createObjectURL(fileData);
      //   // URL.revokeObjectURL(video.src);

      //   // $("#video-player").prop("src", videoURL);
      //   document.getElementById("video-player").setAttribute("src", videoURL);
      //   duration = document.getElementById("video-player").duration;
      //   //videoRef.current.src = videoURL;
      //   //URL.revokeObjectURL(videoRef.current.src);
      //   const videoElement = videoRef.current;
      //   if (!videoElement) {
      //     console.error("Video is not available.");
      //     return;
      //   }
      //   const snapshots = await createSnapshots(duration, document.getElementById("video-player"));
      //   console.log("Generated Snapshots:", snapshots);
      //   snapshots.forEach((fileData) => data.append("source", fileData));
      //   data.append("type", "manual-video");
      // }else{
      //   data.append("type", "manual-audio");
      //   data.append("source", fileData);
      // }
      if(fileData){
        data.append("type", "manual-audio");
        data.append("source", fileData);
      }else{
        const snapshots = await createSnapshots(0, videoRef);
        // console.log("Generated Snapshots:", snapshots);
        snapshots.forEach((fileData) => data.append("source", fileData));
        data.append("type", "manual-video");
      }
      
      const CancelToken = axios.CancelToken;
      source = CancelToken.source();
      setCancelToken(source);
    
    }

    let reqObj = {
      url : `upload`,
      method : 'post',
      data: data
    };
    await HarmixService.executeRequest(reqObj, source ? (event) => {
      setShowProgress(true);
      let progress = Math.round((100 * event.loaded) / event.total);
      setProgressValue(progress);
    } : null,source ? source : null).then(async(resp) => {
      // console.log("uploaded response data : ", resp)
      if(resp.status === "success"){
        // const filterObj = {
        //   "audio_reference": {
        //     "external": {
        //         "upload_id": resp.upload_id
        //     }
        //   }
        // }
        setPageNo(0);
        setTrackList([])
        setProcessing(false);
        const tempFilter = getMusicFilter();
        // console.log("music filter : ", tempFilter)
        setMusicFilterData({...tempFilter, searchRef : resp.upload_id});

        await loadTrackList(getFilterObj({...tempFilter, searchRef : resp.upload_id}), true);
      }else{
        console.log("Something wrong with uploading reference track data : ", resp)
      }
    }).catch((err) => {
      console.log("Track reference upload Error : ", err);
      //Modify the error handling when array returned as error
      if(err.message !== Constants.FILE_UPLOAD_CANCELLED)
        toast(({ closeToast }) => <ErrorToast  message={err?.detail?.message ? `${err.detail.code} : ${err.detail.message}` : `${err.detail}`}/>);
    });
    setProcessing(false);
    setLoadMore(false)
    setLoading(false);
    
    setCancelToken();
    setShowProgress(false);
  }
  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreData, options);
      let target = document.querySelector("#load-more-data");
      observer.observe(target);
    }
  });


  const createSnapshots = async (duration, player) => {
    // $("#video-player").on("canplaythrough", function(e){
    //   duration = e.currentTarget.duration;
    // });
    duration = document.getElementById(
      "video-player").duration;
    const video = player.current;
    const videoElement = document.getElementById("video-player");
    // console.log("video element : ". player, duration, videoElement.metadata, videoElement.getAttribute("duration"))
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (!context) {
      console.error("Canvas context is not available.");
      return [];
    }

    const times = [5, duration * 0.25, duration * 0.5, duration * 0.75, duration - 5];
    const newSnapshots = [];

    for (const time of times) {
      video.currentTime = time;

      await new Promise((resolve) => {
        video.onseeked = async () => {
          try {
            const targetWidth = 226;
            const targetHeight = 226;

            const tempCanvas = document.createElement("canvas");
            const tempContext = tempCanvas.getContext("2d");

            if (!tempContext) {
              console.error("Temporary canvas context is not available.");
              resolve();
              return;
            }

            tempCanvas.width = targetWidth;
            tempCanvas.height = targetHeight;
            tempContext.drawImage(video, 0, 0, targetWidth, targetHeight);

            tempCanvas.toBlob(
              (blob) => {
                if (blob) {
                  const snapshotFile = new File([blob], `snapshot_${time}.jpeg`, {
                    type: "image/jpeg",
                  });
                  newSnapshots.push(snapshotFile);
                } else {
                  console.error("Failed to convert canvas to blob.");
                }
                resolve();
              },
              "image/jpeg",
              1.0
            );
          } catch (error) {
            console.error(`Error generating snapshot for time ${time}:`, error);
            resolve();
          }
        };
      });
    }

    return newSnapshots;
  };
  
  const loadMoreData = async(entries, observer) => {
    entries.map(async(entry) => {
      if (entry.isIntersecting) {
        if (!allDataLoaded && !loading && !processing) {
          setLoadMore(true);
        }
      }
    });
  };

  const displayProjectModal = (trackId) => {
    setTrackToBeAddedToProject(trackId);
    setShowProjectModal(true);
  };

  const closeProjectModal = () => {
    setShowProjectModal(false);
  };

  const removeSearchFilter = (type) => {
    let tempFilter = {...musicFilter}
    switch(type){
      case filterType.text : 
        tempFilter = {...musicFilter, "searchText" : "", "type" : filterType.text};
        setSearchText("");
        break;
      case filterType.youtube:
        setSearchText("");
        tempFilter = {...musicFilter, "searchText" : "", "type" : filterType.text, "searchRef" : ""};
        break;
      case filterType.spotify:
        setSearchText("");
        tempFilter = {...musicFilter, "searchText" : "", "type" : filterType.text, "searchRef" : ""};
        break;
      case "file":
      case filterType.audio_file:
      case filterType.video_file:
        tempFilter = {...musicFilter, "searchText" : "", "type" : filterType.text, "searchRef" : ""};
        break;
      case filterType.track:
        tempFilter = {...musicFilter, "searchText" : "", "type" : filterType.text, "searchRef" : "", segment : null, ignore_vocals: false, prioritize_bpm: false};
        break;
    }
    // console.log("temp filter ; ", tempFilter)
    setMusicFilter(tempFilter);
    CookieManager.setCookie("harmix-music-filter", JSON.stringify(tempFilter));
    
    //if removing 'track' type filter, URL need to reset    
    if(type === filterType.track)
      history.push('/library')

    setTrackList([]);
    setPageNo(0);
    loadTrackList(getFilterObj(tempFilter), true);
      
  }
  const getSearchText = () => {
    let elem = "";
    switch(musicFilter.type){
      case filterType.text : 
        elem = <div className="rounded-full filter-tile-pink text-title px-2 mx-1 pb-1 row-line">
          <img
            src={textFileLogo}
            alt="logo"
            className="h-3.5 mt-1 mr-1"
            title="text search"
          />
          {musicFilter.searchText}
          <CloseModal className="closefilter mt-1.5 ml-3 cursor-pointer" onClick={() => removeSearchFilter(musicFilter.type)} />
        </div>
        break;
      case filterType.youtube : 
        elem = <div className="rounded-full filter-tile-red text-title px-2 mx-1 pb-1 row-line">
          <img
            src={youtubeLogo}
            alt="logo"
            className="h-4 mt-0.5 mr-1"
            title="youtube link search"
          />
          {musicFilter.searchText}
          <CloseModal className="closefilter mt-1.5 ml-3 cursor-pointer" onClick={() => removeSearchFilter(musicFilter.type)} />
        </div>
        break;
      case filterType.spotify : 
        elem = <div className="rounded-full filter-tile-green text-title px-2 mx-1 pb-1 row-line">
          <img
            src={spotifyLogo}
            alt="logo"
            className="h-3.5 mt-1 mr-1"
            title="spotify link search"
          />
          {musicFilter.searchText}
          <CloseModal className="closefilter mt-1.5 ml-3 cursor-pointer" onClick={() => removeSearchFilter(musicFilter.type)} />
        </div>
        break;
      case filterType.audio_file :
      case filterType.video_file : 
        elem = <div className="rounded-full filter-tile-blue text-title px-2 mx-1 row-line">
          {/* <img
            src={fileUploadLogo}
            alt="logo"
            className="h-4 mt-1 mr-1"
            title="file reference search"
          /> */}
          <UploadIcon className="pr-1"  height={"28px"} width={"28px"} style={{'marginTop' : "-5px"}}/>
          {musicFilter.searchText}
          <CloseModal className="closefilter mt-1.5 ml-3 cursor-pointer" onClick={() => removeSearchFilter(musicFilter.type)} />
        </div>
        break;
      
      case filterType.track : 
        elem = <div className="rounded-full filter-tile-gray text-title px-2 mx-1 py-1 row-line">
          <ThumbPrintIcon className="pr-1" style={{height : "20px", width : "auto"}} />
          {musicFilter.searchText}
          <CloseModal className="closefilter mt-1.5 ml-3 cursor-pointer" onClick={() => removeSearchFilter(musicFilter.type)} />
        </div>
        break;

    }

    return (elem)
  }
  const loadFilteredData = async() => {
    const tempFilter = getMusicFilter();
    setMusicFilter(tempFilter);
    await loadTrackList(getFilterObj(tempFilter), true)
  }

   //cancelling file upload
   const cancelUpload=async()=>{
    const source = cancelToken;
    source.cancel(Constants.FILE_UPLOAD_CANCELLED)
    setCancelToken();
    removeSearchFilter("file");
    await HarmixService.executeRequest('','',source).then(async()=> {
      setShowProgress(false);
      await loadFilteredData(getFilterObj());
    })
  }
  
  const setAdditionalFilterData = (key, value) => {
    console.log("toggled : ", key, value)
    const tempFilter = musicFilter;
    if(key =="ignore_vocals" || key == "prioritize_bpm"){
      tempFilter[key] = value;
    }
    setMusicFilter(tempFilter);
    CookieManager.setCookie("harmix-music-filter", JSON.stringify(tempFilter));
    
  }

  return (
    <>
      <div className="items-center pt-20 lg:pt-3 lg:mx-20">
        {/* <div className="w-full flex pt-20">
          <div style={{"margin-top": "-4px"}}>
            <AIIcon  height={"50px"} width={"50px"}/>
          </div>
          <div className="rounded-full aisearch border-pink w-full flex flex-wrap ">
            
            <input
              // onFocus={(e) => {handleSearch(e)}}
              // onChange={(e) => {handleSearch(e.target.value)}}
              onChange={event => { setSearchText(event.target.value) }}
              onKeyDown={handleSearch}
              className={`self-center w-11/12 bg-white cursor-pointer placeholder-textGray-600 rounded-full`}
              // type="text"
              placeholder="Enter a prompt. Paste a YouTube/Spotify link. Upload a reference audio track or your video."
              disabled={loading}
              value={searchText}
            />
            <span className="w-1/12 flex justify-end">
              <button onClick={handleUploadClick} disabled={processing || loading} className="justify-end border-0 p-0 mb-0" title="Click to upload an audio file.">
              <UploadIcon  height={"35px"} width={"35px"}/>
              <input type="file" accept=".mp3,.m4a,.wav,.mp4" style={{ display: "none" }} onChange={(e) => uploadAudioFile(e.target.files[0])} ref={hiddenFileInput} /></button>
            </span>
          </div>
        </div> */}
        <div className="w-full flex pt-20">
          <div className="rounded w-full flex p-1" style={{"borderWidth": "2px"}}>           
            <textarea id="search_box" className="p-1 search-box outline-none border border-textGray-300 text-gray-600"
              onChange={event => { setSearchText(event.target.value) }}
              onKeyDown={handleSearch}
              placeholder="Enter a prompt. Paste a YouTube/Spotify link. Upload a reference audio track or your video."
              disabled={loading}
              value={searchText}
              name="search_box" rows="1" cols="60" />
            <span className="flex justify-start">
              <span onClick={handleUploadClick} disabled={processing || loading} className="justify-end cursor-pointer">
              <img
                src={UploadIconGIF}
                alt="logo"
                className="h-10 mt-2"
                title="Click to upload an audio file."
              />
              <input type="file" accept=".mp3,.m4a,.wav,.mp4" style={{ display: "none" }} onChange={(e) => uploadAudioFile(e.target.files[0])} ref={hiddenFileInput} />
              </span>
            </span>
          </div>
        </div>
        {musicFilter.type === filterType.track && assetId && searchedTrackData && <div className="w-full flex flex-wrap my-1 mx-16" >
          <div className="w-11/12 border rounded-2xl bg-gray-100 px-4">
          <div className="w-full flex border rounded-2xl bg-gray-300">
            <div className="w-5/12 flex">
              <div className="w-4/12 flex self-center">
              {searchedTrackData?.album?.id !=null && searchedTrackData?.album?.image?.small?.url != null ? (
                <Link className="self-center pl-4" to={`/album/${searchedTrackData?.album?.id}`}>
                  <img
                    src={searchedTrackData?.album?.image?.small?.url || defaultImage }
                    alt="Album image"
                    className="hidden sm:flex sm:cursor-pointer"
                    width="100px"
                    onError={()=>{this.src = defaultImage}}
                    />
                </Link>) : (
                  <img
                    alt="Album image"
                    className="hidden sm:flex sm:cursor-pointer"
                    width="100px"
                    src={defaultImage}
                  />)}
              </div>
              <div className="w-8/12 pt-2" style={{"margin-top": "-54px;"}}>
                  <div className="track-title text-sm self-center">
                      {" "}
                      {searchedTrackData?.display_title?.toLowerCase()}
                  </div>
                  <div className="track-artist text-sm self-center">
                      {" "}
                      {Helper.getArtistName(searchedTrackData.display_artists)?.toLowerCase()}{" "}
                  </div>
                  <div className="text-sm self-center">
                      {" "}
                      {new Date(searchedTrackData.duration * 1000).toISOString().substr(14, 5)}
                  </div>
              </div>
            </div>
            <div className="w-7/12 mx-4">
              <SegmentSelector trackData={searchedTrackData}/>
            </div>
          </div>
          <div class="w-full flex">
            <div className="w-2/12 flex">
              <ToggleSwitch label={"Prioritize BPM"} id={"prioritize_bpm"} value={musicFilter.prioritize_bpm} setValue={setAdditionalFilterData} />
            </div>
            <div className="w-2/12">
              <ToggleSwitch label={"Ignore Vocals"} id={"ignore_vocals"} value={musicFilter.ignore_vocals} setValue={setAdditionalFilterData} />
            </div>
            <div className="w-8/12 flex justify-end">
              <button className={'w-4/6 greenbuttonwide'}
                    // style={{width:"12%"}}
                    onClick={() => loadFilteredData()}
                  >           Search
                  </button>
            </div>
          </div>
          </div>
        </div>}
        {!processing && !loading && musicFilter?.searchText?.length > 0 &&
          <div className="w-full flex flex-wrap my-2 mx-1" >
            <b>Search results for :</b> {getSearchText()}
          </div>
        }
        <div className="border-t my-2"></div>
        {(showProgress)  && (
        <div>
        <div className="mt-6 flex text-center ">

        </div>
        <div className="progressbar-main">
        <h3 className="progress-header">Your file is {progress}% uploaded, please wait...</h3>
            <div className="progressbar-container">
              <div >
                <progress className="progressbar" value={progress} max="100" min="0" ></progress>
              </div>
              <div>
                <button
                onClick={() => cancelUpload()}
                className="pt-2 pb-2 pl-4 border-0 cancel-upload pr-4 cancel-btn signin-btn font-bold text-sm mt-3"
                type="button"
              >Cancel Upload</button>
              </div>
            </div>
        </div>
        </div>
        )}

        <div className="w-full">
          {!showProgress && processing && 
            <div className="text-xl text-center text-green-500 my-5">
              We are processing your request, please wait...
            </div>
          }
          {!processing && loading &&  
            <div className='w-full'>
              <BouncingLoader />
            </div>
          }
            {/* <React.Fragment > */}
              <video id="video-player" ref={videoRef} controls style={{ marginTop: "20px", width: "100%", display: "none" }} onLoadedMetadata={searchViaUpload}></video>
            {/* </React.Fragment> */}
          {!processing && !loading && !(trackList  ?.length > 0) ? (
            <div className="text-xl text-center text-red-500 my-5">
              No Tracks Found
            </div>
          ) : 
          (<>
            
            {!processing && trackList?.map((track, index, arr) => (
                <Track 
                  image={track?.metadata?.album_cover_url}
                  albumId={track?.metadata?.album_id}
                  //   searchTags={props.searchTags}
                  key={track?.metadata?.source_id}
                  title={track?.metadata?.track_title}
                  duration={track?.metadata?.duration}
                  previousTrack={index}
                  bpm={track?.metadata?.bpm}
                  versions={track?.metadata?.versions_number}
                  // producer={track.master_ownerships[0]?.label?.label_name}
                  // artist={
                  //   track.artists_publishing_ownerships[0]?.artist
                  //     ?.full_name
                  // }
                  // rights_type={
                  //   track.artists_publishing_ownerships[0]?.rights_type?.key
                  // }
                  album={track.metadata?.album_name}
                  trackId={track?.metadata?.source_id}
                  waveform={track?.metadata?.waveform_url}
                  //setFetchingTrack={props.setFetchingTrack}
                  displayProjectModal={displayProjectModal}
                  similarity={track?.score}
                  description={track?.metadata?.track_description}
                  expandedTrackId={expandedTrackId}
                  setExpandedTrackId={setExpandedTrackId}
                  pageId={"library_tracks"}
                  artists={
                    JSON.parse(track?.metadata?.extra_parameters?.artists ? track?.metadata?.extra_parameters?.artists : "[]")
                  } 
                  producers={
                    JSON.parse(track?.metadata?.extra_parameters?.producers ? track?.metadata?.extra_parameters?.producers : "[]")
                  }
                  loadTrackList={loadFilteredData}
                />
            ))}
          </>)
          }
          {!allDataLoaded &&!loading && (
            <div id="load-more-data" ref={ref} className="p-2">
            <BouncingLoader />
            </div>
          )}
        </div>
      </div>
    {showProjectModal && (
      <AddTrackToProjectPopup
        trackToBeAddedToProject={trackToBeAddedToProject}
        closeProjectModal={closeProjectModal}
      />
    )}
    </>
  );
};

export default HarmixMusic;