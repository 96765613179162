import React, { useState, useEffect, useContext } from 'react';
import CardContent from '@material-ui/core/CardContent';
import API from '../../services/api';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TrackAction from '../TrackItem/trackAction';
import TrackContext from '../../contexts/track-context';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Pause } from '../../assets/pause.svg';
import Amplitude from 'amplitudejs';
import { ReactComponent as Add } from '../../assets/GreenButtonRoundPlusSmall.svg';
import { ReactComponent as DisableAdd } from '../../assets/disableAdd.svg';
import { ReactComponent as Project } from '../../assets/project.svg';
import { ReactComponent as Edit } from '../../assets/edit.svg';
import { ReactComponent as Delete } from '../../assets/delete.svg';
//import { ReactComponent as Edit1 } from '../../assets/edit1.svg';
import { ReactComponent as Delete1 } from '../../assets/delete1.svg';
//import FavoriteIcon from '@material-ui/icons/Favorite';
import MobileAction from './mobileAction';
//import { ReactComponent as Success } from '../../assets/success.svg';
//import {ReactComponent as Points} from '../../assets/vPoints.svg';
import CookieManager from '../../services/CookieManager';
import dayjs from 'dayjs';
import Pagination from '@material-ui/lab/Pagination';
import Constants from '../../services/constants';
import Helper from '../../services/helper'
import ConfirmationPopup from '../../pure-components/ConfirmationPopup';
import MobilePopup from '../../pure-components/MobilePopup';
import { BouncingLoader } from "../../pure-components/Loader";
import AddTrackToProjectPopup from "./addTrackToProjectPopup";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import DownloadIcon from "@material-ui/icons/GetAppSharp";

import DuplicateIcon from "@material-ui/icons/FileCopyOutlined";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ErrorToast, SuccessToast, WarningToast } from '../../pure-components/CustomToast';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { CircularProgress } from '@material-ui/core';
import SvgInline from "../../pure-components/SvgInLine";
import MewoService from '../../services/mewoService';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

export default function Projects() {
  let trackContext = useContext(TrackContext);
  const [currentTrack, setCurrentTrack] = useState('')
  const [project, setProject] = useState({});
  const [projects, setProjects] = useState([]);
  const [searchProjects, setSearchProjects] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
  const [trackToBeRemoveToProject, setTrackToBeRemoveToProject] = useState('');

  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [favs, setFavs] = useState([]);
  const [addProject, setAddProject] = useState({ title: false });
  const [totalProject, setTotalProject] = useState(0);
  const [totalFavs, setTotalFavs] = useState(0);
  const [historyCount, setHistoryCount] = useState(0);
  const [favUpdated, setFavUpdated] = useState("")
  const [historyUpdated, setHistoryUpdated] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(parseInt(process.env.REACT_APP_PAGE_SIZE));
  const [totalProjects, setTotalProjects] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [expanded, setExpanded] = useState({ index: -1, isOpen: false })
  const [searchKey, setSearchKey] = useState('')
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [download, setDownload] = useState([]);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editProjectObj, setEditProjectObj] = useState({});
  const [currentProjectIndex,setCurrentProjectIndex]=useState(0);
  const [projectIndex,setProjectIndex]=useState(0);//holds index of project from which track is played

  const [showTrackModal, setShowTrackModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadProjectData, setDownloadProjectData] = useState();
  const [downloadModalPosition, setDownloadModalPosition] = useState(null);
  const [projectDownloading, setProjectDownloading] = useState(false);
  //currently downloading 1 project at a time
  const [downloadingProjects, setDownloadingProjects] = useState([]);

  const [savingProject, setSavingProject] = useState(null);

  const [showDeleteTrackConfirmPopup, setShowDeleteTrackConfirmPopup] = useState(false);
  const [deletedTrackIndex, setDeletedTrackIndex] = useState("");
  const [deletedTrackFromProject, setDeletedTrackFromProject] = useState("");
  const [deletedTrackMsg, setDeletedTrackMsg] = useState("");
  const [trackDataLoading, setTrackDataLoading] = useState(false);
  // useEffect(() => {
  //   console.log("test 1")
  //   fetchProjects(currPage, pageSize, searchKey);
  // }, []);

  useEffect(() => {
     //console.log("Project Data : ", searchProjects);
  },[searchProjects])
  //rerendering track ui on song prev or next
  useEffect(() => {
    let historyId=trackContext.currentTrackDetail.historyId;
    if(historyId !==undefined && historyId !==null){
      setCurrentTrack(historyId);
    }
  }, [trackContext.currentTrackDetail,currentTrack])

  //adding trackId's to trackContext.trackData to work play previous and next track
  const setTrackData=(index)=>{
    const trackIds=searchProjects[index]?.trackIds?.map((track,index)=>{
      return {
        "id":track.mewoTrackId,
        "_id":track?.track_index
      }
    })
    trackContext.setTrackData(trackIds);
    return trackIds
  }

  useEffect(() => {
    if (localStorage.getItem('favs') !== 'undefined') {
      const favorites = JSON.parse(localStorage.getItem('favs'));
      // const favArray = favorites.map( track => {
      //   return track.trackId.mewoTrackId;
      // });
      favorites !== null && setFavs(favorites);
    };
    if (localStorage.getItem('download') !== 'undefined') {
      const download = JSON.parse(localStorage.getItem('download'));
      download !== null && setDownload(download);
    };
  }, []);

  const handlePagination = (e, pagenumber) => {
    // console.log(e.target.innerText);
    //let temp = e.target.innerText;
    setCurrPage(pagenumber);
  }
  const handleClose = () => {
    setShowTrackModal(false);
  }
  useEffect(() => {
    (async () => {
      await fetchProjects(currPage, pageSize, searchKey);
    })();
  }, [currPage])

  const fetchProjects = async(currPage, pageSize, searchText) => {
    await API.getProjects(currPage, pageSize, searchText).then(data => {
      if (data.success) {
        setProjects(data.data);
        setTotalProject(data.totalCount)
        setSearchProjects(data.data);
        //adding track_index to track for uniqueness as there can be duplicate tracks
        data.data.forEach(project=>project.trackIds.map((track,index)=>track['track_index']=track._id + index))
        setTotalProjects(data.totalCount)
        setTotalPages(Math.ceil(data.totalCount / pageSize));
      } else {
        let errorMsg = data.mssg;
        if (data.code === "401") {
          errorMsg += ", please signin again, if error persist please contact admin!";
        }
        toast(({ closeToast }) => <ErrorToast message={errorMsg} />);
      }
      setIsLoading(false);

    });
  }
  
  useEffect(() => {
    API.getHistoryTracks(1, 1).then(res => {
      setHistoryCount(res.totalCount);
      if (res?.history?.length)
        setHistoryUpdated(dayjs(res.history[0].updatedAt).format('DD.MM.YYYY'))
    })
  }, []);

  const handleAddProject = (e) => {
    let { value } = e.target;
    let newProject = { ...project };
    newProject[e.target.name] = value;
    setProject(newProject);
  }
  const createProject = async(e) => {
    if(!(project?.title?.length > 0))
      return;
    e.stopPropagation();
    await API.createProject({ ...project }).then(async res => {
      if (res.success === false) {
        // console.log('Response create Project:', res);
        toast(({ closeToast }) => <ErrorToast message={`Error Creating Project : ${res?.mssg}`} />); 
      } else {
        setProject({});
        setAddProject({});
        await fetchProjects(currPage, pageSize, searchKey);
      }
    }).catch((err) => {
      toast(({ closeToast }) => <ErrorToast message={`Error Creating Project : ${err.status}: ${err?.message}`} />); 
    });
  }
  const handleAddTrackToFavorites = async(trackId) => {
    setShowTrackModal(false)
    if (!trackContext.validateUser(Constants.ACTION.FAVOURITE)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.FAVOURITE);
      return;
    }

    await API.addTrackToFavorites(trackId).then((res) => {
      if (res.success) {
        localStorage.setItem("favs", JSON.stringify(res.favorites));
        setFavs(res.favorites);
      } else {
        Helper.handleError(res);
      }
    });
  }


  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  }

  const loadProjects = async() => {
    await fetchProjects(currPage, pageSize, searchKey);
  }

  const fetchTrack = (trackId, isAltVersion, index,track_index) => {
    if (track_index === currentTrack) {
      trackContext.setPlayPause(!trackContext.playPause);
      return;
    }
    trackContext.setLoadingTrack(true);
    API.playTrack(trackId).then(res => {
      trackContext.setLoadingTrack(false);
      if(res?.data?.success){
        setCurrentTrack(track_index);
        setProjectIndex(currentProjectIndex)
        trackContext.setalltrackData(trackContext.trackData)
        trackContext.setPlayPause(true);
        if (Amplitude.getSongs().length) {
          Amplitude.stop();
          Amplitude.removeSong(0);
        }    
        res.data.data['historyId']=track_index    
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail(res.data.data)
        trackContext.setCurrentTrackImage(res.data.data.album?.image.xsmall.url)
      }else{
        trackContext.setLoadingTrack(false);
        Helper.handleError(res?.data);
      } 
    });
    if (isAltVersion) {
      fetchAltVersion(trackId, true, index)
    }
  }
  const fetchAltVersion = (trackId, expandval = false, index) => {
    API.getAlternateVersions(trackId).then(res => {
      // console.log('[ALT VERSIONS]', res);
      if(res?.success){
        if (res.data.length) {
          // console.log("IF");
          let newprojects = searchProjects;
          let expandedIndex = expanded.index;
          let project = { ...newprojects[expandedIndex] };
          let track = { ...project.trackIds[index] };
          track.alternateVersions = res.data;
          track.expand = expandval;
          project.trackIds[index] = { ...track }
          newprojects[expandedIndex] = { ...project };
          setSearchProjects([...newprojects]);
        }
      }else{
        Helper.handleError(res);
      }
    });
  }

  const handleSearch = Helper.debounce((searchText) => {
    setCurrPage(1);
    setSearchKey(searchText)
    fetchProjects(1, pageSize, searchText);
  }, 500)
  
  const getTracksInfo = async (trackIds) => {
    let errMsg = "Info : No record found for Track(s) : ";
    let errorOccured = false;
    let trackData  = {};
    const taskArr = trackIds.map(async(trackId, index) => {
      if(!trackId?.length)
        return;

      let reqObj = {  
        url : `public/play/track/${trackId}`, 
        method : 'get'
      };
      
      await MewoService.loadMewoData(reqObj).then(async function(resp) {
        if(resp){
          // console.log("trackData : ", resp);
          trackData[trackId] = {
            album : resp.album, 
            duration : resp.duration,
            mewoTrackId : resp.id,
            mewoTrackUrl : resp.audiofile,
            waveform : resp.waveform,
            title : resp.title

          };
        }
        return;
      }).catch(function(error){
        console.log(`TrackDataLoadingError for Track : ${trackId}`, error);
        errorOccured = true;
        errMsg += `${trackId}, `      
        return;
      });
    });
  
    return await Promise.allSettled(taskArr).then(async results => {
      if(errorOccured)
        toast(({ closeToast }) => <WarningToast message={errMsg} />);
        return trackData;
    }).catch(err => {
      console.log("TrackDataLoadingError : ", err)
    })
  }

  const editProject = async(newindex) => {
    setCurrentProjectIndex(newindex);
    setTrackDataLoading(true);
    const { index, isOpen } = expanded;
    if (newindex >= 0) {
      let missingDetailsTrackIds  = [];
      let newprojects = searchProjects;
      let project = { ...newprojects[newindex] };
      project.trackIds.forEach(async(item) => {
        item.alternateVersions = [];
        if((item.trackId == undefined || item.trackId == null) && (newindex !== index || !isOpen))
          missingDetailsTrackIds.push(item.mewoTrackId);
      });
      if(missingDetailsTrackIds?.length > 0){
        const trackInfo = await getTracksInfo(missingDetailsTrackIds);
        // console.log("trackInfo Arr : ", trackInfo);
        const updatedTrackData = project.trackIds?.map(item => {
          if(trackInfo[item.mewoTrackId]){
            item.trackId = trackInfo[item.mewoTrackId];
          }
          return item;
        });
        // console.log("updated track data : ", updatedTrackData)
        project.trackIds = updatedTrackData;
      }
      newprojects[newindex] = project;
      setSearchProjects(newprojects)
    }
    setEditProjectObj(searchProjects[newindex]);
    let newexpanded = { index: newindex, isOpen: newindex !== index ? true : !isOpen };
    setExpanded(newexpanded);
    setTrackData(newindex);
    setTrackDataLoading(false);
  }
  const deleteProject = (projectId, index) => {
    API.deleteProject(selectedAdmin.id).then(res => {
      // console.log('res delete project : ', res);
      if (res.success === false && res.code === '401') {
        trackContext.setShowSubscribePopup(true);
      } else {
        let newprojects = [...searchProjects];
        newprojects.splice(selectedAdmin.index, 1);
        setShowConfirmPopup(false)
        setSearchProjects(newprojects);
      }
    })

  }
  const updateProject = Helper.debounce((newTitle, index, title) => {
    let newProject = [...searchProjects];
    let obj = {};
    obj[title] = newTitle;
    newProject[index][title] = newTitle;
    setSearchProjects(newProject);
    API.updateProject(newProject[index]._id, { ...obj }).then(res => {

    });
  }, 500)
  const deleteTrackFromProject = async() => {
    let projectId = deletedTrackFromProject;
    let trackIndex = deletedTrackIndex;
    setShowTrackModal(false)
    setSavingProject(projectId);
    if (!trackContext.validateUser(Constants.ACTION.REMOVE_FROM_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.REMOVE_FROM_PROJECT);
      return;
    }
    let tempProject = searchProjects.filter(project => project._id === projectId);
    if (tempProject[0]?.trackIds?.length >= trackIndex) {
      tempProject[0].trackIds.splice(trackIndex, 1);
      //setting-up the "editProjectObj" for consolidated data save
      handleProjectDataChange(tempProject[0].trackIds, "tracks");
      await saveProject();
      setShowDeleteTrackConfirmPopup(false);
      setDeletedTrackFromProject("");
      setDeletedTrackIndex("");
      setDeletedTrackMsg("");
      let project=searchProjects.map((project) => {
        if (project._id === projectId) {
          return { ...project, trackIds: tempProject[0].trackIds };
        }
        return project;
      })
      setSearchProjects(project);
      setTrackData(currentProjectIndex);
      //resetting trackContext.alltrackData if track is deleted
      let index = searchProjects.findIndex(project => project._id === projectId);
      if (index === projectIndex && currentTrack !=='')
        trackContext.setalltrackData(setTrackData(index))
    }
    setSavingProject(null);
  }

  const deleteTrack = (projectId, projectName, trackName, trackIdx) => {
    setShowDeleteTrackConfirmPopup(true);
    setDeletedTrackIndex(trackIdx);
    setDeletedTrackFromProject(projectId);
    setDeletedTrackMsg(`Are you sure, you want to delete track : ${trackName}, from Project : ${projectName} ?`)
  }
  const setConfirmPopup = () => {
    setShowConfirmPopup(false)
  }

  const handleChange = async (project) => {
    if(!project?.trackIds?.length){
      toast(({ closeToast }) => <ErrorToast message={"No tracks added in the project, add some tracks and try again."} />);
      return;
    }
    let cachedUserData = JSON.parse(CookieManager.getCookie('user-data'));
    if (!trackContext.validateUser(Constants.ACTION.GENERATE_LICENSE)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.GENERATE_LICENSE);
      return;
    }
    let userData = {id : cachedUserData._id};
    if(cachedUserData?.userType === "ADMIN" || cachedUserData?.userType === "SUPER_ADMIN")
      userData = {id : cachedUserData.email, userType : "ADMIN"};
    let resp = await API.validateSubscription(userData.id, userData.userType);
    if (resp?.success) {
      if (resp.data?.userType === "FREE" || !resp.data?.subscribed) {
        trackContext.setShowSubscribePopup(true);
        trackContext.setUserAction(Constants.ACTION.GENERATE_LICENSE);
      } else {
        CookieManager.setCookie("user-data", JSON.stringify(resp.data));
        window.location = "/license/" + project._id;
      }
    } else {
      let error = "Error while generating license : " + resp?.mssg;
      toast(({ closeToast }) => <ErrorToast message={error} />);
    }

  }
  const generateLicense = (project) => {
    if(!project?.trackIds?.length){
      toast(({ closeToast }) => <ErrorToast message={"No tracks added in the project, add some tracks and try again."} />);
      return;
    }else{
      if (!trackContext.validateUser(Constants.ACTION.GENERATE_LICENSE)) {
        trackContext.setShowSubscribePopup(true);
        trackContext.setUserAction(Constants.ACTION.GENERATE_LICENSE);
        return;
      }
      window.location = `/license/${project._id}`
    }
  }

  const handleSetAddProject = () => {
    if (!trackContext.validateUser(Constants.ACTION.ADD_TO_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.ADD_TO_PROJECT);
      return;
    }
      setAddProject({ title: true });

  }
  const handleProjectModal = (trackId, projectTrackId) => {
    setShowTrackModal(false)
    if (!trackContext.validateUser(Constants.ACTION.ADD_TO_PROJECT)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.ADD_TO_PROJECT);
      return;
    }
    setTrackToBeAddedToProject(trackId);
    setTrackToBeRemoveToProject(projectTrackId);
    setShowProjectModal(true);

  }

  // duplicate track
  const duplicateTrack = (value, parentIndex) => {
    setSearchProjects(
      searchProjects.map((prow, pindex) => {
        if (pindex === parentIndex) {

          return { ...prow, trackIds: [...prow.trackIds, value] };
        }
        return prow;
      })
    );
    //managing "editProjectObj" for consolidated data save
    let tracks = editProjectObj.trackIds;
    tracks.push(value);
    handleProjectDataChange(tracks);
  };

  // handle dragable events
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    let parentIndex = Number(result.source.droppableId.charAt(0));
    let searchProjects_ = searchProjects;
    const items = Array.from(searchProjects_[parentIndex].trackIds);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    searchProjects[parentIndex].trackIds = items;
    setSearchProjects(searchProjects);
    setTrackData(currentProjectIndex);
    handleProjectDataChange(items, "tracks");
  };

  const handleProjectDataChange = (value, valueType) => {
    let tempProject = editProjectObj;
    if (valueType === 'title')
      tempProject.title = value;
    else if (valueType === 'description')
      tempProject.description = value;
    else
      tempProject.trackIds = value;
    setEditProjectObj(tempProject);
  }

  const saveProject = async() => {
    setIsLoading(true);
    //moulding updated project data
    const tracks = editProjectObj?.trackIds?.map(elem => {
      if(!elem?.trackId?._id){
        delete elem.trackId;
      }
      return elem;
    });
    editProjectObj.trackIds = tracks;
    await API.updateProject(editProjectObj._id, editProjectObj).then((res) => {
      if (res.success) {
        toast(({ closeToast }) => (
          <SuccessToast message={"Project : " + editProjectObj.title + " updated Successfully :)"}/>
        ));
      } else {
        toast(({ closeToast }) => (
          <ErrorToast message={"Project : " + editProjectObj.title + " could not be updated! " + res.mssg}/>
        ));
      }
    }).catch((err) => {
      console.log("Error Updating Project : ", err);
    });
    fetchProjects();
    setExpanded({ index: 0, isOpen: false });
    setEditProjectObj({});
  }

  const downloadPopupClose = () => {
    setDownloadProjectData(null)
    setShowDownloadModal(false);
  };

  const downloadProject = (event, project) => {
    if(!project?.trackIds?.length){
      toast(({ closeToast }) => <ErrorToast message={"No tracks added in the project, add some tracks and try again."} />);
      return;
    }
    if(downloadingProjects?.length > 0 || downloadProjectData){
      toast(({ closeToast }) => <WarningToast message={`Project : "${downloadProjectData?.title}" is currently downloading. Try after completion.`} />);
      return;
    }
    
    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      setProjectDownloading(false);
      return;
    }
    
    setDownloadModalPosition(event.target)
    setShowDownloadModal(true); 
    setDownloadProjectData({projectId : project._id, ...project})
  };

  const handleProjectDownloadOld = async(fileType, projectName) =>{
    setShowDownloadModal(false);
    addDownloadingProjects(downloadProjectData?.projectId)
    let msg = "Your request is being processed, " + (fileType === 'original' ? "please wait." : "download will begin shortly.");
    toast(({ closeToast }) => <SuccessToast message={msg} />);
  
    await API.getProjectTracksZipped(downloadProjectData?.projectId, fileType)
      .then((res) => {
        //console.log('res zip download : ', res);
        if (res?.data?.success) {
          if(fileType != 'original'){
            window.open(res.data.downloadUrl, '_blank');
            if(res.data.message?.length > 0){
              toast(({ closeToast }) => <WarningToast message={res.data.message} />);
              toast(({ closeToast }) => <SuccessToast message={`Downloading... ${downloadProjectData?.title ? 'Project : '+downloadProjectData.title : 'Project'}`} />);
            }else
              toast(({ closeToast }) => <SuccessToast message={`Downloading... ${downloadProjectData?.title ? 'Project : '+downloadProjectData.title : 'Project'}`} />);
          }else
            toast(({ closeToast }) => <SuccessToast message={res.data.message} />);
        }else{
          toast(({ closeToast }) => <ErrorToast message={"ProjectTracksZippingError : " +(res?.data?.mssg?.length ? res.data.mssg : res?.data?.message)} />);
        }
          removeDownloadingProjects(res.data.projectId);
      })
      .catch(err => {
        toast(({ closeToast }) => <ErrorToast message={"ProjectTracksZippingError : " + err?.message} />);
        removeDownloadingProjects(downloadProjectData?.projectId)
      });
    setDownloadProjectData(null)
  }

  const handleProjectDownload = async(fileType) => {
    setShowDownloadModal(false);
    addDownloadingProjects(downloadProjectData?.projectId)
    toast(({ closeToast }) => <SuccessToast message={"Your request is being processed, download will begin shortly."} />);
  
    const filesDataArr = [];
    const trackIds = [];
    let errMsg = "Info : No record found for Track(s) : ";
    let errorOccured = false;
    const taskArr = downloadProjectData.trackIds.map(async(track, index) => {
      //handling duplicate tracks download
      if(trackIds?.includes(track?.mewoTrackId))
        return;

      trackIds.push(track?.mewoTrackId);

      let reqObj = {  
        url : `public/tracks/${track?.mewoTrackId }/files`, 
        method : 'get'
      };
      
      await MewoService.loadMewoData(reqObj).then(async function(resp) {
        if(resp?.audiofile){
          filesDataArr.push(resp.audiofile[fileType]);
        }
        return;
      }).catch(function(error){
        console.log(`TrackDownloadError for Track : ${track?.trackId?.title}`, error);
        errorOccured = true;
        errMsg += `${track?.trackId?.title}, `      
        return;
      });
    });
  
    await Promise.allSettled(taskArr).then(async results => {
      if(errorOccured)
        toast(({ closeToast }) => <WarningToast message={errMsg} />);

      await createLocalFile(filesDataArr);
      
    }).catch(err => {
      removeDownloadingProjects(downloadProjectData?.projectId);
      setDownloadProjectData(null);
      console.log("TrackDownloadError : ", err)
    })
    
  }

  const createLocalFile = async(filesDataArr) => {
    const zip = new JSZip();
    let count = 0;
    const zipFilename = `${downloadProjectData.title}.zip`;
    filesDataArr.forEach(async function (fileData){
      if(fileData){
        const filename = fileData.file_name;
        try {
          const file = await JSZipUtils.getBinaryContent(fileData.url)
          zip.file(filename, file, { binary: true});
          count++;
          if(count === filesDataArr.length) {
            await zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);
              removeDownloadingProjects(downloadProjectData?.projectId);
              setDownloadProjectData(null);
            });
          }
        } catch (err) {
          console.log('Track file downloading error : ',err);
        }
      }
    });
  }
  const addDownloadingProjects = (projectId) => {
    let temp = downloadingProjects;
    temp.push(projectId);
    setDownloadingProjects(temp);
  }

  const removeDownloadingProjects = (projectId) => {
    if(downloadingProjects?.length > 0 && projectId?.length > 0 ){
      let temp = downloadingProjects;
      temp.pop(projectId);
      setDownloadingProjects(temp);
    }
  }
  return (
    <>
      {isLoading ?
        <div className='lg:w-1/2'>
          <BouncingLoader />
        </div> :
        <div className='lg:w-3/4'>
          <div className='px-4 pt-20 md:px-0'>
            <h1 className='text-3xl font-bold mt-1 text-black' > Hello, {trackContext.userDetails.first_name}</h1>
            <h2 className='text-lg self-center text-textGray'> You have {totalProjects} Projects </h2>
          </div>
          <div className="flex w-full mt-2 lg:mt-6 px-4 md:px-0">
            <div className="w-full lg:pr-12">
              <div className="flex w-full">
                <h1 className='hidden lg:block lg:w-1/3 text-base font-bold text-black' > My Projects</h1>
                <div className="w-full lg:w-2/3 lg:justify-end flex">
                  {!addProject.title ?
                    <Add className="mr-2 addbutton cursor-pointer" onClick={handleSetAddProject} /> :
                    <DisableAdd className="mr-2 pointer-events-none " />
                  }
                  <input
                    onChange={(e) => { handleSearch(e.target.value) }}
                    // onChange={handleSearch}
                    type="text"
                    placeholder='Search Projects'
                    className="w-4/5 lg:w-2/5 input-search h-8"
                  />
                </div>
              </div>
              {addProject.title &&
                <>
                  <div className="w-full p-8 transform scale-105 mt-4">
                    <div className="lg:flex w-full">
                      <div className="lg:w-3/4">
                        <input
                          autoFocus={true}
                          onChange={handleAddProject}
                          name="title"
                          className='lg:w-3/5 text-xl focus:outline-none font-semibold  border-b bg-transparent'
                          placeholder='Project Name...'
                        />
                        <div onClick={() => setAddProject({ title: true, description: !addProject.description })}
                          className="w-full cursor-pointer pt-2 text-lg">Add Description</div>
                      </div>
                      
                      <div className="lg:text-right sm:w-2/12 sm:text-sm sm:self-center cursor-pointer lg:block ml-2">
                        <div className={project?.title?.length > 0 ? "greenbutton" : "button" }
                        onClick={(e) => createProject(e)}
                        disabled={!(project?.title?.length > 0)} >
                          Create Project
                        </div>
                      </div>
                    </div>
                  </div>
                  {addProject.description &&
                    <div className="w-full bg-black pl-8 pt-4 pr-3 pb-3 bg-textGray-200  transform scale-105 mt-4">
                      <div className="w-full text-black text-lg">Description</div>
                      <textarea id="descripition" className="p-4 w-full lg:w-9/12 outline-none border border-textGray-300 text-textGray-100"
                        onChange={handleAddProject}
                        name="description" rows="1" cols="60" />
                    </div>
                  }
                </>
              }
              {projectDownloading ? 
              <div className='lg:w-full'>
                <BouncingLoader />
              </div>: 
              <div className="w-full pt-4">
                {
                  searchProjects.map((project, index) =>
                    <div key={project._id} className="w-full">
                      <div
                        className={`flex border-b border-symStrokeGrey ${expanded.index === index && expanded.isOpen ? "hidden" : ""}`}>
                        <div className={`cursor-pointer w-10 h-10 p-3 border self-center rounded-full linearGradient-${index % 15}`}
                          onClick={() => {
                            editProject(index);
                          }}>
                          <Project />
                        </div>
                        <CardContent className='w-4/6 self-center cursor-pointer' onClick={() => {
                          editProject(index);
                        }}>
                          <p className='text-sm font-semibold text-black capitalize break-all'  > {project.title} </p>
                          <div className='text-xs self-center text-textGray-100'> 
                          <p>
                            {project.trackIds?.length} tracks
                          </p> 
                          <p>
                            Edited on : {dayjs(project.updatedAt).format('DD.MM.YYYY')}
                          </p>
                          </div>
                        </CardContent>
                        <div className="w-2/6 lg:w-2/6 flex self-center justify-end items-center">
                          <span className="hidden lg:block cursor-pointer ml-2" >
                            <Edit height="18" width="18" onClick={() => {
                              editProject(index);
                            }} />
                          </span>
                          <span className="hidden lg:block cursor-pointer ml-2" >
                            <Delete height="18" width="18"
                              onClick={() => {
                                //deleteProject(project._id, index)
                                setSelectedAdmin({ id: project._id, index });
                                setShowConfirmPopup(true);
                              }
                              }
                            />
                          </span>
                          <span className="hidden lg:block cursor-pointer ml-2" >
                          {project.trackIds?.length > 0 && (
                            downloadingProjects.includes(project._id) ? 
                            <CircularProgress size='15px'/> : 
                            <DownloadIcon
                              onClick={(event) => downloadProject(event, project)}
                              width="30"
                            />)
                          }
                          </span>
                          {project.trackIds?.length > 0 && (
                            (project.licenseUrl === '' || typeof project.licenseUrl === 'undefined') ?
                              <>
                                <Link className="hidden md:block w-10/12 lg:w-6/12">
                                  <div className={`ml-3 pinkbutton`}
                                    onClick={() => handleChange(project)}
                                    style={{fontSize : "10px"}}
                                  >Generate Licence
                                  </div>
                                </Link>
                                <div className={`md:hidden ml-3 pinkbutton`}
                                  onClick={() => { setShowMobilePopup(true); }}
                                  style={{fontSize : "10px"}}
                                >Generate Licence
                                </div>
                              </>
                              :
                              (new Date(project.licenseUpdatedDate) < new Date(project.updatedAt)) ?
                                <>
                                  <Link className="hidden md:block w-10/12 lg:w-6/12">
                                    <div className={`ml-3 pinkbutton`}
                                    onClick={() => generateLicense(project)}
                                    style={{fontSize : "10px"}}
                                    // style={{paddingLeft:"1.8rem",paddingRight:"1.8rem"}}
                                    >Update Licence
                                    </div></Link>
                                  <div className={`md:hidden ml-3 pinkbutton`}
                                    onClick={() => { setShowMobilePopup(true); }}
                                    style={{fontSize : "10px"}}
                                  >Update Licence
                                  </div>
                                </> : <>
                                  <Link to={`/license/${project._id}`} className="hidden md:block w-10/12 lg:w-6/12">
                                    <div className={`ml-3 greenbutton`}
                                      style={{fontSize : "10px"}}
                                    // style={{paddingLeft:"1.8rem",paddingRight:"1.8rem"}}
                                    >View Licence
                                    </div></Link>
                                  <div className={`md:hidden ml-3 greenbutton`}
                                    onClick={() => { setShowMobilePopup(true); }}
                                    style={{fontSize : "10px"}}
                                  >View Licence
                                  </div></>
                          )}
                          
                            <ExpandMoreIcon
                              onClick={() => editProject(index)}
                              style={{ fontSize: "35px" }}
                              className="trackicon self-center cursor-pointer sm:text-gray-700"
                            />

                        </div>
                      </div>
                      {savingProject === editProjectObj.id ?
                      <div className='lg:w-full'>
                        <BouncingLoader />
                      </div>:  
                      (expanded.index === index && expanded.isOpen &&
                        <div className={`w-full transform scale-x-107`} >
                          <div className="w-full bg-black p-5">
                            <div className="lg:flex w-full ">
                              <div className="w-full flex w-4/6">
                                
                                <div className="w-full w-4/6">
                                  <input
                                    autoFocus={true}
                                    onChange={(e) => { handleProjectDataChange(e.target.value, "title") }}
                                    className='text-xl focus:outline-none font-semibold  border-b text-white bg-transparent'
                                    placeholder='edit Project...'
                                    //  value={project.title}
                                    defaultValue={project.title}
                                  />
                                  <div className="w-full text-white pt-2 text-lg">{project.trackIds?.length} tracks </div>
                                  <h2 style={{ fontSize: "10px" }}
                                    className='w-full pt-2 text-left text-white '> Edited on : {dayjs(project.updatedAt).format('DD.MM.YYYY')} </h2>
                                </div>
                                <div className='w-full w-2/6'>
                                  <div className={`ml-1  lg:ml-0 2xl:ml-1 pinkbutton cursor-pointer `}
                                    onClick={() => saveProject()}
                                    style={{fontSize : "10px"}}
                                    > Save
                                  </div>
                                </div>
                              </div>
                              <div className="w-full w-2/6 flex justify-end">
                                <div className=" flex self-center lg:justify-end items-center">
                                  
                                  <Delete1 height="25" width="25" className="hidden lg:block ml-3 mr-2  
                              cursor-pointer"
                                    onClick={() => {
                                      setExpanded({ index: -1, isOpen: false });
                                      setSelectedAdmin({ id: project._id, index });
                                      setShowConfirmPopup(true);
                                    }
                                    } />
                                  <span className="hidden lg:block cursor-pointer ml-3 mr-3" >
                                    {project.trackIds?.length > 0 && (
                                      downloadingProjects.includes(project._id) ? 
                                      <CircularProgress size='15px' style={{color : "white"}}/> : 
                                      <DownloadIcon
                                        onClick={(event) => downloadProject(event, project)}
                                        width="30"
                                        style={{color : "white"}}
                                      />)
                                    }
                                  </span>
                                  {project.trackIds?.length > 0 && (
                                    (project.licenseUrl === '' || typeof project.licenseUrl === 'undefined') ?
                                      <div className='w-full'>
                                        <Link className="hidden md:block w-8/12">
                                          {/* <div className={`text-center ml-0 lg:ml-4 py-1 text-xs cursor-pointer  bg-white text-black font-medium`} */}
                                          <div className={`ml-1  lg:ml-0 2xl:ml-1 pinkbutton `}
                                          onClick={() => generateLicense(project)}
                                          style={{fontSize : "10px"}}
                                          > Generate Licence
                                          </div>
                                        </Link>
                                        {/* <div className={`md:hidden w-8/12 text-center ml-0 lg:ml-4 py-1 text-xs cursor-pointer  bg-white text-black font-medium`} */}
                                        <div className={`md:hidden pinkbutton `}
                                          onClick={() => { setShowMobilePopup(true); }}
                                          style={{fontSize : "10px"}}
                                        > Generate Licence
                                        </div>
                                      </div>
                                      :
                                      (new Date(project.licenseUpdatedDate) < new Date(project.updatedAt)) ?
                                        <div className='w-full'>
                                         <Link to={`/license/${project._id}`} className="hidden md:block w-8/12">
                                          {/* <div className={`text-center ml-0 lg:ml-4  py-1 text-xs cursor-pointer bg-ctaRed text-white font-medium`} */}
                                          <div className={`ml-1 lg:ml-0 2xl:ml-1  pinkbutton`}
                                          style={{fontSize : "10px"}}
                                          >  Update Licence
                                          </div>
                                        </Link>
                                          {/* <div className={`md:hidden w-8/12 text-center ml-0 lg:ml-4  py-1 text-xs cursor-pointer bg-ctaRed text-white font-medium`} */}
                                          <div className={`md:hidden pinkbutton`}
                                            onClick={() => { setShowMobilePopup(true) }}
                                            style={{fontSize : "10px"}}
                                          >  Update Licence
                                          </div>
                                        </div> :
                                        <div className='w-full'>
                                          <Link to={`/license/${project._id}`} className="hidden md:block w-8/12">
                                            {/* <div className={`text-center ml-0 lg:ml-4  py-1 text-xs cursor-pointer bg-white text-textGray-100 font-medium`} */}
                                            <div className={`ml-1 lg:ml-0 2xl:ml-1 greenbutton `}
                                            style={{fontSize : "10px"}}
                                            >  View Licence
                                            </div>
                                          </Link>
                                          {/* <div className={`md:hidden w-8/12 text-center ml-0 lg:ml-4  py-1 text-xs cursor-pointer bg-white text-textGray-100 font-medium`} */}
                                          <div className={`md:hidden greenbutton`}
                                            onClick={() => { setShowMobilePopup(true) }}
                                            style={{fontSize : "10px"}}
                                          >  View Licence
                                          </div>
                                        </div>
                                  )}
                                  <span className="hidden lg:block cursor-pointer">
                                    <ExpandLessIcon
                                      onClick={() => editProject(index)}
                                      style={{ fontSize: "35px", color: "white" }}
                                      className="trackicon self-center cursor-pointer sm:text-gray-700"
                                    />
                                  </span>

                                  <div className="lg:hidden w-8/12 justify-end" >
                                    <span className='flex' style={{"alignItems": "center"}}>
                                      <SaveIcon
                                        className="w-2/4 lg:hidden ml-3 mr-2  cursor-pointer"
                                        style={{ color: "white" }}
                                        onClick={() => {
                                          saveProject();
                                        }} />
                                      <Delete1 className="w-2/4 lg:hidden ml-3 mr-2  cursor-pointer"
                                        width={"20px"}
                                        height={"20px"}
                                        style={{ color: "white" }}
                                        onClick={() => {
                                          setExpanded({ index: -1, isOpen: false });
                                          setSelectedAdmin({ id: project._id, index });
                                          setShowConfirmPopup(true);
                                        }
                                        } />
                                      <span className="w-2/4 lg:hidden ml-3 mr-3  cursor-pointer">
                                      {
                                        downloadingProjects.includes(project._id) ? 
                                        <CircularProgress size='15px' style={{color : "white"}}/> : 
                                        <DownloadIcon
                                          onClick={(event) => downloadProject(event, project)}
                                          width="30"
                                          style={{color : "white"}}
                                        />
                                      }
                                      </span>
                                      <ExpandLessIcon
                                        onClick={() => editProject(index)}
                                        style={{ fontSize: "35px", color: "white" }}
                                        className="trackicon self-center cursor-pointer sm:text-gray-700"
                                      />
                                    </span>
                                  </div>
                                </div>
                                
                              </div>

                            </div>
                          </div>
                          <div className="w-full bg-black pl-4 lg:pl-5 pr-3 bg-textGray-200">
                            <div className="w-full text-black text-lg">Description</div>

                            <textarea id="description" className="p-4 w-full lg:w-9/12 outline-none border border-textGray-300 text-textGray-100 textArea-description"
                              onChange={(e) => { handleProjectDataChange(e.target.value, "description") }}
                              defaultValue={project.description}
                              name="descripition" rows="1" cols="60" />
                            <DragDropContext onDragEnd={handleOnDragEnd}>
                              <Droppable droppableId={index + "characters"}>
                                {(provided) => (
                                  <ul
                                    className="characters pb-10"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {project.trackIds.map((track, childIdx) => {
                                      return (
                                        <Draggable
                                          key={childIdx + track._id}
                                          draggableId={index + childIdx + track._id}
                                          index={childIdx}
                                          id={childIdx}
                                        >
                                          {(provided) => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="main-track-content">
                                                <div
                                                  key={track._id}
                                                  className="flex w-full box-border"
                                                  style={{alignItems : "center"}}
                                                >
                                                  <div className="self-center play-button sm:w-1/12">
                                                    <div
                                                      aria-label="play/pause"
                                                      className="px-2 outline-none focus:outline-none play-button border-symStrokeGrey cursor-pointer"
                                                      onClick={() =>
                                                        fetchTrack(
                                                          track.mewoTrackId,
                                                          true,
                                                          childIdx,
                                                          track.track_index
                                                        )
                                                      }
                                                    >
                                                      {trackContext.playPause &&
                                                        currentTrack === track.track_index ? (
                                                        <Pause className="outline-none" />
                                                      ) : (
                                                        <Play className="outline-none" />
                                                      )}
                                                    </div>
                                                  </div>
                                                  
                                                  {track.trackId ? 
                                                  <>
                                                  <div className='sm:w-3/12'>
                                                    <h1 className="text-base mr-2 text-black track-title ">
                                                      {" "}
                                                      {track.trackId?.title?.toLowerCase()}
                                                    </h1>
                                                    <h2 className="text-xs self-center text-textGray-100 track-artist">
                                                      {track.trackId?.album?.title?.toLowerCase()}{" "}
                                                    </h2>
                                                  </div>
                                                  <div className="hidden lg:block sm:w-4/12">
                                                    <SvgInline url = {track.trackId?.waveform?.small?.url} />
                                                  </div>
                                                  <div className="hidden lg:block text-center text-xs w-1/12 self-center text-textGray-100">
                                                    {track.trackId?.duration &&
                                                      new Date(
                                                        track.trackId?.duration * 1000
                                                      )
                                                        .toISOString()
                                                        .substr(14, 5)}
                                                  </div>
                                                  </> : 
                                                  <div className="hidden lg:block text-center text-xs w-9/12 self-center text-textGray-100">
                                                    <p style={{color : "red"}}>We have encounterd dificulties processing this track, please remove and add it again.</p>
                                                  </div>
                                                  }
                                                  <div className="lg:flex hidden lg:block sm:w-3/12 justify-end">
                                                    {track.trackId && <>
                                                  
                                                      <TrackAction
                                                        setShowProjectModal={
                                                          setShowProjectModal
                                                        }
                                                        setTrackToBeAddedToProject={
                                                          setTrackToBeAddedToProject
                                                        }
                                                        setTrackToBeRemoveToProject={
                                                          setTrackToBeRemoveToProject
                                                        }
                                                        trackId={
                                                          track.mewoTrackId
                                                        }
                                                        projectTrackId={
                                                          track.trackId?._id
                                                        }
                                                        handleAddTrackToFavorites={
                                                          handleAddTrackToFavorites
                                                        }
                                                        favs={favs}
                                                        download={download}
                                                        setDownload={setDownload}
                                                        projectId={project._id}
                                                        downloadCount={track.downloadCount}
                                                        projectList={searchProjects}
                                                        setProjectList = {setSearchProjects}
                                                      />
                                                      <span
                                                        style={{ fontSize: "4px" }}
                                                        className="self-center cursor-pointer text-gray-600 hover:scale-50 px-3"
                                                        onClick={() =>
                                                          duplicateTrack(
                                                            track,
                                                            index
                                                          )}
                                                      >
                                                        <DuplicateIcon />
                                                      </span>
                                                      
                                                    </>}
                                                  <div
                                                    className={`hidden lg:block px-6 py-1 text-xs cursor-pointer text-ctaRed self-center pl-0`}
                                                    onClick={(e) =>
                                                      deleteTrack(
                                                        project._id,
                                                        project.title,
                                                        track.trackId?.title,
                                                        childIdx
                                                      )
                                                    }
                                                  >
                                                    <Delete height="18" width="18" />
                                                  </div>
                                                  </div>

                                                  <MobileAction
                                                    className={'cursor-pointer'}
                                                    mewoTrackId={
                                                      track.mewoTrackId
                                                    }
                                                    id={track.trackId?._id}
                                                    handleProjectModal={
                                                      handleProjectModal
                                                    }
                                                    favs={favs}
                                                    handleAddTrackToFavorites={
                                                      handleAddTrackToFavorites
                                                    }
                                                    deleteTrackFromProject={
                                                      deleteTrackFromProject
                                                    }
                                                    duplicateTrack={
                                                      duplicateTrack
                                                    }
                                                    projectId={project._id}
                                                    trackData={track}
                                                    projectIndex={index}
                                                    trackIndex={childIdx}
                                                  />
                                                </div>
                                                {track.expand && (
                                                  <div className="alternative-versions-wrapper md:p-2 w-full">
                                                    <div
                                                      className="album-info w-5/6 mb-4"
                                                      style={{ marginLeft: "8.5%" }}
                                                    >
                                                      <div className="flex mb-4">
                                                        <div className="pr-8 pb-2">
                                                          <p className="text-title text-gray-600">
                                                            Album
                                                          </p>
                                                          <h3 className="track-artist">
                                                            {
                                                              track
                                                                .alternateVersions[0]
                                                                ?.album?.title
                                                            }
                                                          </h3>
                                                        </div>
                                                        <div className="pr-8 pb-2">
                                                          <p className="text-title text-gray-600">
                                                            Artists
                                                          </p>
                                                          <h3 className="track-artist">
                                                            {
                                                              track
                                                                .alternateVersions[0]
                                                                ?.artists_publishing_ownerships[0]
                                                                ?.artist?.full_name
                                                            }
                                                            {", " +
                                                              track
                                                                .alternateVersions[0]
                                                                ?.artists_publishing_ownerships[0]
                                                                ?.rights_type?.key}
                                                          </h3>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </ul>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </div>
                      )}
                    </div>
                    //    </AccordionDetails>
                    //  </Accordion> 
                  )}
                <div className='mt-8'>
                  <Pagination count={totalPages} onChange={handlePagination} variant="outlined" shape="rounded" />
                </div>
              </div>}
            </div>
            {
              showProjectModal &&

              <AddTrackToProjectPopup
                trackToBeAddedToProject={trackToBeAddedToProject}
                closeProjectModal={closeProjectModal}
                loadProjects = {loadProjects} 
              />
            }

          </div >
          {
            showConfirmPopup ?
              <ConfirmationPopup
                yesAction={deleteProject}
                cancelAction={setConfirmPopup}
                title="Delete Project" msg="Are you sure, you want to delete this project?" />
              :
              null
          }
          {
            showDeleteTrackConfirmPopup &&
              <ConfirmationPopup
                yesAction={deleteTrackFromProject}
                cancelAction={() => {setShowDeleteTrackConfirmPopup(false);setDeletedTrackFromProject("");setDeletedTrackIndex("")}}
                title="Delete Track From Project" msg={deletedTrackMsg} />
              
          }
          {
            showMobilePopup ?
              <MobilePopup
                setShowMobilePopup={setShowMobilePopup}
              />
              :
              null
          }

        </div >
      }
          <Menu
            id="customized-menu"
            anchorEl={downloadModalPosition}
            open={Boolean(showDownloadModal)}
            onClose={downloadPopupClose}
            style={{ width: "320px" }}
          >
            <MenuItem
              onClick={() => { handleProjectDownload("original")}}
              className="card-action"
            >
              <span className="card-action-text">
                Original HD
              </span>
            </MenuItem>
            <MenuItem
              onClick={() => { handleProjectDownload("hd_mp3")}}
              className="card-action"
            >
              <span className="card-action-text">
                MP3 HD
              </span>
            </MenuItem>
            <MenuItem
              onClick={() => { handleProjectDownload("sd_mp3")}}
              className="card-action"
            >
              <span className="card-action-text">
                MP3 SD
              </span>
            </MenuItem>
          </Menu>
    </>
  )
}
