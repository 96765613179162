import React, { useEffect, useState, useContext } from 'react';
import API from '../../services/api';
import { RippleLoader } from '../../pure-components/Loader';
import { toast } from 'react-toastify';

import TrackAction from '../TrackItem/trackAction';
import AddTrackToProject from '../Projects/addTrackToProject';
import TrackContext from '../../contexts/track-context';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Amplitude from 'amplitudejs';
import { ReactComponent as Success } from '../../assets/success.svg'
import Helper from '../../services/helper'
import Constants from '../../services/constants';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Pause } from '../../assets/pause.svg';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import './style.css';

// import * as resp from './res.json';

let tempDuration = [];
let tempDuration1 = [];


export default function Downloads() {
	let trackContext = useContext(TrackContext);

	const [currentTrack, setCurrentTrack] = useState('')
	const [downloads, setDownloads] = useState([]);
	const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
	const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState(false);
	const [trackToBeRemoveToProject, setTrackToBeRemoveToProject] = useState('');

	const [currPage, setCurrPage] = useState(1);
	const [showProjectModal, setShowProjectModal] = useState(false);
	const [favs, setFavs] = useState([]);
	const [searchKey, setSearchKey] = useState('')
	const [pageSize, setPageSize] = useState(process.env.REACT_APP_PAGE_SIZE);
	const [history, setHistory] = useState([]);
	const [searchHistory, setSearchHistory] = useState([]);
	const [totalPages, setTotalPages] = useState(1);

	const [download, setDownload] = useState([]);
	const [clicked, setClicked] = useState(false);
	const [clickedMonth, setClickedMonth] = useState(false);
	// const [totalDuration, setTotalDuration] = useState([]);


	useEffect(() => {
		if (localStorage.getItem('favs') !== 'undefined') {
			const favorites = JSON.parse(localStorage.getItem('favs'));
			// const favArray = favorites.map( track => {
			//   return track.trackId.mewoTrackId;
			// });
			favorites !== null && setFavs(favorites);
		};
		if (localStorage.getItem('download') !== 'undefined') {
			const download = JSON.parse(localStorage.getItem('download'));
			download !== null && setDownload(download);
		};

	}, []);

	useEffect(() => {
		// console.log('called');
		API.getDownloadTrackList().then(res => {
			// console.log('Downloaded Tracks:',res.data);
			// console.log(resp)
			setDownloads(res.data);
			Object.keys(res.data).map((key) => {
				if (key.includes('_duration')) {

					let monthTemp = key.split('_')[0] + '_' + key.split('_')[1]
					let getMonth = key.split('_')[2]
					tempDuration.push(res.data[key])
					// setTotalDuration(totalDuration.push(res.data[key]))
					// totalDuration.map( ele => console.log(ele.key))
					// console.log( tempDuration,'=>', res.data[key] )
				}
			})
		})
		//Saving Total Duration with Month in a Array
	}, []);
	const handleAddTrackToFavorites = (trackId) => {
		API.addTrackToFavorites(trackId).then(res => {
			if(res?.success){
				localStorage.setItem('favs', JSON.stringify(res.favorites));
				setFavs(res.favorites);
			}else{
				Helper.handleError(res);
			}
		})
	}
	const closeProjectModal = () => {
		setShowProjectModal(false);
		setLoaderAddTrackToProject(false);
	}
	const fetchTrack = (e, trackId, historyId) => {
		e.stopPropagation();
		if (historyId === currentTrack) {
			trackContext.setPlayPause(!trackContext.playPause);
			return;
		}

		trackContext.setLoadingTrack(true);
		API.playTrack(trackId).then(res => {
			// console.log('Media Data:', res.data);
			if(res?.data?.success){
				trackContext.setLoadingTrack(false);
				setCurrentTrack(historyId);
				trackContext.setCurrentTrack(res.data.data.audiofile)
				trackContext.setCurrentTrackDetail(res.data.data)
				trackContext.setCurrentTrackImage(res.data.data.album.image.xsmall.url)

				trackContext.setPlayPause(true);
				let volume = Amplitude.getVolume() > 0.5 ? Amplitude.getVolume() : 20;

				if (Amplitude.getSongs().length) {
					Amplitude.stop()
					Amplitude.removeSong(0)

					let artistName = ''
					if (typeof res.data.data.display_artists !== 'undefined') {
						artistName = res.data.data.display_artists[0]?.artist.full_name
					}
					// console.log('Artist Name:', artistName);
					Amplitude.init({
						continue_next: false,
						volume: volume,
						songs: [
							{
								name: res.data.data.display_title,
								artist: artistName,
								album: '',
								url: res.data.data.audiofile,
								cover_art_url: res.data.data.album.image.xsmall.url
							}
						],
						waveforms: {
							sample_rate: 70
						}
					})
					Amplitude.play();

				}
			}else{
				Helper.handleError(res?.data);
			}
		});
	}

	const toggleYear = (e, currentTarget) => {
		e.stopPropagation();
		if (currentTarget.classList.contains('active')) {
			currentTarget.classList.remove('active');
		} else {
			currentTarget.className += ' active';
		}
	}

	const toggleMonth = (e, currentTarget) => {
		if ( currentTarget.classList.contains('active') ) {
			currentTarget.classList.remove('active');
		} else {
			currentTarget.className += ' active';
		}
	}

	const handleSearch = Helper.debounce((searchText) => {
		// console.log('Search Text FrontEnd:',downloads);
		// setCurrPage(1)
		setSearchKey(searchText)
		API.getDownloadTrackList(searchText).then(res => {
			if (res.success) {
				// console.log('History Tracks:', res.data);
				setHistory(res.data);
				setSearchHistory(res.data);
				// setTotalPages(Math.ceil(res.totalCount/pageSize));
				Object.keys(res.data).map((key) => {
					if (key.includes('_duration')) {
						let monthTemp = key.split('_')[0] + '_' + key.split('_')[1]
						let getMonth = key.split('_')[2]
						tempDuration1.push(res.data[key])
						// setTotalDuration(totalDuration.push(res.data[key]))
						// totalDuration.map( ele => console.log(ele.key))
						// console.log( tempDuration1,'=>', res.data[key] )
					}
				})
			}

			//  API.getHistoryTracks(1, pageSize,searchText).then(res => {
			// if (res.success) {
			//   // console.log('History Tracks:', res.history);
			//   setHistory(res.history);
			//   setSearchHistory(res.history);
			//   setTotalPages(Math.ceil(res.totalCount/pageSize));
			// }

		});
	}, 500)


	return (
		<div className='flex h-screen lg:w-3/4'>
			<div className='w-full mb-24 overflow-auto hide-scroll'>
				<div className="w-full justify-between flex pt-4">
					<h2 className='text-bold text-sm sm:text-lg font-bold text-black'>Download History</h2>
					<input
						onChange={(e) => { handleSearch(e.target.value) }}
						//  onChange={handleSearch}
						type="text"
						placeholder='Search History'
						className="w-1/2 sm:w-1/3 input-search h-8"
					/>
				</div>
				<div className='mt-4'>
					{
							
						searchKey === '' ? (

							downloads.downloadCount ?
								downloads.year.map( (year, index) => 
									<div key={index}>
										<div key={index} className='accordion-header bg-ctaRed text-white text-sm font-bold cursor-pointer' onClick={(e) => toggleYear(e, e.currentTarget)}>
											<ArrowDropUpIcon className='up-arrow' fontSize="large" />
											<ArrowDropDownIcon className='down-arrow' fontSize="large" />
											{year}
										</div>
										<div className='accordion-body'>
											{
												Object.keys( downloads[`${year}_month`] ).map( (month, i) => 
													<React.Fragment key={i}>
														<div key={i}  className='accordion-header flex text-white font-bold cursor-pointer' style={{backgroundColor: '#808080'}} onClick={(e) => toggleMonth(e, e.currentTarget)}>
															<div className='w-2/3' style={{width: '68%'}}>
																<ArrowDropUpIcon className='up-arrow' fontSize="default" />
																<ArrowDropDownIcon className='down-arrow' fontSize="default" />
																{month}
															</div>
															<div>{String(downloads[`${year}_month_${month}_duration`]).split('.')[0]}:{(String(downloads[`${year}_month_${month}_duration`]).split('.')[1]) || '00'}</div>
														</div>
														<div className='accordion-body'>
														{
															downloads[`${year}_month`][month].map( (track, index) =>
																<div className='flex border-b' key={track._id}>
																 {/* <div className="flex border-b"> */}
																	<IconButton
																			aria-label="play/pause"
																			className='outline-none focus:outline-none play-button'
																			onClick={(e) => fetchTrack(e, track.trackId.mewoTrackId, track._id)}
																	>
																			{
																				(trackContext.playPause && currentTrack === track._id) ?
																					<Pause className='outline-none' />
																					:
																					<Play className='outline-none' />
																			}

																		</IconButton>
																	{/* </div> */}
																	<div className='w-4/6 sm:w-2/6 self-center flex flex-col'>
																		<h1 className='text-sm truncate sm:w-10/12 sm:text-base mr-2 text-black capitalize'> {track.trackId.title}</h1>
																		<h1 className='text-xs text-textGray-100 capitalize font-normal'> {track.trackId.album.title}</h1>
																	</div>
																	<div className="hidden sm:flex w-2/3">
																		<h2 className='hidden text-center sm:block w-full text-xs self-center text-textGray-100 font-normal'> 
																			Downloaded on {new Date(track.updatedAt).toDateString()} </h2>
																	</div>
																	<div className="hidden sm:flex items-center w-1/3">
																		<h2 className='hidden sm:block w-full text-xs self-center text-textGray-100 font-normal'> {String(parseFloat(track.trackId.duration).toFixed(2)).split('.')[0]}:{String(parseFloat(track.trackId.duration).toFixed(2)).split('.')[1]} </h2>
																	</div>
																	<TrackAction
																		setShowProjectModal={setShowProjectModal}
																		setTrackToBeAddedToProject={setTrackToBeAddedToProject}
																		setTrackToBeRemoveToProject={setTrackToBeRemoveToProject}
																		trackId={track.trackId.mewoTrackId}
																		projectTrackId={track.trackId._id}
																		handleAddTrackToFavorites={handleAddTrackToFavorites}
																		favs={favs}
																		download={download}
																		setDownload={setDownload}
																	/>
																</div>
															)
														}
														</div>
													</React.Fragment>	
												)
											}
										</div>
									</div>
								)
								:
								<div className={(downloads.downloadCount !== undefined) ? 'text-red-500 flex' : 'text-red-500 hidden'}>
									No Downloaded Tracks
								</div>
						)
							:
							//False Condition

							searchHistory.downloadCount ? 
								searchHistory.year.map( (year, index) => 
									<div key={index}>
										<div className='accordion-header bg-ctaRed text-white text-sm font-bold cursor-pointer' onClick={(e) => toggleYear(e, e.currentTarget)}>
											<ArrowDropUpIcon className='up-arrow' fontSize="large" />
											<ArrowDropDownIcon className='down-arrow' fontSize="large" />
											{year}
										</div>
										<div className='accordion-body'>
											{
												Object.keys( searchHistory[`${year}_month`] ).map( (month, i) => 
													<React.Fragment key={i}>
														<div className='accordion-header flex text-white font-bold cursor-pointer' style={{backgroundColor: '#808080'}} onClick={(e) => toggleMonth(e, e.currentTarget)}>
															<div className='w-2/3' style={{width: '68%'}}>
																<ArrowDropUpIcon className='up-arrow' fontSize="default" />
																<ArrowDropDownIcon className='down-arrow' fontSize="default" />
																{month}
															</div>
															<div>{String(searchHistory[`${year}_month_${month}_duration`]).split('.')[0]}:{(String(searchHistory[`${year}_month_${month}_duration`]).split('.')[1]) || '00'}</div>
														</div>
														<div className='accordion-body'>
														{
															searchHistory[`${year}_month`][month].map( (track, index) =>
																<div className='flex border-b' key={track._id}>
																 {/* <div className="flex border-b"> */}
																<IconButton
                                    aria-label="play/pause"
                                    className='outline-none focus:outline-none play-button'
                                    onClick={(e) => fetchTrack(e, track.trackId.mewoTrackId, track._id)}
																>
                                    {
                                      (trackContext.playPause && currentTrack === track._id) ?
                                        <Pause className='outline-none' />
                                        :
                                        <Play className='outline-none' />
                                    }

                                  </IconButton>
																	{/* </div> */}
																	<div className='w-4/6 sm:w-2/6 self-center flex flex-col'>
																		<h1 className='text-sm truncate sm:w-10/12 sm:text-base mr-2 text-black capitalize'> {track.trackId.title}</h1>
																		<h1 className='text-xs text-textGray-100 capitalize font-normal'> {track.trackId.album.title}</h1>
																	</div>
																	<div className="hidden sm:flex w-2/3">
																		<h2 className='hidden text-center sm:block w-full text-xs self-center text-textGray-100 font-normal'> 
																			Downloaded on {new Date(track.updatedAt).toDateString()} </h2>
																	</div>
																	<div className="hidden sm:flex items-center w-1/3">
																		<h2 className='hidden sm:block w-full text-xs self-center text-textGray-100 font-normal'> {String(parseFloat(track.trackId.duration).toFixed(2)).split('.')[0]}:{String(parseFloat(track.trackId.duration).toFixed(2)).split('.')[1]} </h2>
																	</div>
																	<TrackAction
																		setShowProjectModal={setShowProjectModal}
																		setTrackToBeAddedToProject={setTrackToBeAddedToProject}
																		setTrackToBeRemoveToProject={setTrackToBeRemoveToProject}
																		trackId={track.trackId.mewoTrackId}
																		projectTrackId={track.trackId._id}
																		handleAddTrackToFavorites={handleAddTrackToFavorites}
																		favs={favs}
																		download={download}
																		setDownload={setDownload}
																	/>
																</div>
															)
														}
														</div>
													</React.Fragment>	
												)
											}
										</div>
									</div>
								)
								:
								<div className={(searchHistory.downloadCount !== undefined) ? 'text-red-500 flex' : 'text-red-500 hidden'}>
									No Downloaded Tracks
								</div>

					}
				</div>
			</div>
			{
				showProjectModal &&

				<AddTrackToProject trackToBeAddedToProject={trackToBeAddedToProject} closeProjectModal={closeProjectModal} />
			}
		</div>
	)
}