import React from "react";
import Constants from "../../services/constants";
import { ReactComponent as Logo1 } from "../../assets/logo-day.svg";
import TwitterLogo from "../../assets/logo/twitter_logo.gif"
import XLogo from "../../assets/logo/x_logo.png"
import FacebookLogo from "../../assets/logo/facebook_logo.gif"
import YoutubeLogo from "../../assets/logo/youtube_logo.gif"
import InstaLogo from "../../assets/logo/insta_logo.gif"

export default function Footer() {
  return (
    <div
      className="footer-wrapper p-3 px-8 md:mx-20 pt-10"
      style={{ fontSize: "14px", fontWeight: "100", fontFamily: "Synthese" }}
    >
      <div className="border-t mb-12"></div>
      <div className="sm:flex sm:mb-20 sw-full m:flex-no-wrap flex flex-wrap">
        <div className="sm:flex sm:flex-wrap text-xds lg:text-sm">
          <div className="mb-10  sm:mr-32 flex justify-start text-left ">
            <ul>
              <li className="pb-1">
                <a href={`/about`}>About Us</a>
              </li>
              <li className="pb-1">
                <a href={`/terms`}>Terms of Service</a>
              </li>
              <li className="pb-1">
                <a href={`/privacy`}>Privacy Policy</a>
              </li>
              <li className="pb-1">
                <a href={`/cookies`}>Cookie Policy</a>
              </li>
            </ul>
          </div>
          <div className="mb-10 flex justify-start text-left">
            <ul>
              <li className="pb-1">
                <a href={`/contact`}>Contact Us</a>
              </li>
              <li className="pb-1">
                <a href={`/faqs`}>FAQ's</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-1/4 flex flex-col mb-4 flex-1">
          <div className="sm:flex sm:justify-end mb-6 flex justify-center">
            <Logo1 alt="brand-logo" />
          </div>
          <div className="sm:flex sm:justify-end mb-6 mt-2 flex justify-center">
            {/* <div className="mr-3">
              <a href={Constants.SM_TWITTER_LINK} target="_blank">
                <img
                  src={XLogo}
                  alt="logo"
                  className="h-8"
                  title="Twitter"
                />
              </a>
            </div> */}
            <div className="mr-3">
              <a href={Constants.SM_FACEBOOK_LINK} target="_blank">
                <img
                  src={FacebookLogo}
                  alt="logo"
                  className="h-8"
                  title="Facebook"
                />
              </a>
            </div>
            <div className="mr-3">
              <a href={Constants.SM_YOUTUBE_LINK} target="_blank">
                <img
                  src={YoutubeLogo}
                  alt="logo"
                  className="h-8"
                  title="Youtube"
                />
              </a>
            </div>
            <div className="mr-3">
              <a href={Constants.SM_INSTA_LINK} target="_blank">
                <img
                  src={InstaLogo}
                  alt="logo"
                  className="h-8"
                  title="Instagram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mb-32 text-center" style={{ color: "#999999" }}>
        ©{new Date().getFullYear()} SyncMama. All rights reserved.
      </div>
    </div>
  );
}
