import CookieManager from "./CookieManager";
import Helper from "./helper";
import axios from "axios";

const loadMewoData = async(req, retries = 0) => {
    //load x-auth from local
    req.token = CookieManager.getCookie("x-auth");

    return await executeRequest(req).then(res => {
      return res;
    }).catch(async(err) => {
      if(err?.response?.status === 400 && err?.response?.data?.key === "invalid_token" && retries < 3){
        return await refreshToken().then(async(tokenData) =>{
          if(tokenData?.success){
            retries++;
            req.token = tokenData.token;
            //setting token to local for futher use
            CookieManager.setCookie("x-auth", tokenData.token);
            return loadMewoData(req, retries);
          }else{
            throw "Error Refreshing Token, please login again!"
          }

        }).catch(err => {
          throw err;
        });
      }else{
        throw err;
      }    
    })
  }

  const executeRequest = async (req) => {
    const URL = process.env.REACT_APP_MEWO_URL + req.url;
    let headers = null;
    if(req?.headers){
      headers = req.headers
    }else{
      headers = {
        'x-host': process.env.REACT_APP_X_HOST,
        'x-preferred-language': "en", 
        'Content-Type': 'application/json'
      };
    }
    //adding no-cache
    headers['Cache-Control'] = 'no-cache';
    headers['Pragma'] = 'no-cache';
    headers['Expires'] =  '0';
    
    Helper.addAttributeToObject(headers, "x-auth", req.token);
    Helper.addAttributeToObject(headers, "x-host", process.env.REACT_APP_X_HOST);
    
    return await axios({
      method: req.method,
      url: URL,
      headers: headers,
      data : req.data,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,

    }).then(resp => {
      return resp.data ? resp.data : resp;
    })
    .catch(err => {
      throw err;
    });
  }


  const refreshToken = async() => {
    let { email } = JSON.parse(CookieManager.getCookie("user-data") ? CookieManager.getCookie("user-data") : "{}");
    let headers =  {
      "Content-Type": "application/json"
    }
    if( email?.length > 0 )
      headers["userId"] = Helper.encode(email);
    
    return await axios({
        method: "post",
        url: process.env.REACT_APP_BASE_URL + "api/users/create-session",
        headers: headers,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
  
      }).then(resp => {
        // console.log("refresh token response : ", resp)
        return resp.data;
      })
      .catch(err => {
        throw err;
      });
    
  }


  
const MewoService = {loadMewoData, refreshToken, executeRequest}

export default MewoService;