import React, {  useEffect, useState, useContext  } from 'react';
import TrackContext from '../../contexts/track-context';
import { ReactComponent as Close } from '../../assets/close.svg';
import Amplitude from 'amplitudejs';
import API from '../../services/api';
//import AlternateTracks from '../TrackItem/alternate-tracks';
import CardContent from '@material-ui/core/CardContent';
//import Tabs from '@material-ui/core/Tabs';
import FavoriteIcon from '@material-ui/icons/Favorite';
import UnFavoriteIcon from '@material-ui/icons/FavoriteBorderOutlined';
//import {ReactComponent as CreateNewFolderIcon} from '../../assets/folder.svg';
import {ReactComponent as CreateNewFolderWhiteIcon} from '../../assets/folder-white.svg';
import {ReactComponent as CloseModal } from '../../assets/modal-close.svg';
import Helper from '../../services/helper';
import Constants from '../../services/constants';

export default function TrackDetail(props) {
  // console.log("props:", props);
  // console.log("props currentTrackDetail", props);
  let trackContext = useContext(TrackContext);
  // console.log("trackContext", trackContext);

  const {searchTags}= trackContext.trackFilter ; 
  const [alternateVersions, setAlternateVersions] = useState([]);
  const [favs, setFavs] = useState([]);
  const [hdmp3File, setHdmp3File] = useState(false);
  const [originalFile, setOriginalFile] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showAlternatives, setAlternatives] = useState(false)
  const [isFav, setIsFav] = useState(false);

  const fetchTrack = (trackId, isAltVersion = false) => {
    if (trackId === trackContext.currentTrackDetail.id) {
      trackContext.setPlayPause(!trackContext.playPause);
      return ;
    }
    if (Amplitude.getSongs().length) {
      Amplitude.stop()
      Amplitude.removeSong(0)
    }
    trackContext.setpreviousTrack(props.previousTrack);
    props.setFetchingTrack(true);
    API.playTrack(trackId).then(res => {
      if(res?.data?.success){
        props.setFetchingTrack(false);
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail(res.data.data)
        trackContext.setCurrentTrackImage(res.data.data.album.image.xsmall.url);
      }else{
        Helper.handleError(res?.data);
      }  
    });
    // check alternative versions
    if (!isAltVersion) {
      API.getAlternateVersions(trackId).then(res => {
        // console.log('[ALT VERSIONS]', res);
        if (res?.success) {
          if(res.data?.length)setAlternateVersions(res.data);
        }else{
          Helper.handleError(res);
        } 
      });
    }
  }

  const getAlternateVersionsMobile = () => {
    API.getAlternateVersions(trackContext.currentTrackDetail.id).then(res => {
      if(res?.success){
        if (res.data.length) {
          setAlternateVersions(res.data);
        }
        else{
          let altVer={album:{title:props.album},artists_publishing_ownerships:[{
            artist:{full_name:props.artist},
            rights_type:{key:props.rights_type}
          }]};
        
          setAlternateVersions([{...altVer}]);

        }
      }else{
        Helper.handleError(res);
      }
      // setExpand(expandval)
    });
  }

  useEffect(() => {
    getAlternateVersionsMobile();
  }, []);

  useEffect(() => {
    // console.log('track-detail : ', trackContext.currentTrackDetail)
    let favs = JSON.parse(localStorage.getItem('favs'));
    if (favs !== null) {
        if (favs.indexOf(trackContext.currentTrackDetail.id) !== -1) {
        setIsFav(true);
      } else {
        setIsFav(false);
      }
    }
  },[trackContext.currentTrackDetail])

  const handleAddTrackToFavorites = (trackId) => {
    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      props.CloseTrackDetail();
      return;
    }
    API.addTrackToFavorites(trackId).then(res => {
      if(res?.success){
        localStorage.setItem('favs', JSON.stringify(res.favorites));
        setIsFav(res.favorites?.includes(trackContext.currentTrackDetail.id));
      }else{
        Helper.handleError(res);
      }
    });

  }
  
  // const handleProjectModal = (trackId) => {
  //   if (trackContext.userDetails === undefined) {
  //     trackContext.setShowSubscribePopup(true);
  //     trackContext.setUserAction('Project');

  //   } else {
  //     let trackId=trackContext.currentTrackDetail.id;
  //     props.displayProjectModal(trackId);
  //   }
  // }

  const handleDownloadTrack =(event,trackId) => {

    if (!trackContext.validateUser(Constants.ACTION.DOWNLOAD)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.DOWNLOAD);
      return;
    }
    let currentTarget = event.currentTarget;
      API.getDownloadTracks(trackId).then(res => {
      if(res?.audiofile!==undefined && res.audiofile !== null){
        setHdmp3File(res.audiofile.hd_mp3.url)
        setOriginalFile(res.audiofile.original.url)
        setShowDownloadModal(currentTarget);
      }else{
        Helper.handleError(res);
      }
    })

  }

  const handleClose = () => {
    setShowDownloadModal(false);
  }
  
  const handleDownloadArchive=(trackId,quality)=>{
    API.downloadArchiveTrack(trackId,quality).then(res => {
    })
  }

  const setDownloadHistory = (trackId) => {
    API.setDownloads(trackId).then(res => {
      // 
    })
  }

  

  const addSearchTag = (tagId, tagName) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let tags_not = newTags.tags_not || [];
    let Contexttags = newTags.tags || [];
    Contexttags.push(tagId)
    tags_not=tags_not.filter(itemnot => itemnot!==tagId);
    newTags.tags = Contexttags;
    newTags.tags_not = tags_not;
    newTrackFilter.searchTags=newTags;
    newTrackFilter.fetchList= true ;
    newTrackFilter.url= '' ;
    trackContext.setTrackFilter(newTrackFilter);
    localStorage.setItem('filter',JSON.stringify(newTrackFilter))
  }

  const removeSearchTag = (tagId, tagName) => {
    let newTrackFilter = { ...trackContext.trackFilter };
    let newTags = { ...newTrackFilter.searchTags };
    let tags_or = newTags.tags_or || [];
    let contexttags = newTags.tags || [];
    let tags_not = newTags.tags_not || [];
    if(tags_or.some(item1 => item1.includes(tagId)))
    {
     tags_not.push(tagId);
     tags_or = tags_or.filter(item => {
         if (item.every(subItem => tags_not.includes(subItem))) {
             tags_not = tags_not.filter(itemnot => !item.includes(itemnot));
             return false;
         }
         return true;
     })   
    }
    if(contexttags.includes(tagId))
    {
     contexttags = contexttags.filter(item => item !== tagId)
    }
    newTags.tags_or = tags_or;
    newTags.tags_not = [...tags_not];
    newTags.tags = contexttags;
    newTrackFilter.searchTags=newTags;
    trackContext.setTrackFilter(newTrackFilter); 
    localStorage.setItem('filter',JSON.stringify(newTrackFilter))

  }

  const handleAlternative =() =>{
    setAlternatives(true);
  }

  const alternateVersionsProps = {
    alternateVersions, 
    fetchTrack, 
    favs, 
    setFavs, 
    handleAddTrackToFavorites,
    //props.handleProjectModal,
    handleDownloadTrack,
    showDownloadModal,
    handleClose,
    hdmp3File,
    originalFile,
    handleDownloadArchive,
    setDownloadHistory
  }

  return (
    <div className="sm:hidden flex flex-col fixed inset-0 h-screen w-full bg-black text-white p-4">
    <div className="overflow-y-scroll pb-4" style={{height: '82%'}}>
      <div className='flex justify-end px-4 pt-10'>
        <CloseModal className='cursor-pointer' onClick={props.CloseTrackDetail}/>
      </div>
      <div className='flex w-full'>
        <CardContent className='w-full sm:w-2/12 self-center '>
          <h1 className='text-2xl font-medium text-white capitalize' > {props.currentTrackDetail?.currentTrackDetail?.title}</h1>
          <h2 className='text-xs self-center text-textGray-100 capitalize display '> {props.currentTrackDetail.currentTrackDetail?.artist} </h2>
        </CardContent>
      </div>

      <div className='px-4 sm:hidden w-full flex flex-wrap self-center text-white'>
        {
          props.currentTrackDetail?.currentTrackDetail?.tags?.sort((a,b)=>(a.names[0].value < b.names[0].value) ? -1 : 1).map((tag,index) => (
            <div  key={tag.id} value={index}
            onClick={() => {if(!(searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !searchTags.tags_not?.includes(tag.id)
              && searchTags.tags?.includes(tag.id))) addSearchTag(tag.id, [...tag.names].map((eachTagName) => eachTagName.locale === 'en' ? eachTagName.value: null))}} 
            className={
              (searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !searchTags.tags_not?.includes(tag.id)) ||
              searchTags.tags?.includes(tag.id)?
            `flex pt-1 pb-1 pl-2 pr-2 mr-1 mb-2 text-xs border text-white border-white rounded-full cursor-pointer`
              :
              `flex pt-1 pb-1 pl-2 pr-2 mr-1 mb-2 text-xs border text-white border-white rounded-full cursor-pointer hover:bg-symbgGray hover:scale-75`
              }
            > {[...tag.names].map((eachTagName) => eachTagName.locale === 'en' ? eachTagName.value: null)}
            {(searchTags.tags_or?.some(item1 => item1.includes(tag.id)) && !searchTags.tags_not?.includes(tag.id)
              && searchTags.tags?.includes(tag.id)) &&
            <Close 
            onClick={(e) => {e.stopPropagation();removeSearchTag(tag.id, [...tag.names].map((eachTagName) => eachTagName.locale === 'en' ? eachTagName.value: null))}}
            className="mt-1 ml-1" 
            />
            }
            </div>
          
          ))
        }
      </div> 
      <div className="px-4 flex flex-wrap w-1/2 mt-4 mb-2">
        <div className="sm:hidden text-center ml-1 w-2/12 text-sm self-center block text-white font-medium">
          {
            128 + 'BPM'
          }
        </div>
        <div className="sm:hidden text-center ml-12 w-2/12 text-sm self-center block text-white font-medium">
          {props.currentTrackDetail.currentTrackDetail.duration && new Date(props.currentTrackDetail.currentTrackDetail.duration* 1000).toISOString().substr(14, 5)}
        </div>
      </div>
      <div className='px-4 ml-1 album-info w-full mb-4 sm:ml-64 sm:w-5/6'>
        <div className='pr-8 pb-2'>
          <p className='text-gray-600 text-sm'>Album</p>
          <h3 className='text-white text-sm font-medium'>{props.currentTrackDetail.currentTrackDetail?.album?.title}</h3>
        </div>
        {/* <div className='pr-8 pb-2'>
          <p className='text-gray-600 text-sm'>Producer</p>
          <h3 className='text-white text-sm font-medium'>
          -
          </h3>
        </div>
        <div className='pr-8 pb-2'>
          <p className='text-gray-600 text-sm'>Publisher</p>
          <h3 className='text-white text-sm font-medium'>
            -
          </h3>
        </div>
        <div className='pr-8 pb-2'>
          <p className='text-gray-600 text-sm'>Year</p>
          <h3 className='text-white text-sm font-medium'>
            2020
          </h3>
        </div> */}
        <div className="w-full"> 
          <p className='text-gray-600 text-sm'>Artists</p>
          <div className="flex">
            <h1 className='text-white text-sm font-medium capitalize'> 
              {props.currentTrackDetail.currentTrackDetail?.artist} 
            </h1>
            <h3 className='text-white text-sm font-light capitalize'> 
            {
              props.currentTrackDetail.currentTrackDetail?.artist_type
            } </h3>
          </div>
          {/* <div className="flex">
            <h1 className='text-white text-sm font-medium capitalize'> - </h1>
            <h3 className='text-white text-sm font-light capitalize'>Producer</h3>
          </div> */}
        </div>
      </div>

      {/* <div 
        className="px-4 sm:ml-56 sm:w-10/12 sm:pr-16 sm:pl-8"
        onClick={handleAlternative}>
        <p className='text-gray-600 text-sm ml-1' >Alternatve versions and Edits</p>
        {
          showAlternatives && 
          <div className='px-8 py-2'>
          <AlternateTracks {...alternateVersionsProps}/>
          </div>
        }
      </div> */}
      

    </div>  
    <div className="max-h-screen bg-black fixed bottom-0 left-0 w-full border-t pt-5 px-6">
      { 
       isFav ? 
        <div className="flex justify-start mb-5" onClick={() => handleAddTrackToFavorites(props.trackId)}>
        <FavoriteIcon
          style={{fontSize: '30px'}} 
          className='fav-icon self-center right-0 cursor-pointer text-ctaRed hover:scale-50'        
        />
        <div className='text-white text-xl font-medium ml-3'>Favourite</div>
        </div>
        :
        <div className="flex justify-start mb-5"  onClick={() => handleAddTrackToFavorites(props.trackId)}>
        <UnFavoriteIcon
          style={{fontSize: '30px'}} 
          className='unfav-icon self-center cursor-pointer text-gray-600 hover:text-ctaRed'
        />
        <div className='text-white text-xl font-medium ml-3'>Favourite</div>
        </div>
      }
      <div className="flex justify-start mb-5" onClick={() =>{
        if (trackContext.userDetails === undefined || Object.keys(trackContext.userDetails ||{}).length === 0) {
          props.CloseTrackDetail();
        }
         props.handleProjectModal(trackContext.currentTrackDetail.id)
      }}>
        <CreateNewFolderWhiteIcon          
          className='add-to-proj-mob self-center cursor-pointer text-gray-700 hover:text-blue-600 '
        />
        <div className='text-white text-xl font-medium ml-3'>Add to Projects</div>
      </div>
      </div>
    </div>
  );
}
