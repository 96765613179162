import React from "react"
import { useEffect } from "react"

const TableComp = (props) => {
    useEffect(() => {
        console.log(props.data)
    }, [props.data])
    return (
    <div className='w-full border' style={{"height" : "auto", "maxHeight" : "60vh", "overflowX" : "scroll"}}>
        <div className='w-full center-align'>
            <table className="w-full border">
                <thead>
                <tr className="text-xs p-1" style={{background : 'gray'}}>
                    {props.headers?.map(header => 
                        <th style={{"textAlign" : "center"}}>{header}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {props.data?.map((rowData, index) => 
                <tr className="text-xs" style={{background : index%2===0 ? 'white' : 'lightgray'}}>
                    {rowData?.map(data => 
                        <td className="py-1" style={{"textAlign" : "center", "wordBreak": "break-word"}}>{data}</td>
                    )}
                </tr>)
                }
                </tbody>
            </table>
        </div>
        {/* <div className="w-full">
            <div className="w-8/12">
                <label>{(props.pageNo * Constants.DEFAULT_PAGE_SIZE) + 1} to {((props.pageNo + 1) * Constants.DEFAULT_PAGE_SIZE) > props.totalCount ? props.totalCount : ((props.pageNo + 1) * Constants.DEFAULT_PAGE_SIZE) } of {props.totalCount}</label>
            </div>
        </div> */}
    </div>)
}

export default TableComp