import React, { useState, useContext } from 'react';
import TrackContext from '../../contexts/track-context';
import { ReactComponent as ThumbPrintIcon } from '../../assets/auto_fix_high_black_36dp.svg';
import CookieManager from '../../services/CookieManager';

function AISearchMobile (props){
  const [showProjectModal, setShowProjectModal] = useState(false);
  let trackContext = useContext(TrackContext);       
  const handleMobileSearch = () => {
    let userData = JSON.parse(CookieManager.getCookie('user-data'));
    if (userData === null) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction('AI Search');
    } else if (trackContext.userDetails.userType === 'FREE' && userData.subscribed === false) {
      trackContext.setShowProjectModal(true);
    } else {
      trackContext.setShowProjectModal(true);
    }
  }
  return (
    <div className="">
    {
      (['/signin', '/signup', '/forgot-password'].indexOf(props.props.location.pathname) === -1 
       && String(props.props.location.pathname).includes('/set-password') === false) ?
      <div className="flex md:hidden flex-wrap md:px-8 bg-white pt-3 md:mx-20 w-full">
        <div className="w-full flex">
          <div className="pr-1 pl-3">
            <ThumbPrintIcon />
          </div>
          <input
            onFocus={handleMobileSearch}
            className={`p-2 self-center h-8	border bg-white outline-none text-textGray-100 rounded-full aisearch w-4/5
            ${showProjectModal ? "border-symStrokeGrey placeholder-textGray-100 dis-aisearch" :" cursor-pointer placeholder-textGray-600  border-black"}`}  
            type="text" 
            placeholder='AI Search'
            disabled={showProjectModal ? true :false}
          />
        </div>
        <div className="px-5 pt-2 pb-5">
          <h1 className="text-textGray-100 text-base leading-tight font-sans">Paste in a Youtube or Vimeo link or keyword to let our AI find something similar</h1>
        </div>
      </div>
      : null
    }
    </div>
  );
}

export default AISearchMobile;