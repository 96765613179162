import React, { useState } from 'react';
import HubspotForm from "react-hubspot-form";
import './style.css';


export default function Support() {


  return (
    <div className='flex text-center flex-wrap'>
    <div className='self-center text-center w-full'>
      <h1 className='w-full description2 pt-20'>Submit a Support Ticket</h1>
      <div className='hform'>
      <HubspotForm
      portalId="23971077"
      formId="0f43af02-32a2-4477-b9f9-8cb2ab118fc4"
      onSubmit={() => console.log("Submit!")}
      onReady={(form) => console.log("Form ready!")}
      loading={<div></div>}
    />
    </div>
    </div>
  </div>
  )
}
