import React, { useState, useEffect } from "react";
import "./styles/global.css";
import Register from "./components/Register";
import Navbar from "./components/Navbar";
import CookieManager from "./services/CookieManager";
import ListTracks from "./components/ListTracks";
import Sidebar from "./components/Sidebar";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import EmailVerification from "./components/EmailVerification";
import Projects from "./components/Projects";
import Favorites from "./components/Favorites";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MediaPlay from "./components/MediaPlay";
import PlayHistory from "./components/PlayHistory";
import Downloads from "./components/Downloads";
import Amplitude from "amplitudejs";
//import License from './components/License'
import AllLicenses from "./components/License/all-licenses";
import SingleLicense from "./components/License/license";
import TrackContext from "./contexts/track-context";
import { LinearProgress } from "@material-ui/core";
import Signin from "./components/Register/signin";
import SignupConfirm from "./components/Register/signupconfirm";
import SignupVerify from "./components/Register/signupverify";
import Footer from "./pure-components/Footer";
import { Link } from "react-router-dom";
import Helper from "./services/helper";
import Profile from "./components/Profile";
import Billing from "./components/Billing";
import Constants from "./services/constants";
import Support from "./components/Support";
import API from "./services/api";
import SubscribePopup from "./pure-components/SubscribePopup";
import AlertSubscribePopup from "./pure-components/AlertSubscribePopup";
import ForgotPassword from "./components/Register/forgot-password";
import SetNewPassword from "./components/Register/set-new-password";
import Admin from "./components/Admin";
import ChoosePlan from "./components/ChoosePlan";
import NotFoundPage from "./components/NotFoundPage";
import AlbumDetails from "./components/AlbumDetails";
import Voicegen from "./components/Voicegen";
import ErrorBoundary from "./pure-components/ErrorBoundary";
import musicImage from "./assets/default_music.png";
import NewInspiration from "./components/NewInspiration";
import PlaylistDetails from "./components/PlaylistDetails";
import LicenseInsight from "./components/Admin/licenseInsight";
import ArtistTrackView from "./components/ArtistTrackView";
import SoundFx from "./components/SoundFx";
import LogoutPopup from "./pure-components/LogoutPopup";
import MewoService from "./services/mewoService";
import { toast } from "react-toastify";
import { ErrorToast } from "../src/pure-components/CustomToast";
import Catalog from "./components/Catalog";
import CatalogAlbum from "./components/Catalog/catalog-album";

import ReactGA from 'react-ga';
import RouteChangeTracker from './services/RouteChangeTracker'
import HomePage from "./components/LandingPage";

import HarmixLibrary from "./components/Harmix/library"

import AboutUs from "./components/KnowledgeBase/AboutUs";
import ServiceTerms from "./components/KnowledgeBase/TermsServices";
import PrivacyPolicy from "./components/KnowledgeBase/PrivacyPolicy";
import CookiesPolicy from "./components/KnowledgeBase/Cookies";
import ContactUs from "./components/KnowledgeBase/ContactUs";
import Faqs from "./components/KnowledgeBase/FAQPage";

export default withRouter(function App({ location, match }) {
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [loadingTrack, setLoadingTrack] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [path, setCurrentPathname] = useState("/");
  const [currentTrack, setCurrentTrack] = useState("");
  const [previousTrack, setpreviousTrack] = useState(-1); // it wil remove
  const [trackData, setTrackData] = useState([]);
  const [currentTrackImage, setCurrentTrackImage] = useState("");
  const [currentTrackDetail, setCurrentTrackDetail] = useState({});
  const [resultFromMaia, setResultFromMaia] = useState([]);
  const [maiaOnWork, setMaiaOnWork] = useState(false);
  const [playPause, setPlayPause] = useState(false);
  const [totaltracks, setTotaltracks] = useState(0);
  const [searchkey,setSearchkey]=useState("");  
  const [filterMenu, setFilterMenu] = useState( [
    { key: "mood", value: "MOODS" },
    { key: "genres", value: "GENRES" },
    { key: "instruments", value: "INSTRUMENTS" },
    { key: "structures", value: "STRUCTURES" },
    { key: "style", value: "STYLES" },
    { key: "usage", value: "USAGES" },
    { key: "duration", value: "DURATION", isSlider: true },
    { key: "bpm", value: "BPM", isSlider: true },
  ]
 );
  const [trackFilter, setTrackFilter] = useState({
    searchTags: {},
    selectedTags: [],
    bpm: [],
    duration: [],
    fetchList: true,
    searchText: "",
    url: "",
    searchTitle: "",
    selectedChildTags: [],
    searchId:"",
    fileName:"",
    trackId:"",
  });
  const [searchAlbums, setSearchAlbums] = useState(false);
  const filterDefaultMenu =  [
    { key: "mood", value: "MOODS" },
    { key: "genres", value: "GENRES" },
    { key: "instruments", value: "INSTRUMENTS" },
    { key: "structures", value: "STRUCTURES" },
    { key: "style", value: "STYLES" },
    { key: "usage", value: "USAGES" },
    { key: "duration", value: "DURATION", isSlider: true },
    { key: "bpm", value: "BPM", isSlider: true },
  ]

  const filterData ={
    searchTags: {},
    selectedTags: [],
    bpm: [],
    duration: [],
    fetchList: true,
    searchText: "",
    url: "",
    searchTitle: "",
    selectedChildTags: [],
    searchId:"",
    fileName:"",
    trackId:"",
  }

  const excludedTagIDs = ["621fa40f4576ab0016018578"]
  
  //Initializing Google Analytics dependency
  ReactGA.initialize(Constants.GA_MEASURMENT_ID);

  useEffect(() => {

    //
    if (window.location.pathname === "/"||
        window.location.pathname.startsWith( `/search/track`)||
        window.location.pathname.startsWith( `/search/albums/`)
     ) {
      setFilterMenu(
        JSON.parse(localStorage.getItem("filterMenu_music")) || filterDefaultMenu
      );
      setTrackFilter(JSON.parse(localStorage.getItem("filter_music"))  || filterData
      );
    } else if (window.location.pathname.startsWith("/sound-design")||
               window.location.pathname.startsWith(`/sound-design/search/track/`)||
               window.location.pathname.startsWith(`/sound-design/search/albums/`)

      ){
      setFilterMenu(
        JSON.parse(localStorage.getItem("filterMenu_soundfx"))||filterDefaultMenu
      );
      setTrackFilter(JSON.parse(localStorage.getItem("filter_soundfx")) || filterData

      );
    }
  }, [location.pathname]);

  useEffect(() => {
    if (window.location.pathname === "/"||
       window.location.pathname.startsWith( `/search/track`)||
       window.location.pathname.startsWith( `/search/albums/`) 
    ) {
      localStorage.setItem("filterMenu_music", JSON.stringify(filterMenu));
      localStorage.setItem("filter_music", JSON.stringify(trackFilter));
    }
     else if (window.location.pathname === ("/sound-design")||
     window.location.pathname.startsWith(`/sound-design/search/track/`)||
     window.location.pathname.startsWith(`/sound-design/search/albums/`)

     ) {
      localStorage.setItem("filterMenu_soundfx", JSON.stringify(filterMenu));
      localStorage.setItem("filter_soundfx", JSON.stringify(trackFilter));
    }
  }, [filterMenu, trackFilter]);

  const [loading, setIsLoading] = useState(true);

  // const [filterMenu,setFilterMenu] = useState([{ key: "mood", value: "MOODS" }, { key: "genres", value: "GENRES" }, { key: "instruments", value: "INSTRUMENTS" },
  //   { key: "structures", value: "STRUCTURES" }, { key: "style", value: "STYLES" }, { key: "usage", value: "USAGES" }, { key: "duration", value: "DURATION",isSlider:true },
  //    { key: "bpm", value: "BPM",isSlider:true }]);
  // const [trackFilter, setTrackFilter] = useState({searchTags:{},selectedTags:[],bpm:[],duration:[],fetchList:true,searchText:'',url:''});
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [showAlertPopups, setShowAlertPopups] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [userAction, setUserAction] = useState("");
  const [error, setError] = useState("");
  const [dashboardMenu, setDashboardMenu] = useState([
    { key: "My Projects", value: "/projects" },
    { key: "My Favourites", value: "/favourites" },
    { key: "My Play History", value: "/history" },
    { key: "My Profile", value: "/profile" },
    { key: "My Invoices", value: "/billing" },
    { key: "Support", value: "/support" },
  ]);
  const [showProjectModal, setShowProjectModal] = useState(false);

  const [tags, setAllTags] = useState([]);
  const [clearLocal, setClearLocal] = useState("clear");
  const [clearData, setClearData] = useState(false);
  const [showMayaLoader, setShowMayaLoader] = useState(false);
  const [allTracksLoaded, setAllTracksLoaded] = useState(false);
  const [resultFromMawoSearch, setResultFromMawoSearch] = useState(false);
  const [filterSubMenu, setFilterSubMenu] = useState([]);
  const [filterSubMenuChild, setFilterSubMenuchild] = useState([]);
  const [searchTime,setSearchTime]=useState(0);
  const [intervalID,setIntervalId]=useState(null);
  const [stopSearch, setStopSearch] = useState(false);
  const [showProgress,setShowProgress]=useState(false);
  const [progress,setProgressValue]=useState(0);
  const [fileName,setAudioFileName]=useState('');
  const [isFileUpload,setFileUpload]=useState(false); // file upload flag
  const [cancelToken,setCancelToken]=useState(); // token for cancel file upload request 
  const [AISearch,setSearch]=useState(false);
  const [showLoaderMagic, setShowLoaderMagic] = useState(false)
  const [songData,setSongData]=useState([]);// holds track data for autoplay next track
  const [alltrackData,setalltrackData]=useState([]);//holds data to play next or prev track
  const [playingFrom,setPlayingFrom]=useState('');//holds page refrence from where track is played
  const [searchDescription, setSearchDescription] = React.useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);

  //set default image function
    const setDefaultImage=(event)=>{
    //replacement of broken Image
    event.target.src = musicImage;
  };
  useEffect(() => {
    if (window.location.pathname === "/"||window.location.pathname.startsWith( `/search/track`)) {
      localStorage.setItem(
        "filterSubMenu_music",
        JSON.stringify(filterSubMenu)
      );
      localStorage.setItem(
        "filterSubMenuChild_music",
        JSON.stringify(filterSubMenuChild)
      );
    } else if (window.location.pathname === "/sound-design"||window.location.pathname.startsWith(`/sound-design/search/track/`)) {
      localStorage.setItem(
        "filterSubMenu_soundfx",
        JSON.stringify(filterSubMenu)
      );
      localStorage.setItem(
        "filterSubMenuChild_soundfx",
        JSON.stringify(filterSubMenuChild)
      );
    }
  }, [filterSubMenu, filterSubMenuChild]);

  useEffect(() => {
    if (window.location.pathname === "/"||window.location.pathname.startsWith( `/search/track`)) {
      setFilterSubMenu(
        JSON.parse(localStorage.getItem("filterSubMenu_music")) || []
      );
      setFilterSubMenuchild(
        JSON.parse(localStorage.getItem("filterSubMenuChild_music")) || []
      );
    } else if (window.location.pathname === "/sound-design"||window.location.pathname.startsWith(`/sound-design/search/track/`)) {
      setFilterSubMenu(
        JSON.parse(localStorage.getItem("filterSubMenu_soundfx")) || []
      );
      setFilterSubMenuchild(
        JSON.parse(localStorage.getItem("filterSubMenuChild_soundfx")) || []
      );
    }
  }, [location.pathname]);

  const handleResultfromMaia = (resultFromAI) => {
    setResultFromMaia(resultFromAI);
  };

  const handleMaiaOnWork = (value) => {
    setMaiaOnWork(value);
  };

  useEffect(() => {
    const X_ACCESS_TOKEN = CookieManager.getCookie("x-access-token");
    const userData = CookieManager.getCookie("user-data");
    if (X_ACCESS_TOKEN && userData !== undefined && userData !== null && userData !== "null") {
      setUserSignedIn(true);
      let details = JSON.parse(userData);
      setUserDetails(details);
      // console.log('User Data :',userDetails)
      //API.updateUserCookie();
    }
    //  else {
    //   Helper.clearStorageAndCookie();
    // }
  }, []);

  useEffect(() => {
    let tempDashboardMenu = [...dashboardMenu];
    if (userDetails?.userType === JSON.parse(process.env.REACT_APP_USER_TYPE)?.ADMIN
        || userDetails?.userType === JSON.parse(process.env.REACT_APP_USER_TYPE)?.SUPER_ADMIN
    ) {
      if(!tempDashboardMenu.filter((item) => item.key === "Admin" )?.length)
        tempDashboardMenu.push({ key: "Admin", value: "/admin" });
    }

    // billing section removed for now,[we can send the invoice from stripe directly to the customer via Email] 
    // TODO : 
    // billing section would only be visible to the Corporate Admin.
    // Admin could control the whole billing thing from this panel and will have invoices insight.
    if ((userDetails?.userType === JSON.parse(process.env.REACT_APP_USER_TYPE)?.ADMIN
        || userDetails?.userType === JSON.parse(process.env.REACT_APP_USER_TYPE)?.SUPER_ADMIN
        || userDetails?.userType === JSON.parse(process.env.REACT_APP_USER_TYPE)?.CORPORATE)
        ){
        tempDashboardMenu = tempDashboardMenu.filter((item) => item.key !== "My Invoices");
    }
    setDashboardMenu(tempDashboardMenu);

  }, [userDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { pathname } = location;
    setCurrentPathname(pathname);
    const X_ACCESS_TOKEN = CookieManager.getCookie("x-access-token");
    if (X_ACCESS_TOKEN) {
      let unsignedUserRoutes = ["/signin", "/forgot-password"];
      if (
        unsignedUserRoutes.indexOf(pathname) !== -1 ||
        pathname.includes("/set-password")
      ) {
        window.location = "/";
      }

      if (pathname === "/signup/") {
        window.location.href = `${location.pathname}${location.hash}`;
      }
    }
    if (pathname.indexOf("license") !== -1) {
      setCurrentTrack("");
      setPlayPause(false);
      setCurrentTrackDetail({});
      if (Amplitude.getSongs().length) {
        Amplitude.stop();
        Amplitude.removeSong(0);
      }
    }
  }, [location.pathname]);

  const setFetchingTrack = (val) => {
    setLoadingTrack(val);
  };

  //TODO : remove tags loading as we are using Harmix search engine
  useEffect(() => {
    if(!(tags?.length > 0)){
      (async() => {
        let reqObj = { 
          url : 'public/options/tags', 
          method : 'get'
        };
        await MewoService.loadMewoData(reqObj).then((res) => {
          if(res){
            let data = res;
            let filteredTags = filterTags(data);
            setAllTags(filteredTags);
          }
        }).catch((err) => {
          toast(({ closeToast }) => <ErrorToast message={"Tags Loading Error : " + err?.response?.data?.message}/>);
        });
      })();
    }
  }, []);

  //hiding subscribe popup, logout popup, music search arena whenever URL path is being changes
  useEffect(() => {
    setShowSubscribePopup(false);
    setShowLogoutPopup(false);
    setSearch(false);
  }, [path, location.pathname])

  const filterTags = (tags) => {
    if(tags?.length > 0){
      let tempTagList = [];
      tags.forEach(element => {
        if(element && !excludedTagIDs.includes(element.id)){
          let subCat = element.sub_categories.filter((elem) => {
            return !excludedTagIDs.includes(elem.id) 
          });
          if(subCat?.length > 0){
            subCat.forEach(elem => {
              let childCat = elem.tags.filter(tag => {
                return !excludedTagIDs.includes(tag.id)
              })
              elem.tags = childCat;
            })
          }
          tempTagList.push({...element, "sub_categories" : subCat});
        }
      });
      return tempTagList;
    }else{
      return tags;
    }
  }

  const validateUser = (action) => {
    let profileData = JSON.parse(CookieManager.getCookie("user-data") || "{}"); 
    let valid = true;
    if (profileData?.email === undefined) {
      valid = false;
    }else{
      let userType = JSON.parse(process.env.REACT_APP_USER_TYPE);

      //validate all userType privilege
      switch(profileData.userType){
        case userType.FREE : 
          if(action === Constants.ACTION.DOWNLOAD || action === Constants.ACTION.TTS || action === Constants.ACTION.GENERATE_LICENSE)
            valid = false;
        break;
        case userType.ADMIN :
          if((profileData.isSuspended || (new Date(profileData.renewalDate).getTime() < new Date().getTime()))  && (action === Constants.ACTION.DOWNLOAD || action === Constants.ACTION.TTS || action === Constants.ACTION.GENERATE_LICENSE))
            valid = false;
        break;
        case userType.SOLO :
        case userType.SOLO_PRO : 
        case userType.CORPORATE :
          if(!profileData.subscribed && (action === Constants.ACTION.DOWNLOAD || action === Constants.ACTION.TTS || action === Constants.ACTION.GENERATE_LICENSE))
            valid = false;  
          break;
        default : 
          break;
      }
    }
    return valid;

  }
  return (
    <TrackContext.Provider
      value={{
        setCurrentTrack,
        setCurrentTrackDetail,
        setCurrentTrackImage,
        currentTrack,
        currentTrackDetail,
        currentTrackImage,
        playPause,
        setPlayPause,
        setLoadingTrack,
        totaltracks,
        setTotaltracks,
        previousTrack,
        setpreviousTrack,
        trackData,
        setTrackData,
        trackFilter,
        setTrackFilter,
        filterMenu,
        setFilterMenu,
        userDetails,
        setShowSubscribePopup,
        setCurrPage,
        currPage,
        setUserAction,
        setUserDetails,
        loading,
        setIsLoading,
        showProjectModal,
        setShowProjectModal,
        setCurrentPathname,
        path,
        setFetchingTrack,
        setShowAlertPopups,
        error,
        setError,
        clearData,
        setClearData,
        showMayaLoader,
        setShowMayaLoader,
        allTracksLoaded,
        setAllTracksLoaded,
        resultFromMawoSearch,
        setResultFromMawoSearch,
        filterSubMenu,
        setFilterSubMenu,
        filterSubMenuChild,
        setFilterSubMenuchild,
        stopSearch,
        setStopSearch,
        intervalID,
        setIntervalId,
        searchTime,
        setSearchTime,
        setDefaultImage,
        showProgress,
        setShowProgress,
        progress,
        setProgressValue,
        maiaOnWork,
        handleMaiaOnWork,
        fileName,
        setAudioFileName,
        validateUser,
        isFileUpload,
        setFileUpload,
        cancelToken,
        setCancelToken,
        searchkey,
        setSearchkey,
        searchAlbums,
        setSearchAlbums,
        AISearch,
        setSearch,
        showLoaderMagic, 
        setShowLoaderMagic,
        songData,
        setSongData,
        alltrackData,
        setalltrackData,
        setPlayingFrom,
        playingFrom,
        searchDescription,
        setSearchDescription,
        showLogoutPopup,
        setShowLogoutPopup
              }}
    >
      <ErrorBoundary>
        <RouteChangeTracker />
        <div className="">
          <>
            <Navbar
              match={match}              
              location={location}
              handleMaiaOnWork={handleMaiaOnWork}
              showProgress={showProgress}
              handleResultfromMaia={handleResultfromMaia}
              name={userDetails?.first_name ? `${userDetails?.first_name} ${userDetails?.last_name}` : ""}
            />
            <Route path="/new-inspiration" component={NewInspiration}  />
            <Route path="/playlists/:playlistId" component={PlaylistDetails} />
            
            <Route path="/signup/" component={Register} />
            <Route path="/choose-plan" component={ChoosePlan} />
            <Route path="/album/:albumId" component={AlbumDetails} />                
            <Route path="/artist/:artistId" component={ArtistTrackView} />                
            <Route path="/sound-fx" component={SoundFx}  />
            <Route path="/catalogs" component={Catalog}  />
            <Route path="/catalog/albums/:catalogId" component={CatalogAlbum}  />
            <Route path="/home" component={HomePage}  />
            <Route path="/library/:assetId?" component={HarmixLibrary}  />

            {/* KB solution links */}
            <Route path="/about" component={AboutUs}  />
            <Route path="/terms" component={ServiceTerms}  />
            <Route path="/privacy" component={PrivacyPolicy}  />
            <Route path="/cookies" component={CookiesPolicy}  />
            <Route path="/contact" component={ContactUs}  />
            <Route path="/faqs" component={Faqs}  />

            {(path === "/" ||
              path.includes("/search/track/") ||
              path.includes( `/search/albums/`)) && (
              <div className=" flex-wrap lg:px-8 bg-white pt-20 lg:pt-3 lg:mx-20">
                <div className="content-wrapper">
                  <Sidebar
                    tags={tags}
                    location={location}
                    handleMaiaOnWork={handleMaiaOnWork}
                    handleResultfromMaia={handleResultfromMaia}
                  />
                  <ListTracks
                    maiaOnWork={maiaOnWork}
                    handleMaiaOnWork={handleMaiaOnWork}
                    resultFromMaia={resultFromMaia}
                    setFetchingTrack={setFetchingTrack}
                  />
                </div>
              </div>
            )}
                  
          </>
          {userSignedIn ? (
            <>
              <Route path="/insights" component={LicenseInsight} />
              
              <div
                className={
                  path === "/choose-plan"
                    ? "flex flex-wrap bg-white pt-16 "
                    : "flex flex-wrap p-3 lg:px-8 bg-white pt-16 lg:pt-3 lg:mx-20"
                }
              >
                <div
                  className={`${path === `/404page/`
                      ? "w-full lg:flex lg:flex-col lg:pt-16"
                      : path === `/` ||
                        path.includes("/search/track/") ||
                        path.includes( `/search/albums/`) ||
                        path === `/sound-design` ||
                        path === `/inspiration`
                      ? `w-full ${path.includes("/album")
                            ? "lg:flex lg:flex-col-reverse"
                            : "lg:flex lg:flex-row-reverse"
                        } `
                      : `w-full ${path.includes("/album")
                            ? "lg:flex lg:flex-col-reverse "
                            : "lg:flex lg:flex-row-reverse lg:pt-16"
                        } `
                  } `}
                >
                  {(dashboardMenu.filter((item) => item.value === path).length >
                    0 ||
                    path.indexOf("license") !== -1) && (
                    <>
                        <div
                          className="user-account-nav flex lg:inline-block pb-4 lg:pb-6 pt-4 lg:pt-24 lg:pl-0 lg:w-1/4 bg-white lg:bg-black overflow-x-auto lg:ml-5 lg:min-h-screen"
                        >
                        {dashboardMenu.map((item, index) => {
                          return (
                            <Link key={index} to={item.value}>
                              <div
                                key={item.id}
                                  className={`uppercase lg:w-full lg:flex lg:items-center lg:h-10 px-4 mr-2 py-1 text-xl lg:text-white cursor-pointer ${path === item.value
                                    ? "bg-ctaRed text-white font-semibold"
                                    : "font-normal"
                                }`}
                              >
                                {item.key}
                              </div>
                            </Link>
                          );
                        })}
                        <div className="ml-4">
                          <button className="navPinkbuttonwide" onClick={() => setShowLogoutPopup(true)}>
                              SIGN OUT
                          </button>
                        </div>
                      </div>
                      <Route path="/projects" component={Projects} />
                      <Route path="/admin" component={Admin} />
                      <Route path="/favourites" component={Favorites} />
                      <Route path="/history" component={PlayHistory} />
                      <Route path="/downloads" component={Downloads} />
                      <Route path="/profile" component={Profile} />
                      <Route path="/billing" component={Billing} />
                      <Route path="/support" component={Support} />
                      <Route path="/licenses" component={AllLicenses} />
                      <Route
                        path="/license/:projectId"
                        component={SingleLicense}
                      />
                    </>
                  )}

                  {path !== "/"|| path.includes(`/search/track/`) || path.includes(`/search/albums/`) &&
                    ![
                      "/404page/",
                      "/set-password",
                      "/projects",
                      "/admin",
                      "/favourites",
                      "/history",
                      "/profile",
                      "/billing",
                      "/support",
                      "/licenses",
                      "/signin",
                      "/choose-plan",
                      "/signup/",
                      "/sound-design",
                      "/inspiration",
                      "/downloads",
                      "/new-inspiration"
                    ].includes(path) &&
                    !path.includes("/license/") &&
                    !path.includes("/album/") && (
                    !path.includes("/playlists/") &&
                      <Route
                        component={() => {
                          window.location.href = "/projects";
                          return null;
                        }}
                      />
                    )}
                  
                </div>
              </div>
            </>
          ) : (
            <>

              <Switch>
                <Route
                  path="/email-verification/:token"
                  component={EmailVerification}
                />
                <Route path="/signin" component={Signin} />
                <Route path="/confirmation" component={SignupConfirm} />
                <Route path="/verification" component={SignupVerify} />
                <Route path="/playlists/:playlistId" component={PlaylistDetails} />
                <Route path="/404page" component={NotFoundPage} />
                <Route path="/forgot-password" component={ForgotPassword} />

                <Route
                  path="/set-password/:resetCode"
                  component={SetNewPassword}
                />
                <Route path="/set-password" component={SetNewPassword} />
                  
                {
                  [
                    "/projects",
                    "/admin",
                    "/favourites",
                    "/history",
                    "/profile",
                    "/billing",
                    "/support",
                    "/licenses",
                  ].includes(path) ||
                  path.includes("/license/") ? (
                    <Redirect to="/signin" />
                  ) : (
                    path !== "/" &&
                    path === `/search/track/${searchkey}` &&
                    path === `/search/albums/${searchkey}` &&
                    path !== "/signin" &&
                    path !== "/signup" &&
                    path !== "/sound-fx" &&
                    path !== "/inspiration" &&
                    path !== "/new-inspiration" &&              
                    path !== "/signup/" &&
                    path !== "/confirmation" &&            
                    !path.includes("/404page/") &&
                    !path.includes("/playlists/") &&
                    !path.includes("set-password") &&
                    !path.includes("/catalogs") && (
                      <Route
                        component={() => {
                          window.location.href = process.env.REACT_APP_MKT_SITE_404;
                          return null;
                        }}
                      />
                    )
                  )
                  // <Redirect to='https://www.syncmama.com/404page' />
                }
              </Switch>
            </>
          )}

          <div
            id="sync-media-player"
            className="fixed bottom-0 w-full h-1 z-10"
          >
            {loadingTrack && <LinearProgress className="z-20" />}
            {currentTrack !== "" && (
              <div className="flex justify-center">
                <MediaPlay
                  currentTrackDetail={currentTrackDetail}
                  currentTrackImage={currentTrackImage}
                  currentTrack={currentTrack}
                />
              </div>
            )}
          </div>
          <ToastContainer
            position='top-right'
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {
            showSubscribePopup ?
              <SubscribePopup action={userAction} />
              :
              null
          }
          {
            showLogoutPopup ?
              <LogoutPopup />
              :
              null
          }
          {showAlertPopups ? <AlertSubscribePopup error={error} /> : null}
          {!path?.includes("/home") && <Footer />}
        </div>
      </ErrorBoundary>
    </TrackContext.Provider>
  );
});