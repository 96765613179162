import React, { useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import API from '../../services/api';
import CancelIcon from '@material-ui/icons/Close';
import './style.css';
import { BouncingLoader } from "../../pure-components/Loader";
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ErrorToast, InfoToast, SuccessToast } from '../../pure-components/CustomToast';
import Helper from '../../services/helper';

function AddTrackToProjectPopup(props) {
  const [projectTitle, setProjectTitle] = useState('');
  const [projects, setProjects] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [tempProjects, setTempProjects] = useState([]);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
  const [trackAddedProjects, setTrackAddedProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    (async () => {

      setTrackToBeAddedToProject(props.trackToBeAddedToProject);
      setIsLoading(true);
      await fetchProjects().catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={`Error Loading Projects : ${err.status}: ${err?.message}`} />);
      })
    })();
  }, []);

  const createProject = async(value) => {
    if(!(value?.length > 0))
      return;
    if(projects?.find((elem) => {
      return elem?.title?.toLowerCase() === value?.toLowerCase()
    })){
      toast(({ closeToast }) => <ErrorToast message={`Already have a project with the name : ${value}, please change the name.`} />); 
      return;
    }
    await API.createProject({ title: value }).then(async(res) => {
      setProjectTitle('');
      await fetchProjects().catch((err) => {
        toast(({ closeToast }) => <ErrorToast message={`Error Loading Projects : ${err.status}: ${err?.message}`} />); 
      });
    }).catch((err) => {
      toast(({ closeToast }) => <ErrorToast message={`Error Creating Project : ${err.status}: ${err?.message}`} />); 
    });
    setProjectTitle('');
  }
  const fetchProjects = async() => {
    await API.getProjects().then(data => {
      let listproject = data.data?.map(item => {
        if (item.trackIds?.length > 0 && item.trackIds?.some(item1 => item1.mewoTrackId === props.trackToBeAddedToProject)) {
          item.isChecked = true;
        }
        return item;
      })
      setProjects(listproject);
      setTempProjects(listproject);
      setIsLoading(false);
    }).catch(err => {throw err});
  }
  const handleProjectTitle = async (e) => {
    if (e.key === 'Enter') {
      await createProject(projectTitle);
    }
  }

  const handleSearchProjects = (e) => {
    let { value } = e.target;
    setSearchKeyword(value);
    if (value !== '' && value.length > 1) {
      let filteredProjects = projects.filter(project => project.title.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      setProjects(filteredProjects);
    }

    if (value === '') {
      setProjects(tempProjects);
    }
  }

  const addProject = async() => {
    await createProject(projectTitle);
  }

  const addTrackToProjects = async() => {   
    props.closeProjectModal();
    if (trackAddedProjects?.length) {
      let taskArr = [];
      trackAddedProjects.map(projectId => {
        taskArr.push(API.addTrackToProject(trackToBeAddedToProject, projectId).then(res => {
          if (res?.success)
            toast(({ closeToast }) => <SuccessToast message={res.mssg} />);
          else
            Helper.handleError(res);
        }));
      });
      await Promise.allSettled(taskArr).then(res => {
      });
    }else{
      toast(({ closeToast }) => <InfoToast message={"No project(s) selected to add the track."} />);
    }
  }

  const addTrackToProject = async (projectId) => {
    await API.addTrackToProject(trackToBeAddedToProject, projectId).then(res => {
      toast(({ closeToast }) => <SuccessToast message={res.mssg} />);
    }).catch((err) => {
      toast(({ closeToast }) => <ErrorToast message={err} />);
    });
  }

  const trackAddedToProject = (projectId, e) => {
    let isChecked = e.target.checked;
    let projectList = trackAddedProjects;
    if (isChecked && projectList.indexOf(projectId) < 0) {
      projectList.push(projectId);
    }
    else if (!isChecked && projectList.indexOf(projectId) >= 0) {
      var index = projectList.indexOf(projectId);    // <-- Not supported in <IE9
      projectList.splice(index, 1);
    }
    setTrackAddedProjects(projectList);
  }

  return (
    <div className='flex fixed top-4 h-screen w-full subscribe-modal justify-center' style={{ zIndex: '9' }}>
      <div className='self-center w-full lg:w-2/5 sm:w-3/5 shadow-xl' style={{ background: 'rgba(255,255,255, 0,5)' }}>
        <div className='w-full flex justify-centre px-5 mb-2'>
          <div className="w-full lg:text-center sm-padding-left lg:block">
            <h1>Add to Project</h1>
          </div>
          <CancelIcon
            className="cursor-pointer"
            onClick={() => props.closeProjectModal()}
            style={{ fontSize: '24px' }}
          />
        </div>
        <div className='flex justify-end'>
        </div>
        <div className='border-b border-t border-gray-300 px-10 pt-2'>
          <div className='w-full'>
            <div
              className='p-2 flex w-full pl-0'>
              <div className="lg:text-left sm:text-center sm:w-9/12 sm:text-sm sm:self-center lg:block">
                <input
                  className='w-full custom-popup-search'
                  placeholder='Enter Project Name'
                  onChange={event => { setProjectTitle(event.target.value) }}
                  onKeyDown={handleProjectTitle}
                  style={{ borderColor: "#1A1A1A" }}
                  value={projectTitle}
                />
              </div>
              <div className="lg:text-right sm:w-3/12 sm:text-sm sm:self-center cursor-pointer lg:block ml-2">
              <div className={projectTitle?.length > 0 ? "greenbutton" : "button" }
                onClick={() => addProject()}
                disabled={!(projectTitle?.length > 0)} >
                  Add Project
                </div>
              </div>

            </div>
            <input
              onChange={() => handleSearchProjects()}
              className='p-1 pl-2  mb-2 w-full text-l focus:outline-none bg-black border-b placeholder-white text-white'
              placeholder='Search projects'
            />
            <div className="flex w-full justify-between">
              <div className="lg:text-left sm:w-6/12 sm:text-sm sm:self-center sm:text-textGray-100 lg:block sm-box-align">
                My Projects
              </div>
              <div className="lg:text-center sm:w-3/12 sm:text-sm sm:self-center sm:text-textGray-100 lg:block">
                Tracks
              </div>
              <div className="lg:text-center sm:w-3/12 sm:text-sm sm:self-center sm:text-textGray-100 lg:block">
                Updated
              </div>
            </div>
            {isLoading && (
              <BouncingLoader />
            )}
            {projects?.length ? (
              <>
                <div style={{ overflowY: "auto", height: "40vh" }}>
                  {projects.map((project, index) =>
                    <>
                      <div
                        key={project._id}
                        className='pt-2 pb-2 
                  hover:bg-gray-200 cursor-pointer flex w-full justify-between'>
                        <div className="lg:text-left sm:w-6/12 sm:text-sm sm:self-center lg:block sm-box-align">
                          <Checkbox
                            label='My checkbox'
                            labelstyle={{ color: 'black' }}
                            checked={project.isChecked}
                            onChange={(e) => trackAddedToProject(project._id, e)}
                            iconstyle={{ fill: 'black' }}
                            inputstyle={{ color: 'black' }}
                            style={{ color: 'black' }}
                          />
                          <Link to=''>
                            <span className='cursor-pointer'>{project.title}</span>
                          </Link>
                        </div>
                        <div className="lg:text-center sm:text-center sm:w-3/12 sm:text-sm sm:self-center lg:block">
                          {project.trackIds?.length}
                        </div>
                        <div className="lg:text-center sm:text-center sm:w-3/12 sm:text-sm sm:self-center lg:block">
                          {dayjs(project.updatedAt).format('DD.MM.YYYY')}
                        </div>

                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (!isLoading) && (
              <div className="text-xl text-center text-red-500 p-5">No Projects Found</div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-center align-center p-2 w-full px-10">
          <div className='lg:text-left sm:text-center sm:w-6/12 text-gray-500 text-sm '>
          </div>
          <div className="lg:text-right justify-center sm:text-center sm:w-6/12 cursor-pointer px-2">
            <div className="greenbutton" onClick={() => addTrackToProjects()}>
              Save
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default AddTrackToProjectPopup;