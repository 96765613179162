import React from "react";
import { useEffect, useState, useRef } from "react";
import "./style.css";
import { BouncingLoader } from "../../pure-components/Loader";
import API from "../../services/api";
import { Link } from "react-router-dom";
import Helper from "../../services/helper";
import defaultImage from "../../assets/default_music.png";
import CookieManager from "../../services/CookieManager";
import { ReactComponent as Close } from "../../assets/BlackButtonRoundMinusSmall.svg";
import MewoService from "../../services/mewoService";
import Constants from "../../services/constants";


const defaultMessage = "Search by clicking on a keyword";

const SoundFx = (props) => {
  const soundFXFilterTags = ["Ambience", "Assembly Line", "Atmosphere", "Beds", "Blips", "Bombs", "Booms", "Braams", "Bridges",
                            "Builds", "Bumper", "Bursts", "Claps", "Designers", "Drones", "Effect", "Elements", "Endings", "Foley", 
                            "FX", "Guns", "Hits", "Impact", "Intros", "Jingles", "Layers", "Logo", "Loop", "Pings", "Pulses", "Riff", "Rise", 
                            "Rumble", "Signature", "Slams", "Soundscapes", "Stabs", "Sting", "Stomps", "Strikes", "Suck Backs", 
                            "Swells", "Textures", "Tool", "Transitions", "Whooshes"] 

  const ref = useRef(null);
  const [selectedTag, setSelectedTag] = useState(CookieManager.getCookie("selected-soundfx-tag") || "");
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [allDataLoaded, setAllDataLoaded] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [soundFXList, setSoundFXList] = useState([]);

  useEffect(() => {
    // console.log("selected Tag : ", selectedTag);
    if(selectedTag?.length > 0){
      (async() => {
        await loadSoundFXData(selectedTag)
      })()
    }
  }, [selectedTag])

  useEffect(() => {
    if(loadMore)
      (async() => {
        await loadSoundFXData(selectedTag)
      })()
    
  }, [loadMore])

  const onTagSelect = (elem, index)=>{
    if(loading)
      return;
    if(selectedTag != elem){
      CookieManager.setCookie("selected-soundfx-tag", elem)
      setSelectedTag(elem);
    }else{
      CookieManager.setCookie("selected-soundfx-tag", "")
      setSelectedTag("");
    }
    setPageNo(0);
    setAllDataLoaded(true);
    setSoundFXList([]);
    
  }

  const onTagRemove = async()=>{
    CookieManager.setCookie("selected-soundfx-tag", "")
    setPageNo(0);
    setAllDataLoaded(true);
    setSoundFXList([]);
    setSelectedTag("");
    
  }

  const loadSoundFXData = async(query = "") => {
    setLoading(true);
    let reqObj = {
      url : `public/search/panel`,
      method : 'post',
      data: JSON.stringify({
        query,
        albums: { page: pageNo, max: Constants.ALBUM_TRACKS_DEFAULT_PAGE_SIZE, search_descriptions: false },
      }),
    };
    await MewoService.loadMewoData(reqObj).then((resp) => {
      // console.log("response data : ", resp)
      let tempDataList = [...soundFXList];
      if(resp?.albums?.hits?.length > 0)
        tempDataList.push(...resp?.albums?.hits)

      if(resp?.albums?.hits?.length > 0 && tempDataList?.length < resp?.albums?.total?.value){
        setAllDataLoaded(false);
        setPageNo(pageNo+1);
      }else
        setAllDataLoaded(true);

      setSoundFXList(tempDataList);
    }).catch((err) => {
      console.log("SoundFX Data Loading Error : ", err);
      Helper.handleError(err);
    });
    setLoadMore(false)
    setLoading(false);
  }

  useEffect(() => {
    if (ref.current !== null) {
      // INTERSECTION OBSERVER
      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      };
      let observer = new IntersectionObserver(loadMoreData, options);
      let target = document.querySelector("#load-more-data");
      observer.observe(target);
    }
  });


  const loadMoreData = async(entries, observer) => {
    entries.map(async(entry) => {
      if (entry.isIntersecting) {
        if (!allDataLoaded) {
          setLoadMore(true);
        }
      }
    });
  };

  return (
    <div className="lg:px-8 items-center flex-1 pt-20 lg:pt-3 sm:mx-8 mx-8 lg:mx-20 playlist">
      <div className="flex w-full flex-wrap hidden lg:flex">
        {soundFXFilterTags?.map((elem, index) => (
          <div
            key={index}
            className={`flex px-2 py-1 mx-1 my-1 text-xs syncbutton cursor-pointer rounded-full ${selectedTag == elem
                ? " px-4 bg-symbgGray "
                : "px-4  syncbuttongrey hover:bg-symbgGray hover:scale-75"
            }`}
            onClick={() => {onTagSelect(elem)}}
          >
            {elem}
            {elem === selectedTag && 
              <Close onClick={(e) => {
                  e.stopPropagation();
                  onTagRemove()
                }}
                className="filterclose mx-3"
              />
            }
          </div>
        ))}
      </div>

      <div className="border-t my-2"></div>
      <div className="w-full">
        {loading &&  
          <div className='w-full'>
            <BouncingLoader />
          </div>
        }
        {!(selectedTag?.length > 0) &&  
          <div className="text-xl text-center text-green-500 my-5">
          {defaultMessage}
        </div>
        }
        {!loading && selectedTag?.length > 0 && !(soundFXList?.length > 0) ? (
          <div className="text-xl text-center text-red-500 my-5">
            No Data Found
          </div>
        ) : 
        (<div className="bg-white grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-10">
          {soundFXList?.map((elem, index, arr) => (
            <>
            <Link to={`/album/${elem?.id}`}>
                <div className=" music-tile" key={elem?.id}>
                  <div className="image-container">
                    {elem != null && elem?.image != null ? (
                      <img
                        src={elem?.image?.large?.url || defaultImage}
                        alt="Album img"
                        className=" sm:flex border sm:cursor-pointer rounded-2xl "
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <img
                        src={defaultImage}
                        alt="Album img"
                        className="sm:flex border-b sm:cursor-pointer music-image justify-center align-middle "
                        width="100%"
                        height="100%"
                      />
                    )}
                    <span className="play_icon" title="Play"></span>
                  </div>
                  <div className=" p-2 ">
                    <p className="text-sm"><b>{elem?.title}</b> </p>

                    {elem.descriptions?.length > 0 &&
                      <p className="text-sm font-light text-left description"
                        title={elem.descriptions[0]?.value}
                      >
                        {elem.descriptions[0]?.value || elem?.title}
                      </p>
                    }
                  </div>
                </div>
              </Link>
            </>
          ))}
        </div>)
        }
        {!allDataLoaded && (
          <div id="load-more-data" ref={ref} className="p-2">
           <BouncingLoader />
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundFx;