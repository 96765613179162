import React, { useState } from "react";
import "./style.css";
import edit from "../../assets/edit.png";
import audio from "../../assets/audio.png";
import VoiceFaceMap from "../../services/voice-face-mapping.json";

function Card({ image, name, selected, selectImage, id }) {
  const [person, setPerson] = useState(name);
  const [toggle, setToggle] = useState(false);

  const handleEdit = () => {
    setToggle(true);
  };

  const handleSave = () => {
    sessionStorage.setItem("name", person);
  };
  const changeText = (e) => {
    setPerson(e.target.value);
  };

  // React.useEffect(()=>{
  //   const n = localStorage.getItem("name");
  //   if (person !== "") {
  //     setPerson(n);
  //   }
  // },[])
  // React.useEffect(()=>{
  //   localStorage.setItem('name',person)
  // },[person])

  const printname = () => {
    console.log(person);
  };

  return (
    <div
      className="inline-block w-60 sm:w-1/2 md:w-1/3 lg:w-1/4  px-4 py-3"
      onClick={() => {
        selectImage(id);
      }}
    >
      <div
        className="w-full flex flex-wrap justify-center"
        class={
          selected === name
            ? "w-full flex flex-wrap justify-center rounded-3xl pb-4 border border-gray-500 border-pink"
            : "w-full flex flex-wrap justify-center rounded-3xl pb-4 border border-gray-300"
        }
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="rounded-full overflow-hidden w-full flex justify-center py-4">
          <img
            src={require("../../assets/voice-face/" + image)}
            className="w-full rounded-full"
            alt="Voice Icon"
            style={{ width: "150px", height: "150px" }}
          />
        </div>
        <div className="row w-full">
          <div className="font-bold">{person}</div>
        </div>
      </div>
    </div>
  );
}

export default Card;
