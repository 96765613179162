import React from 'react';

function AllLicenses() {

  
  return(
    <div className='flex h-screen'>
      <div className='w-1/4'></div>
      <div className='border-blue-100 border-solid w-2/4'>
        <h2 className='text-bold text-xl text-gray-600 w-full'>Licenses</h2>
      </div>
    </div>  
  )
}

export default AllLicenses;