import React, { useState, useEffect } from 'react';
import { ReactComponent as DownloadLic } from '../../assets/downloadLic.svg';
import Constants from '../../services/constants';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import API from '../../services/api';
import { toast } from 'react-toastify';
import { ErrorToast } from '../../pure-components/CustomToast';
export default function License() {

    const [monthNames, setMonthNames] = useState([{ key: "January", count: 0 }, { key: "February", count: 0 }, { key: "March", count: 0 }, { key: "April", count: 0 }, { key: "May", count: 0 },
    { key: "June", count: 0 }, { key: "July", count: 0 }, { key: "August", count: 0 }, { key: "September", count: 0 }, { key: "October", count: 0 }, { key: "November", count: 0 }, { key: "December", count: 0 }]);

    //, "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];
    const currentMonth = new Date().getMonth();
    const currentyear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentyear);

    let startYear = 2000;
    let years = []
    while (currentyear >= startYear) {
        years.push(startYear);
        startYear++;
    };
    useEffect(() => {
        let query = [
            {
                '$project': {
                    'month': {
                        '$month': '$createdAt'
                    },
                    'year': {
                        '$year': '$createdAt'
                    }
                }
            }, {
                '$group': {
                    '_id': {
                        'month': '$month',
                        'year': '$year'
                    },
                    'total': {
                        '$sum': 1
                    },
                    'month': {
                        '$first': '$month'
                    },
                    'year': {
                        '$first': '$year'
                    }
                }
            }, {
                '$match': {
                    'year': selectedYear
                }
            }
        ]
        API.getLicAggregate(query).then(res => {
            if (res.success) {
                let newMonthNames = [...monthNames];
                if (res.data.length) {
                    res.data.forEach(item => {
                        newMonthNames[item.month - 1].count = item.total
                    })
                    setMonthNames([...newMonthNames]);
                }
                else {
                    setMonthNames([{ key: "January", count: 0 }, { key: "February", count: 0 }, { key: "March", count: 0 }, { key: "April", count: 0 }, { key: "May", count: 0 },
                    { key: "June", count: 0 }, { key: "July", count: 0 }, { key: "August", count: 0 }, { key: "September", count: 0 }, { key: "October", count: 0 }, { key: "November", count: 0 }, { key: "December", count: 0 }])
                }
                //    setCountUser(res.data);
            }
        })
    }, [selectedYear]);

    const downloadLic = (index,liccount) => {
        if (index <= currentMonth) {
            if(liccount<=0)
            {
                toast(({ closeToast }) => <ErrorToast message="No Track to download!"/>);
                 }
              else{   
            var link = document.createElement('a');
            link.setAttribute('download', null);
            link.style.display = 'none';
            document.body.appendChild(link);
            link.setAttribute('href', `${process.env.REACT_APP_BASE_URL}api/license/OP?month=${index + 1}&year=${selectedYear}`);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
                var link1 = document.createElement('a');
                link1.setAttribute('download', null);
                link1.style.display = 'none';
                document.body.appendChild(link1);
                link1.setAttribute('href', `${process.env.REACT_APP_BASE_URL}api/license/CMO?month=${index + 1}&year=${selectedYear}`);
                link1.click();
                document.body.removeChild(link1);
            }, 1000)
        }
        }
    }
    const handleChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <>
            <FormControl className="w-2/12" variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={selectedYear}
                    onChange={handleChange}
                    label="Year"
                >
                    {
                        years.map(item => {
                            return <MenuItem value={item}>{item}</MenuItem>
                        })
                    }


                </Select>
            </FormControl>

            <div className='flex flex-wrap'>

                {
                    monthNames.map((month, index) =>
                        <div key={index} className={`flex py-4 m-2 border border-symStrokeGrey last:border-b-0
              ${index > currentMonth ? "cursor-not-allowed " : "cursor-pointer"}`}
                            style={{ width: "22.5%" }}
                            onClick={() => downloadLic(index,month.count)}
                        // href="http://localhost:4000/api/license/OP"
                        >
                            <div className='self-center w-11/12 pl-2'>
                                <h1 className='text-2xl mr-2 text-black  font-bold' > {month.key}</h1>
                                <h2 className='text-xs self-center text-black font-light'>{month.count} licenses </h2>
                            </div>
                            <div className='w-1/12 -mt-2 mr-4'>
                                <DownloadLic />
                            </div>
                        </div>
                    )
                }
            </div>


        </>
    )
}
