import React from 'react';
import "./style.css";

export default function PrivacyPolicy() {

  return (
    <div className='flex text-center flex-wrap'>
      <div className='self-center text-center w-full mt-10 p-5'>
        <h1 className='w-full mt-10 text-2xl'>Privacy Policy</h1>
        <div className='self-center text-left w-full main-content'>

    <h3>Type of website: e-commerce</h3>
    <p>Effective date: 1 December 2021</p>

    <p>www.syncmama.com (the&quot;Website&quot;) is owned and operated by Silk Road
            Communications Private Limited (the &quot;Company&quot;, &quot;we&quot;). Through the Website, the Company
            allows users to subscribe to exclusive music content, and obtain a license to use the same on the payment of
            a subscription fee (the &quot;Services&quot;). &nbsp;Please note that this Privacy Policy
            (&quot;Policy&quot;) is only applicable to the users of the Website, and the information and data gathered
            therefrom. We advise you to read this Policy carefully.</p>

    <p>Your privacy is important to us and we aim for our Services to be a safe and
            enjoyable experience for our subscribers. We respect and safeguard the personally identifiable information
            (&quot;Personal Data&quot;) we receive and collect from you on our Website and in the course of our
            interactions online.</p>

    <p>Terms not defined herein will have the meaning ascribed to them in the Company&#39;s
            Terms of Service.</p>

    <h3>1. Consent</h3>
    <p>By using our Website, you agree that you consent to the conditions set out in this
            Privacy Policy. You may withdraw your consent at any time.</p>

    <h3>2. Personal Data We Collect</h3>
    <p>We collect Personal Data from you when you register on the Website and subscribe to
            the Services. When you register with the Website, we will collect certain Personal Data from you in order to
            provide you with a user account to utilize the Services.</p>

    <p>However, you may not be able to access certain Services that require registration,
            subscription, or need you to reveal your identity and/or other information about you for the purpose of
            issuing a synchronization license. In particular, you may not download music content without subscribing to
            the Services and accepting the terms and conditions applicable thereon.</p>

    <h3>3. Data Collection</h3>
    <p>We only collect data that helps us achieve and carry out the Services promised to
            you. We will not collect any data beyond the data listed below without notifying you first.</p>

    <h3>4. Data Collected in a Non-Automatic Way</h3>
    <p>To perform our Services, we may collect the following data:</p>
    <p>1. First and Last Name</p>
    <p>2. Company Name</p>
    <p>3. Email Address</p>
    <p>4. Address</p>
    <p>5. Tax Information</p>
    <p>6. Payment Information</p>
    <p>7. Usage Information</p>

    <p>This data may be collected when you create and operate an account. This data will be
            used for the purpose of issuing a license to use the music.</p>

    <h3>5. Who We Share Personal Data With</h3>
    <p>We may disclose user data to any member of the Company who reasonably needs access to
            user data to achieve the purpose set out in the Terms of Service and in this Privacy Policy.</p>

    <p>Data 1-4 and 7 may be disclosed to a licensed Copyright Society.</p>

    <h3>6. Data Collected in an Automatic Way</h3>
    <p>In addition, we may collect demographic information such as information about your
            computer, location (through GPS), hardware, software, platform, media, Internet Protocol (IP) address and
            connection, information about online activity such as feature usage and click paths.</p>

    <h3>7. Other Disclosures</h3>
    <p>We will not sell or share your data with other third parties. We will keep
            confidential and protect your Personal Data except where disclosure is required or permitted by law.
    </p>

    <h3>8. How Long We Store Your Personal Data</h3>
    <p>User data will be stored for as long as you keep your account on the Website.
    </p>

    <h3>9. How We Protect Your Personal Data</h3>
    <p>We take all reasonable precautions to ensure that user data is secure and that you
            are protected. We use industry standard security technology and procedures to maintain the confidentiality
            and accuracy of all user information, and to prevent its loss or misuse. Our user data is stored on heavily
            protected cloud servers which can only be accessed from specific locations and IP addresses. Additionally,
            user passwords are encrypted and hashed, and are never stored in the clear. Although we cannot guarantee the
            absolute security of data transmission over the Internet, we take all necessary measures to maintain the
            integrity of user data. </p>

    <p>All data is only accessible to our authorized employees, who are bound by strict
            confidentiality agreements and a breach of this agreement would result in the employee&#39;s
            termination.</p>

    <p>If you choose to provide us with your Personal Data, we may transfer that Personal
            Data from your country or jurisdiction to other countries or jurisdictions around the world. Your Personal
            Information may be collected and stored on servers located in the territory of India and such other
            countries as we may disclose from time to time.</p>

    <h3>10. Cookies and Other Tracking Technologies</h3>
    <p>Our use of cookies and other tracking technologies allows us to improve our Website
            and Services and consequently your experience with the same. We may also analyze information that does not
            include Personal Information for trends and statistics. Tracking technologies may automatically record
            information such as Internet domain and host names; other information about your geographic location; IP
            addresses; browser software and operating system types; CPU type; your manner of connecting to the Internet
            (e.g., connection speed through narrowband or broadband access); click paths; dates and times that our
            Website is accessed; and duration of use of our Services. Please read our Cookies Policy together with this
            document.</p>

    <h3>11. Uses of Personal Data</h3>
    <p>We may use Personal Data and other information received and collected from you to
            provide the Services you have requested, including for the purpose of issuing licenses, sending you updates
            or to contact you upon your request and when otherwise necessary. We may also use Personal Data for
            auditing, research and analysis, and to operate and improve our Services. </p>

    <p>When we use third parties to assist us in processing your Personal Data, we require
            that they comply with our Privacy Policy and any other appropriate confidentiality and security
            measures.</p>

    <p>Unless otherwise agreed, where we propose to use your Personal Data for any other
            uses we will ensure that we notify you first. You will also be given the opportunity to withhold or withdraw
            your consent for your use other than as listed above.</p>

    <h3>12. Your Rights as a User</h3>
    <p>You have the following rights:</p>
    <p>- Right to be informed;</p>
    <p>- Right of access;</p>
    <p>- Right to rectification</p>
    <p>- Right to be forgotten</p>
    <p>- Right to restrict processing;</p>
    <p>- Right to data portability;</p>
    <p>- Right to object.</p>

    <p>If you would like to exercise any of your rights hereunder, please contact:
            support@syncmama.com</p>

    <h3>13. Contacting You</h3>
    <p>We may contact you using the Personal Information you have given us:</p>
    <p>- in relation to the functioning of any service you have signed up for in order to
            ensure that we can deliver the Services to you;</p>
    <p>- in relation to any transaction entered by you on our Website to subscribe to
            Services;</p>
    <p>- where you have opted to receive further correspondence;</p>
    <p>- where you have issued a query or requested information from us;</p>
    <p>- to invite you to participate in surveys, opinion polls, etc., about our Services or
            otherwise (participation is always voluntary); and</p>
    <p>- to provide you information about our new products and Services.</p>

    <h3>14. Accuracy</h3>
    <p>To the extent that you do provide us with Personal Data, we wish to maintain it
            accurate and current. To update or correct that Personal Data, please do it from your personal account or
            contact us and we will make reasonable efforts to incorporate the changes as soon as practicable. You can
            obtain the information we currently hold on you by emailing the Company at support@syncmama.com. We will use
            our best efforts to send you the information within fourteen (14) days of receipt of your email.</p>

    <h3>15. Change of Ownership</h3>
    <p>In the event that ownership or control of the Company was to change, your Personal
            Data may be transferred. If such a transfer results in a material change in the use of your Personal Data,
            the Company will provide notice about the choices you have to decline to permit such a transfer.</p>

    <h3>16. Law</h3>
    <p>By using this Website and the Services, you consent to the terms of this Privacy
            Policy and to our use and management of Personal Data for the purposes and in the manner herein provided.
            Should this Privacy Policy change, we intend to take every reasonable step to ensure that these changes are
            brought to your attention.</p>

    <p>This Privacy Policy shall be governed by and construed in accordance with the laws of
            the Republic of India. Further, it is irrevocably and unconditionally agreed that the courts of Mumbai,
            India shall have exclusive jurisdiction to entertain any proceedings in relation to any disputes arising out
            of the same.</p>


    <p>Mr. Achille Forler</p>
    <p>Director</p>
    <p>Silk Road Communications Private Limited</p>
    <p>A-1502 Thane One Corporate IT Park</p>
    <p>DIL Complex, Majiwada</p>
    <p>Thane - 400610</p>
    <p>Maharashtra, India</p>
    <p>Email : support@syncmama.com</p>
    </div>
    </div>
      </div>
  )
}