import React, { useState, useContext, useEffect, } from 'react';
import EmailIcon from '../../assets/email_icon.png';
import CookieManager from '../../services/CookieManager';

function SignupConfirm() {

useEffect(() => {
  let cachedUserData = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
  if(cachedUserData!==null){
    window.location = "/signin";
  }
})
return(
    <>
    <div className='md:flex'>
      <div className='flex md:w-1/2 mx-auto pt-16 p-4 md:px-16 md:pt-32 h-full' style={{background: '#FFF'}}>
        
        <div className='mt-5 w-full'>
            <div className='stepsbox flex flex-wrap mb-10 pl-4 w-full'>
                <div className='steps flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>1</div>
                    <div className='stepstext text-sm pl-2'>Enter<br/>Details</div>
                </div>
                <div className='steps active flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>2</div>
                    <div className='stepstext text-sm pl-2'>Confirm<br/>Email</div>
                </div>
                <div className='steps flex-1 flex flex-wrap'>
                    <div className='stepsnumber'>3</div>
                    <div className='stepstext text-sm pl-2'>Start with<br/>SyncMama</div>
                </div>
            </div> 
          <div className='verifybox'> 
          <div className='p-2 verifyicon mb-10'>
            <img src={EmailIcon} alt="Email icon" className="verifyicon"/>
          </div>
          <div className='p-2 verifytext mb-10'>
            <h1 className='text-3xl font-bold mb-10' style={{lineHeight: '40px'}}>YOU HAVE GOT MAIL</h1>
            <span className=''>Please check your email to confirm your account</span>
          </div>
          </div>
 

        </div>
      </div>
      <div className='hidden md:flex md:w-1/2 min-height-100' style={{color: '#FFF'}}>
          <div className='timessquare'>
          </div>
      </div> 
    </div> 
    </>
  )
}

export default SignupConfirm;