import React, { useState, useEffect, useContext } from 'react';
import TrackContext from '../../contexts/track-context';
import API from '../../services/api';
import { toast } from 'react-toastify';
import {ReactComponent as Success } from '../../assets/success.svg';
import Pagination from '@material-ui/lab/Pagination';
import Constants from '../../services/constants';
import Helper from '../../services/helper'
import ConfirmationPopup from '../../pure-components/ConfirmationPopup';
import { SuccessToast } from '../../pure-components/CustomToast';
import { ReactComponent as Delete } from '../../assets/delete.svg';

export default function UserList() {

  let trackContext = useContext(TrackContext);
  const [userList,setUserList]=useState([]);
  let [allUser, setAllUser] = useState(true);
  const [showConfirmPopup,setShowConfirmPopup]=useState(false)
  const [selectedAdmin,setSelectedAdmin]=useState({});
  const [popupObj,setPopupObj]=useState({});
  const [sendRequset,setSendRequset]=useState({});
  useEffect(() => {
    fetchAdmin();
  }, []);

  const fetchAdmin =(query,searchText,pageNum,pageSize)=>{
    
    API.getUserList(query,searchText).then(res => {
        if (res.success) {
          setUserList(res.data)
        }
      })
  }
  
  const suspendUser =(id,isSuspended)=>{
    API.suspendUser(selectedAdmin.id,{isSuspended:!selectedAdmin.isSuspended}).then(res => {
      if (res.success) {
        setShowConfirmPopup(false);
        fetchAdmin();
      }
    })
  }
  const removeUser =(id)=>{
    API.deleteUser(selectedAdmin.id).then(res => {
      if (res.success) {
          // toast(({ closeToast }) => <div class="flex  m-1"><Success className="p-3 mr-2 border-r border-gray-500"/>
          // <div className="self-center">
          //   <h1 className='text-base text-black ' > Success. Great job!</h1>
          //   <h2 className='text-xs self-center text-gray-700 '> Admin deleted successfully </h2>
          //  </div> </div>);
          toast(({ closeToast }) => <SuccessToast  message="User deleted successfully "/>);

                   setShowConfirmPopup(false);
        fetchAdmin();
      }
    })
  }
  const handleSearch = Helper.debounce((searchText) => {
    let query=allUser? {} : {filter:{isSuspended:true}};
    fetchAdmin(query,searchText);
  },500);
  const setConfirmPopup=()=>{
    setShowConfirmPopup(false)
  }
  return (
    <>
          
              <div className='text-lg w-full'>
              <input
                onChange={(e) => { handleSearch(e.target.value) }}
               // onChange={handleSearch}
                type="text"
                placeholder='Search User'
                className="w-4/5 mx-2 input-search h-8"
              />
              </div>
              <div className='flex p-4' >
              <div 
                className={`p-1 align-center text-center ${allUser ? "bg-black text-white":""} text-xs w-1/2 cursor-pointer`}
               onClick={() => {
                   setAllUser(true);
                   fetchAdmin()}}
             >
                All Users
              </div>
              <div 
                className={`p-1 align-center text-center text-xs w-1/2 cursor-pointer ${!allUser ? "bg-black text-white":""} `}
                onClick={() => {
                    setAllUser(false);
                    fetchAdmin({filter:{isSuspended:true}})}}
             >
               Suspended Users
              </div>
             
            </div>
            {
              userList.map((user, index) =>
              <div key={user._id} className='flex w-full border-b border-symStrokeGrey last:border-b-0' >
              <div className='w-4/6 self-center p-2 pl-0'>
                <h1 className='text-sm mr-2 text-black newprojects' > {user.first_name}</h1>
                <h2 className='text-xs self-center text-textGray-100 newprojects'>{user.email} </h2>
              </div>
             
              <div className='{`hidden sm:block row w-2/6 py-1 text-xs text-symLink self-center`}' >
                <div className={`cursor-pointer`}
                 onClick={(e) =>{ 
                  // suspendUser(copuser._id,copuser.isSuspended)
                  setSendRequset("Suspend")
                  setSelectedAdmin({id:user._id,isSuspended:user.isSuspended});
                  setPopupObj({title:`${user.isSuspended ? "Unsuspend" :"Suspend"} Corporate Users`,msg:`Are you sure, you want to ${user.isSuspended ? "Unsuspend" :"Suspend"} this user?`})
                  setShowConfirmPopup(true);
                 }}
                 >{user.isSuspended ? "Unsuspend" :"Suspend"}
                </div>
                <Delete height="18" width="18" className="hidden lg:block cursor-pointer"
                  onClick={(e) => {
                    // removeAdmin(admin._id)
                    setSendRequset("Remove")
                    setSelectedAdmin({id:user._id});
                    setPopupObj({title:"Remove Corporate Users",msg:"Are you sure, you want to remove this user?"})
                    setShowConfirmPopup(true);
    
                  }}
                />
              </div>
            </div>
              )}  
             
             {
        showConfirmPopup ? 
        <ConfirmationPopup giveStyle="-54rem" yesAction={sendRequset==="Remove" ? removeUser :suspendUser}
        cancelAction={setConfirmPopup}

         title={popupObj.title} msg={popupObj.msg}/>
        : 
        null 
      }
    </>
  )
}
