import React, { useState } from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ReactComponent as Points} from '../../assets/vPoints.svg';

function MobileAction(props) {
  const [showTrackModal, setShowTrackModal] = useState(false);

  // useEffect(() => {
  //   // console.log(props.mewoTrackId)
  // })
  const handleClose = () => {
    setShowTrackModal(false);
  }
  const handleProjectModal = (trackId,projectTrackId) => {
    setShowTrackModal(false);
    props.handleProjectModal(trackId,projectTrackId);
    // let userData = JSON.parse(CookieManager.getCookie('user-data'));
    // if (trackContext.userDetails.email === undefined || userData.subscribed === false) {
    //   trackContext.setShowSubscribePopup(true);
    //   trackContext.setUserAction('Add to project');
    // } else {
    //  setTrackToBeAddedToProject(trackId);
    //   setTrackToBeRemoveToProject(projectTrackId);
    //   setShowProjectModal(true);
    // }
  }

  return (
    <>
    <div 
                              className='flex justify-end py-8 text-center w-10 sm:w-0 sm:hidden cursor-pointer' 
                             onClick={(e)=>{
                             setShowTrackModal(e.currentTarget);

                             }}
                             >
                              <Points  className='cursor-pointer'/>    
                            </div>
                            <Menu
                                    id={props.id}
                                    anchorEl={ showTrackModal}
                                    open={Boolean( showTrackModal)}
                                    onClose={ handleClose}
                                    style={{ width: "320px" }}
                                  >
                                    <MenuItem
                                      onClick={() =>  handleProjectModal( props.mewoTrackId, props.id)}
                                      className='card-action'>
                                      <span className='card-action-text'>Add to another project</span>
                                    </MenuItem>
                                    <MenuItem
                                            onClick={() =>  props.handleAddTrackToFavorites( props.mewoTrackId)}
                                            className='card-action'>
                                      {
                                       props.favs.indexOf( props.mewoTrackId) === -1 ?
                                      <span className='card-action-text'>Favourite </span>
                                        :
                                        <span className='card-action-text'> Unfavourite                                      
                                              </span>
                                      }
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) =>  props.duplicateTrack(props.trackData,  props.projectIndex)}

                                      className='card-action'>
                                      <span className='card-action-text'>Duplicate</span>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={(e) =>  props.deleteTrackFromProject(props.projectId,  props.trackIndex)}

                                      className='card-action'>
                                      <span className='card-action-text'>Remove</span>
                                    </MenuItem>
                                   
                                  </Menu>
    </>
  )
}

export default MobileAction;