import React from "react";
import { toast } from "react-toastify";
import { ErrorToast } from "../pure-components/CustomToast";
import CookieManager from "./CookieManager";

function signOut() {
    var date = new Date();
    //setting past time to expire cookie data
		date.setTime(date.getTime() + (-1*24*60*60*1000));
    document.cookie = 'user-data=""; expires=' + date.toUTCString() + '; path=/';
    document.cookie = 'x-auth=""; expires=' + date.toUTCString() + '; path=/';
    document.cookie = 'x-access-token=""; expires=' + date.toUTCString() + '; path=/';
    document.cookie = 'last-seen=""; expires=' + date.toUTCString() + '; path=/';
    // console.log('signing out')
    window.location = '/signin';
    clearStorageAndCookie();
}

function clearStorageAndCookie() {
  localStorage.clear();
  CookieManager.deleteCookie('user-data');
  CookieManager.deleteCookie('x-auth');
  CookieManager.deleteCookie('x-access-token');
}

function sanitizeAndCreateJSON(info) {
  let encodedString = encodeURI(info);
  let splittedString = encodedString.split('%0A');
 //console.log({splittedString});
  let obj = {}
  let decodedString = splittedString.map( str => {
  //  console.log(str)
    if (str !== '') {
      let keyValue = decodeURI(str).split(':');
    //  console.log(keyValue);
      obj[keyValue[0]] = keyValue[1];
    }
  });
  return obj
}
function debounce(fn, wait) {
  let t;
  return function () {
    clearTimeout(t)
    t = setTimeout(() => fn.apply(this, arguments), wait)
  }
}

function encode(text) {
  if(text?.trim().length){
    text = Buffer(text).toString("base64");
    text = text.split("").reverse().join("");
    const n = (text.length/2) % text.length;
    return (text.slice(n) + text.slice(0, n)); 
  }
  return text;
}

function decode(data) {
  if(data?.trim().length){
      data = data.split("").reverse().join("");
      const n = (data.length/2) % data.length;
      data = (data.slice(n) + data.slice(0, n)); 
      data = new Buffer(data, 'base64').toString("ascii");
  }
  return data;
};

function resolveStringValue(data) {
  if(data?.trim().length > 0)
    return data.trim();
  return "";
};

function handleError(res){
  //if token invalid, sign out the user
  if(res?.status === 400 && res?.key === "invalid_token"){
    toast(({ closeToast }) => <ErrorToast message={"Session expired, please login to continue."}/>);
    setTimeout(() => {
      signOut();
    }, 5000);
  }else{
    toast(({ closeToast }) => <ErrorToast message={(res?.mssg?.length ? res?.mssg : "") +  (res?.message?.length ? res?.message : "")}/>);
  }
}

function isExist(dataObj){
  if(dataObj===null || dataObj ===undefined)
    return false;
  else{
    return true;
  }
}

function firstWordToUpper(str){
  if(str?.length > 0)
    return str.split(".")?.map((str1, index) =>
    str1.charAt(0).toUpperCase() + str1.slice(1)).join();
  else
    return null;
}

const getArtistName = (artists) => {  
  let name = "";
  if(artists?.length > 0)
    artists.map((elem, index) => {
      if(index < artists.length - 1)
        name = name + `${elem?.artist?.full_name}, `
      else
      name = name + elem?.artist?.full_name
    })
  return name;
}

const getArtistNameFromList = (artists) => {  
  let name = "";
  if(artists?.length > 0)
    artists.map((elem, index) => {
      if(index < artists.length - 1)
        name = name + `${elem?.name}, `
      else
      name = name + elem?.name
    })
  return name;
}

const addAttributeToObject = (obj, key, value) => {
  if((obj!==null && obj !== undefined) && key?.length && value?.length){
      obj[key] = value;
  }
  return obj;
};

const Helper = { signOut, sanitizeAndCreateJSON, debounce, clearStorageAndCookie, encode, decode, resolveStringValue, handleError, isExist, firstWordToUpper, getArtistName, addAttributeToObject, getArtistNameFromList };

export default Helper;