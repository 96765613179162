
import React, {useState, useEffect} from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ label, value, setValue }) => {
  const [toggle, setToggle] = useState(value);
  useEffect(() => {
  }, [toggle]);
  return (
    <div className="">
      <div className="toggle-point">
        <input type="checkbox" className="checkbox" 
               name={label} id={label} checked={toggle} onChange={()=>{setValue(!toggle); setToggle(!toggle)}} />
        <label className="label" htmlFor={label}>
          <span className="inner1" />
          <span className="point" />
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
