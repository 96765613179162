import React, { useEffect, useState, useContext } from 'react';
import API from '../../services/api';
import { toast } from 'react-toastify';
import Helper from '../../services/helper';
import AddTrackToProjectPopup from '../Projects/addTrackToProjectPopup';
import TrackAction from '../TrackItem/trackAction';
import TrackContext from '../../contexts/track-context';
import { ReactComponent as Play } from '../../assets/play.svg';
import { ReactComponent as Pause } from '../../assets/pause.svg';
import CardContent from '@material-ui/core/CardContent';
import dayjs from 'dayjs';
import Amplitude from 'amplitudejs';
import Constants from '../../services/constants';
import Pagination from '@material-ui/lab/Pagination';
import CookieManager from '../../services/CookieManager';

export default function Favorites(props) {
  let trackContext = useContext(TrackContext);
  const [favorites, setFavorites] = useState([]);
  const [searchFavorites, setSearchFavorites] = useState([]);
  const [trackToBeAddedToProject, setTrackToBeAddedToProject] = useState('');
  const [loaderAddTrackToProject, setLoaderAddTrackToProject] = useState();
  const [trackToBeRemoveToProject, setTrackToBeRemoveToProject] = useState('');
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [favs, setFavs] = useState([]);
  const [download, setDownload] = useState([])
  const [currPage, setCurrPage] = useState(1);
  // const [pageSize, setPageSize] = useState(3);
  const [pageSize, setPageSize] = useState(process.env.REACT_APP_PAGE_SIZE);
  const [totalPages, setTotalPages] = useState(10);
  const [searchKey, setSearchKey] = useState('')
  const [currentPlayingTrack,setCurrentPlayingTrack]=useState('');

  useEffect(() => {
    API.getFavoriteTracks(currPage, pageSize, searchKey).then(res => {
      if (res?.success) {
        setFavorites(res.data);
        setSearchFavorites(res.data);
        setTrackData(res.data);
        setTotalPages(Math.ceil(res.totalCount / pageSize));
        if (localStorage.getItem('favs') !== 'undefined') {
          const favorites = JSON.parse(localStorage.getItem('favs'));
          favorites !== null && setFavs(favorites);
        };
        if (localStorage.getItem('download') !== 'undefined') {
          const download = JSON.parse(localStorage.getItem('download'));
          download !== null && setDownload(download);
        };
      } else {
        Helper.handleError(res);
      }
    })
  }, []);


  const handlePagination = (e, pagenumber) => {
    //  let temp = e.target.innerText;
    setCurrPage(pagenumber)
  }

  useEffect(() => {
    API.getFavoriteTracks(currPage, pageSize, searchKey).then(res => {
      if (res?.success) {
        setFavorites(res.data);
        setSearchFavorites(res.data);
        setTotalPages(Math.ceil(res.totalCount / pageSize));
        let favs = res.data.map(tracks => {
          return tracks.trackId.mewoTrackId
        });
        setTrackData(res.data);
      }else{
        Helper.handleError(res);
      }

    });
  }, [currPage]);

//rerendering track ui on song prev or next
useEffect(() => {
    let audiofile=trackContext?.currentTrackDetail?.audiofile;
    const trackIndex=trackContext.alltrackData?.findIndex((track)=>track?.trackId?.mewoTrackUrl === audiofile && track?._id === trackContext?.currentTrackDetail?.historyId );
    if(trackIndex !==-1){
      setCurrentPlayingTrack(trackContext.alltrackData?.[trackIndex]?.trackId?.mewoTrackId + trackIndex);
    }
  }, [trackContext.currentTrackDetail,currPage,trackContext.alltrackData])
  
  const handleAddTrackToFavorites = (trackId) => {

    if (!trackContext.validateUser(Constants.ACTION.FAVOURITE)) {
      trackContext.setShowSubscribePopup(true);
      trackContext.setUserAction(Constants.ACTION.FAVOURITE);
      return;
    }
    API.addTrackToFavorites(trackId).then(res => {
      // console.log({ favRes: res });
      if (res?.success) {
        localStorage.setItem('favs', JSON.stringify(res.favorites));
        setFavs(res.favorites);
        // let fevnewlist=favorites.splice(index,1);
        let fevnewlist = favorites.filter(item => {
          return trackId !== item.trackId.mewoTrackId
        })
        let serFav = searchFavorites.filter(item => {
          return trackId !== item.trackId.mewoTrackId
        })
        setFavorites(fevnewlist);
        setSearchFavorites(serFav)
        setTrackData(serFav)
        currentPlayingTrack!=='' && trackContext.setalltrackData(setTrackData(serFav));
        // toast.success(res.mssg, {
        //   position: toast.POSITION.BOTTOM_LEFT
        // });
      }else{
        Helper.handleError(res)
      }
    });
  }

  const closeProjectModal = () => {
    setShowProjectModal(false);
    setLoaderAddTrackToProject(false);
  }
  const fetchTrack = async (trackId,_id,index) => {
    if (trackId === trackContext.currentTrackDetail.id) {
      trackContext.setPlayPause(!trackContext.playPause);
      return;
    }
    if (Amplitude.getSongs().length) {
      Amplitude.stop()
      Amplitude.removeSong(0)
    }
    trackContext.setLoadingTrack(true);
    await API.playTrack(trackId).then(res => {
      if(res?.data?.success){
        setCurrentPlayingTrack(trackId + index);
        res.data.data['historyId']=_id        
        trackContext.setalltrackData(trackContext.trackData);
        trackContext.setLoadingTrack(false);
        trackContext.setCurrentTrack(res.data.data.audiofile)
        trackContext.setCurrentTrackDetail(res.data.data)
        trackContext.setCurrentTrackImage(res.data.data.album.image.xsmall.url)
      }else{
        trackContext.setLoadingTrack(false);
        Helper.handleError(res?.data);
      }
    });
  }
  const handleSearch = Helper.debounce((searchText) => {
    setSearchKey(searchText);
    setCurrPage(1)
    API.getFavoriteTracks(1, pageSize, searchText).then(res => {
      if (res?.success) {
        setFavorites(res.data);
        setSearchFavorites(res.data);
        setTotalPages(Math.ceil(res.totalCount / pageSize));
      }else{
        Helper.handleError(res);
      }
    });
  }, 500)

  //adding trackId's to trackContext.trackData to work play previous and next track
  const setTrackData=(data)=>{
    const trackIds = data?.map((track) => {
        return {
          id: track?.trackId?.mewoTrackId,
          _id:track?._id,
          ...track
        };
      });
      trackContext.setTrackData(trackIds);
      return trackIds
    }

  return (
    <div className='flex h-screen lg:h-full justify-center lg:w-3/4'>
      <div className='sm:mt-6 px-4 md:px-0	w-full sm:w-3/4 overflow-auto hide-scroll'>
        <div className="text-sm lg:flex-grow flex">
          <h1 className='hidden sm:block w-1/3 pl-6 text-lg font-bold text-black' >My favourites</h1>
          <div className="w-2/3 justify-end flex">
            <input
              // onChange={handleSearch}
              onChange={(e) => { handleSearch(e.target.value) }}
              type="text"
              placeholder='Search favourites'
              className="w-full sm:w-2/5 input-search h-8"
            />
          </div>
        </div>
        <div className='mt-4'>
          {
            searchFavorites.length ?
              <>
                {
                  searchFavorites.map((track, index) =>
                    <div className='flex w-full border-b border-symStrokeGrey'>
                      <div className='self-center play-button'>
                        <div
                          aria-label="play/pause"
                          className='sm:pl-6 outline-none focus:outline-none play-button border-symStrokeGrey cursor-pointer'
                          onClick={() => fetchTrack(track.trackId.mewoTrackId, track._id,index)}
                        >
                          {
                           (trackContext.playPause && track.trackId.mewoTrackId + index === currentPlayingTrack) ?
                           <Pause className='outline-none' />
                              :
                              <Play className='outline-none' />
                          }
                        </div>
                      </div>
                      <CardContent className='w-4/6 sm:w-2/6 self-center'>
                        <h1 className='text-base mr-2 text-black track-title text-description text-description' > {track.trackId.title?.toLowerCase()}</h1>
                        <h2 className='text-xs self-center text-textGray-100 capitalize'>{track.trackId.album.title?.toLowerCase()} </h2>
                      </CardContent>
                      <h2 className='hidden sm:block w-2/6 text-xs self-center text-black '> Added on {dayjs(track.updatedAt).format('DD.MM.YYYY')} </h2>
                      <div className="hidden sm:block text-right text-sm w-1/6 self-center justify-end text-textGray-100">
                        {track.trackId.duration && new Date(track.trackId.duration * 1000).toISOString().substr(14, 5)}
                      </div>
                      <div className="w-2/6 sm:w-1/6 flex self-center justify-end items-baseline">

                        <TrackAction
                          setShowProjectModal={setShowProjectModal}
                          setTrackToBeAddedToProject={setTrackToBeAddedToProject}
                          setTrackToBeRemoveToProject={setTrackToBeRemoveToProject}
                          trackId={track.trackId.mewoTrackId}
                          projectTrackId={track.trackId._id}
                          handleAddTrackToFavorites={handleAddTrackToFavorites}
                          favs={favs}
                          download={download}
                          setDownload={setDownload}
                        />
                      </div>
                    </div>
                  )
                }
                <div className='mt-8'>
                  <Pagination count={totalPages} onChange={handlePagination} variant="outlined" shape="rounded" />
                </div>
              </>
              :
              <div className='text-red-500 p-6'>
                No Tracks Found
              </div>
          }
        </div>
      </div>
      {
        showProjectModal &&

        <AddTrackToProjectPopup trackToBeAddedToProject={trackToBeAddedToProject} closeProjectModal={closeProjectModal} />
      }
    </div>
  )
}
