import { CircularProgress, LinearProgress, TextField } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TrackContext from '../../contexts/track-context';
import API from '../../services/api';
import Constants from '../../services/constants';
import StripeForm from '../Register/stripe-form';
import SubscriptionForm from './subscription-form';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function ChoosePlan() {

  let location = useLocation();
  const [plans, setPlans] = useState([]);
  const [planChosen, setPlanChosen] = useState('');
  const [billingInfo, setBillingInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [noPaymentMethodFound, setNoPaymentMethodFound] = useState(false);
  const [overlayLoader, setOverlayLoader] = useState(false);
  const [tax, setTax] = useState({taxName: '', taxPercentage: 0, taxId: ''});

  let trackContext = useContext(TrackContext);

  useEffect(() => {
    let hash = String(location.hash).substring(1).toUpperCase();
    API.getProducts(hash).then((res) => {
      let prods = res.data.filter(prod => {
        if ( prod.name === `${hash}_MONTHLY` || prod.name === `${hash}_YEARLY`) {
          return prod;
        }
      });
      // console.log('[PRODS ] ', prods)
      setPlans(prods);
      // console.log({prods});
      if (prods.length === 0) {
        // handle when no products match
      }
      API.getBillingInfo(trackContext.userDetails._id).then(res => {
        setLoading(false)
        if (res.data.success) {
          // console.log('[res billing profile] : ', res);
          setBillingInfo(res.data.data);
        } else {
          setNoPaymentMethodFound(true);
        }
      });
    });

    getTaxRates();

  }, []);

  const getTaxRates = async () => {
    let tax = await API.getTaxRate();
    // console.log('tax rate : ', tax);
    let taxInfo = {
      taxName: tax.data.display_name,
      taxPercentage: tax.data.percentage,
      taxId: tax.data.id
    };
    // console.log('[tax info]', taxInfo);
    setTax(taxInfo);
  }

  return (
    <div className='flex flex-wrap'>
      <div className='md:w-1/2 px-10 pl-32 py-10' style={{background: 'rgb(242, 245, 246)'}}>
        {
          String(plans[0]?.metadata?.type).toLowerCase() === 'solo_pro' &&
          <>
            <h3 className='text-3xl font-bold'>
              Solo Pro
            </h3>
            <h3 className='text-3xl font-bold'>
              Membership
            </h3>
          </>
          }
          {
            String(plans[0]?.metadata?.type).toLowerCase() === 'solo' &&
            <h3 className='text-3xl font-bold'>
              Solo Membership
            </h3>
          }

        <div className='pt-4 sm:w-2/3'>
          <TextField
            className='w-full'
            variant='outlined'
            label="Full Name"
            defaultValue={trackContext.userDetails.first_name}
            disabled={true}
          />
        </div>
        <div className='pt-4 sm:w-2/3'>
          <TextField
            className='w-full'
            variant='outlined'
            label="Email"
            defaultValue={trackContext.userDetails.email}
            disabled={true}
          />
        </div>
        <div className='pt-4 sm:w-2/3'>
          <TextField
            className='w-full'
            variant='outlined'
            label="Password"
            defaultValue={'******'}
            disabled={true}
          />
        </div>
      </div>
      <div className='md:w-1/2 bg-black text-white p-10 h-full md:p-10 md:pr-24' style={{minHeight: '300px'}}>
        <div className='flex flex-wrap'>
          <h1 className='text-2xl font-bold pb-4 w-full'>Choose Plan</h1>
          <div className='w-full flex flex-wrap'>
          {
            plans.length ?
            <>
              {
                plans.map( plan => 
                  <div className='w-1/2 p-1' key={plan.id}>
                    <button 
                      onClick={() => setPlanChosen(plan.id)}
                      className={plan.id === planChosen 
                        ? 'w-full border border-gray-600 py-4 bg-gray-800' : 'w-full border border-gray-600 py-4'
                    }>
                      <span className='font-sans'>&#x20B9;</span>
                      {plan.unit_amount_decimal/100}
                      <span>
                      { plan.unit_amount_decimal !== undefined ?
                        `(+ ${ ((tax.taxPercentage/100) * (plan.unit_amount_decimal/100)).toFixed(2) } ${tax.taxName})`
                        : ''
                      }
                    </span>
                      {' /' + plan.recurring.interval}
                    </button>
                  </div>
                )
              }
              <div className='py-4 px-1 w-full'>
                <h2 className='text-2xl font-semibold mb-4 mt-4'>Payment Method</h2>
                <Elements stripe={stripePromise}>
                  <SubscriptionForm
                    tax={tax}
                    planChosen={planChosen} 
                    setOverlayLoader={setOverlayLoader}
                  />
                </Elements>
              </div>
            </>
            :
            <CircularProgress />
          }
          </div>
        </div>
        
      </div>
      {
      overlayLoader &&
      <div
        style={{backdropFilter: 'blur(5px)', background: 'rgba(255,255,255, 0.2)'}}
        className='flex fixed top-0 left-0 h-full w-full justify-center' >
        <div className='shadow-md w-1/3 self-center p-10 bg-white' style={{height: '140px'}}>
          <h2 className='text-ctaRed mb-4 font-semibold'>Subscribing</h2>
          <LinearProgress />
          <h2 className='text-sm text-gray-600 mt-2'>Please wait...</h2>
        </div>
      </div>
      }
    </div>
  )
}