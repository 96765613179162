import React, { useEffect, useState, useContext } from 'react';
import API from '../../services/api';
import { useLocation, useHistory } from 'react-router-dom';
import { ErrorToast, SuccessToast, WarningToast } from '../../pure-components/CustomToast';
import { toast } from 'react-toastify';
import Helper from '../../services/helper';
import { TextField } from '@material-ui/core';
import { ReactComponent as TrackAcc } from '../../assets/TrackAcc.svg';
import { ReactComponent as Minimize } from '../../assets/minimize.svg';
import { ReactComponent as Logo } from '../../assets/Logo2.svg';
import Constants from '../../services/constants';
import TrackContext from '../../contexts/track-context';
import { BouncingLoader } from "../../pure-components/Loader";
import CookieManager from '../../services/CookieManager';
import MewoService from '../../services/mewoService';

function SingleLicense() {

  const [licenseDetails, setLicenseDetails] = useState('');
  const [trackInfo, setTrackInfo] = useState('');
  const [helperText, setHelperText] = useState({})
  // const [project, setProject] = useState({})
  // const [copyrightInfo, setCopyrightInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [edited, setEdited] = useState(false);

  const ref = React.createRef();

  let location = useLocation();
  const history = useHistory();
  let trackContext = useContext(TrackContext);
  if (trackContext.userDetails.userType === 'FREE') {
    // console.log({location});
    window.location = '/projects'
  }
  let currentLocation = useLocation();
  // console.log({currentLocation})
  const user = CookieManager.getCookie("user-data")?.length ? JSON.parse(CookieManager.getCookie("user-data")) : null;
    
  useEffect(() => {
    // console.log({location});
    let locArr = location.pathname.split('/');
    //console.log({locArr},locArr.length);
    if (locArr.length === 3) {
      let projectId = locArr[2];
      (async() => {
        await API.getProjectWithLicenseData(projectId).then(async res => {
        // console.log("data : ", res);
        if (res.success) {

          let obj = res.data?.licenseData;
          obj.releaseDate = obj.releaseDate ? new Date(obj.releaseDate).toISOString().substr(0, 10) : "";
          
          obj.musicDurationInSec = obj.musicDuration;
          obj.totalDurationInSec = obj.totalDuration;
          
          obj.projectDuration = {
            hr : parseInt(parseInt(obj.totalDuration)/3600).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
            min : parseInt(parseInt(parseInt(obj.totalDuration)%3600)/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
            sec : parseInt(parseInt(parseInt(obj.totalDuration)%3600)%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
          }

          obj.musicDuration = obj.musicDuration ? `${parseInt(parseInt(obj.musicDuration)/3600).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(parseInt(parseInt(obj.musicDuration)%3600)/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(parseInt(parseInt(obj.musicDuration)%3600)%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}` : "00:00:00";
          obj.totalDuration = obj.totalDuration ? `${parseInt(parseInt(obj.totalDuration)/3600).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(parseInt(parseInt(obj.totalDuration)%3600)/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(parseInt(parseInt(obj.totalDuration)%3600)%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}` : "00:00:00";
          
          const missingDetailstrackIds = [];

          let trackInfo = res.data.projectData?.trackIds?.map(item => {
            let trackDetails = res.data.licenseData.trackDetails || []
            let info = trackDetails.filter(track => {
              return track._id === item.mewoTrackId;
            })
            if(info.length){
              item.duration = parseInt(info[0].duration);
              item.min = parseInt(parseInt(info[0].duration)/60);
              item.sec = parseInt(parseInt(info[0].duration)%60);
            }else{
              item.duration = 0;
              //adding copyrightInfo, if not present fetch and add
              //adding copyrightInfo, only for new tracks added in the project.
              if(!item.copyrightInfo?.length){
                missingDetailstrackIds.push(item.mewoTrackId)
              }
            }
            return item;
          });
          // console.log("trackInfo : ", trackInfo, missingDetailstrackIds)
          if(missingDetailstrackIds?.length > 0){
            const copyrightInfo = await getCopyrightInfo(missingDetailstrackIds);
            console.log("copyrightInfo Arr : ", copyrightInfo)
            setTrackInfo(trackInfo?.map(item => {
              if(copyrightInfo[item.mewoTrackId]?.length){
                item.copyrightInfo = copyrightInfo[item.mewoTrackId];
              }
              return item;
            }))
          }else{
            setTrackInfo(trackInfo);
          }
          // console.log("updated data : ", trackInfo)
          //calculating music duration from tracks duration used for
          if(trackInfo?.length){
            let musicDuration = 0;
            trackInfo.map(track => {
              if(track.duration){
                musicDuration += track.duration
              }
            })
            if(musicDuration > 0){
              obj.musicDurationInSec = musicDuration;
              obj.musicDuration = `${parseInt(parseInt(musicDuration)/3600)}:${parseInt(parseInt(parseInt(musicDuration)%3600)/60)}:${parseInt(parseInt(parseInt(musicDuration)%3600)%60)}`;
            }
          }
          //setting project data 
          obj.project = res.data.projectData;
          setLicenseDetails({ ...obj });
          setLoading(false);          
        } else {
          toast(({ closeToast }) => <ErrorToast message={res?.message + res?.mssg} />);
          // window.location = '/projects';
        }
        }).catch(err => {
          console.log("Error loading : ", err);
        })
      })();
      
    } else {
      //  console.log('ELSE 2');
      // window.location = '/projects';
    }
  }, []);

  const getCopyrightInfo = async (trackIds) => {
    let errMsg = "Info : No record found for Track(s) : ";
    let errorOccured = false;
    let copyrightData  = {};
    const taskArr = trackIds.map(async(trackId, index) => {
      if(!trackId?.length)
        return;

      let reqObj = {  
        url : `public/copyright/track/${trackId }`, 
        method : 'get'
      };
      
      await MewoService.loadMewoData(reqObj).then(async function(resp) {
        if(resp){
          // console.log("copyrightData : ", resp);
          copyrightData[trackId] = resp;
        }
        return;
      }).catch(function(error){
        console.log(`TrackDownloadError for Track : ${trackId}`, error);
        errorOccured = true;
        errMsg += `${trackId}, `      
        return;
      });
    });
  
    return await Promise.allSettled(taskArr).then(async results => {
      if(errorOccured)
        toast(({ closeToast }) => <WarningToast message={errMsg} />);
        return copyrightData;
    }).catch(err => {
      console.log("CopyrightInfoLoadingError : ", err)
    })
  }
  
  const createLicenseData = () => {
    let currentDate = new Date().toISOString().split('T')[0].split('-').join('');
    let timestamp = Date.now();
      
    let firstThreeChar = user.first_name.slice(0,3).toUpperCase();
    let userId = user._id.toString().toUpperCase(); 
    let lastFiveChar = userId.slice(userId.length-5, userId.length);
    let licenseNumber = `${currentDate}-${firstThreeChar}${lastFiveChar}-${timestamp}`;

  }

  const handlePDF = async (e) => {
    setLoading(true);
    let error = false;
    let errMsg = "Error/Missing field value : ";
    let newProject = licenseDetails.project;
    let temp = {...helperText}, obj = {};

    if (!newProject.title) {
      temp["title"] = "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Project Name, ";
    }
    if (!licenseDetails.category) {
      temp["category"] = "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Category, ";
    }
    if (!licenseDetails.distributionChannel) {
      temp["distributionChannel"] = "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Distribution Channel, ";
    }
    if (!licenseDetails.releaseDate) {
      temp["releaseDate"] = "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Release Date, ";
    }
    if (!licenseDetails.musicDuration || licenseDetails.musicDuration === 0) {
      temp["musicDuration"] = "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Music Duration, ";
    }
    else {
      var a = licenseDetails.musicDuration ? licenseDetails.musicDuration.split(':') : [];
      if (a.length === 0) {
        temp["musicDuration"] = "Required field, cannot be empty";
        setHelperText(temp);
        error = true;
        errMsg = errMsg + "Music Duration, ";
      }
      let hr = a[0] || 0
      let min = a[1] || 0;
      let sec = a[2] || 0;
      var seconds = parseInt(hr * 3600) + parseInt(min * 60) + parseInt(sec);
      obj.musicDuration = parseInt(seconds)
    }
    if (!licenseDetails.totalDuration || licenseDetails.totalDuration === 0 || helperText?.totalDuration?.length > 0) {
      temp["totalDuration"] = helperText?.totalDuration?.length > 0 ? helperText.totalDuration : "Required field, cannot be empty";
      setHelperText(temp);
      error = true;
      errMsg = errMsg + "Project Duration, ";
    }
    else {
      var a = licenseDetails.totalDuration ? licenseDetails.totalDuration.split(':') : [];
      if (a.length === 0) {
        temp["totalDuration"] = "Required field, cannot be empty";
        setHelperText(temp);
        error = true;
        errMsg = errMsg + "Total Duration, ";
      }
      let hr = a[0] || 0
      let min = a[1] || 0;
      let sec = a[2] || 0;
      var seconds = parseInt(hr * 3600) + parseInt(min * 60) + parseInt(sec);
      obj.totalDuration = parseInt(seconds)
    }
      let trackDetails = trackInfo.map(track => {
      let copyrightInfo = Helper.sanitizeAndCreateJSON(track.copyrightInfo);
      
      if (!track.duration || track.error) {
        error = true;
        errMsg = errMsg + `Track Duration Used for : ${copyrightInfo.DisplayTitle}, `;
      }
      return {
        _id: track.mewoTrackId,
        ...copyrightInfo,
        duration: parseInt(track.duration)
      }
    })

    if (error) {
      setLoading(false);
      toast(({ closeToast }) => <ErrorToast message={errMsg} />);
      return;
    }
    let target = document.querySelector('#license-wrapper-new').innerHTML;
    if (new Date(licenseDetails.updatedAt) <= new Date(licenseDetails.project.updatedAt) || licenseDetails.licenseUrl === "" || edited) {
      obj = { _id: licenseDetails._id, trackDetails, ...obj }
      //if(licenseDetails.project.title!==project.title || licenseDetails.project.description!==project.description )
      //{
      obj.project = licenseDetails.project;
      obj.category = licenseDetails.category;
      obj.distributionChannel = licenseDetails.distributionChannel;

      obj.releaseDate = licenseDetails.releaseDate;
      // }
      obj.action = licenseDetails.licenseUrl === "" ? "add" : "edit";
      await API.generatePdf(target, obj).then(res =>{
        //console.log(res);
        if(res?.success){
          if(res.data?.licenseUrl?.length){
            
          let newlicenseDetails = { ...licenseDetails };
          newlicenseDetails.licenseUrl = res.data?.licenseUrl;
          newlicenseDetails.updatedAt = res.data?.updatedAt;
          setLicenseDetails({ ...newlicenseDetails });
          setEdited(false);
          setLoading(false);
          toast(({ closeToast }) => <SuccessToast message="License generated successfully!" />);
          }
          else{
            toast(({ closeToast }) => <ErrorToast message="Could not able to generate License!" />);  
          }
        }else{
          toast(({ closeToast }) => <ErrorToast message={"Could not able to generate License! "+ res?.message + res?.mssg} />);
        }
      })
      .catch(res =>{
        console.log(res);
        toast(({ closeToast }) => <ErrorToast message={"Could not able to generate License! "+ res?.message + res?.mssg} />);
      });
    }
  }
  const handleBlur = (e, index) => {
    const patt = /^[0-9:]+$/;
    let duration = e.target.value;
    let newtrackInfo = [...trackInfo]
    if ((duration.match(/:/g) || []).length) {
      newtrackInfo[index].duration = duration;
    }
    else
      if (duration.length === 1) {
        newtrackInfo[index].duration = `00:0${duration}`
      }
      else if (duration.length === 2) {
        newtrackInfo[index].duration = `00:${duration}`
      }
      else {
        newtrackInfo[index].duration = `${duration.substr(0, duration.length - 2)}:${duration.substr(-2)}`
      }
    setTrackInfo([...newtrackInfo])
    calculateTotalDuration();
    setEdited(true);
  }
  const handleDurationBlur = (e, name) => {
    let duration = e.target.value;
    let temp = { ...helperText };
    let newlicenseDetails = { ...licenseDetails }
    if (duration === "") {
      newlicenseDetails[name] = "";
      if (e.target.value === "")
        temp[name] = "Required field, cannot be empty";
      else
        temp[name] = "";
    }
    else if ((duration.match(/:/g) || []).length) {
      newlicenseDetails[name] = duration;
      temp[name] = "";
    }
    else
      if (duration.length === 1) {
        newlicenseDetails[name] = `00:0${duration}`
        temp[name] = "";
      }
      else if (duration.length === 2) {
        newlicenseDetails[name] = `00:${duration}`
        temp[name] = "";
      }
      else {
        newlicenseDetails[name] = `${duration.substr(0, duration.length - 2)}:${duration.substr(-2)}`
        temp[name] = "";
      }
    
    setHelperText(temp);
    setLicenseDetails({ ...newlicenseDetails });
    setEdited(true);
  }
  const handleChange = (e, index) => {
    // const patt =/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;
    const patt = /^[0-9:]+$/
    //  console.log(e.target.value,patt.test(e.target.value),trackInfo[index])

    if (patt.test(e.target.value) || e.target.value === "") {

      if (patt.test(e.target.value)) {
        let newtrackInfo = [...trackInfo]
        newtrackInfo[index].duration = e.target.value
        newtrackInfo[index].error = false;
        setTrackInfo([...newtrackInfo])
      }
      else {
        let newtrackInfo = [...trackInfo];
        newtrackInfo[index].error = true;
        newtrackInfo[index].duration = ""
        setTrackInfo([...newtrackInfo])
      }
    }
    else {
      let newtrackInfo = [...trackInfo]
      newtrackInfo[index].duration = trackInfo[index].duration || "";
      setTrackInfo([...newtrackInfo])
    }
  }
  const handleDurationChange = (e, name) => {
    const patt = /^[0-9:]+$/;
    let newlicenseDetails = { ...licenseDetails };
    if (patt.test(e.target.value) || e.target.value === "") {
      if (patt.test(e.target.value)) {
        newlicenseDetails[name] = e.target.value;
      }
      else {
        newlicenseDetails[name] = ""
      }
    }
    setLicenseDetails({ ...newlicenseDetails });

  }
  const handleInputChange = (e, field) => {
    let newlicenseDetails = { ...licenseDetails };
    newlicenseDetails.project[field] = e.target.value
    setLicenseDetails(newlicenseDetails);
    let temp = { ...helperText };
    if (e.target.value === "")
      temp[field] = "Required field, cannot be empty";
    else
      temp[field] = "";
    setHelperText(temp);
  }
  const handleUsageInputChange = (e, field) => {
    let newlicenseDetails = { ...licenseDetails };
    newlicenseDetails[field] = e.target.value;
    setLicenseDetails({ ...newlicenseDetails });
    let temp = { ...helperText };
    if (e.target.value === "")
      temp[field] = "Required field, cannot be empty";
    else
      temp[field] = "";
    setHelperText(temp);
    setEdited(true);
  }

  const handleTrackDurationChange = (e, index, type) => {
    if(type){
      let newtrackInfo = [...trackInfo]
      
      type === 'min' ? newtrackInfo[index].min = parseInt(e.target.value) : newtrackInfo[index].sec = parseInt(e.target.value) 
      newtrackInfo[index].error = false;
      setTrackInfo([...newtrackInfo])
    }
  }

  const handleTrackDurationBlur = (index) => {
    let newTrackInfo = [...trackInfo]

    //checking for track size limit
    let trackDuration = 0;
    let copyrightInfo = Helper.sanitizeAndCreateJSON(newTrackInfo[index].copyrightInfo);
    if(copyrightInfo !== undefined && copyrightInfo !== null){    
      trackDuration = parseInt(copyrightInfo["Length"]?.trim()?.toString()?.split(".")[0] * 60) + parseInt(copyrightInfo["Length"]?.trim()?.toString()?.split(".")[1]);
    }
    // else{
    //   trackDuration = parseInt(newTrackInfo[index]?.trackId?.duration)
    // }
    let tempMusicDuration = 0;
    if(newTrackInfo[index].min){
      if(newTrackInfo[index].min > 59){
        newTrackInfo[index].error = true;
        newTrackInfo[index].errorMsg = `min should not be more than 59`;
        setTrackInfo([...newTrackInfo])
        return;
      }
      tempMusicDuration += parseInt(newTrackInfo[index].min) * 60;
    }
    if(newTrackInfo[index].sec){
      if(newTrackInfo[index].sec > 59){
        newTrackInfo[index].error = true;
        newTrackInfo[index].errorMsg = `sec should not be more than 59`;
        setTrackInfo([...newTrackInfo])
        return;
      }
      tempMusicDuration += newTrackInfo[index].sec;
    }
    if(tempMusicDuration > 0){
      if(tempMusicDuration > trackDuration){
        newTrackInfo[index].error = true;
        newTrackInfo[index].errorMsg = `used duration can't be more than track duration (i.e. ${parseInt(trackDuration/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(trackDuration%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})})`;
        setTrackInfo([...newTrackInfo])
        return;
      }
      newTrackInfo[index].duration = tempMusicDuration ;
      newTrackInfo[index].error = false;
      setTrackInfo([...newTrackInfo])
      calculateTotalDuration();
      setEdited(true);
    }
  }
  
  const handleProjectDurationBlur = (e, type) => {
    let projectDurationObj = licenseDetails.projectDuration ? licenseDetails.projectDuration : { "hr" :0, "min" : 0, "sec" : 0}
    if(type){
      // let totalDuration = licenseDetails.totalDuration;
      if(type === 'hr'){
        projectDurationObj.hr = parseInt(e.target.value);
      }else if(type === 'min'){
        projectDurationObj.min = parseInt(e.target.value);
      }else{
        projectDurationObj.sec = parseInt(e.target.value);
      }

      let temp = { ...helperText };
      
      let totalDuration = parseInt(projectDurationObj.hr * 3600) + parseInt(projectDurationObj.min * 60) + parseInt(projectDurationObj.sec) ;

      if(totalDuration < licenseDetails.musicDurationInSec){
        temp["totalDuration"] = `Project duration can't be less than music duration (i.e. ${licenseDetails.musicDuration})`;
        setHelperText(temp)  
      }else{
        temp["totalDuration"] = "";
        setHelperText(temp)
      }
      setEdited(true);
      setLicenseDetails({...licenseDetails, "projectDuration" : projectDurationObj, "totalDuration" : `${parseInt(projectDurationObj.hr).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(projectDurationObj.min).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt(projectDurationObj.sec).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`})

    }
  }
  const handleShowTrack = (index) => {
    let newtrackInfo = [...trackInfo];
    let trackobj = { ...newtrackInfo[index] };
    //   console.log(index,trackobj)

    trackobj.show = !trackobj.show;
    newtrackInfo[index] = { ...trackobj }
    setTrackInfo([...newtrackInfo])

  }

  const generateFieldsPerTrack = (track, index) => {
    let copyrightInfo = Helper.sanitizeAndCreateJSON(track.copyrightInfo);
    // console.log({copyrightInfo});
    return (
      <div className='pb-4' key={index}>
        <div className='pb-3 w-full flex'>
          <div className='w-3/6 text-sm font-semibold self-center flex cursor-pointer'
            onClick={() => {
              handleShowTrack(index);
            }}>
            <div id="trackact" >{track.show ? <Minimize className="mr-3" /> : <TrackAcc className="mr-3" />}</div>
            {copyrightInfo?.DisplayTitle}
          </div>
          <div className='w-full pl-8 pl-0 w-3/6 justify-end text-sm'>
            <div className='flex'>
              <span className="self-center text-textGray-100 track-duration">Track Duration Used<span className='projectName' style={{color: 'red'}}>*</span></span>
              
              <div className={`w-1/3 flex focus:outline-none border-0 border ml-3 p-1 ${track.error ? " border-ctaRed " : ""}`} >
              <span className='w-full'>
                <p className='text-xs w-full'>min</p> 
                <input
                  type="number"
                  className='w-full'
                  value={track.min}
                  placeholder="mm"
                  min={"0"}
                  max={"59"}
                  onChange={(e) => {handleTrackDurationChange(e, index, "min")}}
                  onBlur = {() => {handleTrackDurationBlur(index)}}
                /> 
              </span>
              <span className='px-3 mt-4'><b> : </b></span>
              <span className='w-full'>
                <p className='text-xs w-full'>sec</p> 
                <input
                  type="number"
                  className='w-full'
                  value={track.sec}
                  placeholder="ss"
                  min={"0"}
                  max={"59"}
                  onChange = {(e) => {handleTrackDurationChange(e, index, "sec")}}
                  onBlur = {() => {handleTrackDurationBlur(index)}}
                />
              </span>
              </div>
            </div>
            <div className='w-full w-3/6 justify-end'>
              {track.error && <h1 style={{color: 'red'}}>{track.errorMsg}</h1>}
            </div>
          </div>
          
        </div>
        <div className={`pl-8 ${track.show ? "" : "hidden"}`}>
          <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Authors</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Composers?.split(";").join(",")}</div>
          </div>
          {/* <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Composer</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Composers}</div>
          </div> */}
          {/* <div className='py-1 flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Arranger</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Arranger}</div>
          </div>
          <div className='py-1 flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Performers</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Performer}</div>
          </div> */}
          {/* <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>IPI</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.IPI}</div>
          </div> */}
          <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>ISRC</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.ISRC ? copyrightInfo.ISRC : "-"}</div>
          </div>
          <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>ISWC</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.ISWC ? copyrightInfo.ISWC : "-"}</div>
          </div>
          <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Publisher</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Publishers ? copyrightInfo.Publishers : "-"}</div>
          </div>
          {/* <div className='py-1 flex'>
            <div className='text-sm w-1/4 text-textGray-100'>SubPublisher</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.SubPublisher}</div>
          </div> */}
          <div className='flex'>
            <div className='text-sm w-1/4 text-textGray-100'>Labels</div>
            <div className='text-sm w-3/4 text-textGray-500'> {copyrightInfo?.Labels ? copyrightInfo.Labels : "-"}</div>
          </div>
        </div>
      </div>
    )
  }

  const generateTrackDetailsComp = (track, index) => {
    let copyrightInfo = Helper.sanitizeAndCreateJSON(track.copyrightInfo);
    // console.log({copyrightInfo});
    return (
      <div className='w-full py-1' key={index}>
        <div className="w-full  flex py-0.5">
          <div className='text-sm w-3/6 flex'><b>{copyrightInfo.DisplayTitle}</b></div>
          <div className="w-3/6 text-textGray-500 text-sm">Music Duration Used : <b>{`${parseInt(track.duration/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt((track.duration%60)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`}</b></div>
        </div>
        <div className="w-full flex py-0.5 text-textGray-500 text-sm">Authors : {copyrightInfo?.Composers ? copyrightInfo?.Composers?.split(";").join(",") : "-"}</div>
        <div className="w-full  flex py-0.5">
          <div className="w-3/6 flex text-textGray-500 text-sm">ISWC : {copyrightInfo?.ISWC ? copyrightInfo.ISWC : "-"}</div>
          <div className="w-3/6 flex text-textGray-500 text-sm">ISRC : {copyrightInfo?.ISRC ? copyrightInfo.ISRC : "-"}</div>
        </div>
        <div className="w-full  flex py-0.5">
          <div className="w-3/6 flex text-textGray-500 text-sm">Publisher : {copyrightInfo?.Publishers ? copyrightInfo.Publishers : "-"}</div>
          <div className="w-3/6 flex text-textGray-500 text-sm">Labels : {copyrightInfo?.Labels ? copyrightInfo.Labels : "-"}</div>
        </div>
      </div>
    )
  }

  const calculateTotalDuration = () => {
    if(trackInfo?.length){
      let musicDuration = 0;
      trackInfo.map(track => {
        if(track.duration){
          musicDuration += parseInt(track.duration);
        }
      })
      if(musicDuration > 0){
        let licenseData = licenseDetails;
        licenseData.musicDuration = `${parseInt(musicDuration/3600).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt((musicDuration%3600)/60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${parseInt((musicDuration%3600)%60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
        setLicenseDetails(licenseData);
      }
    }
  }

  const handleReleaseDateBlur = (e) =>{
    //conditional notes based on the existing license flow
    //if created & updated date is same, license is not creatd yet, we can put any release date
    //if created & updated date is not the same, license created once, wee can put the release date restrictions
    
    let invalidSelection = false;
    let newlicenseDetails = { ...licenseDetails };  
    let temp = {...helperText}
    if (e.target.value === ""){
      temp["releaseDate"] = "Required field, cannot be empty";
      invalidSelection = true;
    }
    else
      temp["releaseDate"] = "";

    let selectedDate= new Date(e.target.value).setHours(0,0,0,0);
    let refDate = new Date().setHours(0,0,0,0)
    let createdAt = new Date(newlicenseDetails.createdAt).setHours(0,0,0,0);
    let updatedAt = new Date(newlicenseDetails.updatedAt).setHours(0,0,0,0);
    let existingReleaseDate = new Date(newlicenseDetails.releaseDate).setHours(0,0,0,0);
    
    if(createdAt < updatedAt){
      if(selectedDate < createdAt){
        temp["releaseDate"] = `Release Date can't be prior to license created date (i.e. ${new Date(newlicenseDetails.createdAt)?.toDateString()}).`;
        invalidSelection = true;
      }
    }else{
      if(selectedDate < refDate){
        temp["releaseDate"] = "Release Date can't be past date.";
        invalidSelection = true;
      }
    }
    setHelperText(temp);
    if(!invalidSelection){
      newlicenseDetails["releaseDate"] = e.target.value;
      setLicenseDetails({ ...newlicenseDetails });
      setEdited(true);
    }
  }

  const checkReleaseDateFieldStatus = () =>{
    let disabled = false
    // console.log(licenseDetails)
    if(licenseDetails?.createdAt && licenseDetails?.updatedAt && licenseDetails?.releaseDate){
      let createdDate= new Date(licenseDetails.createdAt).setHours(0,0,0,0);
      let updatedDate= new Date(licenseDetails.updatedAt).setHours(0,0,0,0);
      let releaseDate= new Date(licenseDetails.releaseDate).setHours(0,0,0,0);
      let today = new Date().setHours(0,0,0,0);
      // console.log(updatedDate , createdDate, releaseDate, updatedDate > createdDate, today > releaseDate)
      if(updatedDate > createdDate && today > releaseDate )
        disabled = true;
    } 
    return disabled;
  }
  return (
    <>
      {
        loading ?
          <div className='w-3/4 pt-10'>
            <BouncingLoader />
          </div>
          :
          <>
          <div id='license-wrapper' className='licensewrapper border-blue-100 border-solid w-3/4 pt-10'>
            {/* <h2 className='font-bold  w-full'
          style={{fontSize:"28px"}}
          >License Generation</h2> */}

            <div className='flex w-full pt-6 self-center'>
              {/* <div className='logo-wrapper self-center w-12 h-12'>
              <img className='w-full' src={require('../../assets/Logo1.svg')} />
            </div> */}
              <Logo className="self-center" />
              <h4 id="licTitle" className='ml-4 text-base self-center font-semibold uppercase w-full'
                style={{ fontSize: "20px" }}
              >Non-Exclusive Music Synchronization License</h4>

              <div id="downloadPDF" className='flex my-6 w-2/6'>
                <div className="w-2/6"></div>
                {
                  ((new Date(licenseDetails?.updatedAt) < new Date(licenseDetails?.project?.updatedAt)) || licenseDetails.licenseUrl === "" || edited)
                  &&
                  <button className={'w-3/6 greenbuttonwide'}
                    // style={{width:"12%"}}
                    onClick={handlePDF}
                  >           Save
                  </button>
                }
                {
                  licenseDetails?.licenseUrl !== '' &&
                  <div className={'w-3/6 greenbuttonwide'}>

                    <a
                      rel='noopener'
                      href='#'
                      onClick={(e) => { e.preventDefault(); API.downloadLicenseUrl(licenseDetails.licenseUrl) }}
                    >
                      Download
                    </a>
                  </div>
                }
                <div className="w-1/6"></div>
              </div>
            </div>
            <div id="downloadPDF" className='hidden flex my-6 w-4/6'>
              <div className="w-2/6"></div>
              {
                ((new Date(licenseDetails?.updatedAt) < new Date(licenseDetails?.project?.updatedAt)) || licenseDetails.licenseUrl === "" || edited)
                &&
                <div className={`w-3/6 mr-2 text-center p-2 text-base cursor-pointer  bg-black text-white font-medium`}
                  // style={{width:"12%"}}
                  onClick={handlePDF}
                >           Save

                </div>
              }
              {
                licenseDetails?.licenseUrl !== '' &&
                <div className={`w-3/6 mr-2 text-center p-2 text-base cursor-pointer  bg-black text-white font-medium`}>

                  <a
                    rel='noopener'
                    href='#'
                    onClick={(e) => { e.preventDefault(); API.downloadLicenseUrl(licenseDetails.licenseUrl) }}
                  >
                    Download
                  </a>
                </div>
              }
              <div className="w-1/6"></div>

            </div>
            <div id='license-wrapper1' className='bg-white rounded mt-8 w-9/12' ref={ref}>
              <div className="w-full  flex">
                <div className='text-sm w-2/6 pt-2'>License Number</div>
                <div className="w-4/6 text-textGray-500 text-sm py-2 ">{licenseDetails.licenseNumber}</div>
              </div>
              <div className="w-full  flex">
                <div className='text-sm  w-2/6 pt-2'>License Date</div>
                <div className="w-3/6 text-textGray-500 text-sm py-2"> {licenseDetails?.updatedAt ? new Date(licenseDetails?.updatedAt).toDateString() : ""} </div>
              </div>
              <div className="w-full  flex">
                <div className='text-sm  w-2/6 pt-2'>Licensor</div>
                <div className="w-4/6 py-2">
                  <div className="text-textGray-500 text-sm "> {licenseDetails.licensor} </div>
                  <div className="text-textGray-100 text-sm pt-2 "> Owner of 100% of the Literary &amp; Musical Rights and 100% of the Phonographic rights. </div>
                </div>
              </div>
              <div className="w-full pt-2 flex">
                <div className='text-sm w-2/6 pt-2'>Licensee</div>
                <div className="w-4/6 py-2">
                  <div className="text-textGray-500 text-sm "> {licenseDetails?.licensee?.first_name} </div>
                  <div className="text-textGray-500 pt-2 text-sm  "> {licenseDetails?.licensee?.companyAddress} </div>
                  <div className="text-textGray-500 pt-2 text-sm"> {licenseDetails?.licensee?.taxNumber} </div>

                </div>
              </div>
              <div className='w-full font-semibold text-base '>List of Compositions</div>
              {/* <div class="w-full projectName  flex">
                <div className='text-sm  w-2/6 self-center'>Project Name</div>
                <TextField variant="outlined"
                  onChange={(e) => handleInputChange(e, 'title')}
                  error={helperText.title !== undefined && helperText.title !== '' ? true : false}
                  type="text"
                  name="title"
                  placeholder='Name'
                  className="w-4/6 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.project?.title}
                  helperText={helperText.title}
                />
              </div>
              <div class="w-full  projectName flex pt-2">
                <div className='text-sm w-2/6 pt-2'>Project Description</div>
                <TextField variant="outlined"
                  onChange={(e) => handleInputChange(e, 'description')}
                  error={helperText.description !== undefined && helperText.description !== '' ? true : false}
                  type="text"
                  rows={3} multiline
                  name="Description"
                  placeholder='Description'
                  className="w-4/6 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.project?.description}
                  helperText={helperText.description}
                />
              </div>
              <div class="w-full  flex hidden">
                <div className='text-sm  w-2/6 pt-2'>Project Name</div>
                <div className="w-4/6 text-textGray-500 text-sm py-2 ">{licenseDetails?.project?.title}</div>
              </div>
              <div class="w-full  flex hidden">
                <div className='text-sm w-2/6 pt-2'>Project Description</div>
                <div className="w-4/6 text-textGray-500 text-sm py-2 ">{licenseDetails?.project?.description}</div>
              </div> */}
              <div className='w-full  flex pt-8   pb-2'>
                <div className="w-full py-2">
                  {
                    //licenseDetails.project?.trackIds
                    trackInfo && trackInfo.map((track, index) =>
                      generateFieldsPerTrack(track, index)
                    )
                  }
                </div>
              </div>


            </div>
            <div className='w-12/12  pt-2 border-t border-textGray-300'></div>
            <div className='w-10/12 ' >
              {/* <div class="w-full  flex">
                <div className='text-sm w-4/12 pt-2'>Licensed Usage</div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails.licensedUsage}</div>
              </div> */}
              <div className='w-full font-semibold text-base pb-8  '>Licensed Usage</div>
              <div className="w-full projectName  flex">
                <div className='text-sm  w-4/12 self-center'>Project Name </div>
                <TextField variant="outlined"
                  error={helperText.title !== undefined && helperText.title !== '' ? true : false}
                  type="text"
                  name="title"
                  placeholder='Name'
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.project?.title}
                  helperText={helperText.title}
                  disabled = {true}
                />
              </div>
              <div className="w-full  projectName flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Project Description </div>
                <TextField variant="outlined"
                  error={helperText.description !== undefined && helperText.description !== '' ? true : false}
                  type="text"
                  rows={1} multiline
                  name="Description"
                  placeholder='Description'
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.project?.description}
                  helperText={helperText.description}
                  disabled = {true}
                />
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm  w-4/12 pt-2'>Project Name </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.project?.title}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm w-4/12 pt-2'>Project Description </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.project?.description}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm  w-4/12 pt-2'>Category </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.category}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm  w-4/12 pt-2'>Music duration </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.musicDuration}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm w-4/12 pt-2'>Project duration </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.totalDuration}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm w-4/12 pt-2'>Release date </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.releaseDate}</div>
              </div>
              <div className="w-full  flex hidden">
                <div className='text-sm w-2/6 pt-2'>Distribution channel </div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails?.distributionChannel}</div>
              </div>
              <div className="w-full projectName  flex pt-2">
                <div className='text-sm  w-4/12 self-center'>Category <span style={{color: 'red'}}>*</span></div>
                <TextField variant="outlined"
                  onChange={(e) => handleUsageInputChange(e, 'category')}
                  error={helperText.category !== undefined && helperText.category !== '' ? true : false}
                  type="text"
                  name="category"
                  placeholder='Personal project, Social media, Corporate video, website'
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.category}
                  helperText={helperText.category}
                />
              </div>

              <div className="w-full projectName  flex pt-2">
                <div className='text-sm  w-4/12 pt-2 self-center'>Music duration</div>
                <TextField variant="outlined"
                  error={helperText.musicDuration !== undefined && helperText.musicDuration !== '' ? true : false}
                  type="text"
                  name="musicDuration"
                  placeholder="hh:mm:ss"
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.musicDuration}
                  helperText={helperText.musicDuration}
                  disabled = {true}
                />
              </div>
              <div className="w-full  projectName flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Project duration <span style={{color: 'red'}}>*</span></div>
                
                {/* <TextField variant="outlined"
                  error={helperText.totalDuration !== undefined && helperText.totalDuration !== '' ? true : false}
                  type="text"
                  name="totalDuration"
                  placeholder="mm:ss"
                  onChange={(e) => { handleDurationChange(e, "totalDuration") }}
                  onBlur={(e) => { handleDurationBlur(e, "totalDuration") }}
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.totalDuration}
                  helperText={helperText.totalDuration}
                /> */}

                <div className={`w-9/12 flex focus:outline-none border-0 border p-1 pl-5 ${helperText.totalDuration ? " border-ctaRed " : ""}`} 
                  style={{"borderWidth" : "2px", "borderColor" : "#e30185","borderRadius" : "25px"}}>
                  <span className='w-1/12'>
                    <p className='text-xs w-full'>hr</p> 
                    <input
                      type="number"
                      className='w-full'
                      value={licenseDetails?.projectDuration?.hr}
                      placeholder="00"
                      min={"0"}
                      max={"59"}
                      onChange={(e) => handleProjectDurationBlur(e, "hr")}
                      onBlur={(e) => handleProjectDurationBlur(e, "hr")}
                    /> 
                  </span>
                  <span className='px-3 mt-4'><b> : </b></span>
                  <span className='w-1/12'>
                    <p className='text-xs w-full'>min</p> 
                    <input
                      type="number"
                      className='w-full'
                      value={licenseDetails?.projectDuration?.min}
                      placeholder="00"
                      min={"0"}
                      max={"59"}
                      onChange={(e) => handleProjectDurationBlur(e, "min")}
                      onBlur={(e) => handleProjectDurationBlur(e, "min")}
                    /> 
                  </span>
                  <span className='px-3 mt-4'><b> : </b></span>
                  <span className='w-1/12'>
                    <p className='text-xs w-full'>sec</p> 
                    <input
                      type="number"
                      className='w-full'
                      value={licenseDetails?.projectDuration?.sec}
                      placeholder="00"
                      min={"0"}
                      max={"59"}
                      onChange={(e) => handleProjectDurationBlur(e, "sec")}
                      onBlur={(e) => handleProjectDurationBlur(e, "sec")}
                    />
                  </span>
                </div>
              </div>
              {helperText?.totalDuration && <span className='w-full flex text-xs justify-end text-red-500'>{helperText.totalDuration}</span>}

              <div className="w-full  projectName flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Release date <span style={{color: 'red'}}>*</span></div>
                <TextField variant="outlined"
                  onChange={(e) => handleReleaseDateBlur(e)}
                  //onBlur={(e) => handleReleaseDateBlur(e)}
                  error={helperText.releaseDate !== undefined && helperText.releaseDate !== '' ? true : false}
                  type="date"
                  name="releaseDate"
                  placeholder='Release date'
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.releaseDate}
                  helperText={helperText.releaseDate}
                  disabled = {checkReleaseDateFieldStatus() && !edited ? true : false}
                />
              </div>
              <div className="w-full  projectName flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Distribution channel <span style={{color: 'red'}}>*</span> </div>
                <TextField variant="outlined"
                  onChange={(e) => handleUsageInputChange(e, 'distributionChannel')}
                  error={helperText.distributionChannel !== undefined && helperText.distributionChannel !== '' ? true : false}
                  type="text"
                  name="distributionChannel"
                  placeholder='Youtube, Facebook, etc.'
                  className="w-9/12 p-10 self-center outline-none border border-textGray-300 text-textGray-500"
                  value={licenseDetails?.distributionChannel}
                  helperText={helperText.distributionChannel}
                />
              </div>
              <div className="w-full  flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Territory</div>
                <div className="w-9/12 text-textGray-500 text-sm py-2 ">{licenseDetails.territory}</div>
              </div>
              <div className="w-full  flex pt-2">
                <div className='text-sm w-4/12 pt-2'>Period</div>
                <div className="w-9/12 py-2 ">
                  <div className="text-textGray-500 text-sm ">{licenseDetails.period} in the project</div>
                  <div className="text-textGray-100 text-sm pt-10"> The rights are granted on a non-exclusive and non-transferable basis. The Licensor warrants that the
                    exercise by the Licensee of these rights assigned does not infringe the rights of any
                    third party for the duration of the License.The Licensee agrees that this License will be void if the Project violates the law or the information provided is erroneous. This License shall be construed by Indian law, and the parties submit to the exclusive jurisdiction of the courts in Mumbai.</div>
                </div>
              </div>
            </div>
          </div>

          <div id='license-wrapper-new' className='licensewrapper border-blue-100 border-solid hidden'>
            <div className='flex w-full pt-6'>
              <Logo className='w-1/6'/>
              <h6 id="licTitle" className='ml-4 text-base font-semibold uppercase w-5/6'
                style={{ fontSize: "12px" }}
              >MUSIC SYNCHRONIZATION LICENSE</h6>
            </div>
            
            <div id='license-wrapper1' className='bg-white rounded mt-4'>
              <div className="w-full  flex" style={{ fontSize: "10px" }}><h6>PRINCIPAL TERMS</h6></div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm w-2/6'>License Number</div>
                <div className="w-4/6 text-textGray-500 text-sm">{licenseDetails.licenseNumber}</div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>License Date</div>
                <div className="w-3/6 text-textGray-500 text-sm"> {licenseDetails?.updatedAt ? new Date(licenseDetails?.updatedAt).toDateString() : ""} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Licensor</div>
                <div className="w-4/6">
                  <div className="text-textGray-500 text-sm "> {licenseDetails.licensor} </div>
                  <div className="text-textGray-100 text-sm pt-0.5 "> Owner of 100% of the Literary &amp; Musical Rights and 100% of the Phonographic rights. </div>
                </div>
              </div>
              <div className="w-full flex py-0.5">
                <div className='text-sm w-2/6'>Licensee</div>
                <div className="w-4/6">
                  <div className="text-textGray-500 text-sm "> {licenseDetails?.licensee?.first_name} </div>
                  <div className="text-textGray-500 text-sm  "> {licenseDetails?.licensee?.companyAddress} </div>
                  <div className="text-textGray-500 text-sm"> {licenseDetails?.licensee?.taxNumber} </div>
                </div>
              </div>
            </div>
            <div id='license-wrapper1' className='bg-white rounded mt-2'>
              <div className="w-full  flex" style={{ fontSize: "10px" }}><h4>LICENSED USAGE</h4></div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm w-2/6'>Name of Production</div>
                <div className="w-4/6 text-textGray-500 text-sm">{licenseDetails?.project?.title}</div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Description of Production</div>
                <div className="w-4/6 text-textGray-500 text-sm"> {licenseDetails?.project?.description ? licenseDetails?.project?.description : "-"} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Distribution Channels</div>
                <div className="w-4/6 text-textGray-500 text-sm "> {licenseDetails.distributionChannel} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Total Project Duration</div>
                <div className="w-4/6 text-textGray-500 text-sm "> {licenseDetails.totalDuration} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Total Music Used</div>
                <div className="w-4/6 text-textGray-500 text-sm "> {licenseDetails.musicDuration} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Release Date</div>
                <div className="w-4/6 text-textGray-500 text-sm "> {licenseDetails.releaseDate} </div>
              </div>
              <div className="w-full  flex py-0.5">
                <div className='text-sm  w-2/6'>Licensed Rights</div>
                <div className="w-4/6 text-textGray-500 text-sm "> The right to synchronize the Works with this Production; upload and commercially distribute this Production worldwide and perpetually on the Channel(s). </div>
              </div>
              <div className="text-textGray-100 text-sm pt-0.5 ">These Principal Terms are subject to the Standard Terms below and are governed by them unless changed by both the Licensor and the Licensee in writing.</div>
            </div>
            <div id='license-wrapper1' className='bg-white rounded mt-2'>
              <div className="w-full  flex" style={{ fontSize: "10px" }}><h4>LIST OF LICENSED WORK(S)</h4></div>
              <div className='w-full'>
                  {
                    trackInfo && trackInfo.map((track, index) =>
                      generateTrackDetailsComp(track, index)
                    )
                  }
              </div>
            </div>
            <div id='license-wrapper1' className='bg-white rounded mt-2'>
              <div className="w-full  flex" style={{ fontSize: "10px" }}><h4>STANDARD TERMS</h4></div>
              <ul style={{ listStyleType: 'decimal', paddingLeft : '16px'}}>
                <li>We guarantee that the Licensed Music does not infringe on any third-party’s intellectual property rights.</li>
                <li>In exchange for your Subscription Fee, we grant you this License on a non-exclusive basis.</li>
                <li> You are granted the right to use the Licensed Music in this Production and monetize your Production.</li>
                <li> You are responsible for generating a license for your Production on our website. If you comply with this License, you are granted the right, worldwide and perpetually, to monetize and commercially exploit your Production. Suppose you do not generate a license for your Production. In that case, we cannot distinguish between a licensed and unlicensed use and thus reserve the right to monetize any unlicensed use of our Music on YouTube/or other channels.</li>
                <li> Please keep in mind that YouTube and other distribution channels do not keep accurate and up-to-date copyright information. If you ever receive a copyright claim from them for our licensed music, please send this License to the claimant and mark us in copy (support@syncmama.com); we will remove the claim without delay. You can also use the YouTube Claim Release functionality in your Project page.</li>
                <li> This License does not transfer ownership of our Music to you.</li>
                <li> This License does not allow you to create derivative works from the Licensed Music unless authorized by us in writing.</li>
                <li> This License does not include the right to collect the public performance and the mechanical royalties of the Licensed Music; these are subject to the rights of the relevant Copyright Societies having jurisdiction in any part of the Territory.</li>
                <li> You accept that this License will be void if you, or the licensed Production, violate applicable legislation, if the information given is incorrect, or if you breach any of our terms and conditions.</li>
                <li> This License is governed by Indian law, and Mumbai courts have exclusive jurisdiction.</li>
              </ul>
            </div>
          </div>
        </>
      }
    </>
  )
}

export default SingleLicense;